let initialState = {
  locationData: [],
  ownedLocationData: [],
  leasedLocationData: [],
  dispositionTabData: [],
  tabValue: 0,
}

export function LocationReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case 'SAVE_LOCATION_DATA':
      return Object.assign({}, state, {
        locationData: payload,
      })

    case 'SAVE_OWNED_LOCATION_DATA':
      return Object.assign({}, state, {
        ownedLocationData: payload,
      })

    case 'SAVE_LEASED_LOCATION_DATA':
      return Object.assign({}, state, {
        leasedLocationData: payload,
      })

    case 'SAVE_DISPOSITION_DATA':
      return Object.assign({}, state, {
        dispositionTabData: payload,
      })

    case 'SET_TAB_VALUE':
      return Object.assign({}, state, {
        tabValue: payload,
      })
    default:
      return state
  }
}
