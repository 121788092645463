import { combineReducers } from 'redux'
import { SearchReducer } from './searchReducer'
import { IndexReducer } from './indexReducer'
import { CommonReducer } from './commonReducer'
import { WorkflowReducer } from './workflowReducer'
import { UploadReducer } from './uploadReducer'
import { LocationReducer } from './locationReducer'
import { BulkEditReducer } from './bulkEditReducer'

const appReducer = combineReducers({
  search: SearchReducer,
  index: IndexReducer,
  common: CommonReducer,
  workflow: WorkflowReducer,
  upload: UploadReducer,
  location: LocationReducer,
  bulkEdit: BulkEditReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
