import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useToasts } from 'react-toast-notifications'
import './AddLocationDataStyle.css'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import { validateDate } from '../common/validation'
import { useAuth } from '@praxis/component-auth'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

export default function FormDialog({ locId, handleClosed, isEdit }) {
  const auth = useAuth()
  const { session } = auth
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const { addToast } = useToasts()
  const [expLeaseDateError, setExpLeaseDateError] = useState(false)
  const [missingDateError, setMissingDateError] = useState(false)
  const [recreatedDateError, setRecreatedDateError] = useState(false)

  const [obj, setObj] = useState({
    id: '',
    locationNumber: '',
    fileLetter: '',
    fileLetterDesc: '',
    fileType: '',
    tenantName: '',
    locationSuite: '',
    soldTo: '',
    acreage: '',
    expirationDate: '',
    total_files: 0,
    checked_out_files: '',
    checkout_by: '',
    checkout_date: '',
    checkin_date: '',
    status: '',
    missing_date: '',
    recreated_date: '',
    notes: '',
  })
  useEffect(() => {
    if (locId !== undefined && isEdit === true) {
      getFileLetterDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locId, isEdit])

  const getFileLetterDetails = () => {
    let url = apiConfig.apiUrl.getfileletterbyid + `${locId}`

    axios
      .get(url)
      .then(response => {
        setObj({
          id: response.data.id,
          locationNumber: response.data.location_number,
          fileLetter: response.data.file_letter,
          fileLetterDesc: response.data.file_letter_desc,
          fileType: response.data.file_type,
          tenantName: response.data.tenant_name,
          locationSuite: response.data.location_suite,
          expirationDate: response.data.expiration_date,
          soldTo: response.data.sold_to,
          acreage: response.data.acreage,
          createdBy: response.data.created_by,
          createdDate: response.data.created_date,
          total_files: response.data.total_files,
          status: response.data.status,
          missing_date: response.data.missing_date,
          recreated_date: response.data.recreated_date,
          notes: response.data.notes,
        })

        setOpen(true)
      })
      .catch(err => console.log(err))
  }

  const updateFileLetterSubmit = event => {
    let payload = {}
    let locid = obj.id
    payload['file_letter'] = obj.fileLetter
    payload['file_letter_desc'] = obj.fileLetterDesc
    payload['file_type'] = obj.fileType
    payload['tenant_name'] = obj.tenantName
    payload['location_suite'] = obj.locationSuite
    payload['sold_to'] = obj.soldTo
    payload['acreage'] = obj.acreage
    payload['expiration_date'] = obj.expirationDate
    payload['created_by'] = obj.createdBy
    payload['modified_by'] = session.userInfo.fullName
    payload['created_date'] = obj.createdDate
    payload['total_files'] = obj.total_files
    payload['status'] = obj.status
    payload['missing_date'] = obj.missing_date
    payload['recreated_date'] = obj.recreated_date
    payload['notes'] = obj.notes

    let url = apiConfig.apiUrl.updatefileletter + `/${locid}`

    axios
      .put(url, payload)
      .then(res => {
        addToast('fileletter saved successfully', {
          appearance: 'success',
          autoDismiss: true,
        })

        handleClose()
      })
      .catch(err => {
        addToast('Error!fileletter could not be saved.', {
          appearance: 'error',
          autoDismiss: true,
        })

        handleClose()
      })
  }

  const handleClose = () => {
    setOpen(false)
    setObj('')
    handleClosed(false)
  }

  const handleSaveEdit = e => {
    e.preventDefault()
    if (isEdit) {
      updateFileLetterSubmit()
    }
  }

  return (
    <div style={{ marginLeft: '90%' }}>
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick="true"
        aria-labelledby="form-dialog-title"
      >
        <form validate autoComplete="off" onSubmit={handleSaveEdit}>
          <DialogTitle id="form-dialog-title">
            Edit File Letter and File Letter Description
          </DialogTitle>
          <DialogContent>
            <div className="metadata-container">
              <div className="metadata-textfield">
                <TextField
                  required
                  disabled
                  id="outlined-search"
                  label={'Location Number'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.locationNumber}
                  onChange={e =>
                    setObj({ ...obj, locationNumber: e.target.value })
                  }
                />
                <br />
                <TextField
                  required
                  disabled
                  id="outlined-search"
                  label={'File Letter'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.fileLetter}
                  onChange={e => setObj({ ...obj, fileLetter: e.target.value })}
                />{' '}
                <br />
                <TextField
                  required
                  id="outlined-search"
                  label={'File Letter Description'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.fileLetterDesc}
                  onChange={e =>
                    setObj({ ...obj, fileLetterDesc: e.target.value })
                  }
                />
                <br />
                <FormControl className={classes.selects}>
                  <InputLabel shrink htmlFor="filetype">
                    <b>File Type</b>
                  </InputLabel>
                  <NativeSelect
                    // disabled
                    id="filetype"
                    value={obj.fileType}
                    onChange={e =>
                      setObj({
                        ...obj,
                        fileType: e.target.value,
                      })
                    }
                    input={<BootstrapInput />}
                    // required
                  >
                    <option value={''}></option>
                    <option value={'Parent'} disabled>
                      Parent
                    </option>
                    <option value={'Correspondence'} disabled>
                      Correspondence
                    </option>
                    <option value={'Tenant'}>Tenant</option>
                    <option value={'Excess Land'}>Excess Land</option>
                  </NativeSelect>
                </FormControl>
                <br />
                {obj.fileType === 'Tenant'
                  ? ((obj.soldTo = ''),
                    (obj.acreage = ''),
                    (
                      <div>
                        <TextField
                          id="outlined-search"
                          label={'Tenant Name'}
                          type={String}
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
                          value={obj.tenantName}
                          onChange={e =>
                            setObj({ ...obj, tenantName: e.target.value })
                          }
                        />
                        <br />
                        <TextField
                          id="outlined-search"
                          label={'Location Suite'}
                          type={String}
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
                          value={obj.locationSuite}
                          onChange={e =>
                            setObj({ ...obj, locationSuite: e.target.value })
                          }
                        />{' '}
                        <br />
                        <FormControl>
                          <TextField
                            id="fileletter-leaseexpirationdate"
                            label={'Lease Expiration Date'}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type={String}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={obj.expirationDate || undefined}
                            onChange={event => {
                              let valid = validateDate(event.target.value)
                              setExpLeaseDateError(valid)

                              setObj({
                                ...obj,
                                expirationDate: event.target.value,
                              })
                            }}
                            placeholder="mm/dd/yyyy"
                            error={expLeaseDateError}
                            helperText={
                              expLeaseDateError
                                ? 'Enter in correct format.For e.g. 09/23/2020'
                                : ''
                            }
                          />
                        </FormControl>
                      </div>
                    ))
                  : obj.fileType === 'Excess Land'
                  ? ((obj.tenantName = ''),
                    (obj.locationSuite = ''),
                    (obj.expirationDate = ''),
                    (
                      <div>
                        <TextField
                          id="outlined-search"
                          label={'Sold To'}
                          type={String}
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
                          value={obj.soldTo}
                          onChange={e =>
                            setObj({ ...obj, soldTo: e.target.value })
                          }
                        />
                        <br />
                        <TextField
                          id="outlined-search"
                          label={'How Much Acreage?'}
                          type={String}
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
                          value={obj.acreage}
                          onChange={e =>
                            setObj({ ...obj, acreage: e.target.value })
                          }
                        />{' '}
                        <br />
                      </div>
                    ))
                  : ''}
                <TextField
                  id="outlined-search"
                  label={'Total Files'}
                  type="number"
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.total_files}
                  onChange={e =>
                    setObj({ ...obj, total_files: e.target.value })
                  }
                />
                <br />
                <TextField
                  id="notes"
                  label={'Notes'}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.notes}
                  onChange={e => setObj({ ...obj, notes: e.target.value })}
                />
                <br />
                <FormControl className={classes.selects}>
                  <InputLabel shrink htmlFor="status">
                    <b>Status</b>
                  </InputLabel>
                  <NativeSelect
                    // disabled
                    id="status"
                    value={obj.status}
                    onChange={e =>
                      setObj({
                        ...obj,
                        status: e.target.value,
                      })
                    }
                    input={<BootstrapInput />}
                    // required
                  >
                    <option value={''}></option>
                    <option value={'Missing'}>Missing</option>
                    <option value={'Recreated'}>Recreated</option>
                  </NativeSelect>
                </FormControl>
                {/* <br /> */}
                {obj.status === 'Missing' && (
                  // <FormControl>
                  <TextField
                    id="fileletter-missing-date"
                    label={'Missing Date'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type={String}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    value={obj.missing_date || undefined}
                    onChange={event => {
                      let valid = validateDate(event.target.value)
                      setMissingDateError(valid)

                      setObj({
                        ...obj,
                        missing_date: event.target.value,
                      })
                    }}
                    placeholder="mm/dd/yyyy"
                    error={missingDateError}
                    helperText={
                      missingDateError
                        ? 'Enter in correct format.For e.g. 09/23/2020'
                        : ''
                    }
                  />
                  // {/* </FormControl> */}
                )}
                {obj.status === 'Recreated' && (
                  // <FormControl>
                  <TextField
                    id="fileletter-recreated-date"
                    label={'Recreated Date'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type={String}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    value={obj.recreated_date || undefined}
                    onChange={event => {
                      let valid = validateDate(event.target.value)
                      setRecreatedDateError(valid)

                      setObj({
                        ...obj,
                        recreated_date: event.target.value,
                      })
                    }}
                    placeholder="mm/dd/yyyy"
                    error={recreatedDateError}
                    helperText={
                      recreatedDateError
                        ? 'Enter in correct format.For e.g. 09/23/2020'
                        : ''
                    }
                  />
                  // </FormControl>
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={recreatedDateError || missingDateError}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
