import apiConfig from '../../config/apiConfig'
import moment from 'moment'

export const returnMetadataSearchFields = response => {
  let creationDate = response.createdDate
    ? moment(response.createdDate).format('MM/DD/YYYY')
    : response.createdTimestamp
    ? moment(response.createdTimestamp).format('MM/DD/YYYY')
    : ''
  if (response.contentType === apiConfig.apiUrl.REALESTATE) {
    return {
      ContentType: response.contentType,
      DocumentGroup: response.documentGroup,
      DocumentType: response.documentType,
      LocationNumber: response.locationNumber,
      LocationName: response.locationName,
      LocationState: response.locationState,
      LocationCity: response.locationCity,
      LocationRegion: response.locationRegion,
      DocumentTitle: response.documentTitle,
      PhysicalFileNumber: response.physicalFileNumber,
      BusinessPartnerFollowUp: response.businessFollowUpName,
      LegalFollowUp: response.legalFollowUpName,
      DocumentPurpose: response.documentPurpose,
      FileLetter: response.fileLetter,
      FileLetterDescription: response.fileLetterDescription,
      IsUrgent: response.isUrgent,
      DocumentStatus: response.documentStatus,
      RecordingStatus: response.recordingStatus,
      DocumentNotes: response.documentNotes,
      SAPContractNumber: response.sapContractNumber,
      Codes: response.codes,
      ExpirationDate: response.expirationDate,
      DocumentDate:
        response.documentDate === ''
          ? ''
          : moment(response.documentDate).format('MM/DD/YYYY'),
      CreatedDate: creationDate,
      CreatedBy: response.createdUserName,
      ModifiedDate: response.modifiedDate,
      ModifiedBy: response.modifiedUserName,
      FileName: response.fileName,
      LocationId: response.id || response.metadataId,
      tossFilePath: response.destTossFilePath,
      FileUrl: response.fileUrl,
    }
  }
  if (response.contentType === apiConfig.apiUrl.PACAMPAYABLEBUDGET) {
    return {
      ContentType: response.contentType,
      DocumentGroup: response.documentGroup,
      DocumentType: response.documentType,
      LocationNumber: response.locationNumber,
      LocationName: response.locationName,
      LocationState: response.locationState,
      LocationCity: response.locationCity,
      LocationRegion: response.locationRegion,
      DocumentTitle: response.documentTitle,
      FiscalYear: response.fiscalYear,
      ExpiryYear: response.expiryYear,
      SAPContractNumber: response.sapContractNumber,
      Operator: response.operator,
      // TargetprsTotalRecAmount: response.targetPrsTotalRecAmount,
      TargetPrsBudgetAmount: response.targetPrsBudgetAmount,
      ProRataShare: response.proRataShare,
      CreatedDate: creationDate,
      CreatedBy: response.createdUserName,
      ModifiedDate: response.modifiedDate,
      ModifiedBy: response.modifiedUserName,
      FileName: response.fileName,
      LocationId: response.id || response.metadataId,
      tossFilePath: response.destTossFilePath,
      FileUrl: response.fileUrl,
    }
  }
  if (response.contentType === apiConfig.apiUrl.PACAMPAYABLERECONCILIATION) {
    return {
      ContentType: response.contentType,
      DocumentGroup: response.documentGroup,
      DocumentType: response.documentType,
      LocationNumber: response.locationNumber,
      LocationName: response.locationName,
      LocationState: response.locationState,
      LocationCity: response.locationCity,
      LocationRegion: response.locationRegion,
      DocumentTitle: response.documentTitle,
      FiscalYear: response.fiscalYear,
      ExpiryYear: response.expiryYear,
      SAPContractNumber: response.sapContractNumber,
      Operator: response.operator,
      // TargetPRSTotalBudgetAmount: response.targetPRSTotalBudgetAmount,
      TargetPrsTotalRecAmount: response.targetPrsTotalRecAmount,
      ProRataShare: response.proRataShare,
      CreatedDate: creationDate,
      CreatedBy: response.createdUserName,
      ModifiedDate: response.modifiedDate,
      ModifiedBy: response.modifiedUserName,
      FileName: response.fileName,
      LocationId: response.id || response.metadataId,
      tossFilePath: response.destTossFilePath,
      FileUrl: response.fileUrl,
    }
  }
  if (response.contentType === apiConfig.apiUrl.PACAMPAYABLEUNEXPECTED) {
    return {
      ContentType: response.contentType,
      DocumentGroup: response.documentGroup,
      DocumentType: response.documentType,
      LocationNumber: response.locationNumber,
      LocationName: response.locationName,
      LocationState: response.locationState,
      LocationCity: response.locationCity,
      LocationRegion: response.locationRegion,
      DocumentTitle: response.documentTitle,
      FiscalYear: response.fiscalYear,
      ExpiryYear: response.expiryYear,
      SAPContractNumber: response.sapContractNumber,
      TotalDollarImpact: response.totalDollarImpact,
      CreatedDate: creationDate,
      CreatedBy: response.createdUserName,
      ModifiedDate: response.modifiedDate,
      ModifiedBy: response.modifiedUserName,
      FileName: response.fileName,
      LocationId: response.id || response.metadataId,
      tossFilePath: response.destTossFilePath,
      FileUrl: response.fileUrl,
    }
  }

  if (response.contentType === apiConfig.apiUrl.PACAMREBILLBUDGET) {
    return {
      ContentType: response.contentType,
      DocumentGroup: response.documentGroup,
      DocumentType: response.documentType,
      LocationNumber: response.locationNumber,
      LocationName: response.locationName,
      LocationState: response.locationState,
      LocationCity: response.locationCity,
      LocationRegion: response.locationRegion,
      DocumentTitle: response.documentTitle,
      FiscalYear: response.fiscalYear,
      ExpiryYear: response.expiryYear,
      SAPContractNumber: response.sapContractNumber,
      SAPCustomerNumber: response.sapCustomerNumber,
      TotalCustomerBudgetAmount: response.totalCustomerBudgetAmount,
      CreatedDate: creationDate,
      CreatedBy: response.createdUserName,
      ModifiedDate: response.modifiedDate,
      ModifiedBy: response.modifiedUserName,
      FileName: response.fileName,
      LocationId: response.id || response.metadataId,
      tossFilePath: response.destTossFilePath,
      FileUrl: response.fileUrl,
    }
  }

  if (response.contentType === apiConfig.apiUrl.PACAMREBILLRECONCILIATION) {
    return {
      ContentType: response.contentType,
      DocumentGroup: response.documentGroup,
      DocumentType: response.documentType,
      LocationNumber: response.locationNumber,
      LocationName: response.locationName,
      LocationState: response.locationState,
      LocationCity: response.locationCity,
      LocationRegion: response.locationRegion,
      DocumentTitle: response.documentTitle,
      FiscalYear: response.fiscalYear,
      ExpiryYear: response.expiryYear,
      SAPContractNumber: response.sapContractNumber,
      SAPCustomerNumber: response.sapCustomerNumber,
      TotalCustomerRecAmount: response.totalCustomerRecAmount,
      CreatedDate: creationDate,
      CreatedBy: response.createdUserName,
      ModifiedDate: response.modifiedDate,
      ModifiedBy: response.modifiedUserName,
      FileName: response.fileName,
      LocationId: response.id || response.metadataId,
      tossFilePath: response.destTossFilePath,
      FileUrl: response.fileUrl,
    }
  }
  if (response.contentType === apiConfig.apiUrl.PACAMREBILLUNEXPECTED) {
    return {
      ContentType: response.contentType,
      DocumentGroup: response.documentGroup,
      DocumentType: response.documentType,
      LocationNumber: response.locationNumber,
      LocationName: response.locationName,
      LocationState: response.locationState,
      LocationCity: response.locationCity,
      LocationRegion: response.locationRegion,
      DocumentTitle: response.documentTitle,
      FiscalYear: response.fiscalYear,
      ExpiryYear: response.expiryYear,
      SAPContractNumber: response.sapContractNumber,
      SAPCustomerNumber: response.sapCustomerNumber,
      TotalDollarImpact: response.totalDollarImpact,
      CreatedDate: creationDate,
      CreatedBy: response.createdUserName,
      ModifiedDate: response.modifiedDate,
      ModifiedBy: response.modifiedUserName,
      FileName: response.fileName,
      LocationId: response.id || response.metadataId,
      tossFilePath: response.destTossFilePath,
      FileUrl: response.fileUrl,
    }
  }
  if (response.contentType === apiConfig.apiUrl.PALEASE) {
    return {
      ContentType: response.contentType,
      DocumentGroup: response.documentGroup,
      DocumentType: response.documentType,
      LocationNumber: response.locationNumber,
      LocationName: response.locationName,
      LocationState: response.locationState,
      LocationCity: response.locationCity,
      DocumentTitle: response.documentTitle,
      LocationRegion: response.locationRegion,
      FiscalYear: response.fiscalYear,
      ExpiryYear: response.expiryYear,
      SAPContractNumber: response.sapContractNumber,
      // SAPCustomerNumber: response.sapCustomerNumber,
      // TotalDollarImpact: response.totalDollarImpact,
      CreatedDate: creationDate,
      CreatedBy: response.createdUserName,
      ModifiedDate: response.modifiedDate,
      ModifiedBy: response.modifiedUserName,
      FileName: response.fileName,
      LocationId: response.id || response.metadataId,
      tossFilePath: response.destTossFilePath,
      FileUrl: response.fileUrl,
    }
  }
}
