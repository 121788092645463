import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { workflowMain } from '../common/styles'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import UpdateDispDate from './EditDispositionDate'
import EditIcon from '@material-ui/icons/Edit'
import MaterialTable from 'material-table'
import { tableIcons } from '../common/commonFunctionalities'
import { useSelector, useDispatch } from 'react-redux'

export const DispositionDate = props => {
  const classes = workflowMain()
  // const [dispositionDate, setDispositionDate] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [reloadTable, setReloadTable] = useState(false)
  const [editLoc, setEditLoc] = useState()
  const [locId, setLocId] = useState()
  const dispatch = useDispatch()
  const dispositionData = useSelector(
    state => state.location.dispositionTabData
  )
  const columns = [
    {
      field: 'locid',
      title: 'LocationNumber',
      defaultSort: 'asc',
    },
    {
      field: 'name',
      title: 'LocationName',
    },
    {
      field: 'disposition_date',
      title: 'DispositionDate',
    },
  ]

  const getDispositionData = () => {
    setIsLoading(true)
    let url = apiConfig.apiUrl.getlocation

    axios
      .get(url)
      .then(response => {
        let data = response.data
        let tempList = []
        if (response.status === 200) {
          for (const item in data) {
            tempList.push({ ...data[item], locationId: item })
          }
        }
        dispatch({ type: 'SAVE_DISPOSITION_DATA', payload: tempList })
        props.setRefreshDispositionData(false)
        // setDispositionDate(tempList)
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (dispositionData && dispositionData.length === 0) {
      getDispositionData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (reloadTable || props.refreshDispositionData) {
      getDispositionData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadTable, props.refreshDispositionData])

  return (
    <div>
      <Paper className={classes.paper}>
        <MaterialTable
          options={{
            showTitle: false,
            search: true,
            toolbar: true,
            filtering: false,
            exportButton: true,
            exportAllData: true,
            exportFileName: 'DispositionDate',
            pageSizeOptions: [10, 25, 50, 100],
            loadingType: 'overlay',
            pageSize: 100,
            headerStyle: { position: 'sticky', top: 0 },
            maxBodyHeight: '68vh',
            emptyRowsWhenPaging: false,
          }}
          columns={columns}
          data={dispositionData}
          icons={tableIcons}
          isLoading={isLoading}
          actions={[
            {
              icon: EditIcon,
              tooltip: 'Update Disposition Date',
              onClick: (event, rowData) => {
                setLocId(rowData.locid)
                setEditLoc(true)
              },
            },
          ]}
          localization={{
            body: {
              emptyDataSourceMessage: '',
            },
          }}
        />
        {editLoc ? (
          <UpdateDispDate
            locId={locId}
            setRefreshLocationData={props.setRefreshLocationData}
            setRefreshDispositionData={props.setRefreshDispositionData}
            isEdit={editLoc}
            handleClosed={setEditLoc}
            setReloadTable={setReloadTable}
          />
        ) : null}
      </Paper>
    </div>
  )
}
