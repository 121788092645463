import React, { useEffect, useState } from 'react'
import { commonStyle } from '../common/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { TaskPane } from './taskPane'
import { useDispatch } from 'react-redux'
import { useAuth } from '@praxis/component-auth'
import { useToasts } from 'react-toast-notifications'
import apiConfig from '../../config/apiConfig'
import { useForm } from 'react-hook-form'
import ConsoleWindow from '../consolewindow/ConsoleWindow'
import { WorkflowDetails } from './workflowTableMapping'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
// import InfoIcon from '@material-ui/icons/Info'
// import NotesIcon from '@material-ui/icons/Notes'
import { validateWorkflowFinalSubmit } from '../common/validation'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import { columns, options } from '../workflow/workflowTableMapping'
import MUIDataTable from 'mui-datatables'
import DialogContentText from '@material-ui/core/DialogContentText'
import { CSVLink } from 'react-csv'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

export const WorkflowData = ({
  workflowInfo,
  openDocument,
  workflowReduxState,
  handleTabChange,
  contentType,
}) => {
  const classes = commonStyle()
  // const [taskData, setTaskData] = useState(null)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { session } = auth
  const { addToast } = useToasts()
  const {
    register,
    errors,
    control,
    handleSubmit,
    setError,
    clearError,
  } = useForm({ reValidateMode: 'onSubmit' })
  const [loadConsoleWindow, setLoadConsoleWindow] = useState(false)
  const [tempList, setTempList] = useState(null)
  const [text, setText] = useState('')
  const [open, setOpen] = React.useState(false)
  const [checked, setChecked] = React.useState(false)
  const [historyOpen, setHistoryOpen] = React.useState(false)
  const [historyData, setHistoryData] = useState([])
  const [downloadData, setDownloadData] = useState([])
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

  const {
    Parser,
    transforms: { unwind },
  } = require('json2csv')
  const [consoleWindowProp, setConsoleWindowProp] = useState({
    dialogTitle: 'Warning!',
    dialogContent: apiConfig.apiUrl.delete_workflow_dialog_content,
    dialogFirstButton: apiConfig.apiUrl.delete_workflow_first_button,
    dialogSecondButton: apiConfig.apiUrl.delete_workflow_second_button,
    buttonClicked: '',
  })

  const openDoc = () => {
    openDocument(workflowInfo, 'file')
  }

  const saveWorkflow = () => {
    let userData = session.userInfo
    let name = userData.email.split('@')
    let payload = {}
    let tasks = workflowReduxState.workflowTasks[workflowInfo.value]
    delete tasks['isEdited']
    payload['tasks'] = Object.values(tasks)
    payload['user_name'] = name[0]
    payload['user_id'] = userData.lanId

    let url =
      apiConfig.apiUrl.save_workflow +
      `/${workflowInfo.workflow_id}/${workflowInfo.current_bucket}/${workflowInfo.location_number}`
    axios
      .put(url, payload)
      .then(res => {
        addToast('Workflow saved successfully', {
          appearance: 'success',
          autoDismiss: true,
        })
      })
      .catch(err => {
        console.log(err)
        addToast('Error!Workflow could not be saved.', {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  // const handleWorkflowSubmitSkipPA = () => {
  //   let userData = session.userInfo
  //   let name = userData.email.split('@')
  //   let payload = {}
  //   let tasks = workflowReduxState.workflowTasks[workflowInfo.value]

  //   delete tasks['isEdited']
  //   payload['tasks'] = Object.values(tasks)
  //   payload['user_name'] = name[0]
  //   payload['user_id'] = userData.lanId

  //   let url =
  //     apiConfig.apiUrl.submit_workflow_retrigger +
  //     `/${workflowInfo.workflow_id}/${workflowInfo.current_bucket}/${workflowInfo.location_number}`
  //   axios
  //     .post(url, payload)
  //     .then(res => {
  //       closeTab()
  //       addToast('Workflow submitted successfully', {
  //         appearance: 'success',
  //         autoDismiss: true,
  //       })
  //       setIsSubmitDisabled(false)
  //       setRetriggerDialogOpen(false)
  //     })
  //     .catch(err => {
  //       setIsSubmitDisabled(false)

  //       console.log(err)
  //       addToast('Error!Workflow could not be submitted', {
  //         appearance: 'error',
  //         autoDismiss: true,
  //       })
  //       setRetriggerDialogOpen(false)
  //     })
  // }

  const normalWorkflowSubmit = () => {
    let userData = session.userInfo
    let name = userData.email.split('@')
    let payload = {}
    let tasks = workflowReduxState.workflowTasks[workflowInfo.value]

    delete tasks['isEdited']
    payload['tasks'] = Object.values(tasks)
    payload['user_name'] = name[0]
    payload['user_id'] = userData.lanId
    payload['skip_pa_flag'] = workflowInfo.skip_pa_flag

    let bucketName =
      workflowInfo.current_bucket === 'RE-DEFECT-BOTH'
        ? 'RE-METADATA-DEFECT'
        : workflowInfo.current_bucket
    let url =
      apiConfig.apiUrl.submit_workflow +
      `/${workflowInfo.workflow_id}/${bucketName}/${workflowInfo.location_number}`
    axios
      .post(url, payload)
      .then(res => {
        closeTab()
        addToast('Workflow submitted successfully', {
          appearance: 'success',
          autoDismiss: true,
        })
        setIsSubmitDisabled(false)
      })
      .catch(err => {
        setIsSubmitDisabled(false)
        if (err.response.status === 400) {
          addToast(
            'Error - Please update DocumentStatus/RecordingStatus before submitting the workflow',
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        } else {
          console.log(err)
          addToast('Error!Workflow could not be submitted', {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
  }

  const handleWorkflowSubmit = data => {
    let errorList = validateWorkflowFinalSubmit(
      workflowInfo.current_bucket,
      data
    )
    if (errorList.length !== 0) {
      // eslint-disable-next-line array-callback-return
      errorList.map(error => {
        if (error === 'formError') {
          addToast('Error!Please select only one dropdown', {
            appearance: 'error',
            autoDismiss: true,
          })
        } else setError(error, 'notMatch')
      })
    } else {
      setIsSubmitDisabled(true)

      normalWorkflowSubmit()
    }
  }

  useEffect(() => {
    if (
      Object.keys(workflowReduxState.workflowTasks).length === 0 ||
      !workflowReduxState.workflowTasks[workflowInfo.value]
    ) {
      let selectedBucket = workflowInfo.task_bucket
        ? workflowInfo.task_bucket
        : workflowInfo.current_bucket
      let url =
        apiConfig.apiUrl.get_workflow +
        `/${workflowInfo.workflow_id}/${selectedBucket}`
      axios
        .get(url)
        .then(res => {
          if (typeof res.data !== 'object') throw new Error(res.data)
          else {
            // setTaskData(res.data)
            const dataToBeSaved = {}
            const tempMap = {}
            res.data.map(item => (tempMap[item['task_label']] = item))
            tempMap['isEdited'] = true
            dataToBeSaved[workflowInfo.value] = tempMap
            dispatch({
              type: 'SAVE_WORKFLOW_TASKS',
              payload: dataToBeSaved,
            })
          }
        })
        .catch(err => console.log(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowInfo.workflow_id])

  useEffect(() => {
    if (consoleWindowProp.buttonClicked === 'Delete') {
      deleteWorkflow()
      setLoadConsoleWindow(false)
    } else {
      setLoadConsoleWindow(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consoleWindowProp.buttonClicked])

  const deleteWorkflow = () => {
    let userData = session.userInfo
    let name = userData.email.split('@')
    let url =
      apiConfig.apiUrl.delete_workflow +
      `/${workflowInfo.workflow_id}/${userData.lanId}/${name[0]}/${
        workflowInfo.location_number
      }`

    axios
      .delete(url)
      .then(response => {
        if (response.status === 200) {
          addToast('Workflow deleted successfully', {
            appearance: 'success',
            autoDismiss: true,
          })
          closeTab()
        } else throw new Error('Error!Workflow could not be deleted')
      })
      .catch(err => {
        console.log(err)
        addToast(err, {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  useEffect(() => {
    getNotes(workflowInfo.workflow_id)
  }, [workflowInfo])

  const getNotes = noteId => {
    let url = apiConfig.apiUrl.workflownotes + `/${noteId}`

    axios
      .get(url)
      .then(response => {
        setTempList(response.data)
      })
      .catch(err => console.log(err))
  }
  useEffect(() => {
    if (historyOpen) {
      let url =
        apiConfig.apiUrl.getworkflowhistory + `/${workflowInfo.workflow_id}`

      axios
        .get(url)
        .then(response => {
          setHistoryData(response.data)
        })
        .catch(err => console.log(err))
    } else {
      setHistoryData([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyOpen])

  const handleWorknotesSubmit = event => {
    event.preventDefault()
    let userData = session.userInfo
    let name = userData.email.split('@')
    let payload = {}
    payload['workflow_id'] = workflowInfo.workflow_id
    payload['username'] = name[0]
    payload['text'] = text
    payload['importance_flag'] = checked
    let url = apiConfig.apiUrl.addworkflownotes

    axios
      .post(url, payload)
      .then(res => {
        if (res.status === 200) {
          setText('')
          setChecked(false)
          handleClose()
          getNotes(workflowInfo.workflow_id)
        }
        addToast('Worknotes added successfully', {
          appearance: 'success',
          autoDismiss: true,
        })
      })
      .catch(err => {
        addToast('Error!Worknotes could not be submitted', {
          appearance: 'error',
        })
      })
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handlecheckboxChange = event => {
    setChecked(event.target.checked)
  }

  const handleHistoryClickOpen = () => {
    setHistoryOpen(true)
  }

  const handleHistoryClose = () => {
    setHistoryOpen(false)
  }

  const disableSubmit = workflowInfo => {
    let selectedBuckets = new Set([
      'REBILL-BUDGET-COMPLETE',
      'RE-CLOSE-COMPLETE',
      'PAYABLE-UNEXPECTED-COMPLETE',
      'PAYABLE-BUDGET-COMPLETE',
      'LEASE-AGREEMENT-COMPLETE',
      'RE-CLOSE-TERMINATED',
      'PAYABLE-TERMINATED',
      'REBILL-TERMINATED',
      'LEASE-AGREEMENT-TERMINATED',
      'CAM-TERMINATED',
    ])

    if (selectedBuckets.has(workflowInfo.current_bucket)) {
      return true
    }
    return isSubmitDisabled
  }

  const historyExport = () => {
    const fields = [
      // {
      //   label: 'Workflow ID',
      //   value: 'workflow_id',
      // },
      {
        label: 'Created Date',
        value: 'created_date',
      },
      {
        label: 'User Action',
        value: 'action',
      },
      {
        label: 'From Bucket',
        value: 'current_bucket_name',
      },
      {
        label: 'To Bucket',
        value: 'future_bucket_name',
      },
      {
        label: 'Location Number',
        value: 'location_number',
      },
      {
        label: 'Modified UserName',
        value: 'user_name',
      },

      {
        label: 'Task Question',
        value: 'task_details.task_label',
      },
      {
        label: 'Task Response',
        value: 'task_details.task_response_options',
      },
    ]

    const transforms = [unwind({ paths: ['task_details'], blankOut: true })]

    const json2csvParser = new Parser({ fields, transforms })
    setDownloadData(json2csvParser.parse(historyData))
  }

  const handleFieldsChange = event => {
    //for manipulating state for specific taskpane
    clearError(event.target.id)
    const savedPayload = JSON.parse(
      JSON.stringify(workflowReduxState.workflowTasks)
    )
    const payloadToBeModified = savedPayload[workflowInfo.value]

    payloadToBeModified[event.target.id].default_option = event.target.value
    payloadToBeModified['isEdited'] = false
    dispatch({
      type: 'SAVE_WORKFLOW_TASKS',
      payload: savedPayload,
    })
  }
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const closeTab = () => {
    let tempEvent = { target: { tagName: 'path', hasSubmitted: true } }
    handleTabChange(tempEvent, workflowInfo.value)
  }

  const cancelTab = () => {
    let tempEvent = { target: { tagName: 'path' } }
    handleTabChange(tempEvent, workflowInfo.value)
  }

  const disabledButton = bucketName => {
    let selectedBuckets = new Set([
      'REBILL-BUDGET-COMPLETE',
      'RE-CLOSE-COMPLETE',
      'PAYABLE-UNEXPECTED-COMPLETE',
      'PAYABLE-BUDGET-COMPLETE',
      'LEASE-AGREEMENT-COMPLETE',
      'RE-CLOSE-TERMINATED',
      'PAYABLE-TERMINATED',
      'REBILL-TERMINATED',
      'LEASE-AGREEMENT-TERMINATED',
      'CAM-TERMINATED',
    ])

    if (selectedBuckets.has(bucketName)) {
      return true
    }
    return false
  }

  return (
    <div className={classes.root}>
      <div className={classes.outerContainer}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={3} className={classes.information}>
              {/* <Paper square className={classes.root}> */}
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                scrollButtons="off"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon tabs example"
              >
                <Tab label="Notes" />
                <Tab label="Details" />
              </Tabs>
              {/* </Paper> */}
              <TabPanel value={value} index={1}>
                <div>
                  <span className={classes.label}>Workflow ID: </span>
                  {workflowInfo.value}
                </div>
                <div>
                  <span className={classes.label}>Created on: </span>
                  {workflowInfo.created_date}
                </div>
                <div>
                  <span className={classes.label}>Indexed on: </span>
                  {workflowInfo.indexed_on}
                </div>
                <div>
                  <span className={classes.label}>Current User: </span>
                  {workflowInfo.current_bucket_user_name}
                </div>
                <div>
                  <span className={classes.label}>Last Bucket: </span>
                  {workflowInfo.last_bucket}
                </div>
                <div>
                  <span className={classes.label}>Last User: </span>
                  {workflowInfo.last_Bucket_user_name}
                </div>
                <hr />
                <div>
                  <span className={classes.label}>Location Number: </span>
                  {workflowInfo.location_number}
                </div>
                <div>
                  <span className={classes.label}>Location Name: </span>
                  {workflowInfo.location_name}
                </div>
                <div>
                  <span className={classes.label}>Location State: </span>
                  {workflowInfo.location_state}
                </div>
                <div>
                  <span className={classes.label}>Location Region: </span>
                  {workflowInfo.location_region}
                </div>
                <hr />

                <WorkflowDetails
                  classes={classes}
                  workflowInfo={workflowInfo}
                  contentType={contentType}
                />
              </TabPanel>
              <TabPanel
                value={value}
                index={0}
                style={{ overflowY: 'scroll', height: '500px' }}
              >
                <center>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                  >
                    Add Notes
                  </Button>
                </center>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                >
                  <form
                    validate
                    autoComplete="off"
                    onSubmit={handleWorknotesSubmit}
                  >
                    <div>
                      <DialogTitle id="form-dialog-title">
                        Add Work Notes
                      </DialogTitle>
                      <DialogContent>
                        <TextField
                          required
                          id="outlined-search"
                          label={'Worknotes Text'}
                          type={String}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          autoFocus
                          multiline
                          rows={6}
                          value={text}
                          onChange={e => {
                            setText(e.target.value)
                          }}
                        />
                        <FormControlLabel
                          value="start"
                          control={
                            <Checkbox
                              color="primary"
                              checked={checked}
                              onChange={handlecheckboxChange}
                            />
                          }
                          label="Important"
                          labelPlacement="end"
                        />
                        {/* </form> */}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </DialogActions>
                    </div>
                  </form>
                </Dialog>
                <hr />
                {tempList &&
                  tempList.map(item => {
                    return (
                      <div>
                        <div>
                          <span className={classes.label}>Created By: </span>
                          {item.username}
                        </div>
                        <div>
                          <span className={classes.label}>Created On: </span>
                          {item.timestamp}
                        </div>
                        <br />
                        <div>
                          <span className={classes.label}>Notes: </span>
                          {JSON.stringify(item.importance_flag) === 'true' ? (
                            <font color="red">
                              <i>{item.text}</i>{' '}
                            </font>
                          ) : (
                            <i>{item.text}</i>
                          )}
                        </div>
                        <hr style={{ borderTopWidth: '5px' }} />
                      </div>
                    )
                  })}
              </TabPanel>
            </Grid>
            <Grid item xs={9}>
              <Grid Container direction="column">
                <Grid item xs={12}>
                  <div className={classes.buttonContainer}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      onClick={openDoc}
                    >
                      View Document
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={handleHistoryClickOpen}
                      color="primary"
                    >
                      View Workflow History
                    </Button>
                    {/* <Historyexport /> */}
                    <Dialog
                      fullWidth="true"
                      maxWidth="lg"
                      open={historyOpen}
                      onClose={handleHistoryClose}
                      aria-labelledby="form-dialog-title"
                    >
                      <DialogTitle id="form-dialog-title">
                        Workflow History
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          {/* <CSVLink
                            style={{ textAlign: 'right' }}
                            onClick={historyExport}
                            filename={`${workflowInfo.workflow_id}.csv`}
                            data={downloadData}
                          >
                            <Tooltip title="Download">
                              <CloudDownloadIcon />
                            </Tooltip>
                          </CSVLink> */}
                          <MUIDataTable
                            title={''}
                            data={historyData}
                            columns={columns}
                            options={options}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleHistoryClose} color="primary">
                          Cancel
                        </Button>
                        <Button color="primary">
                          <CSVLink
                            onClick={historyExport}
                            filename={`${workflowInfo.workflow_id}.csv`}
                            data={downloadData}
                          >
                            Download History
                          </CSVLink>
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <form onSubmit={handleSubmit(handleWorkflowSubmit)}>
                      <TaskPane
                        register={register}
                        errors={errors}
                        control={control}
                        classes={classes}
                        taskData={
                          workflowReduxState.workflowTasks[workflowInfo.value]
                        }
                        handleFieldsChange={handleFieldsChange}
                        bucketName={workflowInfo.current_bucket}
                        routeToList={workflowReduxState.routeToList}
                      />
                      <Grid container className={classes.actionButtonDiv}>
                        <div className={classes.centerButton}>
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            disabled={
                              Object.keys(workflowReduxState.workflowTasks)
                                .length > 0 &&
                              workflowReduxState.workflowTasks[
                                workflowInfo.value
                              ]
                                ? workflowReduxState.workflowTasks[
                                    workflowInfo.value
                                  ].isEdited
                                : true
                            }
                            onClick={saveWorkflow}
                          >
                            Save
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            onClick={cancelTab}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            disabled={disableSubmit(workflowInfo)}
                          >
                            Submit
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            onClick={() => setLoadConsoleWindow(true)}
                            disabled={disabledButton(
                              workflowInfo.current_bucket
                            )}
                          >
                            Delete
                          </Button>
                        </div>
                      </Grid>
                    </form>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
      {loadConsoleWindow && (
        <ConsoleWindow cwProp={[consoleWindowProp, setConsoleWindowProp]} />
      )}
    </div>
  )
}
