/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSelector } from 'react-redux'
import { NumberFormatCustomPrefix } from '../common/commonFunctionalities'
import apiConfig from '../../config/apiConfig'

const PACAMRebillUnexpected = props => {
  return (
    <div>
      {props.type === apiConfig.apiUrl.INDEX ? (
        <PACAMRebillUnexpectedIndex {...props} />
      ) : (
        <PACAMRebillUnexpectedSearch {...props} />
      )}
    </div>
  )
}

const PACAMRebillUnexpectedSearch = props => {
  const [, setParentObj] = props.metaDataObj
  const [
    paCAMRebillUnexpectedSearchObj,
    setpaCAMRebillUnexpectedSearchObj,
  ] = useState({})

  useEffect(() => {
    if (
      (props.savedMetaData &&
        Object.entries(props.savedMetaData).length === 0) ||
      props.savedMetaData === undefined
    ) {
      setpaCAMRebillUnexpectedSearchObj(props.unmodifiedData)
    } else {
      let obj = returnREObj({ ...props.savedMetaData })
      let newObj = { ...obj, id: props.unmodifiedData.id }
      setpaCAMRebillUnexpectedSearchObj(newObj)
    }
  }, [])

  useEffect(() => {
    setParentObj(paCAMRebillUnexpectedSearchObj)
  }, [paCAMRebillUnexpectedSearchObj])

  return (
    <PACAMRebillUnexpectedForm
      {...props}
      pacamrebillunexpected={[
        paCAMRebillUnexpectedSearchObj,
        setpaCAMRebillUnexpectedSearchObj,
      ]}
    />
  )
}

const PACAMRebillUnexpectedIndex = props => {
  const [parentObj, setParentObj] = props.metaDataObj
  const [
    paCAMRebillUnexpectedIndexObj,
    setPaCAMRebillUnexpectedIndexObj,
  ] = useState({})

  useEffect(() => {
    setParentObj(paCAMRebillUnexpectedIndexObj)
  }, [paCAMRebillUnexpectedIndexObj])

  useEffect(() => {
    if (Object.keys(parentObj).length > 0) {
      let newObj = { ...parentObj, contentType: props.contentType }
      // let newObj = { contentType: props.contentType }

      setPaCAMRebillUnexpectedIndexObj(newObj)
    }
  }, [])

  //called only when indexing status = complete
  useEffect(() => {
    if (props.refreshCompleteMetadata) {
      // let obj = refactoredObject({ ...parentObj })
      let newObj = { ...parentObj, contentType: props.contentType }
      setPaCAMRebillUnexpectedIndexObj(newObj)
    }
  }, [props.refreshCompleteMetadata])

  //For clearing indexing data on switch of content type
  useEffect(() => {
    if (props.clearOnContentTypeChange) {
      let newObj = { contentType: props.contentType }
      setPaCAMRebillUnexpectedIndexObj(newObj)
    }
  }, [props.clearOnContentTypeChange])

  return (
    <PACAMRebillUnexpectedForm
      {...props}
      pacamrebillunexpected={[
        paCAMRebillUnexpectedIndexObj,
        setPaCAMRebillUnexpectedIndexObj,
      ]}
    />
  )
}

const returnREObj = data => {
  return {
    locationNumber: data.location_number || '',
    locationName: data.location_name || '',
    locationState: data.location_state || '',
    locationCity: data.location_city || '',
    locationRegion: data.location_region || '',
    documentTitle: data.document_title || '',
    documentGroup: data.document_group || '',
    documentType: data.document_type || '',
    contentType: data.content_type || '',
    fiscalYear: data.fiscal_year || '',
    expiryYear: data.expiry_year || '',
    sapContractNumber: data.sap_contract_number || '',
    sapCustomerNumber: data.sap_customer_number || '',
    totalDollarImpact: data.total_dollar_impact || '',
  }
}

const PACAMRebillUnexpectedForm = props => {
  const [
    paCAMRebillUnexpectedObj,
    setPaCAMRebillUnexpectedObj,
  ] = props.pacamrebillunexpected
  const BootstrapInput = props.bootStrapInput
  const classes = props.useStyleClass
  const metadataObj = useSelector(state => state.common.locationData)
  const [locationData, setLocationData] = useState(metadataObj)
  const [docArray, setDocArray] = useState([])
  const [docTitleError, setDocTitleError] = useState(false)

  const { docGroupDocType, contentType } = props

  useEffect(() => {
    setDocArray(
      docGroupDocType[contentType][paCAMRebillUnexpectedObj.documentGroup]
    )
  }, [paCAMRebillUnexpectedObj.documentGroup])

  useEffect(() => {
    if (locationData) {
      setPaCAMRebillUnexpectedObj({
        ...paCAMRebillUnexpectedObj,
        locationNumber: locationData.location_number,
        locationName: locationData.location_name,
        locationCity: locationData.location_city,
        locationState: locationData.location_state,
        locationRegion: locationData.location_region,
      })
    } else {
      setPaCAMRebillUnexpectedObj({
        ...paCAMRebillUnexpectedObj,
        locationNumber: '',
        locationName: '',
        locationCity: '',
        locationState: '',
        locationRegion: '',
      })
    }
  }, [locationData])

  useEffect(() => {
    if (
      paCAMRebillUnexpectedObj.fiscalYear &&
      paCAMRebillUnexpectedObj.fiscalYear.length === 4
    ) {
      setPaCAMRebillUnexpectedObj({
        ...paCAMRebillUnexpectedObj,
        expiryYear: (
          Number(paCAMRebillUnexpectedObj.fiscalYear) + 6
        ).toString(),
      })
    }
  }, [paCAMRebillUnexpectedObj.fiscalYear])

  return (
    <div className="contenttype-container">
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="pacamrebillunexpected-documentgroup">
          Document Group *
        </InputLabel>
        <NativeSelect
          id="pacamrebillunexpected-documentgroup"
          value={paCAMRebillUnexpectedObj.documentGroup}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_group
              : paCAMRebillUnexpectedObj.documentGroup
          }
          onChange={e =>
            setPaCAMRebillUnexpectedObj({
              ...paCAMRebillUnexpectedObj,
              documentGroup: e.target.value,
            })
          }
          required
          input={<BootstrapInput />}
        >
          {Object.keys(docGroupDocType[contentType]).map(value => {
            return (
              <option value={value} key={value}>
                {value}
              </option>
            )
          })}
        </NativeSelect>
      </FormControl>
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="pacamrebillunexpected-documenttype">
          Document Type *
        </InputLabel>
        <NativeSelect
          id="pacamrebillunexpected-documenttype"
          value={paCAMRebillUnexpectedObj.documentType}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_type
              : paCAMRebillUnexpectedObj.documentType
          }
          onChange={e =>
            setPaCAMRebillUnexpectedObj({
              ...paCAMRebillUnexpectedObj,
              documentType: e.target.value,
            })
          }
          input={<BootstrapInput />}
          required
        >
          {docArray &&
            docArray.map(value => {
              return (
                <option value={value} key={value}>
                  {value}
                </option>
              )
            })}
        </NativeSelect>
      </FormControl>
      <Autocomplete
        options={metadataObj}
        autoHighlight={true}
        getOptionLabel={option =>
          option.location_number ? option.location_number : ''
        }
        className="autocomplete"
        // getOptionLabel={option => option.location_number}
        onChange={(index, obj) => setLocationData(obj)}
        // blurOnSelect={true}
        value={{
          location_number: paCAMRebillUnexpectedObj.locationNumber,
          location_name: paCAMRebillUnexpectedObj.locationName,
          location_city: paCAMRebillUnexpectedObj.locationCity,
          location_state: paCAMRebillUnexpectedObj.locationState,
          location_region: paCAMRebillUnexpectedObj.locationRegion,
        }}
        type={Number}
        disabled={Object.entries(metadataObj).length ? false : true}
        renderInput={params => (
          <FormControl>
            <InputLabel
              shrink
              className={classes.textfieldLabel}
              htmlFor="pacamrebillunexpected-autopopulate"
            >
              Location Number *
            </InputLabel>
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              required
              // label="Location Number"
              className={classes.textField}
              variant="outlined"
            />
          </FormControl>
        )}
      />

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillunexpected-locationname"
        >
          Location Name{' *'}
        </InputLabel>
        <TextField
          required
          id="pacamrebillunexpected-locationname"
          // label={'Location Name'}
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          style={{ color: 'black' }}
          disabled={true}
          value={paCAMRebillUnexpectedObj.locationName || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_name
              : paCAMRebillUnexpectedObj.locationName
          }
          onChange={e =>
            setPaCAMRebillUnexpectedObj({
              ...paCAMRebillUnexpectedObj,
              locationName: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillunexpected-state"
        >
          State *
        </InputLabel>
        <TextField
          required
          id="pacamrebillunexpected-state"
          // label={'State'}
          type={String}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paCAMRebillUnexpectedObj.locationState || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_state
              : paCAMRebillUnexpectedObj.locationState
          }
          onChange={e =>
            setPaCAMRebillUnexpectedObj({
              ...paCAMRebillUnexpectedObj,
              locationState: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillunexpected-city"
        >
          City *
        </InputLabel>
        <TextField
          required
          id="pacamrebillunexpected-city"
          // label={'City'}
          type={String}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paCAMRebillUnexpectedObj.locationCity || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_city
              : paCAMRebillUnexpectedObj.locationCity
          }
          onChange={e =>
            setPaCAMRebillUnexpectedObj({
              ...paCAMRebillUnexpectedObj,
              locationCity: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillunexpected-region"
        >
          Region
        </InputLabel>
        <TextField
          required
          id="pacamrebillunexpected-region"
          // label="Region"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paCAMRebillUnexpectedObj.locationRegion || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_region
              : paCAMRebillUnexpectedObj.locationRegion
          }
          onChange={e =>
            setPaCAMRebillUnexpectedObj({
              ...paCAMRebillUnexpectedObj,
              locationRegion: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillunexpected-documenttitle"
        >
          Document Title *
        </InputLabel>
        <TextField
          required
          id="pacamrebillunexpected-documenttitle"
          // label={'Document Title'}
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          inputProps={{ maxLength: '500' }}
          multiline
          rowsMax="4"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMRebillUnexpectedObj.documentTitle || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_title
              : paCAMRebillUnexpectedObj.documentTitle
          }
          onChange={e => {
            if (e.target.value.length === 500) {
              setDocTitleError(true)
            } else setDocTitleError(false)
            setPaCAMRebillUnexpectedObj({
              ...paCAMRebillUnexpectedObj,
              documentTitle: e.target.value,
            })
          }}
          error={docTitleError}
          helperText={
            docTitleError ? 'You have reached the limit of 500 characters' : ''
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillunexpected-fiscalyear"
        >
          Fiscal Year{' '}
        </InputLabel>
        <TextField
          id="pacamrebillunexpected-fiscalyear"
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMRebillUnexpectedObj.fiscalYear || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.fiscal_year
              : paCAMRebillUnexpectedObj.fiscalYear
          }
          onChange={e => {
            if (e.target.value.length > 4) return false
            else {
              setPaCAMRebillUnexpectedObj({
                ...paCAMRebillUnexpectedObj,
                fiscalYear: e.target.value,
              })
            }
          }}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="paCAMRebillUnexpectedObj-expiryyear"
        >
          Expiry Year{' '}
        </InputLabel>
        <TextField
          type="number"
          id="paCAMRebillUnexpectedObj-expiryyear"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMRebillUnexpectedObj.expiryYear || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.expiry_year
              : paCAMRebillUnexpectedObj.expiryYear
          }
          onChange={e => {
            if (e.target.value.length > 4) return false
            else {
              setPaCAMRebillUnexpectedObj({
                ...paCAMRebillUnexpectedObj,
                expiryYear: e.target.value,
              })
            }
          }}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillunexpected-sapcontractnumber"
        >
          SAP Contract Number{' '}
        </InputLabel>
        <TextField
          id="pacamrebillunexpected-sapcontractnumber"
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMRebillUnexpectedObj.sapContractNumber || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.sap_contract_number
              : paCAMRebillUnexpectedObj.sapContractNumber
          }
          onChange={e =>
            setPaCAMRebillUnexpectedObj({
              ...paCAMRebillUnexpectedObj,
              sapContractNumber: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillunexpected-sapcustomernumber"
        >
          SAP Customer Number{' '}
        </InputLabel>
        <TextField
          id="pacamrebillunexpected-sapcustomernumber"
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMRebillUnexpectedObj.sapCustomerNumber || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.sap_customer_number
              : paCAMRebillUnexpectedObj.sapCustomerNumber
          }
          onChange={e =>
            setPaCAMRebillUnexpectedObj({
              ...paCAMRebillUnexpectedObj,
              sapCustomerNumber: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillunexpected-totaldollarimpact"
        >
          Total Dollar Impact{' '}
        </InputLabel>
        <TextField
          id="pacamrebillunexpected-totaldollarimpact"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMRebillUnexpectedObj.totalDollarImpact || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.total_dollar_impact
              : paCAMRebillUnexpectedObj.totalDollarImpact
          }
          onChange={e =>
            setPaCAMRebillUnexpectedObj({
              ...paCAMRebillUnexpectedObj,
              totalDollarImpact: e.target.value,
            })
          }
          InputProps={{
            inputComponent: NumberFormatCustomPrefix,
          }}
        />
      </FormControl>
    </div>
  )
}

export default PACAMRebillUnexpected
