import React, { forwardRef } from 'react'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import Pageview from '@material-ui/icons/Pageview'
import LastPage from '@material-ui/icons/LastPage'
import Clear from '@material-ui/icons/Clear'
import Edit from '@material-ui/icons/Edit'
import SearchIcon from '@material-ui/icons/Search'
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import ViewColumn from '@material-ui/icons/ViewColumn'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import NumberFormat from 'react-number-format'

export const CHECKIN = 'CHECKIN'
export const CHECKOUT = 'CHECKOUT'

export const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  Pageview: forwardRef((props, ref) => <Pageview {...props} ref={ref} />),
  // Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  Search: forwardRef((props, ref) => null),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <CheckIcon {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => (
    <AssignmentReturnedIcon {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
}

// export function TabPanel(props) {
//   const { children, value, index, ...other } = props

//   return (
//     <Typography
//       component="div"
//       role="tabpanel"
//       hidden={value !== index}
//       id={`full-width-tabpanel-${index}`}
//       aria-labelledby={`full-width-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box p={3}>{children}</Box>}
//     </Typography>
//   )
// }

export function getFileExtensionType(filename) {
  return filename.split('.').pop()
}

const byString = (o, s) => {
  if (!s) {
    return
  }

  s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  s = s.replace(/^\./, '') // strip a leading dot
  var a = s.split('.')
  for (var i = 0, n = a.length; i < n; ++i) {
    var x = a[i]
    if (o && x in o) {
      o = o[x]
    } else {
      return
    }
  }
  return o
}

export const replaceUrl = url => {
  if (typeof url === 'object' || url === '') return ''
  let modifiedUrl = url.split('?')
  let returnUrl = window.location.origin + '/filename?' + modifiedUrl[1]
  return returnUrl
}
//File URL authentication code

// export const getFieldValue = (rowData, columnDef, lookup = true) => {
//   let value =
//     typeof rowData[columnDef.field] !== 'undefined'
//       ? rowData[columnDef.field]
//       : byString(rowData, columnDef.field)
//   if (columnDef.lookup && lookup) {
//     value = columnDef.lookup[value]
//   }

//   return value
// }

export const getFieldValue = (rowData, columnDef, lookup = true) => {
  let value
  if (columnDef.field === 'FileUrl') {
    value =
      typeof rowData[columnDef.field] !== 'undefined'
        ? replaceUrl(rowData[columnDef.field])
        : byString(rowData, columnDef.field)
  } else {
    value =
      typeof rowData[columnDef.field] !== 'undefined'
        ? rowData[columnDef.field]
        : byString(rowData, columnDef.field)
  }

  if (columnDef.lookup && lookup) {
    value = columnDef.lookup[value]
  }

  return value
}

export const getFieldValueWorkflow = (rowData, columnDef, lookup = true) => {
  let value
  if (
    columnDef.field === 'total_dollar_impact' ||
    columnDef.field === 'target_prs_budget_amount' ||
    columnDef.field === 'target_prs_total_rec_amount' ||
    columnDef.field === 'total_customer_budget_amount' ||
    columnDef.field === 'total_customer_rec_amount' ||
    columnDef.field === 'net_present_value' ||
    columnDef.field === 'min_lease_payment'
  ) {
    value =
      typeof rowData[columnDef.field] !== 'undefined'
        ? formatDollarAmount(rowData[columnDef.field])
        : byString(rowData, columnDef.field)
  } else {
    value =
      typeof rowData[columnDef.field] !== 'undefined'
        ? rowData[columnDef.field]
        : byString(rowData, columnDef.field)
  }
  if (columnDef.lookup && lookup) {
    value = columnDef.lookup[value]
  }

  return value
}

export const NumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
    />
  )
}

export function TabPanel(props) {
  const { children, index, value, ...other } = props
  return (
    <Typography
      component="div"
      role="tabpanel"
      // eslint-disable-next-line eqeqeq
      hidden={value != index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {/* {value === index &&  */}
      <Box p={3}>{children}</Box>
      {/* }  */}
    </Typography>
  )
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const NumberFormatCustomPrefix = props => {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            id: props.name,
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      decimalScale="2"
      fixedDecimalScale={true}
      prefix="$"
    />
  )
}

export const formatDollarAmount = number => {
  let plainNumber =
    typeof number === 'undefined' ||
    number === 'NA' ||
    typeof number === 'object' ||
    number === ''
      ? 0
      : Number(number.replace(/[^0-9.-]+/g, ''))

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(plainNumber)
}
