/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSelector } from 'react-redux'
import { NumberFormatCustomPrefix } from '../common/commonFunctionalities'
import apiConfig from '../../config/apiConfig'

const PACAMRebillReconciliation = props => {
  return (
    <div>
      {props.type === apiConfig.apiUrl.INDEX ? (
        <PACAMRebillReconciliationIndex {...props} />
      ) : (
        <PACAMRebillReconciliationSearch {...props} />
      )}
    </div>
  )
}

const PACAMRebillReconciliationSearch = props => {
  const [, setParentObj] = props.metaDataObj
  const [
    paCAMRebillReconciliationSearchObj,
    setPaCAMRebillReconciliationSearchObj,
  ] = useState({})

  useEffect(() => {
    if (
      (props.savedMetaData &&
        Object.entries(props.savedMetaData).length === 0) ||
      props.savedMetaData === undefined
    ) {
      setPaCAMRebillReconciliationSearchObj(props.unmodifiedData)
    } else {
      let obj = returnREObj({ ...props.savedMetaData })
      let newObj = { ...obj, id: props.unmodifiedData.id }
      setPaCAMRebillReconciliationSearchObj(newObj)
    }
  }, [])

  useEffect(() => {
    setParentObj(paCAMRebillReconciliationSearchObj)
  }, [paCAMRebillReconciliationSearchObj])

  return (
    <PACAMRebillReconciliationForm
      {...props}
      pacamrebillrecon={[
        paCAMRebillReconciliationSearchObj,
        setPaCAMRebillReconciliationSearchObj,
      ]}
    />
  )
}

const PACAMRebillReconciliationIndex = props => {
  const [parentObj, setParentObj] = props.metaDataObj
  const [
    paCAMRebillReconciliationIndexObj,
    setPaCAMRebillReconciliationIndexObj,
  ] = useState({})

  useEffect(() => {
    setParentObj(paCAMRebillReconciliationIndexObj)
  }, [paCAMRebillReconciliationIndexObj])

  useEffect(() => {
    if (Object.keys(parentObj).length > 0) {
      let newObj = { ...parentObj, contentType: props.contentType }
      setPaCAMRebillReconciliationIndexObj(newObj)
    }
  }, [])

  //called only when indexing status = complete
  useEffect(() => {
    if (props.refreshCompleteMetadata) {
      // let obj = refactoredObject({ ...parentObj })
      let newObj = { ...parentObj, contentType: props.contentType }
      setPaCAMRebillReconciliationIndexObj(newObj)
    }
  }, [props.refreshCompleteMetadata])

  //For clearing indexing data on switch of content type
  useEffect(() => {
    if (props.clearOnContentTypeChange) {
      let newObj = { contentType: props.contentType }
      setPaCAMRebillReconciliationIndexObj(newObj)
    }
  }, [props.clearOnContentTypeChange])

  return (
    <PACAMRebillReconciliationForm
      {...props}
      pacamrebillrecon={[
        paCAMRebillReconciliationIndexObj,
        setPaCAMRebillReconciliationIndexObj,
      ]}
    />
  )
}

const returnREObj = data => {
  return {
    locationNumber: data.location_number || '',
    locationName: data.location_name || '',
    locationState: data.location_state || '',
    locationCity: data.location_city || '',
    locationRegion: data.location_region || '',
    documentTitle: data.document_title || '',
    documentGroup: data.document_group || '',
    documentType: data.document_type || '',
    contentType: data.content_type || '',
    fiscalYear: data.fiscal_year || '',
    expiryYear: data.expiry_year || '',
    sapContractNumber: data.sap_contract_number || '',
    sapCustomerNumber: data.sap_customer_number || '',
    totalCustomerRecAmount: data.total_customer_rec_amount || '',
  }
}

const PACAMRebillReconciliationForm = props => {
  const [
    paCAMRebillReconciliationObj,
    setPaCAMRebillReconciliationObj,
  ] = props.pacamrebillrecon
  const BootstrapInput = props.bootStrapInput
  const classes = props.useStyleClass
  const metadataObj = useSelector(state => state.common.locationData)
  const [locationData, setLocationData] = useState(metadataObj)
  const [docArray, setDocArray] = useState([])
  const [docTitleError, setDocTitleError] = useState(false)

  const { docGroupDocType, contentType } = props

  useEffect(() => {
    setDocArray(
      docGroupDocType[contentType][paCAMRebillReconciliationObj.documentGroup]
    )
  }, [paCAMRebillReconciliationObj.documentGroup])

  useEffect(() => {
    if (locationData) {
      setPaCAMRebillReconciliationObj({
        ...paCAMRebillReconciliationObj,
        locationNumber: locationData.location_number,
        locationName: locationData.location_name,
        locationCity: locationData.location_city,
        locationState: locationData.location_state,
        locationRegion: locationData.location_region,
      })
    } else {
      setPaCAMRebillReconciliationObj({
        ...paCAMRebillReconciliationObj,
        locationNumber: '',
        locationName: '',
        locationCity: '',
        locationState: '',
        locationRegion: '',
      })
    }
  }, [locationData])

  useEffect(() => {
    if (
      paCAMRebillReconciliationObj.fiscalYear &&
      paCAMRebillReconciliationObj.fiscalYear.length === 4
    ) {
      setPaCAMRebillReconciliationObj({
        ...paCAMRebillReconciliationObj,
        expiryYear: (
          Number(paCAMRebillReconciliationObj.fiscalYear) + 6
        ).toString(),
      })
    }
  }, [paCAMRebillReconciliationObj.fiscalYear])

  return (
    <div className="contenttype-container">
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="pacamrebillrecon-documentgroup">
          Document Group *
        </InputLabel>
        <NativeSelect
          id="pacamrebillrecon-documentgroup"
          value={paCAMRebillReconciliationObj.documentGroup}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_group
              : paCAMRebillReconciliationObj.documentGroup
          }
          onChange={e =>
            setPaCAMRebillReconciliationObj({
              ...paCAMRebillReconciliationObj,
              documentGroup: e.target.value,
            })
          }
          required
          input={<BootstrapInput />}
        >
          {Object.keys(docGroupDocType[contentType]).map(value => {
            return (
              <option value={value} key={value}>
                {value}
              </option>
            )
          })}
        </NativeSelect>
      </FormControl>
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="pacamrebillrecon-documenttype">
          Document Type *
        </InputLabel>
        <NativeSelect
          id="pacamrebillrecon-documenttype"
          value={paCAMRebillReconciliationObj.documentType}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_type
              : paCAMRebillReconciliationObj.documentType
          }
          onChange={e =>
            setPaCAMRebillReconciliationObj({
              ...paCAMRebillReconciliationObj,
              documentType: e.target.value,
            })
          }
          input={<BootstrapInput />}
          required
        >
          {docArray &&
            docArray.map(value => {
              return (
                <option value={value} key={value}>
                  {value}
                </option>
              )
            })}
        </NativeSelect>
      </FormControl>
      <Autocomplete
        options={metadataObj}
        autoHighlight={true}
        getOptionLabel={option =>
          option.location_number ? option.location_number : ''
        }
        // className={classes.autoComplete}
        className="autocomplete"
        onChange={(index, obj) => setLocationData(obj)}
        // blurOnSelect={true}
        value={{
          location_number: paCAMRebillReconciliationObj.locationNumber,
          location_name: paCAMRebillReconciliationObj.locationName,
          location_city: paCAMRebillReconciliationObj.locationCity,
          location_state: paCAMRebillReconciliationObj.locationState,
          location_region: paCAMRebillReconciliationObj.locationRegion,
        }}
        type={Number}
        disabled={Object.entries(metadataObj).length ? false : true}
        renderInput={params => (
          <FormControl>
            <InputLabel
              shrink
              className={classes.textfieldLabel}
              htmlFor="pacamrebillrecon-autopopulate"
            >
              Location Number{' *'}
            </InputLabel>
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              required
              // label="Location Number"
              className={classes.textField}
              variant="outlined"
            />
          </FormControl>
        )}
      />

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillrecon-locationname"
        >
          Location Name{' *'}
        </InputLabel>
        <TextField
          required
          id="pacamrebillrecon-locationname"
          // label={'Location Name'}
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          style={{ color: 'black' }}
          disabled={true}
          value={paCAMRebillReconciliationObj.locationName || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_name
              : paCAMRebillReconciliationObj.locationName
          }
          onChange={e =>
            setPaCAMRebillReconciliationObj({
              ...paCAMRebillReconciliationObj,
              locationName: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillrecon-state"
        >
          State{' *'}
        </InputLabel>
        <TextField
          required
          id="pacamrebillrecon-state"
          // label={'State'}
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paCAMRebillReconciliationObj.locationState || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_state
              : paCAMRebillReconciliationObj.locationState
          }
          onChange={e =>
            setPaCAMRebillReconciliationObj({
              ...paCAMRebillReconciliationObj,
              locationState: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillrecon-city"
        >
          City{' *'}
        </InputLabel>
        <TextField
          required
          id="pacamrebillrecon-city"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paCAMRebillReconciliationObj.locationCity || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_city
              : paCAMRebillReconciliationObj.locationCity
          }
          onChange={e =>
            setPaCAMRebillReconciliationObj({
              ...paCAMRebillReconciliationObj,
              locationCity: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillrecon-region"
        >
          Region
        </InputLabel>
        <TextField
          required
          id="pacamrebillrecon-region"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paCAMRebillReconciliationObj.locationRegion || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_region
              : paCAMRebillReconciliationObj.locationRegion
          }
          onChange={e =>
            setPaCAMRebillReconciliationObj({
              ...paCAMRebillReconciliationObj,
              locationRegion: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillrecon-documenttitle"
        >
          Document Title{' *'}
        </InputLabel>
        <TextField
          required
          id="pacamrebillrecon-documenttitle"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          inputProps={{ maxLength: '500' }}
          multiline
          rowsMax="4"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMRebillReconciliationObj.documentTitle || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_title
              : paCAMRebillReconciliationObj.documentTitle
          }
          onChange={e => {
            if (e.target.value.length === 500) {
              setDocTitleError(true)
            } else setDocTitleError(false)
            setPaCAMRebillReconciliationObj({
              ...paCAMRebillReconciliationObj,
              documentTitle: e.target.value,
            })
          }}
          error={docTitleError}
          helperText={
            docTitleError ? 'You have reached the limit of 500 characters' : ''
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillrecon-fiscalyear"
        >
          Fiscal Year{' '}
        </InputLabel>
        <TextField
          id="pacamrebillrecon-fiscalyear"
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMRebillReconciliationObj.fiscalYear || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.fiscal_year
              : paCAMRebillReconciliationObj.fiscalYear
          }
          onChange={e => {
            if (e.target.value.length > 4) return false
            else {
              setPaCAMRebillReconciliationObj({
                ...paCAMRebillReconciliationObj,
                fiscalYear: e.target.value,
              })
            }
          }}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="paCAMRebillReconciliationObj-expiryyear"
        >
          Expiry Year{' '}
        </InputLabel>
        <TextField
          type="number"
          id="paCAMRebillReconciliationObj-expiryyear"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMRebillReconciliationObj.expiryYear || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.expiry_year
              : paCAMRebillReconciliationObj.expiryYear
          }
          onChange={e => {
            if (e.target.value.length > 4) return false
            else {
              setPaCAMRebillReconciliationObj({
                ...paCAMRebillReconciliationObj,
                expiryYear: e.target.value,
              })
            }
          }}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillrecon-sapcontractnumber"
        >
          SAP Contract Number{' '}
        </InputLabel>
        <TextField
          id="pacamrebillrecon-sapcontractnumber"
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMRebillReconciliationObj.sapContractNumber || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.sap_contract_number
              : paCAMRebillReconciliationObj.sapContractNumber
          }
          onChange={e =>
            setPaCAMRebillReconciliationObj({
              ...paCAMRebillReconciliationObj,
              sapContractNumber: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillrecon-sapcustomernumber"
        >
          SAP Customer Number{' '}
        </InputLabel>
        <TextField
          id="pacamrebillrecon-sapcustomernumber"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMRebillReconciliationObj.sapCustomerNumber || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.sap_customer_number
              : paCAMRebillReconciliationObj.sapCustomerNumber
          }
          onChange={e =>
            setPaCAMRebillReconciliationObj({
              ...paCAMRebillReconciliationObj,
              sapCustomerNumber: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrebillrecon-totalcustomerrecamount"
        >
          Total Customer Rec Amount{' '}
        </InputLabel>
        <TextField
          id="pacamrebillrecon-totalcustomerrecamount"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMRebillReconciliationObj.totalCustomerRecAmount || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.total_customer_rec_amount
              : paCAMRebillReconciliationObj.totalCustomerRecAmount
          }
          onChange={e =>
            setPaCAMRebillReconciliationObj({
              ...paCAMRebillReconciliationObj,
              totalCustomerRecAmount: e.target.value,
            })
          }
          InputProps={{
            inputComponent: NumberFormatCustomPrefix,
          }}
        />
      </FormControl>
    </div>
  )
}

export default PACAMRebillReconciliation
