import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { workflowMain } from '../common/styles'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import Leased from '../location/UpdateLeased'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import MaterialTable from 'material-table'
import { tableIcons } from '../common/commonFunctionalities'
// import { useToasts } from 'react-toast-notifications'
import { useSelector, useDispatch } from 'react-redux'

export const LeasedLocation = props => {
  const classes = workflowMain()
  // const [locationData, setLocationData] = useState([])
  const [reloadTable, setReloadTable] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [locId, setLocId] = useState()
  const [editLoc, setEditLoc] = useState()
  // const { addToast } = useToasts()
  const leasedLocationData = useSelector(
    state => state.location.leasedLocationData
  )
  const dispatch = useDispatch()
  const columns = [
    {
      field: 'locationId',
      title: 'LocationNumber',
    },
    {
      field: 'assigned_to',
      title: 'AssignedTo',
    },
    {
      field: 'region',
      title: 'Region',
    },
    {
      field: 'valid_location',
      title: 'ValidLocation',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.valid_location === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.valid_location === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.valid_location === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'lease_active',
      title: 'LeaseActive',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.lease_active === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.lease_active === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.lease_active === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'alta_survey',
      title: 'AltaSurvey',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.alta_survey === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.alta_survey === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.alta_survey === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'plat',
      title: 'Plat',
      cellStyle: {
        textAlign: 'left',
      },
      render: rowData => (
        <div>
          {rowData.plat === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.plat === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.plat === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'civil_site_plan',
      title: 'CivilSitePlan',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.civil_site_plan === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.civil_site_plan === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.civil_site_plan === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'conditions_of_approval',
      title: 'ConditionsOfApproval',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.conditions_of_approval === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.conditions_of_approval === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.conditions_of_approval === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'capital_project_request',
      title: 'CapitalProjectRequest',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.capital_project_request === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.capital_project_request === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.capital_project_request === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'agreement_to_lease',
      title: 'AgreementToLease',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.agreement_to_lease === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.agreement_to_lease === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.agreement_to_lease === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'lease',
      title: 'Lease',
      render: rowData => (
        <div>
          {rowData.lease === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.lease === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.lease === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'memorandum_of_lease',
      title: 'MemorandumOfLease',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.memorandum_of_lease === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.memorandum_of_lease === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.memorandum_of_lease === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      feild: 'westwood_review',
      title: 'AcreageReview',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.westwood_review === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.westwood_review === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.westwood_review === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },

    {
      field: 'title_policy',
      title: 'TitlePolicy',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.title_policy === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.title_policy === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.title_policy === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'title_exceptions',
      title: 'TitleExceptions',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.title_exceptions === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.title_exceptions === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.title_exceptions === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'acquisition_notice',
      title: 'AcquisitionNotice',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.acquisition_notice === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.acquisition_notice === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.acquisition_notice === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'transaction_memo_checklist',
      title: 'TransactionMemoChecklist',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.transaction_memo_checklist === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.transaction_memo_checklist === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.transaction_memo_checklist === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'site_develop_agreement',
      title: 'SiteDevelopmentAgreement',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.site_develop_agreement === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.site_develop_agreement === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.site_develop_agreement === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'operation_easement_agreement',
      title: 'Operation&EasementAgreement',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.operation_easement_agreement === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.operation_easement_agreement === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.operation_easement_agreement === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'letter_of_credit',
      title: 'LetterOfCredit',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.letter_of_credit === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.letter_of_credit === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.letter_of_credit === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },
    {
      field: 'rent_commencement',
      title: 'RentCommencement',
      render: rowData => (
        <div style={{ textAlign: 'center' }}>
          {rowData.rent_commencement === 'Yes' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : rowData.rent_commencement === 'No' ? (
            <CloseIcon style={{ color: 'red' }} />
          ) : rowData.rent_commencement === null ? (
            ''
          ) : (
            'N/A'
          )}
        </div>
      ),
    },

    {
      field: 'hold_until',
      title: 'HoldUntil',
    },
  ]
  const getLeasedLocationData = () => {
    setIsLoading(true)
    let url = apiConfig.apiUrl.leased_location

    axios
      .get(url)
      .then(response => {
        let data = response.data
        let tempList = []
        if (response.status === 200) {
          for (const item in data) {
            tempList.push({ ...data[item], locationId: item })
          }
        }
        dispatch({ type: 'SAVE_LEASED_LOCATION_DATA', payload: tempList })

        // setLocationData(tempList)
        setIsLoading(false)
        if (tempList.length === 0) {
          console.log('No records to display for Leased CheckList Data')

          // addToast('No records to display', {
          //   appearance: 'warning',
          //   autoDismiss: true,
          // })
        }
        setReloadTable(false)
        props.setRefreshLeasedData(false)
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (leasedLocationData && leasedLocationData.length === 0) {
      getLeasedLocationData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (reloadTable || props.refreshLeasedData) getLeasedLocationData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadTable, props])

  return (
    <div>
      <Paper className={classes.paper}>
        <MaterialTable
          options={{
            showTitle: false,
            search: true,
            toolbar: true,
            filtering: false,
            exportButton: true,
            exportAllData: true,
            exportFileName: 'Leasedchecklistdata',
            pageSizeOptions: [10, 25, 50, 100],
            loadingType: 'overlay',
            pageSize: 100,
            headerStyle: { position: 'sticky', top: 0 },
            maxBodyHeight: '68vh',
            emptyRowsWhenPaging: false,

            // doubleHorizontalScroll: true,
          }}
          columns={columns}
          data={leasedLocationData}
          icons={tableIcons}
          isLoading={isLoading}
          actions={[
            {
              icon: EditIcon,
              tooltip: 'Edit Checklist',
              onClick: (event, rowData) => {
                setLocId(rowData.locid)
                setEditLoc(true)
              },
            },
          ]}
          localization={{
            body: {
              emptyDataSourceMessage: '',
            },
          }}
        />

        {editLoc ? (
          <Leased
            locId={locId}
            isEdit={editLoc}
            handleClosed={setEditLoc}
            setReloadTable={setReloadTable}
          />
        ) : null}
      </Paper>
    </div>
  )
}
