import React, { Component } from 'react'
import PropTypes from 'prop-types'

import XlsxViewer from './xlsxViewer'

class ExcelViewer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    const container = document.getElementById('pg-viewer')
    const height = container ? container.clientHeight : 0
    const width = container ? container.clientWidth : 0
    this.setState({ height, width })
    const jsonFile = new XMLHttpRequest()
    jsonFile.open('GET', this.props.filePath, true)
    jsonFile.setRequestHeader('Authorization', localStorage.access_token)
    jsonFile.send()
    jsonFile.responseType = 'arraybuffer'

    jsonFile.onload = () => {
      if (jsonFile.status >= 400) {
        this.setState({ error: `fetch error with status ${jsonFile.status}` })
        return
      }
      const resp = jsonFile.response
      this.setState({ data: resp })
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div className="pg-viewer-wrapper">
          <div className="pg-viewer" id="pg-viewer">
            Error
          </div>
        </div>
      )
    }

    if (this.state.data) {
      return (
        <div className="pg-viewer-wrapper">
          <div className="pg-viewer" id="pg-viewer">
            {/* <Driver */}
            <XlsxViewer
              {...this.props}
              width={this.state.width}
              height={this.state.height}
              responseType="arraybuffer"
              data={this.state.data}
            />
          </div>
        </div>
      )
    }
    return (
      <div className="pg-viewer-wrapper">
        <div className="pg-viewer" id="pg-viewer">
          Loading...
        </div>
      </div>
    )
  }
}

ExcelViewer.propTypes = {
  fileType: PropTypes.string.isRequired,
  filePath: PropTypes.string.isRequired,
  onError: PropTypes.func,
  errorComponent: PropTypes.element,
  unsupportedComponent: PropTypes.element,
}

ExcelViewer.defaultProps = {
  onError: () => null,
  errorComponent: null,
  unsupportedComponent: null,
}

export default ExcelViewer
