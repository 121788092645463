import React, { Component } from 'react'
import mammoth from 'mammoth'
import * as jsPDF from 'jspdf'

const margins = {
  top: 40,
  bottom: 60,
  left: 40,
  width: 522,
}

let htmlPage =
  '<!doctype><html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8" /><style> @CHARSET "UTF-8";.page-break { page -break-after: always;page-break-inside: avoid;clear:both;}.page-break-before {page -break-before: always; page-break-inside: avoid;clear:both; }#html-2-pdfwrapper{  position: absolute;left: 20px;top: 50px; bottom: 0; overflow: auto;width: 600px;}</style><script type="text/javascript" src="libs/png_support/zlib.js"></script><script type="text/javascript" src="libs/png_support/png.js"></script><script type="text/javascript" src="jspdf.plugin.addimage.js"></script><script type="text/javascript" src="jspdf.plugin.png_support.js"></script><script type="text/javascript" src="jspdf.js"></script></head ><body><div id="html-2-pdfwrapper">'

export default class WordViewer extends Component {
  state = {
    convertedDoc: null,
  }

  componentDidMount() {
    const jsonFile = new XMLHttpRequest()
    jsonFile.open('GET', this.props.filePath, true)
    jsonFile.setRequestHeader('Authorization', localStorage.access_token)
    jsonFile.send()
    jsonFile.responseType = 'arraybuffer'
    //image.contentType
    let options = {
      // transformDocument: mammoth.transforms.paragraph(transformParagraph),
      convertImage: mammoth.images.imgElement(function(image) {
        return image.read('base64').then(function(imageBuffer) {
          return {
            src: 'data:' + image.contentType + ';base64,' + imageBuffer,
            width: '80%',
          }
        })
      }),
    }
    let that = this
    jsonFile.onreadystatechange = () => {
      if (jsonFile.readyState === 4 && jsonFile.status === 200) {
        mammoth
          .convertToHtml({ arrayBuffer: jsonFile.response }, options)
          .then(result => {
            var text = result.value // The raw text
            let modifiedText = htmlPage + text + '</div></body></html>'
            var pdf = new jsPDF({
              orientation: 'p',
              unit: 'pt',
              format: 'a4',
            })

            let outputfile
            pdf.setFontSize(18)

            pdf.fromHTML(
              modifiedText,
              margins.left,
              margins.top,
              {
                width: margins.width, // max width of content on PDF
              },
              function(dispose) {
                if (dispose.ready) {
                  outputfile = pdf.output('blob')
                  that.setState({ convertedDoc: outputfile })
                }
              },
              margins
            )
          })
          .catch(err => {
            console.log('Error in document', err)
          })
          .done()
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.convertedDoc &&
      this.state.convertedDoc !== prevState.convertedDoc
    ) {
      let iframe = document.getElementById('myFrame')
      // iframe.src = this.state.convertedDoc
      let blobURL = URL.createObjectURL(this.state.convertedDoc)

      iframe.src = blobURL
    }
  }

  render() {
    if (this.state.convertedDoc) {
      return (
        <iframe
          title={this.props.title}
          id="myFrame"
          src={this.state.convertedDoc}
          width="100%"
          height="100%"
        />
      )
    }
    return (
      <iframe
        title={this.props.title}
        id="myFrame"
        srcdoc="<p style='text-align:center;'>Loading...</p>"
        src={this.state.convertedDoc}
        width="100%"
        height="100%"
      />
    )
  }
}
