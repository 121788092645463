import React from 'react'

export const properties = {
  // clientId: '',
  indexSubmitPostKey: '7db25859ffd922010569336cf9b5e34ddee7b89f',
  search: {
    URL: 'https://rdmcoreapi.dev.target.com/search/metadata/',
    key: '?key=7db25859ffd922010569336cf9b5e34ddee7b89f',
    searchFields: ['LocationNumber', 'DocumentType', 'DocumentGroup'],
    metadataViewFields: [
      {
        title: 'LocationNumber',
        field: 'LocationNumber',
        defaultSort: 'asc',
      },
      {
        title: 'LocationName',
        field: 'LocationName',
      },
      {
        title: 'LocationState',
        field: 'LocationState',
      },
      {
        title: 'LocationCity',
        field: 'LocationCity',
      },

      {
        title: 'LocationRegion',
        field: 'LocationRegion',
      },
      {
        title: 'DocumentTitle',
        field: 'DocumentTitle',
        render: rowData =>
          rowData.DocumentTitle.length > 100 ? (
            <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
              {rowData.DocumentTitle}
            </div>
          ) : (
            <div>{rowData.DocumentTitle}</div>
          ),
      },
      {
        title: 'DocumentDate',
        field: 'DocumentDate',
        customSort: (a, b) => {
          if (a.DocumentDate === '' || typeof a.DocumentDate === 'undefined') {
            return -1
          } else if (
            b.DocumentDate === '' ||
            typeof b.DocumentDate === 'undefined'
          ) {
            return 1
          }
          return (
            new Date(a.DocumentDate).getTime() -
            new Date(b.DocumentDate).getTime()
          )
        },
      },

      {
        title: 'PhysicalFileNumber',
        field: 'PhysicalFileNumber',
        // defaultSort: 'asc',
        customSort: (a, b) => {
          if (
            typeof a.PhysicalFileNumber === 'undefined' ||
            a.PhysicalFileNumber === '' ||
            typeof a.PhysicalFileNumber === 'object'
          ) {
            return -1
          } else if (
            typeof b.PhysicalFileNumber === 'undefined' ||
            a.PhysicalFileNumber === '' ||
            typeof a.PhysicalFileNumber === 'undefined' ||
            typeof a.PhysicalFileNumber === 'object'
          ) {
            return 1
          }
          return (
            parseFloat(a.PhysicalFileNumber) - parseFloat(b.PhysicalFileNumber)
          )
        },
      },

      {
        title: 'SapContractNumber',
        field: 'SAPContractNumber',
        customSort: (a, b) => {
          if (
            a.SAPContractNumber === '' ||
            typeof a.SAPContractNumber === 'undefined' ||
            typeof a.SAPContractNumber === 'object'
          ) {
            return -1
          } else if (
            b.SAPContractNumber === '' ||
            typeof b.SAPContractNumber === 'undefined' ||
            typeof b.SAPContractNumber === 'object'
          ) {
            return 1
          }
          return parseInt(a.SAPContractNumber) - parseInt(b.SAPContractNumber)
        },
      },
      {
        title: 'DocumentGroup',
        field: 'DocumentGroup',
      },
      {
        title: 'DocumentType',
        field: 'DocumentType',
      },
      {
        title: 'DocumentStatus',
        field: 'DocumentStatus',
      },
      {
        title: 'RecordingStatus',
        field: 'RecordingStatus',
      },
      {
        title: 'DocumentPurpose',
        field: 'DocumentPurpose',
        render: rowData => (
          <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
            {rowData.DocumentPurpose}
          </div>
        ),
      },

      {
        title: 'FileLetter',
        field: 'FileLetter',
      },

      {
        title: 'FileLetterDescription',
        field: 'FileLetterDescription',
      },
      {
        title: 'FiscalYear',
        field: 'FiscalYear',
        render: rowData => (
          <span>{rowData.FiscalYear === '0' ? '' : rowData.FiscalYear}</span>
        ),
      },
      {
        title: 'CreatedDate',
        field: 'CreatedDate',
        customSort: (a, b) => {
          if (a.CreatedDate === '' || typeof a.CreatedDate === 'undefined') {
            return -1
          } else if (
            b.CreatedDate === '' ||
            typeof b.CreatedDate === 'undefined'
          ) {
            return 1
          }
          return (
            new Date(a.CreatedDate).getTime() -
            new Date(b.CreatedDate).getTime()
          )
        },
      },
      {
        title: 'FileUrl',
        field: 'FileUrl',
        hidden: true,
      },

      // {
      //   title: 'ContentType',
      //   field: 'ContentType',
      // },

      // {
      //   title: 'IsUrgent',
      //   field: 'IsUrgent',
      // },
      // {
      //   title: 'CreatedDate',
      //   field: 'CreatedDate',
      // },
      // {
      //   title: 'CreatedBy',
      //   field: 'CreatedBy',
      // },
      // {
      //   title: 'ModifiedDate',
      //   field: 'ModifiedDate',
      // },
      // {
      //   title: 'ModifiedBy',
      //   field: 'ModifiedBy',
      // },
    ],
  },
  getFileURL: 'https://rdmapi.dev.target.com/',
  new_url: 'https://rdmcoreapi.dev.target.com/',

  base_AD_Groups: 'APP-RDMP-PUBLICDOCUMENTS-STAGE',

  ContentType_AD_group: 'APP-RDMP-PA',

  auth: {
    URL: 'https://rdmapi.dev.target.com/auth/?adgroups=',
  },
}
