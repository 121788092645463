import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import MaterialTable from 'material-table'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogTitle from '@material-ui/core/DialogTitle'
import { tableIcons } from '../common/commonFunctionalities'

const CheckInOut = ({
  selectedRows,
  fileCheckInOut,
  setOperation,
  operation,
  handleClickOpen,
  handleTableClose,
  tableOpen,
}) => {
  const [finalData, setFinalData] = useState([...selectedRows])

  useEffect(() => {
    setFinalData(selectedRows)
  }, [selectedRows])

  // const columnsCheckOut = [
  const [columnsCheckOut] = useState([
    {
      field: 'file_letter',
      title: 'File Letter',
      defaultSort: 'asc',
      editable: 'never',
    },
    {
      field: 'file_letter_desc',
      title: 'File Letter Description',
      editable: 'never',
    },

    {
      field: 'total_files',
      title: 'Total Files',
      editable: 'never',
    },
    {
      field: 'checked_out_files',
      title: 'Already Checkedout Files',
      editable: 'never',
      emptyValue: '0',
    },
    {
      field: 'check_out_files',
      title: 'Checkout Files',
      emptyValue: '0',
    },
    {
      field: 'internal_requester',
      title: 'Internal Requester',
      emptyValue: 'None',
    },

    {
      field: 'external_requester',
      title: 'External Requester',
      emptyValue: 'None',
    },
  ])

  const columnsCheckIn = [
    {
      field: 'file_letter',
      title: 'File Letter',
      defaultSort: 'asc',
      editable: 'never',
    },
    {
      field: 'file_letter_desc',
      title: 'File Letter Description',
      editable: 'never',
    },

    {
      field: 'total_files',
      title: 'Total Files',
      editable: 'never',
    },
    {
      field: 'checked_out_files',
      title: 'Already Checkedout Files',
      editable: 'never',
      emptyValue: '0',
    },
    {
      field: 'check_out_files',
      title: 'CheckIn Files',
      emptyValue: '0',
    },
    {
      field: 'internal_requester',
      title: 'Internal Requester',
      editable: 'never',
    },
    {
      field: 'external_requester',
      title: 'External Requester',
      editable: 'never',
    },
  ]
  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleClickOpen('CHECKOUT')}
        disabled={selectedRows.length === 0}
      >
        CheckOut
      </Button>
      &nbsp;
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleClickOpen('CHECKIN')}
        disabled={selectedRows.length === 0}
      >
        CheckIn
      </Button>
      <ShowTable
        open={tableOpen}
        finalData={finalData}
        setFinalData={setFinalData}
        columns={operation === 'CHECKOUT' ? columnsCheckOut : columnsCheckIn}
        title={operation === 'CHECKOUT' ? 'CheckOut' : 'CheckIn'}
        handleClose={handleTableClose}
        fileCheckInOut={fileCheckInOut}
        operation={operation}
      />
    </div>
  )
}

const ShowTable = ({
  open,
  finalData,
  setFinalData,
  columns,
  handleClose,
  fileCheckInOut,
  title,
  operation,
}) => {
  // const updateRecord = (newValue, rowData, columnDef) => {
  //   const dataUpdate = [...finalData]
  //   const rowUpdate = Object.assign({}, rowData)
  //   const index = rowData.tableData.id
  //   rowUpdate[columnDef.field] = newValue
  //   dataUpdate[index] = rowUpdate
  //   setFinalData([...dataUpdate])
  // }

  const updateRecord = (newData, oldData) => {
    const dataUpdate = [...finalData]
    const index = oldData.tableData.id
    dataUpdate[index] = newData
    setFinalData([...dataUpdate])
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick="true"
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="xl"
    >
      <DialogContent>
        <MaterialTable
          title={`${title} Files`}
          options={{ search: false }}
          columns={columns}
          data={finalData}
          icons={tableIcons}
          //   editable={{
          //     isEditable: true,
          //     // onBulkUpdate: changes =>
          //     //   new Promise((resolve, reject) => {
          //     //     setTimeout(() => {
          //     //       resolve()
          //     //     }, 1000)
          //     //   }),
          //     // onRowDelete: oldData =>
          //     //   new Promise((resolve, reject) => {
          //     //     setTimeout(() => {
          //     //       resolve()
          //     //     }, 1000)
          //     //   }),
          //   }}

          editable={{
            onRowUpdate: (newData, oldData) => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (newData['check_out_files'] > newData.total_files) {
                    //this works for both checkin and checkout
                    reject()
                  } else if (
                    operation === 'CHECKOUT' &&
                    newData['check_out_files'] >
                      newData.total_files - newData.checked_out_files
                  ) {
                    //to be applied only for checkout operation
                    reject()
                  } else if (
                    operation === 'CHECKIN' &&
                    newData['check_out_files'] > newData.checked_out_files
                  ) {
                    //to be applied only for checkin operation
                    reject()
                  } else {
                    updateRecord(newData, oldData)

                    resolve()
                  }
                }, 500)
              })
            },
            onBulkUpdate: changes =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  Object.values(changes).map(eachChange => {
                    const { newData, oldData } = eachChange

                    if (newData['check_out_files'] > newData.total_files) {
                      //this works for both checkin and checkout
                      return reject()
                    } else if (
                      // columnDef.field === 'check_out_files' &&
                      operation === 'CHECKOUT' &&
                      newData['check_out_files'] >
                        newData.total_files - newData.checked_out_files
                    ) {
                      //to be applied only for checkout operation
                      return reject()
                    } else if (
                      // columnDef.field === 'check_out_files' &&
                      operation === 'CHECKIN' &&
                      newData['check_out_files'] > newData.checked_out_files
                    ) {
                      //to be applied only for checkin operation
                      return reject()
                    } else {
                      // updateRecord(newValue, rowData, columnDef)
                      updateRecord(newData, oldData)

                      return resolve()
                    }
                  })
                }, 500)
              }),
          }}
          localization={{
            body: {
              emptyDataSourceMessage: '',
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          onClick={() => fileCheckInOut(finalData)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CheckInOut
