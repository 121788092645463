/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment } from 'react'
import Button from '@material-ui/core/Button'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import './SearchStyle.css'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { useEffect } from 'react'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputBase from '@material-ui/core/InputBase'
import { useAuth } from '@praxis/component-auth'
import { getMembership } from '../common/validation'
import { useDispatch, useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

// import { useHistory } from 'react-router-dom'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',

    border: '1px solid #AC0000',
    fontSize: 14,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#AC0000',
      boxShadow: '#AC0000',
    },
  },
}))(InputBase)

const useStyles = makeStyles(theme => ({
  root: {
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    display: 'inline-block',
  },
  margin: {
    margin: theme.spacing(1),
    minWidth: '200px',
  },
  margin1: {
    margin: theme.spacing(1),
    minWidth: '200px',
    maxWidth: '200px',
  },
  margin2: {
    margin: theme.spacing(1),
    width: '100px',
    marginTop: '-6px',
  },
  button: {
    margin: theme.spacing(1),
    display: 'inline-block',
    // width: 150,
    color: 'white',
    marginTop: 20,
    backgroundColor: '#AC0000',
    '&:hover': {
      backgroundColor: '#AC0000',
    },
  },
  fab: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundColor: '#AC0000',
    '&:hover': {
      backgroundColor: '#AC0000',
    },
  },
  searchSection: {
    // display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#EEE',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: '10px 600px',
  },
}))
export default function SearchSectionOnly(props) {
  const auth = useAuth()

  const { session } = auth

  const search = useSelector(state => state.search.searchState) //redux
  const documentGroupTypePayload = useSelector(
    state => state.common.documentGroupAndDocumentType
  )
  const adGroups = useSelector(state => state.common.allAdgroupList)
  const dispatch = useDispatch()

  const [disableSearch, setDisableSearch] = useState(true)
  const [searchForm, setSearchForm] = useState({
    locationNumber: '',
    address: '',
    documentType: '',
    documentGroup: '',
  })

  const classes = useStyles()
  const inputLabel = React.useRef(null)

  useEffect(() => {
    setSearchForm({
      locationNumber: search.locationNumber,
      address: search.address,
      documentType: search.documentType,
      documentGroup: search.documentGroup,
    })
  }, [])

  useEffect(() => {
    if (session.userInfo && adGroups && search.queryTerm === '') {
      // let typeOfMembership = getMembership(session.userInfo)
      let typeOfMembership = getMembership(adGroups)

      dispatch({
        type: 'SAVE_SEARCH_FORM',
        payload: {
          queryTerm: typeOfMembership,
          searchAccess: typeOfMembership,
        },
      })
    }
  }, [session, adGroups])

  useEffect(() => {
    if (
      !accessPayload &&
      Object.entries(documentGroupTypePayload).length !== 0
    ) {
      let finalObj = { '': [''] }

      let docGroup = documentGroupTypePayload
      let finalPayload = Object.assign(finalObj, docGroup)
      setAccessPayload(finalPayload)
    }
  }, [documentGroupTypePayload])

  const [documentTypeArr, setDocumentTypeArr] = useState([])
  const [accessPayload, setAccessPayload] = useState(null)
  //   const authDetails = useSelector(state => state.common.authDetails)
  // const history = useHistory()

  useEffect(() => {
    accessPayload && setDocumentTypeArr(accessPayload[searchForm.documentGroup])
  }, [searchForm.documentGroup])

  useEffect(() => {
    if (
      searchForm.locationNumber === '' &&
      searchForm.address === '' &&
      searchForm.documentType === '' &&
      searchForm.documentGroup === ''
    ) {
      setDisableSearch(true)
    } else setDisableSearch(false)
  }, [searchForm])

  // if (
  //   Object.keys(authDetails).length === 0 ||
  //   !Object.prototype.hasOwnProperty.call(authDetails, 'access_feature_search')
  // ) {
  //   history.push('/unauthorised')
  // }
  return (
    <div style={{ width: '85%', marginLeft: '7%' }}>
      <Paper>
        <div className={classes.searchSection}>
          <Grid container justify="center">
            <Grid item>
              <TextField
                className={classes.margin}
                label="Location Number"
                id="locationNumber"
                value={searchForm.locationNumber}
                margin="normal"
                onKeyPress={event => {
                  //   console.log("called")
                  if (event.key === 'Enter') {
                    if (searchForm.locationNumber) {
                      dispatch({
                        type: 'SAVE_SEARCH_FORM',
                        payload: {
                          isClicked: true,
                          displaySearchResults: true,
                          locationNumber: searchForm.locationNumber,
                        },
                      })
                    }
                  }
                }}
                onChange={e => {
                  setSearchForm({
                    ...searchForm,
                    locationNumber: e.target.value,
                  })
                  dispatch({
                    type: 'SAVE_SEARCH_FORM',
                    payload: {
                      ...searchForm,
                      locationNumber: e.target.value,
                    },
                  })
                }}
              />
              <TextField
                className={classes.margin}
                label="Text Search"
                id="address"
                value={searchForm.address}
                margin="normal"
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    if (searchForm.address) {
                      dispatch({
                        type: 'SAVE_SEARCH_FORM',
                        payload: {
                          displaySearchResults: true,
                          isClicked: true,
                          address: searchForm.address,
                        },
                      })
                    }
                  }
                }}
                onChange={e => {
                  setSearchForm({ ...searchForm, address: e.target.value })
                  dispatch({
                    type: 'SAVE_SEARCH_FORM',
                    payload: { ...searchForm, address: e.target.value },
                  })
                }}
              />
              <FormControl
                className={classes.margin1}
                id="search-form-documentgroup"
              >
                <InputLabel
                  ref={inputLabel}
                  htmlFor="advancedSearchFields-documentgroup"
                >
                  Document Group
                </InputLabel>
                <NativeSelect
                  id="search-documentgroup-dropdown"
                  value={searchForm.documentGroup}
                  onChange={e => {
                    setSearchForm({
                      ...searchForm,
                      documentGroup: e.target.value,
                      documentType: '',
                    })
                    dispatch({
                      type: 'SAVE_SEARCH_FORM',
                      payload: {
                        ...searchForm,
                        documentGroup: e.target.value,
                        documentType: '',
                      },
                    })
                  }}
                >
                  {accessPayload &&
                    Object.keys(accessPayload).map(value => {
                      return (
                        <option value={value} key={value}>
                          {value}
                        </option>
                      )
                    })}
                </NativeSelect>
              </FormControl>
              <FormControl
                className={classes.margin1}
                id="search-form-documenttype"
              >
                <InputLabel
                  ref={inputLabel}
                  htmlFor="advancedSearchFields-documenttype"
                >
                  Document Type
                </InputLabel>
                <NativeSelect
                  id="search-documenttype-dropdown"
                  value={searchForm.documentType}
                  onChange={e => {
                    setSearchForm({
                      ...searchForm,
                      documentType: e.target.value,
                    })
                    dispatch({
                      type: 'SAVE_SEARCH_FORM',
                      payload: {
                        ...searchForm,
                        documentType: e.target.value,
                      },
                    })
                  }}
                >
                  {documentTypeArr &&
                    documentTypeArr.sort().map(value => {
                      return (
                        <option value={value} key={value}>
                          {value}
                        </option>
                      )
                    })}
                </NativeSelect>
              </FormControl>
              <FormControl className={classes.margin2}>
                <InputLabel shrink htmlFor="demo-customized-select-native" />
                <NativeSelect
                  id="demo-customized-select-native1"
                  value={search.queryTerm}
                  onChange={e => {
                    dispatch({
                      type: 'SAVE_SEARCH_FORM',
                      payload: {
                        queryTerm: e.target.value,
                      },
                    })
                  }}
                  input={<BootstrapInput />}
                >
                  {search.searchAccess === 'both' && (
                    <Fragment>
                      <option value={'both'}>RE & PA</option>
                      <option value={'re'}>RE</option>
                      <option value={'pa'}>PA</option>
                    </Fragment>
                  )}

                  {search.searchAccess === 're' && (
                    <Fragment>
                      <option value={'re'}>RE</option>
                    </Fragment>
                  )}

                  {search.searchAccess === 'pa' && (
                    <Fragment>
                      <option value={'pa'}>PA</option>
                    </Fragment>
                  )}
                </NativeSelect>
              </FormControl>

              <Button
                variant="contained"
                className={classes.button}
                onClick={() => {
                  dispatch({
                    type: 'SAVE_SEARCH_FORM',
                    payload: {
                      ...searchForm,
                      isClicked: true,
                      displaySearchResults: true,
                    },
                  })
                }}
                disabled={disableSearch}
              >
                Search
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => {
                  setSearchForm({
                    locationNumber: '',
                    address: '',
                    documentType: '',
                    documentGroup: '',
                  })
                  dispatch({
                    type: 'SAVE_SEARCH_FORM',
                    payload: {
                      locationNumber: '',
                      address: '',
                      documentType: '',
                      documentGroup: '',
                    },
                  })
                }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
      <br />
    </div>
  )
}
