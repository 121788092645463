/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import { useDispatch } from 'react-redux'
import { fetchContentTypeObj } from '../common/fetchContentTypeObj'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import apiConfig from '../../config/apiConfig'
import { checkFileStyles } from '../common/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ConsoleWindow from '../consolewindow/ConsoleWindow'
import IconButton from '@material-ui/core/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowCircleUp,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import { getFileExtensionType } from '../common/commonFunctionalities'

// import _ from 'lodash'

const CheckFile = ({
  fileNameSearch,
  unmodifiedData,
  type,
  metadata,
  ...props
}) => {
  const classes = checkFileStyles()
  const fileInput = React.useRef(null)

  const metaDataSearch = metadata
  const [percentCompleted, setPercentageComplete] = useState(0)
  const auth = useAuth()
  const { session } = auth
  const userInfo = session.userInfo
  const dispatch = useDispatch()
  // const iframeTriger = useSelector(state => state.common.triggerIframeRerender)
  const { addToast } = useToasts()
  const [open, setOpen] = React.useState(false)
  const [checkInOptions, setCheckInOptions] = React.useState(false)

  /**
   * When comoponent is loaded, function is run to check if metadatasearch is empty,
   * if it is add checkoutstate k,v pair to metadatasearch redux state.
   */
  useEffect(() => {
    let finalPayload

    if (
      metaDataSearch != null &&
      metaDataSearch[fileNameSearch] &&
      // eslint-disable-next-line no-prototype-builtins
      !metaDataSearch[fileNameSearch].hasOwnProperty('checkoutState')
      //    ||
      // Object.entries(metaDataSearch).length === 0
    ) {
      if (
        !unmodifiedData.checkOutUserId ||
        unmodifiedData.checkOutUserId === 'null'
      ) {
        finalPayload = getUpdatedReduxStateObj('checkout')
      } else if (unmodifiedData.checkOutUserId === userInfo.lanId) {
        finalPayload = getUpdatedReduxStateObj('checkin')
      } else if (unmodifiedData.checkOutUserId !== userInfo.lanId) {
        finalPayload = getLockedReduxStateObj(
          'locked',
          unmodifiedData.checkOutUserName
        )
      }
      if (type === 'workflow') {
        dispatch({
          type: 'SAVE_WORKFLOW_METADATA',
          payload: finalPayload,
        })
      } else {
        dispatch({
          type: 'SAVE_SEARCH_METADATA',
          payload: finalPayload,
        })
      }
    }
  }, [metadata])
  // Add checkoutstate to metadatasearch redux state
  const getUpdatedReduxStateObj = value => {
    let metaDataSearchCopy = Object.assign({}, metaDataSearch)
    let bodyObj = fetchContentTypeObj(
      unmodifiedData,
      userInfo,
      unmodifiedData.type,
      unmodifiedData.tossFilePath
    )
    let mergedObj = Object.assign(
      {},
      JSON.parse(bodyObj),
      metaDataSearchCopy[fileNameSearch]
    )
    mergedObj['checkoutState'] = value
    metaDataSearchCopy[fileNameSearch] = mergedObj
    return metaDataSearchCopy
  }
  // Add checkoutstate, checkoutusername to metadatasearch redux state
  const getLockedReduxStateObj = (value, username) => {
    let metaDataSearchCopy = Object.assign({}, metaDataSearch)
    let bodyObj = fetchContentTypeObj(
      unmodifiedData,
      userInfo,
      unmodifiedData.type,
      unmodifiedData.tossFilePath
    )
    let mergedObj = Object.assign(
      {},
      JSON.parse(bodyObj),
      metaDataSearchCopy[fileNameSearch]
    )
    mergedObj['checkoutState'] = value
    mergedObj['checkedOutBy'] = username
    metaDataSearchCopy[fileNameSearch] = mergedObj
    return metaDataSearchCopy
  }
  const checkIfFileIsLockedOrNot = e => {
    let url =
      apiConfig.apiUrl.get_batch_metadata +
      unmodifiedData.id +
      '&contentType=' +
      unmodifiedData.contentType +
      apiConfig.apiUrl.keyUrl
    axios.get(url).then(async res => {
      if (res.status !== 200) {
        console.log(`Server error ${res.status}`)
        addToast('Error! Could not reach the server!', {
          appearance: 'error',
          autoDismiss: true,
        })
      } else if (
        (res.data.checkOutUserId || res.data.checkOutUserName) &&
        res.data.checkOutUserName !== 'null' &&
        res.data.checkOutUserId !== 'null'
      ) {
        console.log(`Checkoutname already exists!`)
        // set checkoutstate to locked and username to something
        let obj = getLockedReduxStateObj('locked', res.data.checkOutUserName)
        if (type === 'workflow') {
          dispatch({
            type: 'SAVE_WORKFLOW_METADATA',
            payload: obj,
          })
        } else {
          dispatch({
            type: 'SAVE_SEARCH_METADATA',
            payload: obj,
          })
        }
      } else {
        const payload = {
          id: unmodifiedData.id,
          contentType: unmodifiedData.contentType,
          checkOutUserId: userInfo.lanId,
          checkOutUserName: userInfo.firstName + '.' + userInfo.lastName,
        }
        return axios
          .post(
            apiConfig.apiUrl.update_checkout_metadata +
              apiConfig.apiUrl.key_url,
            payload
          )
          .then(res => {
            if (res.data.includes('successfully!')) {
              if (type === 'workflow') {
                dispatch({
                  type: 'SAVE_WORKFLOW_METADATA',
                  payload: getUpdatedReduxStateObj('checkin'),
                })
              } else {
                dispatch({
                  type: 'SAVE_SEARCH_METADATA',
                  payload: getUpdatedReduxStateObj('checkin'),
                })
              }
              document.getElementById('checkout-link').click()
            } else {
              addToast('Error! Could not upload Checkout Metadata!', {
                appearance: 'error',
                autoDismiss: true,
              })
            }
          })
      }
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const fileUpload = () => {
    setOpen(false)
    if (type === 'workflow') {
      dispatch({
        type: 'SAVE_WORKFLOW_METADATA',
        payload: getUpdatedReduxStateObj('loading'),
      })
    } else {
      dispatch({
        type: 'SAVE_SEARCH_METADATA',
        payload: getUpdatedReduxStateObj('loading'),
      })
    }

    const uploadedFileExtension = getFileExtensionType(
      fileInput.current.files[0].name
    )
    let currentFilename = unmodifiedData.destTossFilePath.split('/')

    let url
    url =
      apiConfig.apiUrl.update_checkin_metadata +
      `?path=${unmodifiedData.destTossFilePath}` +
      `&id=${unmodifiedData.id}` +
      `&contentType=${unmodifiedData.contentType}` +
      `&userId=${userInfo.lanId}` +
      `&userName=${userInfo.firstName}.${userInfo.lastName}` +
      apiConfig.apiUrl.keyUrl
    if (uploadedFileExtension !== getFileExtensionType(currentFilename[1])) {
      let name = currentFilename[1].split('.').shift()
      name = name + '.' + uploadedFileExtension
      url =
        apiConfig.apiUrl.update_checkin_metadata +
        `?path=${currentFilename[0] + '/' + name}` +
        `&id=${unmodifiedData.id}` +
        `&contentType=${unmodifiedData.contentType}` +
        `&userId=${userInfo.lanId}` +
        `&userName=${userInfo.firstName}.${userInfo.lastName}` +
        apiConfig.apiUrl.keyUrl
    }

    const formData = new FormData()
    formData.append('file', fileInput.current.files[0])
    let config = {
      onUploadProgress: function(progressEvent) {
        let _percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        setPercentageComplete(_percentCompleted)
      },
    }
    axios
      .put(url, formData, config)
      .then(res => {
        if (res.data.includes('Successfully uploaded')) {
          if (
            uploadedFileExtension !== getFileExtensionType(currentFilename[1])
          ) {
            dispatch({
              type: 'TRIGGER_IFRAME_COUNTER',
              payload: 'reloadWithDelay',
            })
          } else {
            dispatch({
              type: 'TRIGGER_IFRAME_COUNTER',
              payload: 'reload',
            })
          }

          if (type === 'workflow') {
            dispatch({
              type: 'SAVE_WORKFLOW_METADATA',
              payload: getUpdatedReduxStateObj('checkout'),
            })
          } else {
            dispatch({
              type: 'SAVE_SEARCH_METADATA',
              payload: getUpdatedReduxStateObj('checkout'),
            })
          }
          addToast('File Upload successful. Reloading file...', {
            appearance: 'success',
            autoDismiss: true,
          })
        }
      })
      .catch(err => {
        if (type === 'workflow') {
          dispatch({
            type: 'SAVE_WORKFLOW_METADATA',
            payload: getUpdatedReduxStateObj('checkin'),
          })
        } else {
          dispatch({
            type: 'SAVE_SEARCH_METADATA ',
            payload: getUpdatedReduxStateObj('checkin'),
          })
        }
        addToast('Error while uploading file! Please try again!', {
          appearance: 'error',
          autoDismiss: true,
        })
      })
    return
  }
  const handleFileUpload = (e, forceUpload = false) => {
    if (!fileInput.current.files.length) {
      e.preventDefault()
      return
    }

    if (fileInput.current.files[0].name !== unmodifiedData.fileName) {
      setOpen(true)
      return
    } else {
      fileUpload()
    }
  }
  const handleMouseLeave = event => {
    event.persist()
    setCheckInOptions(false)
  }

  const cancelCheckout = () => {
    const url = apiConfig.apiUrl.cancel_checkout + apiConfig.apiUrl.key_url

    const payload = {
      id: unmodifiedData.id,
      contentType: unmodifiedData.contentType,
      checkOutUserId: userInfo.lanId,
      checkOutUserName: userInfo.firstName + '.' + userInfo.lastName,
    }

    axios
      .post(url, payload)
      .then(response => {
        if (response.status === 200) {
          if (type === 'workflow') {
            dispatch({
              type: 'SAVE_WORKFLOW_METADATA',
              payload: getUpdatedReduxStateObj('checkout'),
            })
          } else {
            dispatch({
              type: 'SAVE_SEARCH_METADATA',
              payload: getUpdatedReduxStateObj('checkout'),
            })
          }
          addToast('File check out cancelled successfully', {
            appearance: 'success',
            autoDismiss: true,
          })
        }
      })
      .catch(err => {
        addToast('Error while cancelling check-out! Please try again!', {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  const [consoleWindowProp, setConsoleWindowProp] = useState({
    loadConsoleWindowProp: false,
    dialogTitle: 'Check-In',
    dialogContent: 'Are you sure you want to cancel checkout for this file?',
    dialogFirstButton: 'Yes',
    dialogSecondButton: 'No',
    buttonClicked: ``,
  })

  return (
    <div className="checkout-container">
      {metaDataSearch &&
        metaDataSearch[fileNameSearch] &&
        metaDataSearch[fileNameSearch].checkoutState === 'checkin' && (
          <div
            onMouseEnter={() => setCheckInOptions(true)}
            onMouseLeave={handleMouseLeave}
          >
            <Button
              color="primary"
              variant="contained"
              component="span"
              disableElevation={true}
              disableRipple={true}
            >
              Check-In/Cancel
            </Button>
            &nbsp;
            {/* <Fragment> */}
            <input
              accept={apiConfig.metadata.checkoutAcceptedInputFile}
              style={{ display: 'none' }}
              id="checkin-button-file"
              type="file"
              ref={fileInput}
              onChange={handleFileUpload}
            />
            <label htmlFor="checkin-button-file">
              {/* <Button color="primary" variant="contained" component="span">
                Check In */}
              <Tooltip title="Check In">
                <IconButton
                  aria-label="check-in file"
                  component="span"
                  disableRipple
                  style={
                    checkInOptions
                      ? { padding: '0' }
                      : { display: 'none', padding: '0' }
                  }
                  color="green"
                  size="medium"
                >
                  <FontAwesomeIcon
                    icon={faArrowCircleUp}
                    size="lg"
                    color="green"
                  />
                </IconButton>
              </Tooltip>
              {/* </Button> */}
            </label>
            &nbsp;
            <Tooltip title="Cancel check-out">
              <IconButton
                // color="primary"
                aria-label="check-in file"
                component="span"
                onClick={cancelCheckout}
                style={
                  checkInOptions
                    ? { padding: '0' }
                    : { display: 'none', padding: '0' }
                }
                size="medium"
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size="lg"
                  color="orange"
                />
                {/* <CancelIcon style={{color:"orange"}} /> */}
              </IconButton>
            </Tooltip>
            {/* </Fragment> */}
          </div>
        )}
      {metaDataSearch &&
        metaDataSearch[fileNameSearch] &&
        metaDataSearch[fileNameSearch].checkoutState === 'checkout' && (
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={checkIfFileIsLockedOrNot}
            >
              Check Out
            </Button>
          </div>
        )}
      <a
        download
        href={
          apiConfig.apiUrl.getFileUrl +
          unmodifiedData.destTossFilePath +
          '&download=yes'
        }
        className={classes.checkoutButtonLink}
        id="checkout-link"
      >
        File
      </a>
      {metaDataSearch &&
        metaDataSearch[fileNameSearch] &&
        metaDataSearch[fileNameSearch].checkoutState === 'locked' && (
          <div className="locked-container">
            <Tooltip
              title={
                metaDataSearch &&
                metaDataSearch[fileNameSearch] &&
                'Checked By ' +
                  metaDataSearch[fileNameSearch].checkedOutBy +
                  ' '
              }
              // open={true}
              open={props.currentTab === fileNameSearch}
              arrow
              placement="top-end"
            >
              <Button
                color="primary"
                variant="contained"
                className={classes.checkedOutButtonStyle}
                id="disabled-button-locked"
                disabled
              >
                Check Out
              </Button>
            </Tooltip>
          </div>
        )}
      {metaDataSearch &&
        metaDataSearch[fileNameSearch] &&
        metaDataSearch[fileNameSearch].checkoutState === 'loading' && (
          <div>
            <CircularProgress variant="static" value={percentCompleted} />
          </div>
        )}
      <Dialog
        disableBackdropClick="true"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{ textAlign: 'center', color: 'maroon' }}
        >
          {'Warning!!!'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <b>
              The file name you are trying to upload is different from the
              original file name. Do you want to continue?
            </b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={fileUpload} color="primary">
            Yes
          </Button>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
      {consoleWindowProp.loadConsoleWindowProp && (
        <ConsoleWindow cwProp={[consoleWindowProp, setConsoleWindowProp]} />
      )}{' '}
    </div>
  )
}
export default CheckFile
