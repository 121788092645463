import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import './UploadStyle.css'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { useAuth } from '@praxis/component-auth'
import { useToasts } from 'react-toast-notifications'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

registerPlugin(FilePondPluginFileValidateType)

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  paper: {
    height: 'auto',
    backgroundColor: '#efefef',
  },
})

const Upload = props => {
  const classes = useStyles()
  const upload = useSelector(state => state.upload)
  const dispatch = useDispatch()

  const [userId, setUserId] = useState(``)
  const { addToast } = useToasts()
  const auth = useAuth()
  const { session } = auth
  const authDetails = useSelector(state => state.common.authDetails)

  const history = useHistory()

  useEffect(() => {
    if (authDetails && Object.keys(authDetails).length > 0) {
      // eslint-disable-next-line no-prototype-builtins
      if (!authDetails.hasOwnProperty('access_feature_edit')) {
        history.push('/unauthorised')
      }
    }
  }, [authDetails, history])

  const handleFileUpload = async e => {
    dispatch({ type: 'SET_IS_UPLOADING', payload: true })

    // let filesName = upload.files.map(value => value.name)
    let filesName = [...upload.files]

    let numberOfFiles = upload.files.length
    if (numberOfFiles) {
      for (let i = 0; i < numberOfFiles; i++) {
        let formData = new FormData()
        formData.set('file', upload.files[i])
        let url = `${apiConfig.apiUrl.upload_files_url}${userId}/SPLIT/UPLOAD`
        const options = {
          onUploadProgress: progressEvent => {
            const { loaded, total } = progressEvent
            let percent = Math.floor((loaded * 100) / total)
            if (percent < 100) {
              dispatch({ type: 'SET_UPLOAD_PERCENTAGE', payload: percent })
            }
          },
        }
        await axios
          .put(url, formData, options)
          .then(res => {
            if (res.status === 200) {
              dispatch({ type: 'SET_UPLOAD_PERCENTAGE', payload: 100 })

              filesName.splice(0, 1)
              dispatch({ type: 'UPLOAD_FILES', payload: filesName })

              if (!filesName.length) {
                setTimeout(() => {
                  dispatch({ type: 'SET_UPLOAD_PERCENTAGE', payload: 0 })
                }, 2000)
              }
            }
          })
          .catch(err => {
            console.warn(err)
            dispatch({ type: 'SET_UPLOAD_PERCENTAGE', payload: 0 })

            dispatch({ type: 'SET_IS_UPLOADING', payload: false })

            addToast('Error!Issue in uploading the file', {
              appearance: 'error',
              autoDismiss: true,
            })
          })
      }
      dispatch({ type: 'SET_IS_UPLOADING', payload: false })
    }
  }

  React.useEffect(() => {
    setUserId(session.userInfo.lanId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId])

  return (
    <div className="upload-container">
      <div className="upload-files-display-container">
        {upload.uploadPercentage > 0 && (
          <ProgressBar
            striped
            now={upload.uploadPercentage}
            active
            label={`${upload.uploadPercentage}%`}
          />
        )}
        <br />
        <Paper className={classes.paper}>
          <FilePond
            acceptedFileTypes={[
              `application/pdf`,
              `application/msword`,
              `application/vnd.openxmlformats-officedocument.wordprocessingml.document`,
              'text/csv',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-excel',
              'application/octet-stream',
            ]}
            name="file"
            instantUpload="false"
            allowMultiple={true}
            dropValidation={true}
            // maxFiles={10}
            files={upload.files}
            allowReorder={true}
            allowDrop={true}
            disabled={upload.isUploading}
            onupdatefiles={fileItems => {
              let allFiles = fileItems.map(fileItem => {
                return fileItem.file
              })

              dispatch({ type: 'UPLOAD_FILES', payload: allFiles })
            }}
            allowProcess="false"
            server={{
              url: `${apiConfig.apiUrl.upload_files_url}${userId}/SPLIT/UPLOAD`,
              data: upload.files,
              timeout: 300000000,
              process: {
                method: `PUT`,
              },
            }}
          />
        </Paper>
      </div>

      <div className="upload-button-container">
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={handleFileUpload}
          disabled={upload.isUploading || upload.files.length === 0}
        >
          Upload &nbsp; &nbsp;
          <CloudUploadIcon className={classes.rightIcon} />
        </Button>
      </div>
    </div>
  )
}

export default Upload
