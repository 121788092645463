import { createMuiTheme } from '@material-ui/core/styles'

export const praxis = {
  light: '#f05545',
  main: '#263238',
  dark: '#7f0000',
  contrastText: '#fff',
}
export const primary = {
  light: {
    lightBackground: '#D3D3D3',
    lightText: '#2C383F',
    dialogHeaderBackground: '#00838F',
    dialogHeaderTextColor: 'white',
    lightGrayBackground: '#f7f7f7',
    errorTextColor: '#e53935',
    addButtonText: '#F06C01',
    addButtonTextColor: '#00838F',
    disabled: '#808080',
  },
  dark: {
    darkHeaderBackground: '#212E35',
    darkHeaderTextColor: 'white',
    darkLabelText: '#989898',
    labelFontSize: '14px',
    darkContentTextColor: 'black',
  },
  status: {
    success: '#597a0d',
    failed: '#ce3e1a',
  },
  contrastText: '#fff',
  main: '#AC0000',
  hover: '#01a3b2',
}
export const size = {
  small: '12px',
  medium: '15px',
  large: '16px',
  x_large: '18px',
  xx_large: '24px',
}
const praxisTheme = createMuiTheme({
  palette: {
    primary: primary,
    secondary: praxis,
    size: size,
    // type: 'dark',
  },
  typography: {
    // fontFamily: [
    //   'Roboto',
    //   'Helvetica Neue',
    //   'Arial',
    //   'sans-serif',
    //   'calibri',
    // ].join(','),
    fontFamily: ['calibri'].join(','),
    fontSize: 14,
  },
  overrides: {
    MuiListItem: {
      root: {
        paddingTop: '5px',
        paddingBottom: '5px',
        boxSizing: 'inherit',
      },
      gutters: {
        paddingLeft: '16px!important',
        paddingRight: '0px!important',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '18.5px 14px !important',
      },
    },
    MuiTab: {
      root: {
        maxWidth: '400px',
      },
    },
  },
})

export default praxisTheme
