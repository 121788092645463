/* eslint-disable no-prototype-builtins */
import _ from 'lodash'

//      /^(0?[1-9]|1[0-2])[- / .](0?[1-9]|[1-2][0-9]|3[01])[- / .](19|20)[0-9][0-9]$/

export const validateDate = date => {
  if (
    date.match(
      /^(0?[1-9]|1[0-2])[ / .](0?[1-9]|[1-2][0-9]|3[01])[ / .](1|2)[0-9][0-9][0-9]$/
    )
  ) {
    return false
  } else return true
}
export const validatePhysicalFileNo = number => {
  if (number.match(/^[0-9]\d*(\.\d+)?$/)) {
    return false
  } else return true
}
export const validateDateWorkflow = date => {
  if (!date) {
    return true
  } else if (
    date.match(
      /^(0?[1-9]|1[0-2])[ / .](0?[1-9]|[1-2][0-9]|3[01])[ / .](1|2)[0-9][0-9][0-9]$/
    )
  ) {
    return true
  } else {
    // console.log('wrong date')

    return false
  }
}

export const validateDueDate = date => {
  if (!date) {
    return true
  } else if (date.match(/^(Q?[1-4])[ ](1|2)[0-9][0-9][0-9]$/)) {
    return true
  } else {
    return false
  }
}

//for search functionality
export const getMembership = data => {
  // let membership = data.memberOf
  let re = 0
  let pa = 0

  if (
    data.has('APP-RDMP-PUBLICDOCUMENTS-PROD') ||
    data.has('APP-RDMP-PUBLICDOCUMENTS-STAGE')
  ) {
    re++
  }

  if (
    data.has('APP-RDMP-PUBLICDOCS-PA-PROD') ||
    data.has('APP-RDMP-PUBLICDOCS-PA-STAGE')
  ) {
    pa++
  }
  if (re > 0 && pa > 0) return 'both'
  else if (pa > 0) return 'pa'
  else return 're' //keeping RE as default
}

export const getContentType = data => {
  let re = data.hasOwnProperty('access_search_doc_ct_re')
  let pa = data.hasOwnProperty('access_search_doc_ct_pa')

  if (re && pa) {
    return 'both'
  } else if (re) return 're'
  else if (pa) return 'pa'
  else return undefined
}

const transformName = value => {
  let temp = value.split(' ')
  return temp.join('_')
}

export const getContentTypeWorkflowDropdown = data => {
  // let contentType = getContentType(data)
  let tempMap = {}

  if (data.hasOwnProperty('access_workflow_groups')) {
    data['access_workflow_groups'].map(
      val =>
        (tempMap[val] = data[`access_workflow_bucket_${transformName(val)}`])
    )
  }

  return tempMap
}

//this is for indexing metadata and search metadata
export const getContentTypeDropdown = data => {
  let contentType = getContentType(data)
  let contentTypeDropdown = []
  let documentData

  if (contentType === 'both') {
    contentTypeDropdown = [
      ...data['access_search_doc_ct_re'],
      ...data['access_search_doc_ct_pa'],
    ]

    documentData = [
      ...data['access_search_doc_dgdt_re'],
      ...data['access_search_doc_dgdt_pa'],
    ]
  } else if (contentType === 're') {
    contentTypeDropdown.concat(data['access_search_doc_ct_re'])
    documentData = data['access_search_doc_dgdt_re']
  } else if (contentType === 'pa') {
    contentTypeDropdown.concat(data['access_search_doc_ct_pa'])
    documentData = data['access_search_doc_dgdt_pa']
  }
  let tempArray = _.mapValues(
    _.groupBy(documentData, element => element.contentType),
    mappedList => {
      let tempMap = {}

      // eslint-disable-next-line array-callback-return
      mappedList.map(element => {
        if (tempMap.hasOwnProperty(element.documentGroup)) {
          tempMap[element.documentGroup].push(element.documentType)
        } else {
          tempMap[''] = ['']
          tempMap[element.documentGroup] = ['', element.documentType]
        }
      })

      return tempMap
    }
  )
  return tempArray
}

export const getDocumentGroupDocumentType = (data, bulkEdit = false) => {
  let documentGroupArrayRe = {}
  let documentGroupArrayPa = {}

  if (data.hasOwnProperty('access_search_doc_dgdt_re')) {
    documentGroupArrayRe = _.mapValues(
      _.groupBy(
        data['access_search_doc_dgdt_re'],
        element => element.documentGroup
      ),
      mappedList => {
        let emptyArray = ['']
        let tempList = mappedList.map(eachElement => {
          return eachElement.documentType
        })
        return emptyArray.concat(tempList)
      }
    )
    if (bulkEdit) return documentGroupArrayRe
  }
  if (data.hasOwnProperty('access_search_doc_dgdt_pa')) {
    documentGroupArrayPa = _.mapValues(
      _.groupBy(
        data['access_search_doc_dgdt_pa'],
        element => element.documentGroup
      ),
      mappedList => {
        let emptyArray = ['']
        let tempList = mappedList.map(eachElement => {
          return eachElement.documentType
        })
        return emptyArray.concat(tempList)
      }
    )
  }

  return Object.assign(documentGroupArrayRe, documentGroupArrayPa)
}

export const validateTasksDropdown = (value, bucketName) => {
  if (bucketName === 'RES-NEED-RECORDABLE' || bucketName === 'RES-PENDING') {
    if (value === 'No' || value === 'Blank') return false
    return true
  }
  if (bucketName === 'RE-METADATA-DEFECT' || bucketName === 'RE-IMAGE-DEFECT') {
    return value === 'Yes'
  }

  return value !== 'Blank'
}

export const validateWorkflowFinalSubmit = (bucketName, formData) => {
  let errorList = []
  let allValues = []
  switch (bucketName) {
    case 'RE-CONTRACT-REVIEW':
      if (
        formData['Is there a Metadata Defect?'] === 'Yes' &&
        formData['Metadata defect comments from reviewer'] === ''
      ) {
        errorList.push('Metadata defect comments from reviewer')
      }
      if (
        formData['Is there an Image Defect?'] === 'Yes' &&
        formData['Image defect comments from reviewer'] === ''
      ) {
        errorList.push('Image defect comments from reviewer')
      }
      break

    case 'RE-CONTRACT-VALIDATION':
      if (
        formData['Is there a Metadata Defect?'] === 'Yes' &&
        formData['Metadata defect comments from validator'] === ''
      ) {
        errorList.push('Metadata defect comments from validator')
      }
      if (
        formData['Is there an Image Defect?'] === 'Yes' &&
        formData['Image defect comments from validator'] === ''
      ) {
        errorList.push('Image defect comments from validator')
      }
      if (
        formData['Are there review defects?'] === 'Yes' &&
        formData['Review defects comments'] === ''
      ) {
        errorList.push('Review defects comments')
      }
      break

    case 'PAYABLE-BUDGET-MANAGER-REVIEW':
    case 'PAYABLE-REC-MANAGER-REVIEW':
    case 'PAYABLE-UNEXPECTED-MANAGER-REVIEW':
    case 'REBILL-BUDGET-MANAGER-REVIEW':
    case 'REBILL-REC-MANAGER-REVIEW':
    case 'REBILL-UX-MANAGER-REVIEW':
      if (
        formData['Manager Review Completed?'] === 'No' &&
        formData['Not Approved Reason'] === 'Blank'
      ) {
        errorList.push('Not Approved Reason')
      }
      if (
        formData['Manager Review Completed?'] === 'No' &&
        formData['Route to'] === ''
      ) {
        errorList.push('Route to')
      }
      break

    case 'PAYABLE-REC-SITE-OPS-REVIEW':
    case 'PAYABLE-BUDGET-SITE-OPS-REVIEW':
    case 'REBILL-BUDGET-SITE-OPS-REVIEW':
    case 'REBILL-REC-SITE-OPS-REVIEW':
    case 'REBILL-UX-SITE-OPS-REVIEW':
      if (
        formData['Site Ops Review Completed?'] === 'No' &&
        formData['Reason For Pending'] === 'Blank'
      ) {
        errorList.push('Reason For Pending')
      }
      if (
        formData['Site Ops Review Completed?'] === 'No' &&
        formData['Route to'] === ''
      ) {
        errorList.push('Route to')
      }
      break

    case 'REBILL-UNEXPECTED':
    case 'REBILL-BUDGET':
    case 'REBILL-RECONCILIATIONS':
    case 'PAYABLE-UNEXPECTED-PREPARER':
    case 'PAYABLE-BUDGET':
    case 'PAYABLE-RECONCILIATIONS':
      if (
        formData['Send to Manager Review?'] === 'Yes' &&
        formData['Send to Site Ops Review?'] === 'Yes'
      ) {
        errorList.push('Send to Manager Review?')
      }
      if (
        formData['Send to Manager Review?'] === 'No' &&
        formData['Send to Site Ops Review?'] === 'No'
      ) {
        errorList.push('Send to Manager Review?')
      }
      break

    case 'LEASE-AGREEMENT-REVIEWER-1':
      if (
        formData['Review Complete?'] === 'No' &&
        formData['Send to 2nd Reviewer?'] === 'Yes'
      ) {
        errorList.push('Send to 2nd Reviewer?')
      }
      break

    case 'CAM-PAYABLE-PREPARER':
      allValues = Object.values(formData).filter(item => item === 'Yes')
      if (allValues.length !== 1) errorList.push('formError')

      break

    case 'CAM-REBILL-ADDRESS-CHANGE':
      if (formData['Send to Manager Review?'] !== 'Yes') {
        errorList.push('Send to Manager Review?')
      } else {
        if (formData['CAM Status'] === 'Not Started') {
          errorList.push('CAM Status')
        }
      }
      break

    default:
      return errorList
  }
  return errorList
}

//for bulk edit
export const validateRow = data => {
  if (data.documentGroup === '' || data.documentType === '') return false
  return true
}

export const validateEmail = data => {
  if (/(.+)@(.+){2,}\.(.+){2,}/.test(data)) {
    return true
  }
  return false
}

export const validateGeneralUser = alladgroupSet => {
  let adgroupsCopy = new Set(alladgroupSet)
  let adgroups = [
    'APP-RDMP-SCANINDEX-RE-STAGE',
    'APP-RDMP-SCANINDEX-PA-STAGE',
    'APP-RDMP-SCANINDEX-RE-PROD',
    'APP-RDMP-SCANINDEX-PA-PROD',
  ]

  // eslint-disable-next-line array-callback-return
  // adgroups.map(item => {
  //   adgroupsCopy.delete(item)
  // })
  // if (adgroupsCopy.size === 0) return true
  // return false

  let userHasIndexGroup = true
  // eslint-disable-next-line array-callback-return
  adgroups.map(item => {
    if (adgroupsCopy.has(item)) {
      userHasIndexGroup = false
    }
  })
  return userHasIndexGroup
}
