/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSelector } from 'react-redux'
import './RealEstateStyle.css'
import apiConfig from '../../config/apiConfig'
import { validateDate, validatePhysicalFileNo } from '../common/validation'
import axios from 'axios'
import moment from 'moment'

const RealEstate = props => {
  return (
    <div>
      {props.type === apiConfig.apiUrl.INDEX ? (
        <RealEstateIndex {...props} />
      ) : (
        <RealEstateSearch {...props} />
      )}
    </div>
  )
}

const RealEstateSearch = props => {
  const [, setParentObj] = props.metaDataObj
  // const savedMetaData = props.savedMetaData
  const [realEstateSearchObj, setRealEstateSearchObj] = useState({})
  const [reObjDate, setReObjDate] = useState(null)

  useEffect(() => {
    setRealEstateSearchObj({ ...realEstateSearchObj, documentDate: reObjDate })
  }, [reObjDate])

  useEffect(() => {
    if (
      (props.savedMetaData &&
        Object.entries(props.savedMetaData).length === 0) ||
      props.savedMetaData === undefined
    ) {
      let obj = { ...props.unmodifiedData }

      setRealEstateSearchObj(obj)
    } else {
      let obj = returnREObj({ ...props.savedMetaData })
      let newObj = { ...obj, id: props.unmodifiedData.id }
      setRealEstateSearchObj(newObj)
    }
  }, [])

  useEffect(() => {
    setParentObj(realEstateSearchObj)
  }, [realEstateSearchObj])

  return (
    <RealEstateForm
      {...props}
      reObj={[realEstateSearchObj, setRealEstateSearchObj]}
      dateObj={[reObjDate, setReObjDate]}
    />
  )
}

const RealEstateIndex = props => {
  const [parentObj, setParentObj] = props.metaDataObj
  const [realEstateIndexObj, setRealEstateIndexObj] = useState({})

  const [reObjDate, setReObjDate] = useState(null)

  useEffect(() => {
    if (reObjDate) {
      setRealEstateIndexObj({ ...realEstateIndexObj, documentDate: reObjDate })
    }
  }, [reObjDate])

  useEffect(() => {
    setParentObj(realEstateIndexObj)
  }, [realEstateIndexObj])

  useEffect(() => {
    if (Object.keys(parentObj).length > 0) {
      let newObj = { ...parentObj, contentType: props.contentType }
      setRealEstateIndexObj(newObj)
    }
  }, [])

  //called only when indexing status = complete
  useEffect(() => {
    if (props.refreshCompleteMetadata) {
      // let obj = refactoredObject({ ...parentObj })
      let newObj = { ...parentObj, contentType: props.contentType }

      setRealEstateIndexObj(newObj)
    }
  }, [props.refreshCompleteMetadata])

  //For clearing indexing data on switch of content type
  useEffect(() => {
    if (props.clearOnContentTypeChange) {
      let newObj = { contentType: props.contentType }
      setRealEstateIndexObj(newObj)
    }
  }, [props.clearOnContentTypeChange])

  return (
    <RealEstateForm
      {...props}
      reObj={[realEstateIndexObj, setRealEstateIndexObj]}
      dateObj={[reObjDate, setReObjDate]}
    />
  )
}

const returnREObj = data => {
  if (Object.entries(data).length > 0) {
    return {
      documentGroup: data.document_group || '',
      documentType: data.document_type || '',
      locationNumber: data.location_number || '',
      locationName: data.location_name || '',
      locationCity: data.location_city || '',
      locationState: data.location_state || '',
      locationRegion: data.location_region || '',
      documentTitle: data.document_title || '',
      documentDate: data.document_date || '',
      expirationDate: data.expiration_date || '',

      isUrgent: data.is_urgent, //|| 'No',
      documentStatus: data.document_status, // || 'Pending',
      recordingStatus: data.recording_status, // || 'Unrecordable',
      // docFollowUpNumber: data.doc_follow_up_number || '',
      legalFollowUpName: data.legal_follow_up_name || '',
      businessFollowUpName: data.business_follow_up_name || '',
      fileLetter: data.file_letter || '',
      fileLetterDescription: data.file_letter_description || '',
      physicalFileNumber: data.physical_file_number || '',
      documentPurpose: data.document_purpose || '',
      documentNotes: data.document_notes || '',
      contentType: data.content_type || '',
    }
  }
}

const RealEstateForm = props => {
  const [realEstateObj, setRealEstateObj] = props.reObj
  const BootstrapInput = props.bootStrapInput
  const classes = props.useStyleClass
  const metadataObj = useSelector(state => state.common.locationData)
  const [locationData, setLocationData] = useState(metadataObj)
  const [docArray, setDocArray] = useState([])
  const [dateError, setDateError] = useState(false)
  const [physicalFileNoError, setPhysicalFileNoError] = useState(false)
  const [docTitleError, setDocTitleError] = useState(false)
  const [docPurposeError, setDocPurposeError] = useState(false)
  const [docNotesError, setDocNotesError] = useState(false)
  const [expDateError, setExpDateError] = useState(false)
  const { docGroupDocType, contentType } = props
  const [legalUserName, setLegalUserName] = useState([])
  const [businessUserName, setBusinessUserName] = useState([])
  const [fileLetterMap, setFileLetterMap] = useState([])

  useEffect(() => {
    let url = `${apiConfig.apiUrl.legal_adgroup_url}`
    axios.get(url).then(res => {
      if (res.status === 200 && Object.entries(res.data).length > 0) {
        let usernameArray = Object.keys(res.data.display_name_list)
        let tempArray = ['']
        tempArray = tempArray.concat(usernameArray)
        setLegalUserName(tempArray.sort())
        return
      }
      if (res.status !== 200) {
        throw new Error(`The server responded with the status  ` + res.status)
      }
    })
  }, [])

  const customSort = (a, b) => {
    if (a === 'CORR') {
      return 1
    }
    if (b === 'CORR') {
      return -1
    }
    return a.localeCompare(b)
  }

  useEffect(() => {
    let url = `${apiConfig.apiUrl.business_adgroup_url}`
    axios.get(url).then(res => {
      if (res.status === 200 && Object.entries(res.data).length > 0) {
        let usernameArray = Object.keys(res.data.display_name_list)
        let tempArray = ['']
        tempArray = tempArray.concat(usernameArray)
        setBusinessUserName(tempArray.sort())
        return
      }
      if (res.status !== 200) {
        throw new Error(`The server responded with the status  ` + res.status)
      }
    })
  }, [])

  useEffect(() => {
    if (realEstateObj.locationNumber) {
      getFileLetter(realEstateObj.locationNumber)
    }
  }, [realEstateObj.locationNumber])

  useEffect(() => {
    setDocArray(docGroupDocType[contentType][realEstateObj.documentGroup])
    if (
      realEstateObj.documentGroup === 'Correspondence' &&
      realEstateObj.documentDate &&
      !validateDate(realEstateObj.documentDate)
    ) {
      let expDate = moment(realEstateObj.documentDate)
      let formattedExpDate = expDate
        .clone()
        .add(10, 'years')
        .format('MM/DD/YYYY')
      setRealEstateObj({
        ...realEstateObj,
        expirationDate: formattedExpDate,
      })
      return
    }
  }, [realEstateObj.documentGroup])

  useEffect(() => {
    if (locationData) {
      setRealEstateObj({
        ...realEstateObj,
        locationNumber: locationData.location_number,
        locationName: locationData.location_name,
        locationCity: locationData.location_city,
        locationState: locationData.location_state,
        locationRegion: locationData.location_region,
      })
    } else {
      setRealEstateObj({
        ...realEstateObj,
        locationNumber: '',
        locationName: '',
        locationCity: '',
        locationState: '',
        locationRegion: '',
      })
    }
  }, [locationData])

  const getFileLetter = locationNumber => {
    let url = `${apiConfig.apiUrl.get_file_letter}${locationNumber}`
    axios
      .get(url)
      .then(res => {
        if (res.status === 200 && Object.entries(res.data).length > 0) {
          let tempMap = {}
          tempMap[''] = ['']
          res.data.map(
            item => (tempMap[item.file_letter] = item.file_letter_desc)
          )
          setFileLetterMap(tempMap)
        }
        if (res.status !== 200) {
          throw new Error(`The server responded with the status  ` + res.status)
        }
      })
      .catch(err => console.log(err))
  }

  const handleLocationChange = (index, obj) => {
    setLocationData(obj)
  }

  return (
    <div className="contenttype-container">
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="realestate-documentgroup">
          Document Group *
        </InputLabel>
        <NativeSelect
          id="realestate-documentgroup"
          value={realEstateObj.documentGroup}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_group
              : realEstateObj.documentGroup
          }
          onChange={e =>
            setRealEstateObj({
              ...realEstateObj,
              documentGroup: e.target.value,
            })
          }
          required
          input={<BootstrapInput />}
        >
          {Object.keys(docGroupDocType[contentType]).map(value => {
            return (
              <option value={value} key={value}>
                {value}
              </option>
            )
          })}
        </NativeSelect>
      </FormControl>
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="realestate-documenttype">
          Document Type *
        </InputLabel>
        <NativeSelect
          id="realestate-documenttype"
          value={realEstateObj.documentType}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_type
              : realEstateObj.documentType
          }
          onChange={e =>
            setRealEstateObj({ ...realEstateObj, documentType: e.target.value })
          }
          input={<BootstrapInput />}
          required
        >
          {docArray &&
            docArray.map(value => {
              return (
                <option value={value} key={value}>
                  {value}
                </option>
              )
            })}
        </NativeSelect>
      </FormControl>
      <Autocomplete
        options={Object.entries(metadataObj).length && metadataObj}
        className="autocomplete"
        autoHighlight={true}
        getOptionLabel={option =>
          option.location_number ? option.location_number : ''
        }
        onChange={(index, obj) => handleLocationChange(index, obj)}
        value={{
          location_number: realEstateObj.locationNumber,
          location_name: realEstateObj.locationName,
          location_city: realEstateObj.locationCity,
          location_state: realEstateObj.locationState,
          location_region: realEstateObj.locationRegion,
        }}
        type={Number}
        disabled={Object.entries(metadataObj).length ? false : true}
        renderInput={params => (
          <FormControl>
            <InputLabel
              shrink
              htmlFor="realestate-autopopulate"
              className={classes.textfieldLabel}
            >
              Location Number{' * '}
            </InputLabel>
            <TextField
              {...params}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              required
              id="realestate-autopopulate"
              // label="Location Number"
              className={classes.textField}
              variant="outlined"
            />
          </FormControl>
        )}
      />
      <FormControl>
        <InputLabel
          shrink
          htmlFor="realestate-locationname"
          className={classes.textfieldLabel}
        >
          Location Name{' *'}
        </InputLabel>
        <TextField
          required
          id="realestate-locationname"
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          style={{ color: 'black' }}
          disabled={true}
          value={realEstateObj.locationName || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_name
              : realEstateObj.locationName
          }
          onChange={e =>
            setRealEstateObj({ ...realEstateObj, locationName: e.target.value })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          htmlFor="realestate-city"
          className={classes.textfieldLabel}
        >
          City{' *'}
        </InputLabel>
        <TextField
          required
          id="realestate-city"
          // label={'City'}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={realEstateObj.locationCity || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_city
              : realEstateObj.locationCity
          }
          onChange={e =>
            setRealEstateObj({ ...realEstateObj, locationCity: e.target.value })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          htmlFor="realestate-state"
          className={classes.textfieldLabel}
        >
          State{' *'}
        </InputLabel>
        <TextField
          required
          InputLabelProps={{
            shrink: true,
          }}
          id="realestate-state"
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={realEstateObj.locationState || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_state
              : realEstateObj.locationState
          }
          onChange={e =>
            setRealEstateObj({
              ...realEstateObj,
              locationState: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          htmlFor="realestate-region"
          className={classes.textfieldLabel}
        >
          Region
        </InputLabel>
        <TextField
          required
          InputLabelProps={{
            shrink: true,
          }}
          id="realestate-region"
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={realEstateObj.locationRegion || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_region
              : realEstateObj.locationRegion
          }
          onChange={e =>
            setRealEstateObj({
              ...realEstateObj,
              locationRegion: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          htmlFor="realestate-documenttitle"
          className={classes.textfieldLabel}
        >
          Document Title *
        </InputLabel>
        <TextField
          required
          id="realestate-documenttitle"
          inputProps={{ maxLength: '500' }}
          type={String}
          multiline
          rowsMax="4"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={realEstateObj.documentTitle || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_title
              : realEstateObj.documentTitle
          }
          onChange={e => {
            if (e.target.value.length === 500) {
              setDocTitleError(true)
            } else setDocTitleError(false)
            setRealEstateObj({
              ...realEstateObj,
              documentTitle: e.target.value,
            })
          }}
          error={docTitleError}
          helperText={
            docTitleError ? 'You have reached the limit of 500 characters' : ''
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          htmlFor="realestate-documenttitle"
          className={classes.textfieldLabel}
        >
          Document Date{' '}
        </InputLabel>{' '}
        <TextField
          id="realestate-documentdate"
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={realEstateObj.documentDate || undefined}
          onChange={event => {
            let valid = validateDate(event.target.value)
            event.target.value ? setDateError(false) : setDateError(valid)
            if (!valid && realEstateObj.documentGroup === 'Correspondence') {
              let expDate = moment(event.target.value)
              let formattedExpDate = expDate
                .clone()
                .add(10, 'years')
                .format('MM/DD/YYYY')
              setRealEstateObj({
                ...realEstateObj,
                documentDate: event.target.value,
                expirationDate: formattedExpDate,
              })
              return
            }
            setRealEstateObj({
              ...realEstateObj,
              documentDate: event.target.value,
            })
          }}
          placeholder="mm/dd/yyyy"
          error={dateError}
          helperText={
            dateError ? 'Enter in correct format.For e.g. 09/23/2020' : ''
          }
          // FormHelperTextProps={{ 'backgroundColor': '#e6e6e6' }}
        />
      </FormControl>

      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="realestate-isurgent">
          Is Urgent? *
        </InputLabel>
        <NativeSelect
          id="realestate-isurgent"
          value={realEstateObj.isUrgent || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.is_urgent
              : realEstateObj.isUrgent
          }
          onChange={e =>
            setRealEstateObj({ ...realEstateObj, isUrgent: e.target.value })
          }
          input={<BootstrapInput />}
          required
        >
          <option value=""></option>
          <option value={'No'}>No</option>
          <option value={'Yes'}>Yes</option>
        </NativeSelect>
      </FormControl>
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="realestate-documentstatus">
          Document Status *
        </InputLabel>
        <NativeSelect
          id="realestate-documentstatus"
          value={realEstateObj.documentStatus || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_status
              : realEstateObj.documentStatus
          }
          onChange={e =>
            setRealEstateObj({
              ...realEstateObj,
              documentStatus: e.target.value,
            })
          }
          required
          input={<BootstrapInput />}
        >
          <option value=""></option>
          <option value={'Pending'}>Pending</option>
          <option value={'Active'}>Active</option>
          <option value={'Unobtainable'}>Unobtainable</option>
        </NativeSelect>
      </FormControl>
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="realestate-recordingstatus">
          Recording Status *
        </InputLabel>
        <NativeSelect
          id="realestate-recordingstatus"
          value={realEstateObj.recordingStatus || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.recording_status
              : realEstateObj.recordingStatus
          }
          onChange={e =>
            setRealEstateObj({
              ...realEstateObj,
              recordingStatus: e.target.value,
            })
          }
          input={<BootstrapInput />}
          required
        >
          <option value=""></option>
          <option value={'Unrecordable'}>Unrecordable</option>
          <option value={'Recorded'}>Recorded</option>
          <option value={'Unobtainable'}>Unobtainable</option>
          <option value={'Need Recorded Copy'}>Need Recorded Copy</option>
        </NativeSelect>
      </FormControl>
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="realestate-businessFollowUp">
          Business Partner Follow-up
        </InputLabel>
        <NativeSelect
          id="realestate-businessFollowUp"
          value={realEstateObj.businessFollowUpName || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.business_follow_up_name
              : realEstateObj.businessFollowUpName
          }
          onChange={e =>
            setRealEstateObj({
              ...realEstateObj,
              businessFollowUpName: e.target.value,
            })
          }
          input={<BootstrapInput />}
        >
          {businessUserName.map(value => {
            return (
              <option key={value} value={value}>
                {value}
              </option>
            )
          })}
          <option value={'N/A'}>N/A</option>
        </NativeSelect>
      </FormControl>

      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="realestate-legalFollowUp">
          Legal Follow-up
        </InputLabel>
        <NativeSelect
          id="realestate-legalFollowUp"
          value={realEstateObj.legalFollowUpName || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.legal_follow_up_name
              : realEstateObj.legalFollowUpName
          }
          onChange={e =>
            setRealEstateObj({
              ...realEstateObj,
              legalFollowUpName: e.target.value,
            })
          }
          input={<BootstrapInput />}
        >
          {legalUserName.map(value => {
            return (
              <option key={value} value={value}>
                {value}
              </option>
            )
          })}
          <option value={'N/A'}>N/A</option>
        </NativeSelect>
      </FormControl>

      <FormControl className={classes.selects}>
        <InputLabel
          shrink
          htmlFor="realestate-fileletter"
          // className={classes.textfieldLabel}
        >
          File Letter{' '}
        </InputLabel>
        {/* <TextField
          id="realestate-fileletter"
          InputLabelProps={{
            shrink: true,
          }}
          // label={'File Letter'}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={realEstateObj.fileLetter || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.file_letter
              : realEstateObj.fileLetter
          }
          onChange={e =>
            setRealEstateObj({ ...realEstateObj, fileLetter: e.target.value })
          }
        /> */}
        <NativeSelect
          id="realestate-fileletter"
          value={realEstateObj.fileLetter || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.file_letter
              : realEstateObj.fileLetter
          }
          onChange={e =>
            setRealEstateObj({
              ...realEstateObj,
              fileLetter: e.target.value,
              fileLetterDescription: fileLetterMap[e.target.value],
            })
          }
          input={<BootstrapInput />}
        >
          {Object.keys(fileLetterMap)
            .sort(customSort)
            .map(value => {
              return (
                <option key={value} value={value}>
                  {value}
                </option>
              )
            })}
        </NativeSelect>
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          htmlFor="realestate-fileletterdescription"
          className={classes.textfieldLabel}
        >
          File Letter Description{' '}
        </InputLabel>
        <TextField
          id="realestate-fileletterdescription"
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          inputProps={{ readOnly: true }}
          value={realEstateObj.fileLetterDescription || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.file_letter_description
              : realEstateObj.fileLetterDescription
          }
          onChange={e =>
            setRealEstateObj({
              ...realEstateObj,
              fileLetterDescription: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          htmlFor="realestate-physicalfile"
          className={classes.textfieldLabel}
        >
          Physical File Number{' *'}
        </InputLabel>
        <TextField
          required
          id="realestate-physicalfile"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.physical_file_number
              : realEstateObj.physicalFileNumber
          }
          value={realEstateObj.physicalFileNumber || ''}
          // onChange={e =>
          //   setRealEstateObj({
          //     ...realEstateObj,
          //     physicalFileNumber: e.target.value,
          //   })
          // }
          onChange={event => {
            let valid = validatePhysicalFileNo(event.target.value)
            setPhysicalFileNoError(valid)
            setRealEstateObj({
              ...realEstateObj,
              physicalFileNumber: event.target.value,
            })
          }}
          // placeholder="mm/dd/yyyy"
          error={physicalFileNoError}
          helperText={
            physicalFileNoError
              ? 'Only numeric or decimal values accepted. For e.g. 1011.1 or 1016 '
              : ''
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          htmlFor="realestate-documentpurpose"
          className={classes.textfieldLabel}
        >
          Document Purpose{' '}
        </InputLabel>
        <TextField
          id="realestate-documentpurpose"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rowsMax="4"
          inputProps={{ maxLength: '1000' }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={realEstateObj.documentPurpose || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_purpose
              : realEstateObj.documentPurpose
          }
          onChange={e => {
            if (e.target.value.length === 1000) {
              setDocPurposeError(true)
            } else setDocPurposeError(false)
            setRealEstateObj({
              ...realEstateObj,
              documentPurpose: e.target.value,
            })
          }}
          error={docPurposeError}
          helperText={
            docPurposeError
              ? 'You have reached the limit of 1000 characters'
              : ''
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          htmlFor="realestate-documentnotes"
          className={classes.textfieldLabel}
        >
          Document Notes{' '}
        </InputLabel>
        <TextField
          id="realestate-documentnotes"
          // label={'Document Notes'}
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rowsMax="4"
          inputProps={{ maxLength: '1000' }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={realEstateObj.documentNotes || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_notes
              : realEstateObj.documentNotes
          }
          onChange={e => {
            if (e.target.value.length === 1000) {
              setDocNotesError(true)
            } else setDocNotesError(false)
            setRealEstateObj({
              ...realEstateObj,
              documentNotes: e.target.value,
            })
          }}
          error={docNotesError}
          helperText={
            docNotesError ? 'You have reached the limit of 1000 characters' : ''
          }
        />
      </FormControl>
      {/* <TextField
        id="realestate-sapcontractnumber"
        label={'SAP Contract Number'}
        type={String}
        className={classes.textField}
        margin="normal"
        variant="outlined"
        value={realEstateObj.sapContractNumber || ''}
        defaultValue={
          typeof props.savedMetaData !== 'undefined'
            ? props.savedMetaData.sap_contract_number
            : realEstateObj.sapContractNumber
        }
        onChange={e =>
          setRealEstateObj({
            ...realEstateObj,
            sapContractNumber: e.target.value,
          })
        }
      /> */}
      {/* <TextField
        id="realestate-codes"
        label={'Codes'}
        type={String}
        className={classes.textField}
        margin="normal"
        variant="outlined"
        value={realEstateObj.codes || ''}
        defaultValue={
          typeof props.savedMetaData !== 'undefined'
            ? props.savedMetaData.codes
            : realEstateObj.codes
        }
        onChange={e =>
          setRealEstateObj({
            ...realEstateObj,
            codes: e.target.value,
          })
        }
      /> */}

      <FormControl>
        <InputLabel
          shrink
          htmlFor="realestate-documentnotes"
          className={classes.textfieldLabel}
        >
          Expiration Date{' '}
        </InputLabel>
        <TextField
          id="realestate-expirationDate"
          // label="Expiration Date"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={realEstateObj.expirationDate || undefined}
          onChange={event => {
            let valid = validateDate(event.target.value)
            event.target.value === ''
              ? setExpDateError(false)
              : setExpDateError(valid)

            setRealEstateObj({
              ...realEstateObj,
              expirationDate: event.target.value,
            })
            // }
          }}
          placeholder="mm/dd/yyyy"
          error={expDateError}
          helperText={
            expDateError ? 'Enter in correct format.For e.g. 09/23/2020' : ''
          }
        />
      </FormControl>
    </div>
  )
}

export default RealEstate
