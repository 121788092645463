/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import axios from 'axios'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import './IndexViewStyle.css'
// import FileViewer from 'react-file-viewer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TablePagination from '@material-ui/core/TablePagination'
import FormControl from '@material-ui/core/FormControl'
import MetaData from '../search/MetaData'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputBase from '@material-ui/core/InputBase'
import RefreshIcon from '@material-ui/icons/Refresh'
import Skeleton from '@material-ui/lab/Skeleton'
import ConsoleWindow from '../consolewindow/ConsoleWindow'
import apiConfig from '../../config/apiConfig'
import { useAuth } from '@praxis/component-auth'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
// import { useHistory } from 'react-router-dom'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { CustomFileViewer } from '../common/customFileViewer'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router-dom'
// import TabPanel from '@material-ui/lab/'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'FileName' },
  { id: 'date', numeric: true, disablePadding: false, label: 'ScanDate' },
  { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
]

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

function TabPanel(props) {
  const { children, index, value, ...other } = props
  return (
    <Typography
      component="div"
      role="tabpanel"
      // eslint-disable-next-line eqeqeq
      hidden={value != index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {/* {value === index &&  */}
      <Box p={3}>{children}</Box>
      {/* }  */}
    </Typography>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const IndexView = props => {
  // const [value, setValue] = React.useState(0)
  // const [openedTabPanels, setOpenedTabPanels] = useState([])
  //   const [openedTab, setOpenedTab] = useState([])
  //........................
  const auth = useAuth()
  const dispatch = useDispatch()

  const { session } = auth
  const { addToast } = useToasts()

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    img: {
      width: '90%',
      display: 'flex',
    },
    textField: {
      color: 'red',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    button: {
      padding: '15px 4px',
      margin: theme.spacing(1),
      display: 'inline-block',
      width: 150,
      color: 'white',
      backgroundColor: '#AC0000',
      '&:hover': {
        backgroundColor: '#AC0000',
      },
      cursor: 'pointer',
    },
  }))
  const adGroup = useSelector(state => state.index.adGroup) //redux
  const userSelected = useSelector(state => state.index.userSelected)
  const batch = useSelector(state => state.index.batch)
  const batchList = useSelector(state => state.index.batchesList)
  const openedTabs = useSelector(state => state.index.openedTabsIndex)
  const metaData = useSelector(state => state.index.indexMetaData)
  const commonDetails = useSelector(state => state.common.allAdgroupList)
  const authDetails = useSelector(state => state.common.authDetails)
  const classes = useStyles()
  const [metadataSearchResponse, setmetadataSearchResponse] = useState([])
  const [loadingScreen, setLoadingScreen] = useState(true)
  let history = useHistory()

  const [consoleWindowProp, setConsoleWindowProp] = useState({
    loadConsoleWindowProp: false,
    dialogTitle: ``,
    dialogContent: ``,
    dialogFirstButton: ``,
    dialogSecondButton: ``,
    buttonClicked: ``,
  })

  const [disableReleaseBatch, setDisableReleaseBatch] = useState(true)
  const [
    pendingDocumentsRefreshTrigger,
    setPendingDocumentsRefreshTrigger,
  ] = useState(false)
  const [releaseBatchIsTrue, setReleaseBatchIsTrue] = useState(false)
  const [, setCurrentlyOpenDocument] = useState('')

  const onError = e => {
    console.log(e)
  }

  function getFileExtensionType(filename) {
    return filename.split('.').pop()
  }
  useEffect(() => {
    userId && setMetaDataFunc()
  }, [pendingDocumentsRefreshTrigger])

  useEffect(() => {
    if (batch.toBeSelectedBatch && !openedTabs.tab.length) {
      dispatch({
        type: 'SAVE_SELECTED_BATCH',
        payload: {
          selectedBatch: batch.toBeSelectedBatch,
          toBeSelectedBatch: '',
        },
      })
      return
    }
    if (batch.toBeSelectedBatch && openedTabs.tab.length >= 1) {
      setConsoleWindowProp({
        ...consoleWindowProp,
        loadConsoleWindowProp: true,
        dialogTitle: `Warning!`,
        dialogContent: apiConfig.apiUrl.change_batch_dialog_content,
        dialogFirstButton: apiConfig.apiUrl.change_batch_first_button,
        dialogSecondButton: apiConfig.apiUrl.change_batch_second_button,
      })
    }
  }, [batch.toBeSelectedBatch])

  // Run this useEffect only when buttonclick changes
  useEffect(() => {
    if (
      consoleWindowProp.buttonClicked ===
        apiConfig.apiUrl.change_batch_first_button ||
      consoleWindowProp.buttonClicked ===
        apiConfig.apiUrl.change_user_first_button
    ) {
      dispatch({
        type: 'SAVE_OPENED_TABS_INDEX',
        payload: {
          field: 'tab',
          value: [],
        },
      })

      dispatch({
        type: 'SAVE_OPENED_TABS_INDEX',
        payload: {
          field: 'files',
          value: [],
        },
      })

      dispatch({
        type: 'SAVE_OPENED_TABS_INDEX',
        payload: {
          field: 'value',
          value: 0,
        },
      })

      changeFilesView()

      return
    }
    if (consoleWindowProp.buttonClicked === 'Cancel') {
      setConsoleWindowProp({
        ...consoleWindowProp,
        loadConsoleWindowProp: false,
        buttonClicked: '', // need to set it to empty because if you set it to a value say cancel, it wont trigger if it has cancel value
      })
      // need to set both tobe's to empty because if it stays the same upon dropdown change to the same value it wont trigger.
      // for instance if user has selected nobatch and they click nobatch again tobe's won't get triggered and console window won't show up.
      dispatch({
        type: 'SAVE_SELECTED_BATCH',
        payload: {
          toBeSelectedBatch: '',
        },
      })
      dispatch({
        type: 'SAVE_SELECTED_USER',
        payload: {
          toBeSelectedUserName: '',
        },
      })
    }
  }, [consoleWindowProp.buttonClicked]) // button click as

  const changeFilesView = () => {
    dispatch({
      type: 'SAVE_OPENED_TABS_INDEX',
      payload: {
        field: 'value',
        value: 0,
      },
    }) //swtiches to tab pending index
    consoleWindowProp.buttonClicked ===
    apiConfig.apiUrl.change_batch_first_button
      ? dispatch({
          type: 'SAVE_SELECTED_BATCH',
          payload: {
            selectedBatch: batch.toBeSelectedBatch,
            toBeSelectedBatch: '',
          },
        })
      : dispatch({
          type: 'SAVE_SELECTED_USER',
          payload: {
            selectedUserName: userSelected.toBeSelectedUserName,
            toBeSelectedUserName: '',
          },
        })
    setConsoleWindowProp({
      ...consoleWindowProp,
      buttonClicked: ``,
      loadConsoleWindowProp: false,
    })
  }

  let metadataSearchResponseArr = []

  useEffect(() => {
    for (var i = 0, len = metadataSearchResponse.length; i < len; i++) {
      let response = metadataSearchResponse[i]
      var row = {
        FileName: response.fileName,
        ScanDate: response.scanDate,
        IndexingStatus: response.status,
        recordId: response.id,
      }
      metadataSearchResponseArr.push(row)
    }
    if (metadataSearchResponseArr) {
      dispatch({
        type: 'SAVE_OPENED_TABS_INDEX',
        payload: {
          field: 'data',
          value: metadataSearchResponseArr,
        },
      })
      setLoadingScreen(false)
    }
  }, [metadataSearchResponse])

  function openFile(rowData) {
    let apiUrl = `${apiConfig.apiUrl.getFileUrl}${userId}/SPLIT/`
    let tempTab = [...openedTabs.tab]

    let tempFiles = [...openedTabs.files]
    let tempIframeHolder = [...openedTabs.documentViewer]
    let tempMetadata = Object.assign({}, metaData)

    var isTabOpen = tempFiles.find(tab => {
      return tab.fileName === rowData.FileName
    })

    ////////////
    // const handleClick = e => {
    //   let final = openedTab.length + 1
    //   console.log(final)

    //   let templist = [...openedTabPanels]
    //   let copytab = [...openedTab]
    //   copytab.push(_tab)
    //   templist.push(tabelement)
    //   setOpenedTab(copytab)
    //   setOpenedTabPanels(templist)
    //   setValue(final)
    // }
    //////////

    var tabContainerData = {
      value: rowData.FileName,
      url: apiConfig.apiUrl.getFileURL + rowData.FileName,
      fileName: rowData.FileName,
      metaData: rowData,
    }

    if (isTabOpen === undefined) {
      if (openedTabs.tab.length === 10) {
        addToast('Warning!You can open maximum 10 tabs', {
          appearance: 'warning',
          autoDismiss: true,
        })
        return
      }

      const _tab = (
        <Tab
          key={rowData.FileName}
          value={rowData.FileName}
          label={
            <div>
              {rowData.FileName}
              <IconButton id={'delete' + rowData.FileName}>
                <ClearIcon id={'delete' + rowData.FileName} />
              </IconButton>
            </div>
          }
          {...a11yProps(rowData.FileName)}
        />
      )

      let fileExt = getFileExtensionType(rowData.FileName)
      //File URL authentication code oncontextmenu

      const tabelement = (
        <div
          id={rowData.FileName}
          key={rowData.FileName}
          className="main-index-container"
        >
          {fileExt === 'pdf' ? (
            <div className="pdf">
              <SnackbarContent
                message="If you are having trouble viewing this document, download"
                action={
                  <span style={{ textDecoration: 'underline' }}>
                    <a
                      download={rowData.fileName}
                      href={`${apiUrl}${
                        batch.selectedBatch
                      }/${encodeURIComponent(rowData.FileName)}&download=yes`}
                      onContextMenu={event => {
                        event.preventDefault()
                      }}
                    >
                      HERE
                    </a>
                  </span>
                }
              />
              <iframe
                src={`${apiUrl}${batch.selectedBatch}/${encodeURIComponent(
                  rowData.FileName
                )}`}
                key={rowData.FileName}
                width="100%"
                height="100%"
                title={rowData.FileName}
                style={{ height: '90vh' }}
              />
            </div>
          ) : (
            <div className="doc">
              <div>
                <SnackbarContent
                  message="If you are having trouble viewing this document, download"
                  action={
                    <span style={{ textDecoration: 'underline' }}>
                      <a
                        download={rowData.FileName}
                        href={`${apiUrl}${
                          batch.selectedBatch
                        }/${encodeURIComponent(rowData.FileName)}&download=yes`}
                        onContextMenu={event => {
                          event.preventDefault()
                        }}
                      >
                        HERE
                      </a>
                    </span>
                  }
                />
              </div>
              {fileExt === 'csv' || fileExt === 'xlsx' || fileExt === 'docx' ? (
                <CustomFileViewer
                  fileType={fileExt}
                  filePath={
                    apiUrl + 'UPLOAD/' + encodeURIComponent(rowData.FileName)
                  }
                  onError={onError}
                  title={rowData.FileName}
                />
              ) : (
                <div>File type is not supported</div>
                // <FileViewer
                //   fileType={fileExt}
                //   filePath={
                //     apiUrl + 'UPLOAD/' + encodeURIComponent(rowData.FileName)
                //   }
                //   onError={onError}
                // />
              )}
            </div>
          )}
          <div className="metadata-outer-container">
            <MetaData
              tossFilePath={
                userId +
                '/SPLIT/' +
                batch.selectedBatch.toUpperCase() +
                '/' +
                rowData.FileName
              }
              key={rowData.FileName}
              accountName={userId}
              type="index"
              recordId={rowData.recordId}
              setPendingDocumentCounter={[
                pendingDocumentsRefreshTrigger,
                setPendingDocumentsRefreshTrigger,
              ]}
              batchType={
                batch.selectedBatch === `NOBATCH` ||
                batch.selectedBatch === `UPLOAD`
                  ? `NOBATCH`
                  : batch.selectedBatch
              }
              indexingStatus={rowData.IndexingStatus}
              fileNameSelected={rowData.FileName}
            />
          </div>
        </div>
      )
      tempTab.push(_tab)

      setCurrentlyOpenDocument(rowData.FileName)
      tempFiles.push(tabContainerData)
      tempMetadata[rowData.FileName] = {} //add metadata field with key as filename
      tempIframeHolder.push(tabelement)

      dispatch({
        type: 'SAVE_OPENED_TABS_INDEX',
        payload: {
          field: 'tab',
          value: tempTab,
        },
      })
      dispatch({
        type: 'SAVE_OPENED_TABS_INDEX',
        payload: {
          field: 'files',
          value: tempFiles,
        },
      })
      dispatch({
        type: 'SAVE_OPENED_TABS_INDEX',
        payload: {
          field: 'documentViewer',
          value: tempIframeHolder,
        },
      })

      dispatch({
        type: 'SAVE_OPENED_TABS_INDEX',
        payload: {
          field: 'value',
          value: tabContainerData.value,
        },
      })

      dispatch({
        type: 'SAVE_INDEX_METADATA',
        payload: tempMetadata,
      })
    } else {
      dispatch({
        type: 'SAVE_OPENED_TABS_INDEX',
        payload: {
          field: 'value',
          value: isTabOpen.value,
        },
      })
    }
  }

  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = openedTabs.data.map(n => n.FileName)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }
  //   const handleClick = (event, name) => {
  //     const selectedIndex = selected.indexOf(name)
  //     let newSelected = []

  //     if (selectedIndex === -1) {
  //       newSelected = newSelected.concat(selected, name)
  //     } else if (selectedIndex === 0) {
  //       newSelected = newSelected.concat(selected.slice(1))
  //     } else if (selectedIndex === selected.length - 1) {
  //       newSelected = newSelected.concat(selected.slice(0, -1))
  //     } else if (selectedIndex > 0) {
  //       newSelected = newSelected.concat(
  //         selected.slice(0, selectedIndex),
  //         selected.slice(selectedIndex + 1)
  //       )
  //     }
  //     setSelected(newSelected)
  //   }

  const deleteSelectedFiles = () => {
    let url = apiConfig.apiUrl.delete_url
    let payload = {}
    payload.toss_path = userId + `/SPLIT/` + batch.selectedBatch.toUpperCase()
    payload.file_names = selected
    axios
      .delete(url, { data: payload })
      .then(response => {
        if (response.status !== 200) throw Error(response.statusText)
        addToast('Files deleted successfully', {
          appearance: 'success',
          autoDismiss: true,
        })
        if (openedTabs.data.length === payload.file_names.length) {
          dispatch({
            type: 'SAVE_SELECTED_BATCH',
            payload: { selectedBatch: '' },
          })
          setReleaseBatchIsTrue(true)
        } else setmetadataSearchResponse(response.data.records)
        setSelected([])
      })
      .catch(err => console.log(err))
  }

  const isSelected = name => {
    return selected.indexOf(name) !== -1
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }
  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }
  function getSorting(order, orderBy) {
    return order === 'desc'
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy)
  }
  const handleChangePage = (_event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  /**
   * To check if status is pending or not, incase it is disablesbatch button
   */
  useEffect(() => {
    for (let i = 0; i < openedTabs.data.length; i++) {
      if (openedTabs.data[i].IndexingStatus === `PENDING`) {
        setDisableReleaseBatch(true)
        return
      }
      if (i === openedTabs.data.length - 1) {
        setDisableReleaseBatch(false)
      }
    }
  }, [openedTabs.data])

  const handleBatchSubmit = () => {
    let url = `${apiConfig.apiUrl.release_batch_url}${userId}/${batch.selectedBatch}${apiConfig.apiUrl.key_url}`
    axios
      .put(url)
      .then(res => {
        if (res.status === 200) {
          addToast('Batch released successfully', {
            appearance: 'success',
            autoDismiss: true,
          })
          dispatch({
            type: 'SAVE_SELECTED_BATCH',
            payload: { selectedBatch: '' },
          })
          setReleaseBatchIsTrue(true)
        }
      })
      .catch(err => {
        addToast('There was an error connecting to the server', {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  const callAdgroupLists = adgroupList => {
    let url1 =
      apiConfig.apiUrl.adGroupFirtsPart +
      adgroupList[0] +
      apiConfig.apiUrl.adGroupSecondPart
    let url2 =
      apiConfig.apiUrl.adGroupFirtsPart +
      adgroupList[1] +
      apiConfig.apiUrl.adGroupSecondPart

    Promise.all([axios.get(url1), axios.get(url2)])
      .then(responses => {
        return responses.map(response => {
          return response.data
        })
      })
      .then(data => {
        //this variable has {name:lanId}
        let consolidatedData = Object.assign(
          {},
          data[0].display_name_list,
          data[1].display_name_list
        )

        let usernameArray = Object.keys(consolidatedData).sort()
        //this variable has {lanId:name}
        let idMappedData = {}
        // eslint-disable-next-line array-callback-return
        usernameArray.map(item => {
          idMappedData[consolidatedData[item]] = item
        })

        // let useridArray = Object.values(consolidatedData)
        dispatch({
          type: 'SET_USER_AD_GROUP',
          payload: {
            field: 'adGroupUserNamesArray',
            value: usernameArray,
          },
        })
        // dispatch({
        //   type: 'SET_USER_AD_GROUP',
        //   payload: {
        //     field: 'adGroupUserIdArray',
        //     value: useridArray,
        //   },
        // })
        dispatch({
          type: 'SET_USER_AD_GROUP',
          payload: {
            field: 'adGroupUserIdNameMap',
            value: idMappedData,
          },
        })
        dispatch({
          type: 'SET_USER_AD_GROUP',
          payload: {
            field: 'adGroupNameUserIdMap',
            value: consolidatedData,
          },
        })
        // let selectedUser =
        //   usernameArray[
        //     useridArray.findIndex(id => id === session.userInfo.lanId)
        //   ]
        let selectedUser = idMappedData[session.userInfo.lanId]
        dispatch({
          type: 'SAVE_SELECTED_USER',
          payload: {
            selectedUserName: selectedUser,
          },
        })
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    releaseBatchIsTrue && getBatchesList()
  }, [releaseBatchIsTrue])

  const getUserIDsFromADGroup = () => {
    let adgroupList = []
    if (commonDetails.has('APP-RDMP-SCANINDEX-RE-STAGE')) {
      adgroupList.push('APP-RDMP-SCANINDEX-RE-STAGE')
    }
    if (commonDetails.has('APP-RDMP-SCANINDEX-RE-PROD')) {
      adgroupList.push('APP-RDMP-SCANINDEX-RE-PROD')
    }
    if (commonDetails.has('APP-RDMP-SCANINDEX-PA-STAGE')) {
      adgroupList.push('APP-RDMP-SCANINDEX-PA-STAGE')
    }
    if (commonDetails.has('APP-RDMP-SCANINDEX-PA-PROD')) {
      adgroupList.push('APP-RDMP-SCANINDEX-PA-PROD')
    }

    if (adgroupList.length > 1) {
      callAdgroupLists(adgroupList)
    } else {
      let url =
        apiConfig.apiUrl.adGroupFirtsPart +
        adgroupList[0] +
        apiConfig.apiUrl.adGroupSecondPart
      dispatch({
        type: 'SAVE_OPENED_TABS_INDEX',
        payload: {
          field: 'value',
          value: 0,
        },
      })
      axios
        .get(url)
        .then(res => {
          if (res.status === 200 && Object.entries(res.data).length > 0) {
            // let useridArray = Object.values(res.data.display_name_list)
            let consolidatedData = res.data.display_name_list
            let usernameArray = Object.keys(consolidatedData).sort()

            let idMappedData = {}
            // eslint-disable-next-line array-callback-return
            usernameArray.map(item => {
              idMappedData[consolidatedData[item]] = item
            })

            dispatch({
              type: 'SET_USER_AD_GROUP',
              payload: {
                field: 'adGroupUserNamesArray',
                value: usernameArray,
              },
            })
            // dispatch({
            //   type: 'SET_USER_AD_GROUP',
            //   payload: {
            //     field: 'adGroupUserIdArray',
            //     value: useridArray,
            //   },
            // })
            dispatch({
              type: 'SET_USER_AD_GROUP',
              payload: {
                field: 'adGroupUserIdNameMap',
                value: idMappedData,
              },
            })
            dispatch({
              type: 'SET_USER_AD_GROUP',
              payload: {
                field: 'adGroupNameUserIdMap',
                value: consolidatedData,
              },
            })
            // let selectedUser =
            //   usernameArray[
            //     useridArray.findIndex(id => id === session.userInfo.lanId)
            //   ]
            let selectedUser = idMappedData[session.userInfo.lanId]

            dispatch({
              type: 'SAVE_SELECTED_USER',
              payload: {
                selectedUserName: selectedUser,
              },
            })

            return
          }
          if (res.status !== 200) {
            throw new Error(
              `The server responded with the status  ` + res.status
            )
          }
        })
        .catch(err => {
          console.log(err)
          // addToast('There was an error', {
          //   appearance: 'error',
          //   autoDismiss: true,
          // })
        })
    }
  }

  //when username changes
  useEffect(() => {
    if (userSelected.selectedUserName) {
      // let userNameIndexForGettingId =
      //   adGroup.adGroupUserIdArray[
      //     adGroup.adGroupUserNamesArray.findIndex(
      //       name => name === userSelected.selectedUserName
      //     )
      //   ]
      let userNameIndexForGettingId =
        adGroup.adGroupNameUserIdMap[userSelected.selectedUserName]

      dispatch({
        type: 'SAVE_SELECTED_USER',
        payload: {
          selectedUserId: userNameIndexForGettingId,
        },
      })
      userNameIndexForGettingId && setUserId(userNameIndexForGettingId)
      // setOpenDocs([])
    }
  }, [userSelected.selectedUserName])

  const [userId, setUserId] = useState(null)

  useEffect(() => {
    userSelected.selectedUserId
      ? setUserId(userSelected.selectedUserId)
      : setUserId(session.userInfo.lanId)
  }, [session])

  useEffect(() => {
    if (userId && !openedTabs.tab.length) {
      dispatch({
        type: 'SAVE_SELECTED_BATCH',
        payload: { selectedBatch: '' },
      })
      setReleaseBatchIsTrue(true)
    }
  }, [userId])

  const getBatchesList = () => {
    let url = `${apiConfig.apiUrl.get_batchList_url}${userId}${apiConfig.apiUrl.key_url}`
    releaseBatchIsTrue && setReleaseBatchIsTrue(false)
    axios
      .get(url)
      .then(res => {
        setLoadingScreen(false)
        if (res.status === 200 && res.data.batches.length > 0) {
          dispatch({
            type: 'SAVE_BATCH_LIST',
            payload: res.data.batches,
          })
          let batchToBeSet = batch.selectedBatch
            ? batch.selectedBatch
            : res.data.batches[0]
          batch.selectedBatch && setMetaDataFunc()
          dispatch({
            type: 'SAVE_SELECTED_BATCH',
            payload: { selectedBatch: batchToBeSet },
          })
          return
        }
        if (res.data.batches.length === 0) {
          dispatch({
            type: 'SAVE_BATCH_LIST',
            payload: [],
          })
          setmetadataSearchResponse([])
          return
        }
        throw new Error(`The server responded with the status  ` + res.status)
      })
      .catch(err => {
        addToast(err, {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  useEffect(() => {
    userId && batch.selectedBatch && setMetaDataFunc()
  }, [batch.selectedBatch, userId])

  const setMetaDataFunc = () => {
    setLoadingScreen(true)
    let url = `${apiConfig.apiUrl.get_batchList_url}${userId}/${batch.selectedBatch}${apiConfig.apiUrl.key_url}`

    axios.get(url).then(res => {
      if (res.status === 200 && !res.data.records.length) {
        dispatch({
          type: 'SAVE_SELECTED_BATCH',
          payload: { selectedBatch: '' },
        })
        setReleaseBatchIsTrue(true)
        return
      }
      if (res.status === 200) {
        setmetadataSearchResponse(res.data.records)
        setLoadingScreen(false)
      }
      if (res.status !== 200) {
        throw new Error(`The server responded with the status  ` + res.status)
      }
    })
  }

  useEffect(() => {
    if (userSelected.toBeSelectedUserName && !openedTabs.tab.length) {
      dispatch({
        type: 'SAVE_SELECTED_USER',
        payload: {
          selectedUserName: userSelected.toBeSelectedUserName,
          toBeSelectedUserName: '',
        },
      })
      return
    }
    if (userSelected.toBeSelectedUserName && openedTabs.tab.length) {
      setConsoleWindowProp({
        ...consoleWindowProp,
        loadConsoleWindowProp: true,
        dialogTitle: `Warning!`,
        dialogContent: apiConfig.apiUrl.change_batch_dialog_content,
        dialogFirstButton: apiConfig.apiUrl.change_user_first_button,
        dialogSecondButton: apiConfig.apiUrl.change_user_second_button,
      })
    }
  }, [userSelected.toBeSelectedUserName])

  useEffect(() => {
    if (userSelected.selectedUserName === '' && commonDetails) {
      getUserIDsFromADGroup()
    }
  }, [commonDetails])

  useEffect(() => {
    if (authDetails && Object.keys(authDetails).length > 0) {
      // eslint-disable-next-line no-prototype-builtins
      if (!authDetails.hasOwnProperty('access_feature_edit')) {
        history.push('/unauthorised')
      }
    }
  }, [authDetails])

  function handleChange(event, newValue) {
    if (
      (event.target.getAttribute('id') &&
        event.target.getAttribute('id').includes('delete')) ||
      event.target.tagName === 'path'
    ) {
      let tabList = [...openedTabs.tab]

      let fileList = [...openedTabs.files]
      let tempIframeHolder = [...openedTabs.documentViewer]
      let tempMetadata = Object.assign({}, metaData)

      let fileToBeRemoved = fileList.findIndex(val => val.value === newValue)
      tabList.splice(fileToBeRemoved, 1)
      tempIframeHolder.splice(fileToBeRemoved, 1)
      // openDocs.splice(fileToBeRemoved, 1)
      delete tempMetadata[newValue]

      dispatch({
        type: 'SAVE_OPENED_TABS_INDEX',
        payload: {
          field: 'tab',
          value: tabList,
        },
      })

      dispatch({
        type: 'SAVE_OPENED_TABS_INDEX',
        payload: {
          field: 'documentViewer',
          value: tempIframeHolder,
        },
      })

      fileList.splice(fileToBeRemoved, 1)

      dispatch({
        type: 'SAVE_OPENED_TABS_INDEX',
        payload: {
          field: 'files',
          value: fileList,
        },
      })

      dispatch({
        type: 'SAVE_INDEX_METADATA',
        payload: tempMetadata,
      })

      if (newValue === openedTabs.value) {
        if (fileToBeRemoved - 1 < 0) {
          dispatch({
            type: 'SAVE_OPENED_TABS_INDEX',
            payload: {
              field: 'value',
              value: 0,
            },
          })
        } else {
          dispatch({
            type: 'SAVE_OPENED_TABS_INDEX',
            payload: {
              field: 'value',
              value: fileList[fileToBeRemoved - 1].value,
            },
          })
        }
      }
    } else {
      // setValue(newValue)
      dispatch({
        type: 'SAVE_OPENED_TABS_INDEX',
        payload: {
          field: 'value',
          value: newValue,
        },
      })
      //   showFiles()
    }
  }

  const showFiles = () => {
    return (
      openedTabs.documentViewer &&
      openedTabs.documentViewer.map(item => {
        return (
          <TabPanel index={item.key} value={openedTabs.value}>
            {' '}
            {item}
          </TabPanel>
        )
      })
    )
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  return (
    <div className={classes.root}>
      <div className="username-dropdown-container">
        <div className="username-dropdown">
          <FormControl className={classes.margin} id="formcontrol-userid">
            <NativeSelect
              id="demo-customized-select-native"
              value={userSelected.selectedUserName}
              onChange={e =>
                dispatch({
                  type: 'SAVE_SELECTED_USER',
                  payload: { toBeSelectedUserName: e.target.value },
                })
              }
              input={<BootstrapInput />}
            >
              {adGroup.adGroupUserNamesArray.map(value => {
                return (
                  <option key={value} value={value}>
                    {value}
                  </option>
                )
              })}
            </NativeSelect>
          </FormControl>
          <FormControl className={classes.margin} id="formcontrol-batchid">
            <NativeSelect
              id="demo-customized-select-native"
              value={batch.selectedBatch}
              input={<BootstrapInput />}
              onChange={e => {
                e.preventDefault()

                dispatch({
                  type: 'SAVE_SELECTED_BATCH',
                  payload: {
                    toBeSelectedBatch: e.target.value.toUpperCase(),
                  },
                })
              }}
            >
              {batchList.map((value, index) => {
                return (
                  <option key={index} value={value}>
                    {value}
                  </option>
                )
              })}
            </NativeSelect>
          </FormControl>
          {!openedTabs.tab.length && (
            <span className="refresh-logo">
              <RefreshIcon
                color="primary"
                onClick={() => {
                  setLoadingScreen(true)
                  getBatchesList()
                }}
              />
            </span>
          )}
        </div>
      </div>

      <Tabs
        value={openedTabs.value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Pending Documents" {...a11yProps(0)} />
        {openedTabs.tab}
      </Tabs>

      <TabPanel value={openedTabs.value} index={0}>
        <div>
          {loadingScreen && openedTabs.value === 0 ? (
            <Skeleton
              component="div"
              style={{ transform: 'none', marginBottom: '20px' }}
              width={'100%'}
              height={'60vh'}
            />
          ) : (
            <Paper className={classes.paper}>
              <Table className={classes.table} aria-label="customized table">
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={openedTabs.data.length}
                />
                {openedTabs.data.length > 0 ? (
                  <TableBody>
                    {stableSort(openedTabs.data, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.FileName)
                        const labelId = `enhanced-table-checkbox-${index}`
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.FileName}
                            selected={isItemSelected}
                            onClick={event => handleClick(event, row.FileName)}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              onClick={() => openFile(row)}
                            >
                              {loadingScreen ? (
                                <Skeleton
                                  component="span"
                                  width={450}
                                  height={40}
                                />
                              ) : (
                                row.FileName
                              )}
                            </TableCell>
                            <TableCell>
                              {loadingScreen ? (
                                <Skeleton
                                  component="span"
                                  width={200}
                                  height={40}
                                />
                              ) : (
                                row.ScanDate
                              )}
                            </TableCell>
                            <TableCell
                              component="th"
                              name="Vikrant"
                              id={labelId}
                              scope="row"
                              padding="none"
                              onClick={() => openFile(row)}
                            >
                              {loadingScreen ? (
                                <Skeleton
                                  component="span"
                                  width={450}
                                  height={40}
                                />
                              ) : (
                                row.IndexingStatus
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow></TableRow>
                  </TableBody>
                )}
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={openedTabs.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </div>
        <div className="footer-button">
          <div className="footer-button-delete">
            <Button
              variant="contained"
              className={classes.button}
              onClick={deleteSelectedFiles}
              disabled={selected.length > 0 ? false : true}
            >
              Delete
            </Button>
          </div>
          {batch.selectedBatch &&
          openedTabs.data.length && // there should be files
            batch.selectedBatch !== `NOBATCH` &&
            batch.selectedBatch !== `UPLOAD` && (
              <div className="footer-button-submit">
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleBatchSubmit}
                  disabled={disableReleaseBatch}
                >
                  Release
                </Button>
              </div>
            )}
        </div>
      </TabPanel>
      {userId && showFiles()}
      {consoleWindowProp.loadConsoleWindowProp && (
        <ConsoleWindow cwProp={[consoleWindowProp, setConsoleWindowProp]} />
      )}
    </div>
  )
}
