/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import 'react-datepicker/dist/react-datepicker.css'
import NativeSelect from '@material-ui/core/NativeSelect'
import FormControl from '@material-ui/core/FormControl'
import documentPayload from '../reporting/Payload'
import { PaCamNoticeDocs } from '../reporting/PACAMNoticeDocs'
import { LeaseEasementDocs } from '../reporting/LeaseEasementReport'
import { LeaseNoticeDocs } from '../reporting/LeaseNoticeDocs'
import { LeasePendingDocs } from '../reporting/LeasePendingDocs'
import { LeaseReviewDocs } from '../reporting/LeaseReviewDocs'
import { LeaseQuarterlyDocs } from '../reporting/LeaseQuarterlyInbox'
import { CamQuarterlyDocs } from '../reporting/CamQuarterlyInbox'
import { CamPayableBudgetDocs } from '../reporting/PACAMPayableBudget'
import { CamPayableAndRecReturnedRecords } from './ReturnedRecords'
import { CamPayableAndRebillCompletedRecords } from '../reporting/CompletedBudgetReport'
import { CamPayableAndRebillBucketRecords } from '../reporting/BucketWiseRecords'
import Typography from '@material-ui/core/Typography'
import InputBase from '@material-ui/core/InputBase'
import Paper from '@material-ui/core/Paper'
import DatePicker from 'react-datepicker'
import { subDays } from 'date-fns'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    fontWeight: 'bold',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: 'rgb(231, 231, 231)',
    verticalAlign: 'middle',
    lineHeight: 1,
  },
}))
export default function Reporting() {
  const classes = useStyles()
  const [startDate, setStartDate] = useState(subDays(new Date(), 14))
  const [endDate, setEndDate] = useState(new Date())
  const [start, setStart] = useState()
  const [end, setEnd] = useState()
  const [camNoticeDocs, setCamNoticeDocs] = useState([])
  const [leaseEasementDocs, setLeaseEasementDocs] = useState([])
  const [leaseNoticeDocs, setLeaseNoticeDocs] = useState([])
  const [leasePendingDocs, setLeasePendingDocs] = useState([])
  const [leaseReviewDocs, setLeaseReviewDocs] = useState([])
  const [leaseQuarterlyDocs, setLeaseQuarterlyDocs] = useState([])
  const [camQuarterlyDocs, setCamQuarterlyDocs] = useState([])
  const [camPayableBudgetDocs, setCamPayableBudgetDocs] = useState([])
  const [returnedRebillRecords, setReturnedRebillRecords] = useState([])
  const [
    returnedPayableAndRecRecords,
    setReturnedPayableAndRecRecords,
  ] = useState([])
  const [
    returnedPayableUnexpectedRecords,
    setReturnedPayableUnexpectedRecRecords,
  ] = useState([])
  const [payableCompleted, setPayableCompleted] = useState([])
  const [rebillCompleted, setRebillCompleted] = useState([])
  const [payableBucket, setPayableBucket] = useState([])
  const [rebillBucket, setRebillBucket] = useState([])
  const [isLoading, setIsLoading] = useState([true])
  const [state, setState] = useState({
    reportType: '',
    contentType: 'PA CAM',
  })
  var indexValue
  var docArray = []
  const keys = Object.keys(documentPayload)
  const values = Object.values(documentPayload)
  // eslint-disable-next-line array-callback-return
  keys.filter((value, index) => {
    if (value === state.contentType) indexValue = index
  })
  // eslint-disable-next-line array-callback-return
  values.filter((value, index) => {
    if (index === indexValue) {
      docArray = value
    }
  })

  const authDetails = useSelector(state => state.common.authDetails)

  const history = useHistory()

  useEffect(() => {
    if (authDetails && Object.keys(authDetails).length > 0) {
      // eslint-disable-next-line no-prototype-builtins
      if (!authDetails.hasOwnProperty('access_feature_edit')) {
        history.push('/unauthorised')
      } else if (!authDetails['access_feature_edit'].includes('REPORTING')) {
        history.push('/unauthorised')
      }
    }
  }, [authDetails, history])

  useEffect(() => {
    setState({ ...state, reportType: docArray[0] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.contentType])

  const getCamNoticeDocs = () => {
    // event.preventDefault()
    let start = JSON.stringify(startDate)
    start = start.slice(1, 11)
    setStart(start)
    let end = JSON.stringify(endDate)
    end = end.slice(1, 11)
    setEnd(end)
    let url =
      apiConfig.apiUrl.getcamnoticedocs +
      `startDate=${start} 00:00&endDate=${end} 23:59`
    axios
      .get(url)
      .then(response => {
        setCamNoticeDocs(response.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  const getLeaseEasementDocs = () => {
    // event.preventDefault()
    let start = JSON.stringify(startDate)
    start = start.slice(1, 11)
    setStart(start)
    let end = JSON.stringify(endDate)
    end = end.slice(1, 11)
    setEnd(end)
    let url =
      apiConfig.apiUrl.geteasementdocs +
      `startDate=${start} 00:00&endDate=${end} 23:59`
    axios
      .get(url)
      .then(response => {
        setLeaseEasementDocs(response.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  const getLeaseNoticeDocs = () => {
    // event.preventDefault()
    let start = JSON.stringify(startDate)
    start = start.slice(1, 11)
    setStart(start)
    let end = JSON.stringify(endDate)
    end = end.slice(1, 11)
    setEnd(end)
    let url =
      apiConfig.apiUrl.getnoticedocs +
      `startDate=${start} 00:00&endDate=${end} 23:59`
    axios
      .get(url)
      .then(response => {
        setLeaseNoticeDocs(response.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  const getLeasePendingDocs = () => {
    // event.preventDefault()
    let start = JSON.stringify(startDate)
    start = start.slice(1, 11)
    setStart(start)
    let end = JSON.stringify(endDate)
    end = end.slice(1, 11)
    setEnd(end)
    let url =
      apiConfig.apiUrl.getpendingdocs +
      `startDate=${start} 00:00&endDate=${end} 23:59`
    axios
      .get(url)
      .then(response => {
        setLeasePendingDocs(response.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  const getLeaseReviewDocs = () => {
    // event.preventDefault()
    let start = JSON.stringify(startDate)
    start = start.slice(1, 11)
    setStart(start)
    let end = JSON.stringify(endDate)
    end = end.slice(1, 11)
    setEnd(end)
    let url =
      apiConfig.apiUrl.getreviewdocs +
      `startDate=${start} 00:00&endDate=${end} 23:59`
    axios
      .get(url)
      .then(response => {
        setLeaseReviewDocs(response.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  const getLeaseQuarterlyDocs = () => {
    // event.preventDefault()
    let start = JSON.stringify(startDate)
    start = start.slice(1, 11)
    setStart(start)
    let end = JSON.stringify(endDate)
    end = end.slice(1, 11)
    setEnd(end)
    let url =
      apiConfig.apiUrl.getleasequarterlydocs +
      `startDate=${start} 00:00&endDate=${end} 23:59`
    axios
      .get(url)
      .then(response => {
        setLeaseQuarterlyDocs(response.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  const getCamQuarterlyDocs = () => {
    // event.preventDefault()
    let start = JSON.stringify(startDate)
    start = start.slice(1, 11)
    setStart(start)
    let end = JSON.stringify(endDate)
    end = end.slice(1, 11)
    setEnd(end)
    let url =
      apiConfig.apiUrl.getcamquarterlydocs +
      `startDate=${start} 00:00&endDate=${end} 23:59`
    axios
      .get(url)
      .then(response => {
        setCamQuarterlyDocs(response.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  const getCamPayableBudgetDocs = () => {
    // event.preventDefault()
    let start = JSON.stringify(startDate)
    start = start.slice(1, 11)
    setStart(start)
    let end = JSON.stringify(endDate)
    end = end.slice(1, 11)
    setEnd(end)
    let url =
      apiConfig.apiUrl.getcampayablebudgetdocs +
      `startDate=${start} 00:00&endDate=${end} 23:59`
    axios
      .get(url)
      .then(response => {
        setCamPayableBudgetDocs(response.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }
  const getCamPayableAndRecReturnedRecords = () => {
    // event.preventDefault()
    let start = JSON.stringify(startDate)
    start = start.slice(1, 11)
    setStart(start)
    let end = JSON.stringify(endDate)
    end = end.slice(1, 11)
    setEnd(end)
    let url =
      apiConfig.apiUrl.getreturnedpayableandrecrecords +
      `startDate=${start} 00:00&endDate=${end} 23:59`
    axios
      .get(url)
      .then(response => {
        setReturnedPayableAndRecRecords(
          response.data.filter(
            item =>
              item.task_details[5].task_response_options !==
              'Preparer-identified Adjustment Needed'
          )
        )
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
    let url1 =
      apiConfig.apiUrl.getreturnedrebillrecords +
      `startDate=${start} 00:00&endDate=${end} 23:59`
    axios
      .get(url1)
      .then(response => {
        setReturnedRebillRecords(
          response.data.filter(
            item =>
              item.task_details[3].task_response_options !==
              'Preparer-identified Adjustment Needed'
          )
        )
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
    let url2 =
      apiConfig.apiUrl.getreturnedpayableunexpectedrecords +
      `startDate=${start} 00:00&endDate=${end} 23:59`
    axios
      .get(url2)
      .then(response => {
        setReturnedPayableUnexpectedRecRecords(
          response.data.filter(
            item =>
              item.task_details[3].task_response_options !==
              'Preparer-identified Adjustment Needed'
          )
        )
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  const getCamPayableAndRebillCompletedRecords = () => {
    // event.preventDefault()
    let start = JSON.stringify(startDate)
    start = start.slice(1, 11)
    setStart(start)
    let end = JSON.stringify(endDate)
    end = end.slice(1, 11)
    setEnd(end)
    let url =
      apiConfig.apiUrl.getpayablecompletedrecords +
      `startDate=${start} 00:00&endDate=${end} 23:59`
    axios
      .get(url)
      .then(response => {
        setPayableCompleted(response.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
    let url1 =
      apiConfig.apiUrl.getrebillcompletedrecords +
      `startDate=${start} 00:00&endDate=${end} 23:59`
    axios
      .get(url1)
      .then(response => {
        setRebillCompleted(response.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  const getCamPayableAndRebillBucketRecords = () => {
    // event.preventDefault()
    let start = JSON.stringify(startDate)
    start = start.slice(1, 11)
    setStart(start)
    var endDate = moment(start, 'YYYY-MM-DD')
    var end = endDate.add(6, 'days').format('YYYY-MM-DD')
    console.log(end)
    setEnd(end)
    let url =
      apiConfig.apiUrl.getpayablebucketrecords +
      `startDate=${start} 00:00&endDate=${end} 23:59`
    axios
      .get(url)
      .then(response => {
        setPayableBucket(response.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
    let url1 =
      apiConfig.apiUrl.getrebillbucketrecords +
      `startDate=${start} 00:00&endDate=${end} 23:59`
    axios
      .get(url1)
      .then(response => {
        setRebillBucket(response.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }
  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Paper className={classes.paper}>
          <div>
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <Typography>
                <b>Team: </b>
              </Typography>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <FormControl
                className={classes.margin}
                id="formcontrol-contentType"
              >
                <NativeSelect
                  id="content-type-select-native"
                  onChange={e => {
                    setState({ ...state, contentType: e.target.value })
                  }}
                  input={<BootstrapInput />}
                  value={state.contentType}
                >
                  {Object.keys(documentPayload).map(value => {
                    return (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    )
                  })}
                </NativeSelect>
              </FormControl>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <Typography>
                <b>Report Type: </b>
              </Typography>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <FormControl
                className={classes.margin}
                id="formcontrol-contentType"
              >
                <NativeSelect
                  id="content-type-select-native"
                  onChange={e => {
                    setState({ ...state, reportType: e.target.value })
                  }}
                  input={<BootstrapInput />}
                  value={state.reportType}
                >
                  {docArray.map(value => {
                    return (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    )
                  })}
                </NativeSelect>
              </FormControl>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div style={{ display: 'inline-block' }}>
              <Typography>
                <b>From Date: </b>
              </Typography>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div
              style={{
                display: 'inline-block',
                lineHeight: 2.3,
                verticalAlign: 'middle',
              }}
            >
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                onFocus={e => e.target.blur()}
                dateFormat="MM/dd/yyyy"
                className="start-date"
              />
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div style={{ display: 'inline-block' }}>
              {state.reportType === 'Productivity Weekly Volumes report' ? (
                ''
              ) : (
                <Typography>
                  <b>To Date: </b>
                </Typography>
              )}
            </div>
            &nbsp;&nbsp;&nbsp;
            <div
              style={{
                display: 'inline-block',
                lineHeight: 2.3,
                fontWeight: 500,
                verticalAlign: 'middle',
              }}
            >
              {state.reportType === 'Productivity Weekly Volumes report' ? (
                ''
              ) : (
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  onFocus={e => e.target.blur()}
                  dateFormat="MM/dd/yyyy"
                  className="start-date"
                />
              )}
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <Button
                style={{
                  textAlign: 'center',
                  lineHeight: 1.8,
                  verticalAlign: 'middle',
                }}
                onClick={
                  state.reportType === 'Notice Documents Report'
                    ? getCamNoticeDocs
                    : state.reportType === 'Easement Report'
                    ? getLeaseEasementDocs
                    : state.reportType === 'Notice Documents'
                    ? getLeaseNoticeDocs
                    : state.reportType === 'Pending Report'
                    ? getLeasePendingDocs
                    : state.reportType === 'InReview Report'
                    ? getLeaseReviewDocs
                    : state.reportType === 'Quarterly Inbox Report'
                    ? getLeaseQuarterlyDocs
                    : state.reportType === 'CAM Quarterly Inbox Report'
                    ? getCamQuarterlyDocs
                    : state.reportType === 'Payable Budget'
                    ? getCamPayableBudgetDocs
                    : state.reportType === 'Quality Metrics report'
                    ? getCamPayableAndRecReturnedRecords
                    : state.reportType === 'Productivity Metrics report'
                    ? getCamPayableAndRebillCompletedRecords
                    : state.reportType === 'Productivity Weekly Volumes report'
                    ? getCamPayableAndRebillBucketRecords
                    : ''
                }
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>
      <br />
      <br />
      {state.reportType === 'Notice Documents Report' ? (
        <PaCamNoticeDocs
          start={start}
          end={end}
          camNoticeDocs={camNoticeDocs}
          isLoading={isLoading}
          getCamNoticeDocs={getCamNoticeDocs}
        />
      ) : state.reportType === 'Easement Report' ? (
        <LeaseEasementDocs
          start={start}
          end={end}
          leaseEasementDocs={leaseEasementDocs}
          isLoading={isLoading}
          getLeaseEasementDocs={getLeaseEasementDocs}
        />
      ) : state.reportType === 'Notice Documents' ? (
        <LeaseNoticeDocs
          start={start}
          end={end}
          leaseNoticeDocs={leaseNoticeDocs}
          isLoading={isLoading}
          getLeaseNoticeDocs={getLeaseNoticeDocs}
        />
      ) : state.reportType === 'Pending Report' ? (
        <LeasePendingDocs
          start={start}
          end={end}
          leasePendingDocs={leasePendingDocs}
          isLoading={isLoading}
          getLeasePendingDocs={getLeasePendingDocs}
        />
      ) : state.reportType === 'InReview Report' ? (
        <LeaseReviewDocs
          start={start}
          end={end}
          leaseReviewDocs={leaseReviewDocs}
          isLoading={isLoading}
          getLeaseReviewDocs={getLeaseReviewDocs}
        />
      ) : state.reportType === 'Quarterly Inbox Report' ? (
        <LeaseQuarterlyDocs
          start={start}
          end={end}
          leaseQuarterlyDocs={leaseQuarterlyDocs}
          isLoading={isLoading}
          getLeaseQuarterlyDocs={getLeaseQuarterlyDocs}
        />
      ) : state.reportType === 'CAM Quarterly Inbox Report' ? (
        <CamQuarterlyDocs
          start={start}
          end={end}
          camQuarterlyDocs={camQuarterlyDocs}
          isLoading={isLoading}
          getCamQuarterlyDocs={getCamQuarterlyDocs}
        />
      ) : state.reportType === 'Payable Budget' ? (
        <CamPayableBudgetDocs
          start={start}
          end={end}
          camPayableBudgetDocs={camPayableBudgetDocs}
          isLoading={isLoading}
          getCamPayableBudgetDocs={getCamPayableBudgetDocs}
        />
      ) : state.reportType === 'Quality Metrics report' ? (
        <CamPayableAndRecReturnedRecords
          start={start}
          end={end}
          camPayableAndRecReturnedRecords={returnedPayableAndRecRecords}
          camRebillReturnedRecords={returnedRebillRecords}
          camPayableUnexpectedReturnedRecords={returnedPayableUnexpectedRecords}
          isLoading={isLoading}
          getCamPayableAndRecReturnedRecords={
            getCamPayableAndRecReturnedRecords
          }
        />
      ) : state.reportType === 'Productivity Metrics report' ? (
        <CamPayableAndRebillCompletedRecords
          start={start}
          end={end}
          camPayableCompletedRecords={payableCompleted}
          camRebillCompletedRecords={rebillCompleted}
          isLoading={isLoading}
          getCamPayableAndRebillCompletedRecords={
            getCamPayableAndRebillCompletedRecords
          }
        />
      ) : state.reportType === 'Productivity Weekly Volumes report' ? (
        <CamPayableAndRebillBucketRecords
          start={start}
          end={end}
          camPayableBucketRecords={payableBucket}
          camRebillBucketRecords={rebillBucket}
          isLoading={isLoading}
          getCamPayableAndRebillBucketRecords={
            getCamPayableAndRebillBucketRecords
          }
        />
      ) : (
        ''
      )}
    </div>
  )
}
