import React, { Component } from 'react'
// import ReactDataGrid from 'react-data-grid'
import DataGrid from 'react-data-grid'
import 'react-data-grid/dist/react-data-grid.css'
import CSV from 'comma-separated-values'

class CsvViewer extends Component {
  static parse(data) {
    const rows = []
    const columns = []

    new CSV(data).forEach(array => {
      if (columns.length < 1) {
        array.forEach((cell, idx) => {
          columns.push({
            key: `key-${idx}`,
            name: cell,
            resizable: true,
            sortable: true,
            filterable: true,
          })
        })
      } else {
        const row = {}
        array.forEach((cell, idx) => {
          row[`key-${idx}`] = cell
        })
        rows.push(row)
      }
    })

    return { rows, columns }
  }

  constructor(props) {
    super(props)
    // this.state = CsvViewer.parse(props.data)
    this.state = {}
  }

  componentDidMount() {
    if (this.props.data) {
      let newState = CsvViewer.parse(this.props.data)
      this.setState(newState)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState(CsvViewer.parse(this.props.data))
    }
  }

  render() {
    const { rows, columns } = this.state
    if (this.state.columns && this.state.columns.length > 0) {
      return (
        <DataGrid
          columns={columns}
          rows={rows}
          //   columns={this.state.columns}
          //   rowsCount={this.state.rows.length}
          //   rowGetter={i => rows[i]}
          height={this.props.height || 650}
        />
        //   </div>
      )
    } else return null
  }
}

export default CsvViewer
