/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
// import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
// import TextField from '@material-ui/core/TextField'
import MetadataSearch from './MetadataSearch'
import './SearchStyle.css'
// import FormControl from '@material-ui/core/FormControl'
// import InputLabel from '@material-ui/core/InputLabel'
// import { useEffect } from 'react'
// import NativeSelect from '@material-ui/core/NativeSelect'
// import InputBase from '@material-ui/core/InputBase'
// import { useAuth } from '@praxis/component-auth'
// import { getMembership } from '../common/validation'
import { useSelector } from 'react-redux'
// import Paper from '@material-ui/core/Paper'
// import Grid from '@material-ui/core/Grid'
import SearchSectionOnly from './searchSectionOnly'

const useStyles = makeStyles(theme => ({
  root: {
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    display: 'inline-block',
  },
  margin: {
    margin: theme.spacing(1),
    minWidth: '200px',
  },
  margin1: {
    margin: theme.spacing(1),
    minWidth: '200px',
    maxWidth: '200px',
  },
  margin2: {
    margin: theme.spacing(1),
    width: '100px',
    marginTop: '-6px',
  },
  button: {
    margin: theme.spacing(1),
    display: 'inline-block',
    // width: 150,
    color: 'white',
    marginTop: 20,
    backgroundColor: '#AC0000',
    '&:hover': {
      backgroundColor: '#AC0000',
    },
  },
  fab: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundColor: '#AC0000',
    '&:hover': {
      backgroundColor: '#AC0000',
    },
  },
  searchSection: {
    // display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#EEE',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: '10px 600px',
  },
}))
export default function Search(props) {
  const searchObject = useSelector(state => state.search)
  const search = useSelector(state => state.search.searchState)

  const classes = useStyles()
  // const inputLabel = React.useRef(null)

  let userDataReceived

  const authDetails = useSelector(state => state.common.authDetails)

  return (
    <div className={classes.root}>
      <SearchSectionOnly />

      <div className={classes.searchResults}>
        {(searchObject.savedSearchResponse.length > 0 ||
          search.displaySearchResults) && (
          <MetadataSearch
            authDetails={authDetails}
            userData={userDataReceived}
            // showrefreshLogoInMetaDataSearch={showRefreshLogoInMetaDataSearch}
          />
        )}
      </div>
    </div>
  )
}
