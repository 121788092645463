let initialState = {
  bucketName: [],
  openedWorkflowTabs: [],
  openedWorkflowFiles: [],
  openedDocumentViewer: [], //for iframe
  currentTabValue: 0,
  contentType: '',
  bucketList: [],
  contentTypeList: [],
  workflowTasks: {},
  workflowMetadata: {},
  routeToList: [],
  wfOrderBy: -1,
  wfOrderDirection: '',
  workflowData: [],
  workflowCurrentPage: 0,
  workflowRowsPerPage: 10,
}

export function WorkflowReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case 'SAVE_BUCKET_NAME':
      return Object.assign({}, state, {
        bucketName: payload,
      })

    case 'SAVE_CONTENT_TYPE':
      return Object.assign({}, state, {
        contentType: payload,
      })
    case 'SAVE_BUCKET_LIST':
      return Object.assign({}, state, {
        bucketList: payload,
      })
    case 'SAVE_CONTENT_TYPE_LIST':
      return Object.assign({}, state, {
        contentTypeList: payload,
      })
    case 'SAVE_OPENED_WORKFLOW_TABS':
      return Object.assign({}, state, {
        openedWorkflowTabs: payload,
      })
    case 'SAVE_OPENED_WORKFLOW_DOCUMENT_VIEWER':
      return Object.assign({}, state, {
        openedDocumentViewer: payload,
      })
    case 'SAVE_OPENED_WORKFLOW_FILES':
      return Object.assign({}, state, {
        openedWorkflowFiles: payload,
      })

    case 'SAVE_CURRENT_TAB':
      return Object.assign({}, state, {
        currentTabValue: payload,
      })

    case 'SAVE_WORKFLOW_TASKS':
      return Object.assign({}, state, {
        workflowTasks: payload,
      })

    case 'SAVE_WORKFLOW_METADATA':
      return Object.assign({}, state, {
        workflowMetadata: payload,
      })

    case 'SAVE_ROUTE_TO_LIST':
      return Object.assign({}, state, {
        routeToList: payload,
      })

    case 'SAVE_ORDER_BY':
      return Object.assign({}, state, {
        wfOrderBy: payload,
      })
    case 'SAVE_ORDER_DIRECTION':
      return Object.assign({}, state, {
        wfOrderDirection: payload,
      })
    case 'SAVE_WORKFLOW_DATA':
      return Object.assign({}, state, {
        workflowData: payload,
      })
    case 'SAVE_CURRENT_PAGE':
      return Object.assign({}, state, {
        workflowCurrentPage: payload,
      })
    case 'SAVE_ROWS_PER_PAGE':
      return Object.assign({}, state, {
        workflowRowsPerPage: payload,
      })
    default:
      return state
  }
}
