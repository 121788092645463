/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import SearchIcon from '@material-ui/icons/Search'
import ScannerIcon from '@material-ui/icons/Scanner'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import LinearScaleIcon from '@material-ui/icons/LinearScale'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import AppBarRDM from '../appbar/AppBarRDM'
import Login from '../login/Login'
import axios from 'axios'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import { useAuth } from '@praxis/component-auth'
import { useDispatch } from 'react-redux'
import {
  getDocumentGroupDocumentType,
  getContentTypeDropdown,
} from '../common/validation'
import { useHistory } from 'react-router-dom'
import apiConfig from '../../config/apiConfig'
import { useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import TableChartIcon from '@material-ui/icons/TableChart'
import BorderColorIcon from '@material-ui/icons/BorderColor'
// import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import HelpIcon from '@material-ui/icons/Help'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#AC0000',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
  apptoolbar: {
    ...theme.mixins.toolbar,
  },
  unauthorised: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50 %, -50 %)',
    color: '#AC0000',
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: '24px',
  },
  paper: {
    background: 'black',
    color: 'white',
  },
}))

function SideNavBar(props) {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  // eslint-disable-next-line no-unused-vars
  let [authDetails, setAuthDetails] = useState(undefined)
  const auth = useAuth()

  const { isAuthorized, session } = auth
  let history = useHistory()

  const dispatch = useDispatch()
  const metadataObj = useSelector(state => state.common.locationData)
  const savedAuthDetails = useSelector(state => state.common.authDetails)

  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }

  useEffect(() => {
    // console.log(session.userInfo)
    // console.log(savedAuthDetails)
    isAuthorized() &&
      Object.keys(savedAuthDetails).length === 0 &&
      getAuthDetails(session.userInfo.memberOf.join(','))
  }, [isAuthorized()])

  function getAuthDetails(authgroup) {
    let allAuthgroups = authgroup.toUpperCase()
    allAuthgroups = allAuthgroups.split(',')

    let envSpecificAdgroups = []
    console.log(apiConfig.auth.env)
    if (apiConfig.auth.env === 'dev' || apiConfig.auth.env === 'stg') {
      envSpecificAdgroups = allAuthgroups.filter(item => item.includes('STAGE'))
    } else if (apiConfig.auth.env === 'prod') {
      envSpecificAdgroups = allAuthgroups.filter(item => item.includes('PROD'))
    }
    let authgroupSet = new Set(envSpecificAdgroups)
    dispatch({
      type: 'SAVE_ALL_ADGROUPS',
      payload: authgroupSet,
    })
    if (isAuthorized()) {
      setAuthDetails(undefined)
      let filteredAuthGroups = envSpecificAdgroups.join(',')

      axios
        .get(apiConfig.apiUrl.auth_url + filteredAuthGroups)
        .then(res => {
          let receivedData = res.data
          setAuthDetails(receivedData)
          if (Object.keys(res.data).length === 0) {
            throw new Error('Not Authorised')
          }
          dispatch({
            type: 'SAVE_AUTHDETAILS',
            payload: receivedData,
          })
          let docGroupDocType = getDocumentGroupDocumentType(res.data)
          let contentTypeMap = getContentTypeDropdown(res.data)
          //only for bulk edit
          let docGroupDocTypeBulkEdit = getDocumentGroupDocumentType(
            res.data,
            true
          )
          dispatch({
            type: 'SAVE_DOCUMENT_GROUP_DOCUMENT_TYPE_BULK_EDIT',
            payload: docGroupDocTypeBulkEdit,
          })
          // getContentTypeDropdown(res.data)
          dispatch({
            type: 'SAVE_DOCUMENT_GROUP_DOCUMENT_TYPE',
            payload: docGroupDocType,
          })
          dispatch({
            type: 'SAVE_CONTENT_TYPE_DOCUMENT_GROUP_DOCUMENT_TYPE',
            payload: contentTypeMap,
          })
          localStorage.setItem('authDetails', JSON.stringify(res.data))
          !Object.entries(metadataObj).length && getLocationData()
        })
        .catch(err => {
          console.log(err)

          history.push('/unauthorised')
          // } else alert(err)
        })
    }
  }

  const getLocationData = async () => {
    let url = apiConfig.apiUrl.locationApi
    axios.get(url).then(res => {
      if (res.status === 200) {
        dispatch({
          type: 'SAVE_LOCATIONDATA',
          payload: res.data,
        })
      }
    })
  }
  const showToolbar = (access, accessValue = false) => {
    if (isAuthorized()) {
      if (
        savedAuthDetails &&
        Object.prototype.hasOwnProperty.call(savedAuthDetails, access)
      ) {
        if (accessValue) {
          let accessArray = savedAuthDetails[access]
          if (!accessArray.includes(accessValue)) return 'none'
        }
        return 'block'
      }
    }
    return 'none'
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <div style={{ display: isAuthorized() ? 'block' : 'none' }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <AppBarRDM />
          <Login />
        </Toolbar>
      </AppBar>
      <div style={{ display: isAuthorized() ? 'block' : 'none' }}>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
              Menu
            </IconButton>
          </div>
          <Divider />
          <List>
            <div
              style={{
                display: showToolbar('access_feature_search'),
              }}
            >
              <ListItem button divider="true" key="Search">
                <Link
                  to={{
                    pathname: '/',
                  }}
                >
                  <ListItemIcon>
                    <Tooltip title="Search">
                      <SearchIcon />
                    </Tooltip>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <ListItemText primary="Search" />
                  </ListItemIcon>
                </Link>
              </ListItem>
            </div>
            <div
              style={{
                display: showToolbar('access_feature_edit'),
              }}
              id="refresh"
            >
              <ListItem button divider="true" key="Upload">
                <Link to="/upload">
                  <ListItemIcon>
                    <Tooltip title="Upload">
                      <CloudUploadIcon />
                    </Tooltip>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <ListItemText primary="Upload" />
                  </ListItemIcon>
                </Link>
              </ListItem>
            </div>
            <div
              style={{
                display: showToolbar('access_feature_edit'),
              }}
            >
              <ListItem button divider="true" key="Index">
                <Link to="/index">
                  <div>
                    <ListItemIcon>
                      <Tooltip title="Index">
                        <ScannerIcon />
                      </Tooltip>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <ListItemText primary="Index" />
                    </ListItemIcon>
                  </div>
                </Link>
              </ListItem>
            </div>
            <div
              style={{
                display: showToolbar('access_feature_workflow'),
              }}
              id="workflow"
            >
              <ListItem button divider="true" key="Workflow">
                <Link to="/workflow">
                  <ListItemIcon>
                    <Tooltip title="Workflow">
                      <LinearScaleIcon />
                    </Tooltip>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <ListItemText primary="Workflow" />
                  </ListItemIcon>
                </Link>
              </ListItem>
            </div>
            <div
              style={{
                display: showToolbar('access_feature_location'),
              }}
              id="location"
            >
              <ListItem button divider="true" key="Location">
                <Link to="/location">
                  <ListItemIcon>
                    <Tooltip title="Location">
                      <LocationOnIcon />
                    </Tooltip>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <ListItemText primary="Location" />
                  </ListItemIcon>
                </Link>
              </ListItem>
            </div>
            <div
              style={{
                display: showToolbar('access_feature_edit', 'REPORTING'),
              }}
              id="reporting"
            >
              <ListItem button divider="true" key="Reporting">
                <Link to="/reporting">
                  <ListItemIcon>
                    <Tooltip title="Reporting">
                      <TableChartIcon />
                    </Tooltip>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <ListItemText primary="Reporting" />
                  </ListItemIcon>
                </Link>
              </ListItem>
            </div>
            <div
              style={{
                display: showToolbar('access_feature_location', 'BULK EDIT'),
              }}
              // id="refresh"
            >
              <ListItem button divider="true" key="BulkEdit">
                <Link to="/bulkEdit">
                  <div>
                    <ListItemIcon>
                      <Tooltip title="Bulk Edit">
                        <BorderColorIcon />
                      </Tooltip>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <ListItemText primary="BulkEdit" />
                    </ListItemIcon>
                  </div>
                </Link>
              </ListItem>
            </div>

            {/* <div
              style={{
                display: showToolbar('access_feature_admin'),
              }}
              // id="refresh"
            >
              <ListItem button divider="true" key="Health Dashboard">
                <Link to="/health">
                  <div>
                    <ListItemIcon>
                      <Tooltip title="Health Dashboard">
                        <LocalHospitalIcon />
                      </Tooltip>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <ListItemText primary="Health Dashboard" />
                    </ListItemIcon>
                  </div>
                </Link>
              </ListItem>
            </div> */}
            <div
              style={{
                display: showToolbar('access_feature_support'),
              }}
            >
              <ListItem button divider="true" key="Support">
                <Link to="/support">
                  <div>
                    <ListItemIcon>
                      <Tooltip title="Support">
                        <HelpIcon />
                      </Tooltip>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <ListItemText primary="Support" />
                    </ListItemIcon>
                  </div>
                </Link>
              </ListItem>
            </div>
          </List>
        </Drawer>
      </div>
      <main className={classes.content}>
        <div style={{ display: isAuthorized() ? 'block' : 'none' }}>
          <div className={classes.apptoolbar} />
          <Container maxWidth={false} className={classes.container}>
            {isAuthorized() ? props.children : null}
          </Container>
        </div>
      </main>
    </div>
  )
}

export default SideNavBar
