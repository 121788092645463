/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { makeStyles } from '@material-ui/core/styles'
// import Typography from '@material-ui/core/Typography'
import { properties } from '../../Properties'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
// import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
// import PDF from 'react-pdf-js-infinite'
// import FileViewer from 'react-file-viewer'
// import '../../style.css'
// import './IndexViewStyle.css'
import '../index/IndexViewStyle.css'
import './MetadataSearchStyle.css'
import Button from '@material-ui/core/Button'
import MetaData from './MetaData'
// import Skeleton from '@material-ui/lab/Skeleton'
// import CircularProgress from '@material-ui/core/CircularProgress'
import apiConfig from '../../config/apiConfig'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { useDispatch, useSelector } from 'react-redux'
// import TablePagination from '@material-ui/core/TablePagination'
import { useAuth } from '@praxis/component-auth'
import { returnMetadataSearchFields } from '../common/returnMetadataSearchFields'
import CheckFile from './CheckFile'
import { SupportingDocuments } from './supportingDocuments'
import Box from '@material-ui/core/Box'
import {
  tableIcons,
  getFieldValue,
  replaceUrl,
} from '../common/commonFunctionalities'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useToasts } from 'react-toast-notifications'
import { ExportDialog } from '../common/exportDialog'
import { CsvBuilder } from 'filefy'
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { CustomFileViewer } from '../common/customFileViewer'
import Tooltip from '@material-ui/core/Tooltip'
import { TabPanel, a11yProps } from '../common/commonFunctionalities'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import SendMail from '../email/sendMail'
import { validateGeneralUser } from '../common/validation'
import GetAppIcon from '@material-ui/icons/GetApp'
import _ from 'lodash'
import DownloadMultiDialog from './DownloadMultiDialog'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress'

const FileDownload = require('js-file-download')

let authDetails

// let tableOrderBy = -1
// let tableOrderDirection = ''

export default function MetadataSearch(props) {
  const auth = useAuth()
  const { session } = auth

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    img: {
      width: '90%',
      display: 'flex',
    },
    textField: {
      color: 'red',
    },
    button: {
      marginTop: 20,
    },
    paper: {
      marginTop: 10,
    },
    exportTextfield: {
      display: 'flex',
    },
    checkboxGroup: {
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'space-evenly',
      justifyContent: 'space-between',
    },
    individualCheckbox: {
      width: 250,
    },
    exportLabel: {
      alignItems: 'center',
      paddingTop: '2rem',
      fontSize: '1rem',
    },
    exportText: {
      flexGrow: 2,
    },
    copyColor: {
      color: '#AC0000',
      textDecoration: 'none',
    },
    progressWrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    fabProgress: {
      position: 'absolute',
      top: 0,
      left: 15,
      zIndex: 1,
    },
    exportButton: {
      padding: '13px',
    },
  }))

  const [metadataSearchResponse, setmetadataSearchResponse] = useState([])
  // const [value, setValue] = React.useState(0)

  // eslint-disable-next-line no-unused-vars
  const [loadIndexForm, setLoadIndexForm] = useState(new Set())
  // eslint-disable-next-line no-unused-vars
  const [loadingScreen, setLoadingScreen] = useState(false)
  // const [setLoadingScreen] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(100)
  const [copyOfSearchMetadata, setCopyOfSearchMetadata] = React.useState(null)
  const [openWorkflowRetrigger, setOpenWorkflowRetrigger] = React.useState(
    false
  )
  const [sizeCheck, setSizeCheck] = React.useState(0)
  const [toggleDownload, setToggleDownload] = React.useState(false)
  const [deselectRows, setDeselectRows] = React.useState(false)
  const [openDeleteDoc, setOpenDeleteDoc] = React.useState(false)
  const [selectedColumns, setSelectedColumns] = useState([])
  const [tableDataToBeExported, setTableDataToBeExported] = useState(null)
  const [filename, setFilename] = useState('')
  const [exportSelectAll, setExportSelectAll] = useState(true)
  const [metaData, setMetaData] = useState({})
  const [wfRetrigger, setWfRetrigger] = useState('')
  // const [businessType, setBusinessType] = useState('')
  const [loadTable, setLoadTable] = useState(false)
  const { addToast } = useToasts()
  const materialTableSearchRef = React.createRef()

  const classes = useStyles()
  const [state, setState] = React.useState({
    columns: properties.search.metadataViewFields,
    data: [],

    showFiles: [],
  })

  const fixedColumns = properties.search.metadataViewFields
  const openedTabs = useSelector(state => state.search.openedTabs) //redux
  const searchObjectRedux = useSelector(state => state.search)
  const search = useSelector(state => state.search.searchState) //redux
  const pageInfo = searchObjectRedux.searchPageInfo
  const searchMetadata = useSelector(state => state.search.searchMetaData)
  const documentGroupTypePayload = useSelector(
    state => state.common.documentGroupAndDocumentType
  )
  const changedFiles = useSelector(state => state.search.openedTabs.files)
  const iframeTriger = useSelector(state => state.common.triggerIframeRerender)
  const dispatch = useDispatch()
  const authDetailsData = useSelector(state => state.common.authDetails)
  const adgroups = useSelector(state => state.common.allAdgroupList)
  const [selectedRows, setSelectedRows] = useState([])
  const [tableOrderBy, setTableOrderBy] = useState(-1)
  const [tableOrderDirection, setTableOrderDirection] = useState('')
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false)
  const [downloadData, setDownloadData] = useState([])
  const [maxFileSize, setMaxFileSize] = useState([])
  const [inProgress, setInProgress] = useState(false)
  const maxDownloadLimit = 500000
  const maxNoOfFilesForDownload = 5
  useEffect(() => {
    // let currentRef = materialTableRef.current
    if (searchObjectRedux.savedSearchResponse.length > 0) {
      setState({ ...state, data: searchObjectRedux.savedSearchResponse })
      // currentRef.onChangePage({}, search.searchCurrentPage)
    }
    if (!toggleDownload) {
      handleDownloadClose()
    }
  }, [])

  // handles the logic for multiple downloads file size validation and dialog box open
  const handleDownloadClickOpen = async () => {
    if (selectedRows.length > 5) {
      addToast('You can not Download more than 5 files', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }
    setToggleDownload(false)
    addToast('Validating Download', {
      appearance: 'info',
      autoDismiss: true,
      autoDismissTimeout: 2000,
    })
    let rowData = []
    selectedRows.forEach(element => {
      rowData.push({
        LocationNumber: element.LocationNumber,
        DocumentTitle: element.DocumentTitle,
        LocationId: element.LocationId,
        tossFilePath: element.tossFilePath,
      })
    })

    let payload = []
    selectedRows.forEach(element => {
      var fileName = element.LocationId
      var filePath = element.tossFilePath
      payload.push({ LocationId: fileName, tossFilePath: filePath })
    })

    let AlertSize = 0
    let max = 0
    let url = apiConfig.apiUrl.fetchFilSize
    await axios
      .post(url, payload)
      .then(response => {
        if (response.status === 200) {
          let receivedData = response.data
          let merged = []
          for (let i = 0; i < receivedData.length; i++) {
            AlertSize += receivedData[i].fileSize
            max = Math.max(max, receivedData[i].fileSize)
            merged.push({
              ...receivedData[i],
              ...rowData.find(
                itmInner => itmInner.LocationId === receivedData[i].LocationId
              ),
            })
          }
          rowData = merged
        } else if (response.status !== 200) {
          addToast('Your Download was not Validated', {
            appearance: 'error',
            autoDismiss: true,
          })
          handleDownloadClose()
          return
        }
      })
      .catch(err => console.log(err))

    if (AlertSize > maxDownloadLimit) {
      let maxSizeData = []
      let data = rowData
      while (AlertSize > maxDownloadLimit) {
        let temp = _.maxBy(data, 'fileSize')
        AlertSize -= temp.fileSize
        maxSizeData.push(temp.fileSize)
        data = data.filter(ele => ele.LocationId !== temp.LocationId)
      }
      await setMaxFileSize(maxSizeData)
      await setSizeCheck(AlertSize)
      await setDownloadData(rowData)
      setDownloadDialogOpen(true)
    } else if (AlertSize <= maxDownloadLimit) {
      handleFileDownload(selectedRows)
    }
  }
  // closes multiple download dialog (when file size limit is exceeded)
  const handleDownloadClose = () => {
    setSizeCheck(0)
    setSelectedRows([])
    let currentRef = materialTableSearchRef.current
    if (currentRef) currentRef.onAllSelected(false)
    setDownloadDialogOpen(false)
  }
  // deselects selected rows from the material table
  useEffect(() => {
    if (deselectRows) {
      let currentRef = materialTableSearchRef.current
      if (currentRef) currentRef.onAllSelected(false)
    }
  }, [deselectRows])

  // handles multiple file downloads for selected rows from material table
  const handleFileDownload = async rows => {
    let payload = []
    rows.forEach(element => {
      let tempObj = {}
      tempObj['file_name'] =
        element.LocationNumber + '_' + element.DocumentTitle + '.pdf'
      tempObj['toss_file_path'] = element.tossFilePath
      payload.push(tempObj)
    })
    setDeselectRows(true)
    handleSelectionChange([])
    if (payload.length > 0) {
      setInProgress(true)
      addToast('Preparing your Download', {
        appearance: 'info',
        autoDismiss: true,
        autoDismissTimeout: 4000,
      })
      let url = apiConfig.apiUrl.multipleDownloadUrl
      let zipFileName =
        session.userInfo.lanId +
        '_RDMPLUS_' +
        moment().format('MMMM Do YYYY, HH:mm') +
        '.zip'
      await axios
        .post(url, payload, {
          responseType: 'arraybuffer',
        })
        .then(res => {
          FileDownload(res.data, zipFileName)
          if (res.status === 200) {
            addToast('Your Download would start automatically', {
              appearance: 'success',
              autoDismiss: true,
            })
          } else if (res.status === 500) {
            addToast('Download failed', {
              appearance: 'error',
              autoDismiss: true,
            })
          }
        })
        .catch(err => console.log(err.response))
        .finally(setDeselectRows(false))
    }
    setInProgress(false)
  }

  // fetches data when isclicked value changes to true and changes to false once data is retrieved
  useEffect(() => {
    if (search.isClicked) {
      fetchData()
      dispatch({
        type: 'SAVE_SEARCH_FORM',
        payload: { isClicked: false },
      })
      setToggleDownload(false)
    }
    setSelectedColumns(state.columns)
    setFilename(`Search-Report`)
  }, [search.isClicked])

  //for re-rendering <Checkfile> when redux state changes
  useEffect(() => {
    setCopyOfSearchMetadata(searchMetadata)
  }, [searchMetadata])

  //to reload files
  useEffect(() => {
    if (openedTabs.documentViewer.length !== 0) {
      for (let i = 0; i < openedTabs.files.length; i++) {
        let fileUrl =
          apiConfig.auth.domainName.split('.')[0] === 'https://rdmplus'
            ? apiConfig.apiUrl.getFileUrlExternal
            : apiConfig.apiUrl.getFileUrl
        let iFrameurl =
          fileUrl + openedTabs.files[i].unmodifiedData.destTossFilePath
        try {
          let iframe = document.getElementById(
            `${openedTabs.files[i].fileName}`
          )
          axios
            .get(iFrameurl, {
              responseType: 'blob',
              headers: {
                Authorization: localStorage.access_token,
              },
            })
            .then(response => {
              let blobURL = URL.createObjectURL(response.data)

              iframe.src = blobURL
            })
            .catch(error => {
              console.log(error)
            })
        } catch (err) {
          console.log(err)
        }
      }
    }
  }, [openedTabs.documentViewer])

  function fetchData() {
    setLoadingScreen(true)
    setPage(0)
    dispatch({
      type: 'SAVE_CURRENT_SEARCH_PAGE_INFO',
      payload: { searchCurrentPage: 0 },
    })
    authDetails = JSON.parse(localStorage.getItem('authDetails'))

    let docGroupDocType = {}
    if (!search.documentType) {
      Object.keys(documentGroupTypePayload).map(eachKey => {
        let value = JSON.parse(
          JSON.stringify(documentGroupTypePayload[eachKey])
        )
        value.shift()
        docGroupDocType[eachKey] = value
        return value
      })
    }
    let url = search.address
      ? apiConfig.apiUrl.search_elastic +
        `${search.queryTerm}` +
        apiConfig.apiUrl.searchKey
      : apiConfig.apiUrl.searchURL +
        `${search.queryTerm}` +
        apiConfig.apiUrl.searchKey

    if (authDetails) {
      setLoadTable(true)
      axios
        .post(url, {
          location_number: search.locationNumber,
          document_group: search.documentGroup
            ? [search.documentGroup]
            : Object.keys(docGroupDocType),
          document_type: search.documentType
            ? [search.documentType]
            : search.documentGroup
            ? docGroupDocType[search.documentGroup]
            : Array.prototype.concat.apply([], Object.values(docGroupDocType)),
          address: search.address,
        })
        .then(res => {
          setLoadTable(false)

          if (res.status === 200 && res.data.length === 0) {
            // window.alert('No records found')
            addToast('No records found', {
              appearance: 'error',
              autoDismiss: true,
            })
            setLoadingScreen(false)
            setmetadataSearchResponse([])
            dispatch({
              type: 'SAVE_SEARCH_RESPONSE',
              payload: [],
            })
          } else if (res.status === 200) {
            setmetadataSearchResponse(res.data)
          } else {
            setmetadataSearchResponse([])
            dispatch({
              type: 'SAVE_SEARCH_RESPONSE',
              payload: [],
            })
            throw new Error(
              'The server responded with the status  ' + res.status
            )
          }
        })
        .catch(err => {
          setLoadTable(false)

          console.error(err)
          addToast('There was some error in connecting to server', {
            appearance: 'error',
            autoDismiss: true,
          })
        })
    }
  }

  let metadataSearchResponseArr = []
  useEffect(() => {
    for (var i = 0, len = metadataSearchResponse.length; i < len; i++) {
      let response = metadataSearchResponse[i]
      let row = returnMetadataSearchFields(response)
      metadataSearchResponseArr.push(row)
    }
    if (metadataSearchResponseArr.length > 0) {
      setState({ ...state, data: metadataSearchResponseArr })
      dispatch({
        type: 'SAVE_SEARCH_RESPONSE',
        payload: metadataSearchResponseArr,
      })
      setLoadingScreen(false)
    }
  }, [metadataSearchResponse])

  // handles the max/min no of files selected for multiple download
  const handleSelectionChange = async rows => {
    if (rows.length > maxNoOfFilesForDownload) {
      addToast('You can not Download more than 5 files', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setSelectedRows(rows)
    if (rows.length > 1) {
      setToggleDownload(true)
    } else if (rows.length <= 1 && toggleDownload) {
      setToggleDownload(false)
    }
  }

  function handleChangeTabs(event, newValue) {
    if (
      (event.target.getAttribute('id') &&
        event.target.getAttribute('id').includes('delete')) ||
      event.target.tagName === 'path'
    ) {
      let tabList = [...openedTabs.tab]
      let fileList = [...openedTabs.files]
      let tempIframeHolder = [...openedTabs.documentViewer]

      let tempMetadata = Object.assign({}, searchMetadata)

      let fileToBeRemoved = fileList.findIndex(val => val.value === newValue)
      tabList.splice(fileToBeRemoved, 1)
      tempIframeHolder.splice(fileToBeRemoved, 1)

      delete tempMetadata[newValue]

      dispatch({
        type: 'SAVE_OPENED_TABS',
        payload: {
          field: 'tab',
          value: tabList,
        },
      })
      dispatch({
        type: 'SAVE_OPENED_TABS',
        payload: {
          field: 'documentViewer',
          value: tempIframeHolder,
        },
      })

      fileList.splice(fileToBeRemoved, 1)

      dispatch({
        type: 'SAVE_OPENED_TABS',
        payload: {
          field: 'files',
          value: fileList,
        },
      })

      dispatch({
        type: 'SAVE_SEARCH_METADATA',
        payload: tempMetadata,
      })

      if (newValue === openedTabs.value) {
        if (fileToBeRemoved - 1 < 0) {
          dispatch({
            type: 'SAVE_OPENED_TABS',
            payload: {
              field: 'value',
              value: 0,
            },
          })
        } else {
          dispatch({
            type: 'SAVE_OPENED_TABS',
            payload: {
              field: 'value',
              value: fileList[fileToBeRemoved - 1].value,
            },
          })
        }
      }
    } else {
      dispatch({
        type: 'SAVE_OPENED_TABS',
        payload: {
          field: 'value',
          value: newValue,
        },
      })
      // showFiles()
    }
  }

  function onError(e) {
    console.log('error')
  }

  function getFileExtensionType(filename) {
    return filename.split('.').pop()
  }

  const downloadSingleFile = (e, url, filename) => {
    axios
      .get(url, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: localStorage.access_token,
        },
      })
      .then(res => {
        FileDownload(res.data, filename)
        if (res.status === 200) {
          addToast('Your Download would start automatically', {
            appearance: 'success',
            autoDismiss: true,
          })
        } else if (res.status === 500) {
          addToast('Download failed', {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(err => console.log(err.response))
  }
  const fetchBatchMetadataFields = (contentType, locationId) => {
    return new Promise((resolve, reject) => {
      let url = `${apiConfig.apiUrl.get_batch_metadata}${locationId}&contentType=${contentType}${apiConfig.apiUrl.keyUrl}`
      axios.get(url).then(res => {
        if (res.status === 200) return resolve(res.data)
        return reject('The server responded with the status  ' + res.status)
      })
    })
  }

  const handleSelectChange = async (event, data) => {
    event.persist()
    if (event.target.value === 'file') {
      if (openedTabs.tab.length === 10) {
        addToast('Warning!You can open maximum 10 tabs', {
          appearance: 'warning',
          autoDismiss: true,
        })
      } else {
        fetchBatchMetadataFields(data.ContentType, data.LocationId)
          .then(res => openFile(returnMetadataSearchFields(res), res)) // sending along res as well as objects in metadata dont have modified objs
          .catch(err =>
            addToast('There was an error connecting to the server', {
              appearance: 'error',
              autoDismiss: true,
            })
          )
      }
    }
    if (event.target.value === 'retrigger') {
      fetchBatchMetadataFields(data.ContentType, data.LocationId)
        .then(res => openRetrigger(returnMetadataSearchFields(res), res)) // sending along res as well as objects in metadata dont have modified objs
        .catch(err =>
          addToast('There was an error connecting to the server', {
            appearance: 'error',
            autoDismiss: true,
          })
        )
    }
    if (event.target.value === 'delete') {
      fetchBatchMetadataFields(data.ContentType, data.LocationId)
        .then(res => openDeleteFile(returnMetadataSearchFields(res), res)) // sending along res as well as objects in metadata dont have modified objs
        .catch(err =>
          addToast('There was an error connecting to the server', {
            appearance: 'error',
            autoDismiss: true,
          })
        )
    }
    //File URL authentication code
    // if (event.target.value === 'copy') {
    //   let modifiedUrl = replaceUrl(data.FileUrl)
    //   copyUrl({}, modifiedUrl)
    // }
  }

  const copyUrl = async (e, fileUrl) => {
    let modifiedUrl = replaceUrl(fileUrl)

    try {
      await navigator.clipboard.writeText(modifiedUrl)
      addToast('File URL copied', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (err) {
      addToast('File URL could not be copied', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const removeFileFromView = (e, filename) => {
    let tempSet = new Set(loadIndexForm)
    tempSet.delete(filename)
    setLoadIndexForm(tempSet)
  }

  const createTabElement = (identifier, fileName, metaData) => {
    let fileExt = getFileExtensionType(fileName)

    let fileUrl =
      apiConfig.auth.domainName.split('.')[0] === 'https://rdmplus'
        ? apiConfig.apiUrl.getFileUrlExternal
        : apiConfig.apiUrl.getFileUrl
    //File URL authentication code on contextmenu

    const tabelement = (
      <div id={identifier} key={identifier}>
        <div key={identifier + new Date()}>
          {fileExt === 'pdf' ? (
            <div
              className="pdf"
              style={validateGeneralUser(adgroups) ? { width: '100%' } : null}
            >
              <SnackbarContent
                message="If you are having trouble viewing this document, download"
                action={
                  <Fragment>
                    <span style={{ textDecoration: 'underline' }}>
                      <Button
                        color="primary"
                        download={fileName}
                        onContextMenu={event => {
                          event.preventDefault()
                        }}
                        onClick={e =>
                          downloadSingleFile(
                            e,
                            fileUrl + metaData.tossFilePath + '&download=yes',
                            fileName
                          )
                        }
                        style={{
                          minWidth: 10,
                          fontWeight: 900,
                          textDecoration: 'underline',
                        }}
                      >
                        HERE
                      </Button>
                    </span>
                    {apiConfig.auth.domainName.split('.')[0] ===
                    'https://rdmplus' ? null : (
                      <Fragment>
                        <span className={classes.copyColor}>
                          &nbsp;or&nbsp;
                        </span>
                        <Tooltip title="Copy Url">
                          <IconButton
                            size="small"
                            onClick={e =>
                              copyUrl(e, fileUrl + metaData.tossFilePath)
                            }
                          >
                            <FileCopyIcon
                              fontSize="small"
                              style={{ color: 'white' }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Fragment>
                    )}
                  </Fragment>
                }
              />
              <iframe
                name={fileUrl + metaData.tossFilePath}
                key={fileName}
                width="100%"
                height="100%"
                title={fileName}
                style={{ height: '90vh' }}
                id={fileName}
              />
            </div>
          ) : (
            <div
              className="doc"
              key={fileName}
              style={validateGeneralUser(adgroups) ? { width: '100%' } : null}
            >
              <div>
                <SnackbarContent
                  message="If you are having trouble viewing this document, download"
                  action={
                    <Fragment>
                      <span style={{ textDecoration: 'underline' }}>
                        <Button
                          download={fileName}
                          // href={
                          //   fileUrl + metaData.tossFilePath + '&download=yes'
                          // }
                          onContextMenu={event => {
                            event.preventDefault()
                          }}
                          onClick={e =>
                            downloadSingleFile(
                              e,
                              fileUrl + metaData.tossFilePath + '&download=yes',
                              fileName
                            )
                          }
                          color="primary"
                          style={{
                            minWidth: 10,
                            fontWeight: 900,
                            textDecoration: 'underline',
                          }}
                        >
                          HERE
                        </Button>
                      </span>
                      {apiConfig.auth.domainName.split('.')[0] ===
                      'https://rdmplus' ? null : (
                        <Fragment>
                          <span className={classes.copyColor}>
                            &nbsp;or&nbsp;
                          </span>
                          <Tooltip title="Copy Url">
                            <IconButton
                              size="small"
                              onClick={e =>
                                copyUrl(e, fileUrl + metaData.tossFilePath)
                              }
                            >
                              <FileCopyIcon
                                fontSize="small"
                                style={{ color: 'white' }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Fragment>
                      )}
                    </Fragment>
                  }
                />
              </div>

              {fileExt === 'csv' || fileExt === 'xlsx' || fileExt === 'docx' ? (
                <CustomFileViewer
                  fileType={fileExt}
                  filePath={fileUrl + metaData.tossFilePath}
                  onError={onError}
                  title={fileName}
                  key={fileName + iframeTriger}
                />
              ) : (
                <div>File type is not supported</div>
                // <FileViewer
                //   // fileType={getFileExtensionType(file.fileName)}
                //   // fileType={fileExt === 'csv' ? 'xlsx' : fileExt}
                //   fileType={fileExt}
                //   filePath={fileUrl + metaData.tossFilePath}
                //   onError={onError}
                //   key={fileName}
                // />
              )}
            </div>
          )}
        </div>
      </div>
    )
    return tabelement
  }

  //To reload document pane after a new document has been checked in
  useEffect(() => {
    if (iframeTriger !== '') {
      let tempFiles = [...openedTabs.files]
      let tempIframeHolder = [...openedTabs.documentViewer]
      // let tempMetada = Object.assign({}, searchMetadata)
      let tabIndex = tempFiles.findIndex(tab => {
        return tab.value === openedTabs.value
      })

      let newMetadata
      let copyOfMetadata = Object.assign({}, tempFiles[tabIndex].metaData)
      fetchBatchMetadataFields(
        copyOfMetadata.ContentType,
        copyOfMetadata.LocationId
      )
        .then(res => {
          newMetadata = returnMetadataSearchFields(res)
          tempFiles[tabIndex].metaData = newMetadata
          tempFiles[tabIndex].unmodifiedData = res
          tempFiles[tabIndex].fileName = newMetadata.FileName

          let newTabElement = createTabElement(
            res.documentId,
            newMetadata.FileName,
            newMetadata
          )
          tempIframeHolder[tabIndex] = newTabElement
          // tempMetada[]
          // tempFiles[tabIndex].value = newMetadata.FileName

          function dispatchEvents() {
            dispatch({
              type: 'SAVE_OPENED_TABS',
              payload: {
                field: 'files',
                value: tempFiles,
              },
            })

            dispatch({
              type: 'SAVE_OPENED_TABS',
              payload: {
                field: 'documentViewer',
                value: tempIframeHolder,
              },
            })

            dispatch({
              type: 'TRIGGER_IFRAME_COUNTER',
              payload: '',
            })
          }

          iframeTriger === 'reloadWithDelay'
            ? setTimeout(() => {
                dispatchEvents()
              }, 5000)
            : dispatchEvents()
        })
        .catch(err =>
          addToast('There was an error connecting to the server', {
            appearance: 'error',
            autoDismiss: true,
          })
        )
    }
  }, [iframeTriger])

  const showFiles = () => {
    let selectedFile = changedFiles.filter(
      file => openedTabs.value === file.value
    )
    return (
      openedTabs.documentViewer &&
      openedTabs.documentViewer.map(item => {
        return (
          <TabPanel index={item.key} key={item.key} value={openedTabs.value}>
            <div
              className="outer-index-container"
              // key={selectedFile[0].fileName + iframeTriger}
              // key={item.key+iframeTriger}
            >
              {selectedFile && selectedFile.length > 0 && (
                <Box
                  display="flex"
                  flexDirection="row"
                  p={1}
                  m={1}
                  bgcolor="background.paper"
                  justifyContent="flex-end"
                >
                  {apiConfig.auth.domainName.split('.')[0] ===
                  'https://rdmplus' ? null : (
                    <Box p={1}>
                      <SendMail
                        fileUrl={
                          apiConfig.apiUrl.getFileDownloadUrl +
                          selectedFile[0].metaData.tossFilePath
                        }
                        fileName={
                          selectedFile[0].metaData.LocationNumber +
                          '_' +
                          selectedFile[0].metaData.DocumentTitle
                        }
                        sessionData={session.userInfo}
                        filePathData={selectedFile[0].metaData.tossFilePath}
                      />
                    </Box>
                  )}
                  <Box p={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleClickOpen}
                    >
                      Supporting Documents
                    </Button>
                    <SupportingDocuments
                      currentTab={item.key}
                      fileValue={selectedFile[0].value}
                      open={open}
                      handleClose={handleClose}
                      unmodifiedData={selectedFile[0].unmodifiedData}
                    />
                  </Box>

                  <Box p={1}>
                    {authDetailsData &&
                      authDetailsData.access_feature_edit &&
                      authDetailsData.access_feature_edit.indexOf('EDIT') && (
                        <CheckFile
                          currentTab={item.key}
                          fileNameSearch={selectedFile[0].value}
                          unmodifiedData={selectedFile[0].unmodifiedData}
                          type="search"
                          metadata={copyOfSearchMetadata}
                        />
                      )}{' '}
                  </Box>
                </Box>
              )}
              <div className="main-index-container">
                <Fragment>{item}</Fragment>
                {!validateGeneralUser(adgroups) && (
                  <Fragment>
                    {selectedFile && selectedFile.length > 0 && (
                      <Fragment>
                        {loadIndexForm.has(selectedFile[0].value) ? (
                          <div className="metadata-outer-container">
                            <MetaData
                              key={selectedFile[0].value}
                              setPendingDocumentCounter={``}
                              type="search"
                              disableLoadIndexForm={removeFileFromView}
                              metaData={selectedFile[0].metaData}
                              unmodifiedData={selectedFile[0].unmodifiedData}
                              accountName={session.userInfo.lanId}
                              fileNameSearch={selectedFile[0].value}
                            />
                          </div>
                        ) : (
                          <div className="metadata-outer-container">
                            <div className="metadata-edit-container">
                              <br />
                              <br />
                              {authDetailsData &&
                                authDetailsData.access_feature_edit &&
                                authDetailsData.access_feature_edit.indexOf(
                                  'EDIT'
                                ) && (
                                  <div className="metadata-edit-submit">
                                    <Button
                                      style={{ width: '200px' }}
                                      variant="contained"
                                      className={classes.button}
                                      onClick={() => {
                                        let setOfFiles = new Set(loadIndexForm)
                                        setOfFiles.add(selectedFile[0].value)
                                        setLoadIndexForm(setOfFiles)
                                      }}
                                      // eslint-disable-next-line react/jsx-no-duplicate-props
                                      className="edit-button"
                                      id={selectedFile[0].fileName}
                                    >
                                      Edit Metadata
                                    </Button>
                                  </div>
                                )}
                              {showMetaData(selectedFile[0].metaData)}
                            </div>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
              {/* )} */}
            </div>
          </TabPanel>
        )
      })
    )
  }

  function showMetaData(metaData) {
    let metadata = JSON.parse(JSON.stringify(metaData))
    ;[
      'FileName',
      'tossFilePath',
      'CreatedDate',
      'CreatedBy',
      'ModifiedDate',
      'ModifiedBy',
      'LocationId',
      'FileUrl',
      // 'TargetprsTotalRecAmount',
    ].forEach(prop => {
      delete metadata[prop]
    })
    let temp = Object.keys(metadata)
    return temp.map(tem => {
      if (tem !== 'tableData') {
        if (
          tem === 'TargetPrsBudgetAmount' ||
          tem === 'TargetPrsTotalRecAmount' ||
          tem === 'TotalDollarImpact'
        ) {
          return (
            <TextField
              id={`outlined-name-${tem}`}
              key={tem}
              label={tem}
              className={classes.textField}
              value={
                !metadata[tem] || metadata[tem] === undefined
                  ? '-'
                  : new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(metadata[tem])
              }
              margin="normal"
              variant="outlined"
            />
          )
        }
        return (
          <TextField
            id={`outlined-name-${tem}`}
            key={tem}
            label={tem}
            className={classes.textField}
            value={
              !metadata[tem] || metadata[tem] === undefined
                ? '-'
                : metadata[tem]
            }
            margin="normal"
            variant="outlined"
          />
        )
      }
      return null
    })
  }

  function openFile(rowData, unmodData) {
    let tempTab = [...openedTabs.tab]
    let tempFiles = [...openedTabs.files]
    let tempIframeHolder = [...openedTabs.documentViewer]

    let tempMetadata = Object.assign({}, searchMetadata)

    var isTabOpen = tempFiles.find(tab => {
      return tab.fileName === rowData.FileName
    })

    let displayDocumentTitle =
      rowData.DocumentTitle.length > 100
        ? rowData.DocumentTitle.slice(0, 99) + '...'
        : rowData.DocumentTitle

    let fileUrl =
      apiConfig.auth.domainName.split('.')[0] === 'https://rdmplus'
        ? apiConfig.apiUrl.getFileUrlExternal
        : apiConfig.apiUrl.getFileUrl
    var tabContainerData = {
      value: unmodData.documentId,
      // value: rowData.FileName,
      url: replaceUrl(fileUrl + rowData.tossFilePath),
      fileName: rowData.FileName,
      metaData: rowData,
      displayName: rowData.LocationNumber + '_' + displayDocumentTitle,
      fullTitle: rowData.LocationNumber + '_' + rowData.DocumentTitle,
      unmodifiedData: unmodData,
      isEdit: false,
    }

    if (isTabOpen === undefined) {
      const _tab = (
        <Tab
          key={tabContainerData.value}
          value={tabContainerData.value}
          label={
            <div style={{ display: 'flex' }}>
              <Tooltip title={tabContainerData.fullTitle}>
                <Button color="primary"> {tabContainerData.displayName}</Button>
              </Tooltip>

              <IconButton id={'delete' + tabContainerData.value}>
                <ClearIcon id={'delete' + tabContainerData.value} />
              </IconButton>
            </div>
          }
          {...a11yProps(rowData.value)}
        />
      )

      let fileExt = getFileExtensionType(rowData.FileName)
      tempTab.push(_tab)
      tempFiles.push(tabContainerData)

      const tabelement = (
        <div id={tabContainerData.value} key={tabContainerData.value}>
          {fileExt === 'pdf' ? (
            <div
              className="pdf"
              style={validateGeneralUser(adgroups) ? { width: '100%' } : null}
            >
              <SnackbarContent
                message="If you are having trouble viewing this document, download"
                action={
                  <Fragment>
                    <span style={{ textDecoration: 'underline' }}>
                      <Button
                        download={tabContainerData.fileName}
                        // href={
                        //   fileUrl +
                        //   tabContainerData.metaData.tossFilePath +
                        //   '&download=yes'
                        // }
                        onContextMenu={event => {
                          event.preventDefault()
                        }}
                        onClick={e =>
                          downloadSingleFile(
                            e,
                            fileUrl +
                              tabContainerData.metaData.tossFilePath +
                              '&download=yes',
                            tabContainerData.fileName
                          )
                        }
                        color="primary"
                        style={{
                          minWidth: 10,
                          fontWeight: 900,
                          textDecoration: 'underline',
                        }}
                      >
                        HERE
                      </Button>
                    </span>
                    {apiConfig.auth.domainName.split('.')[0] ===
                    'https://rdmplus' ? null : (
                      <Fragment>
                        <span className={classes.copyColor}>
                          &nbsp;or&nbsp;
                        </span>
                        <Tooltip title="Copy Url">
                          <IconButton
                            size="small"
                            onClick={e => copyUrl(e, tabContainerData.url)}
                          >
                            <FileCopyIcon
                              fontSize="small"
                              style={{ color: 'white' }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Fragment>
                    )}
                  </Fragment>
                }
              />
              {/* <iframe
                src={fileUrl + tabContainerData.metaData.tossFilePath}
                key={tabContainerData.fileName + iframeTriger}
                width="100%"
                height="100%"
                title={tabContainerData.fileName}
                style={{ height: '90vh' }}
              /> */}
              <iframe
                id={tabContainerData.fileName + iframeTriger}
                width="100%"
                height="100%"
                title={tabContainerData.fileName}
                style={{ height: '90vh' }}
                key={tabContainerData.fileName + iframeTriger}
                name={fileUrl + tabContainerData.metaData.tossFilePath}
              ></iframe>
            </div>
          ) : (
            <div
              className="doc"
              key={tabContainerData.fileName + iframeTriger}
              style={validateGeneralUser(adgroups) ? { width: '100%' } : null}
            >
              <div>
                <SnackbarContent
                  message="If you are having trouble viewing this document, download"
                  action={
                    <Fragment>
                      <span style={{ textDecoration: 'underline' }}>
                        <Button
                          download={tabContainerData.fileName}
                          onContextMenu={event => {
                            event.preventDefault()
                          }}
                          onClick={e =>
                            downloadSingleFile(
                              e,
                              fileUrl +
                                tabContainerData.metaData.tossFilePath +
                                '&download=yes',
                              tabContainerData.fileName
                            )
                          }
                          color="primary"
                          style={{
                            minWidth: 10,
                            fontWeight: 900,
                            textDecoration: 'underline',
                          }}
                        >
                          HERE
                        </Button>
                      </span>
                      {apiConfig.auth.domainName.split('.')[0] ===
                      'https://rdmplus' ? null : (
                        <Fragment>
                          <span className={classes.copyColor}>
                            &nbsp;or&nbsp;
                          </span>
                          <Tooltip title="Copy Url">
                            <IconButton
                              size="small"
                              onClick={e => copyUrl(e, tabContainerData.url)}
                            >
                              <FileCopyIcon
                                fontSize="small"
                                style={{ color: 'white' }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Fragment>
                      )}
                    </Fragment>
                  }
                />
              </div>

              {fileExt === 'csv' || fileExt === 'xlsx' || fileExt === 'docx' ? (
                <CustomFileViewer
                  fileType={fileExt}
                  filePath={fileUrl + tabContainerData.metaData.tossFilePath}
                  onError={onError}
                  title={tabContainerData.fileName}
                  key={tabContainerData.fileName + iframeTriger}
                />
              ) : (
                <div>File type is not supported</div>
                // <FileViewer
                //   // fileType={getFileExtensionType(file.fileName)}
                //   // fileType={fileExt === 'csv' ? 'xlsx' : fileExt}
                //   fileType={fileExt}
                //   filePath={fileUrl + tabContainerData.metaData.tossFilePath}
                //   onError={onError}
                //   key={tabContainerData.fileName + iframeTriger}
                // />
              )}
            </div>
          )}
        </div>
      )

      tempMetadata[tabContainerData.value] = unmodData
      tempIframeHolder.push(tabelement)

      // state.tab = tempTab
      dispatch({
        type: 'SAVE_OPENED_TABS',
        payload: {
          field: 'tab',
          value: tempTab,
        },
      })

      dispatch({
        type: 'SAVE_OPENED_TABS',
        payload: {
          field: 'files',
          value: tempFiles,
        },
      })
      dispatch({
        type: 'SAVE_OPENED_TABS',
        payload: {
          field: 'documentViewer',
          value: tempIframeHolder,
        },
      })
      dispatch({
        type: 'SAVE_OPENED_TABS',
        payload: {
          field: 'value',
          value: tabContainerData.value,
        },
      })

      dispatch({
        type: 'SAVE_SEARCH_METADATA',
        payload: tempMetadata,
      })
    } else {
      // setValue(isTabOpen.value)
      dispatch({
        type: 'SAVE_OPENED_TABS',
        payload: {
          field: 'value',
          value: isTabOpen.value,
        },
      })
    }
  }

  // const handlePageChange = pageNumber => {
  //   console.log(pageNumber)
  //   // setCurrentPage(pageNumber)
  //   setPage(pageNumber)
  //   dispatch({
  //     type: 'SAVE_CURRENT_PAGE',
  //     payload: pageNumber,
  //   })
  // }

  const checkForSkipPA = receivedData => {
    if (receivedData.documentGroup === 'Lease Document') return true
    if (
      receivedData.documentGroup === 'Real Estate' &&
      [
        'Environmental/Utility/Stormwater',
        'Government Action/Litigation',
        'License Document',
        'Notice of Change',
      ].includes(receivedData.documentType)
    ) {
      return true
    }
    if (
      ['Acquisition Document', 'Disposition Document'].includes(
        receivedData.documentGroup
      ) &&
      ['Other', 'Deed', 'Closing Statement'].includes(receivedData.documentType)
    ) {
      return true
    }
    if (
      receivedData.documentGroup === 'Condominium Document' &&
      ['Association', 'Declaration', 'Other'].includes(
        receivedData.documentType
      )
    ) {
      return true
    }
    if (
      receivedData.documentGroup === 'Operation or Development Document' &&
      ['Operating Agreement', 'Other'].includes(receivedData.documentType)
    ) {
      return true
    }
    return false
  }

  function openRetrigger(rowData, unmodData) {
    let docStatus = unmodData.documentStatus
    if (unmodData.documentStatus === 'Active' && checkForSkipPA(unmodData)) {
      docStatus = 'SkipPA'
    }
    setWfRetrigger(docStatus)
    setMetaData(unmodData)
    handleRetriggerClickOpen()
  }

  function openDeleteFile(rowData, unmodData) {
    // setBusinessType(unmodData.contentType)
    setMetaData(unmodData)
    handleDeleteDocOpen()
  }

  useEffect(() => {
    if (openedTabs.value === 0 && materialTableSearchRef.current) {
      setOrder(tableOrderBy, tableOrderDirection, pageInfo.searchCurrentPage)
    }
  }, [tableOrderBy, tableOrderDirection, openedTabs.value])

  const setOrder = (orderBy, orderDir, _page) => {
    let currentRef = materialTableSearchRef.current
    currentRef.onChangeOrder(orderBy, orderDir)
    currentRef.onChangePage({}, _page)
  }

  const handleChangePage = newPage => {
    dispatch({
      type: 'SAVE_CURRENT_SEARCH_PAGE_INFO',
      payload: { ...pageInfo, searchCurrentPage: newPage },
    })

    setPage(newPage)
  }

  // const handleChangeRowsPerPage = event => {
  //   setRowsPerPage(event.target.value)
  //   setPage(0)
  //   dispatch({
  //     type: 'SAVE_CURRENT_SEARCH_PAGE_INFO',
  //     payload: { searchCurrentPage: 0 },
  //   })
  // }

  const handleChangeRowsPerPage = pageSize => {
    setRowsPerPage(pageSize)
    setPage(0)
  }
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleRetriggerClose = () => {
    setOpenWorkflowRetrigger(false)
  }
  const handleDeleteDocClose = () => {
    setOpenDeleteDoc(false)
  }
  const handleRetriggerClickOpen = () => {
    setOpenWorkflowRetrigger(true)
  }
  const handleDeleteDocOpen = () => {
    setOpenDeleteDoc(true)
  }

  const [exportDialogOpen, setExportDialogOpen] = useState(false)

  const handleExportAllSelect = event => {
    setExportSelectAll(event.target.checked)

    if (event.target.checked) {
      setSelectedColumns(state.columns)
    } else {
      setSelectedColumns([])
    }
  }

  const handleCloseExportDialog = () => {
    setExportDialogOpen(false)
    setSelectedColumns(state.columns)
    setFilename(`Search-Report`)
    setExportSelectAll(true)
  }

  const isColumnSelected = title => {
    let index = selectedColumns.findIndex(item => item.title === title)
    return index !== -1
  }

  const handleColumnSelectChange = event => {
    let tempList = [...selectedColumns]

    if (event.target.checked) {
      let tableCol = state.columns
      let fieldToBePushed = tableCol.filter(
        item => item.title === event.target.name
      )
      tempList.push(fieldToBePushed[0])
    } else {
      tempList = tempList.filter(item => item.title !== event.target.name)
      setExportSelectAll(false)
    }
    setSelectedColumns(tempList)
  }

  const exportCsv = () => {
    let columns = [...selectedColumns]
    // to remove Fileurl column for vendor site
    if (apiConfig.auth.domainName.split('.')[0] === 'https://rdmplus') {
      columns.pop()
    }
    let dataToExport = tableDataToBeExported
    const data = dataToExport.map(rowData =>
      columns.map(columnDef => {
        return getFieldValue(rowData, columnDef)
      })
    )

    const builder = new CsvBuilder((filename || 'Report') + '.csv')
    builder
      // .setDelimeter(this.props.exportDelimiter)
      .setColumns(columns.map(columnDef => columnDef.title))
      .addRows(data)
      .exportFile()

    // setExportDialogOpen(false)
    handleCloseExportDialog()
  }

  const exportProps = {
    open: exportDialogOpen,
    handleClose: handleCloseExportDialog,
    exportCsv,
    classes,
    filename,
    setFilename,
    isColumnSelected,
    handleColumnSelectChange,
    allColumns: state.columns,
    exportSelectAll,
    handleExportAllSelect,
    selectedColumns,
    title: 'Search Report',
  }

  const getAccessForRetrigger = authDetailsData => {
    if (authDetailsData.access_feature_admin) {
      if (
        adgroups.has('APP-RDMP-PUBLICDOCUMENTS-STAGE') ||
        adgroups.has('APP-RDMP-PUBLICDOCUMENTS-PROD')
      ) {
        return true
      }
    }
    return false
  }

  const getAccessForDelete = authDetailsData => {
    if (authDetailsData.access_feature_location) {
      if (
        adgroups.has('APP-RDMP-LOC-RE-STAGE') ||
        adgroups.has('APP-RDMP-LOC-RE-PROD')
      ) {
        return true
      }
    }
    return false
  }

  const workflowRetrigger = (e, skipPA = false) => {
    let payload = {}
    payload['document_id'] = metaData.documentId
    payload['metadata_id'] = metaData.id
    payload['content_type'] = metaData.contentType
    payload['file_name'] = metaData.fileName
    payload['location_number'] = metaData.locationNumber
    payload['location_name'] = metaData.locationName
    payload['location_state'] = metaData.locationState
    payload['location_city'] = metaData.locationCity
    payload['location_region'] = metaData.locationRegion
    payload['document_title'] = metaData.documentTitle
    payload['document_date'] = metaData.documentDate
    payload['expiration_date'] = metaData.expirationDate
    payload['is_urgent'] = metaData.isUrgent
    payload['physical_file_number'] = metaData.physicalFileNumber
    // payload['doc_follow_up_number'] = metaData.docFollowUpNumber
    payload['legal_follow_up_name'] = metaData.legalFollowUpName
    payload['business_follow_up_name'] = metaData.businessFollowUpName
    payload['document_group'] = metaData.documentGroup
    payload['document_type'] = metaData.documentType
    payload['document_status'] = metaData.documentStatus
    payload['recording_status'] = metaData.recordingStatus
    payload['document_purpose'] = metaData.documentPurpose
    payload['fileLetter'] = metaData.fileLetter
    payload['file_letter_description'] = metaData.fileLetterDescription
    payload['document_notes'] = metaData.documentNotes
    // payload['created_user_id'] = metaData.createdUserId
    // payload['created_user_name'] = metaData.createdUserName
    // payload['modified_date'] = metaData.modifiedDate
    // payload['modified_user_id'] = metaData.modifiedUserId
    // payload['modified_user_name'] = metaData.modifiedUserName
    payload['created_user_id'] = session.userInfo.lanId
    payload['created_user_name'] = session.userInfo.fullName
    payload['modified_date'] = ''
    payload['modified_user_id'] = ''
    payload['modified_user_name'] = ''
    payload['file_path'] = metaData.destTossFilePath
    payload['workflow_retrigger_flag'] = true
    if (skipPA) payload['skip_pa_flag'] = true

    let url = apiConfig.apiUrl.workflow_retrigger

    axios
      .post(url, payload)
      .then(res => {
        addToast('workflow retriggered successfully', {
          appearance: 'success',
          autoDismiss: true,
        })

        handleRetriggerClose()
      })
      .catch(err => {
        console.log(err)
        addToast('Error!Issue in retriggering the workflow.', {
          appearance: 'error',
          autoDismiss: true,
        })

        handleRetriggerClose()
      })
  }

  const deleteFile = async () => {
    let documentNameArray = []
    let supportingDocsUrl =
      apiConfig.apiUrl.get_supporting_documents_list +
      `/${metaData.documentId}${apiConfig.apiUrl.searchKey}`
    await axios
      .get(supportingDocsUrl)
      .then(response => {
        let receivedData = response.data
        for (let i = 0; i < receivedData.length; i++) {
          documentNameArray.push(receivedData[i].document_name)
        }
      })
      .catch(err => console.log(err))
    let payload = {}
    payload.id = metaData.id
    payload.document_id = metaData.documentId
    payload.toss_path = metaData.destTossFilePath
    payload.file_name = metaData.fileName
    payload.content_type = metaData.contentType
    payload.user_name = session.userInfo.fullName
    payload.supporting_filenames = documentNameArray
    let url = apiConfig.apiUrl.delete_file
    axios
      .delete(url, { data: payload })
      .then(res => {
        addToast('Document deleted successfully', {
          appearance: 'success',
          autoDismiss: true,
        })

        handleDeleteDocClose()
      })
      .catch(err => {
        if (err.response.status === 400) {
          addToast(
            "Document can't be deleted -  associated workflow is active",
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        } else {
          console.log(err)
          addToast('Error!Issue in deleting file.', {
            appearance: 'error',
            autoDismiss: true,
          })
        }

        handleDeleteDocClose()
      })
  }
  const handleOrderChange = (orderBy, orderDir) => {
    setTableOrderBy(orderBy)
    setTableOrderDirection(orderDir)
    dispatch({
      type: 'SAVE_CURRENT_SEARCH_PAGE_INFO',
      payload: { ...pageInfo, orderDirection: orderDir, orderBy: orderBy },
    })
  }
  // return
  // searchObjectRedux.savedSearchResponse.length > 0 ? (
  //   loadingScreen && openedTabs.value === 0 ? (
  //     // <Skeleton
  //     //   component="div"
  //     //   style={{ transform: 'none', marginBottom: '20px' }}
  //     //   width={'100%'}
  //     //   height={'90vh'}
  //     // />
  //     <div style={{ marginTop: '30%' }}>
  //       <CircularProgress />
  //     </div>
  //   ) : (
  return (
    <div className={classes.root}>
      <Tabs
        value={openedTabs.value}
        onChange={handleChangeTabs}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        wrapped
      >
        <Tab label="Search Results" {...a11yProps(0)} />
        {openedTabs.tab}
      </Tabs>
      <TabPanel value={openedTabs.value} index={0}>
        {/* {openedTabs.value === 0 && ( */}
        <div className="fixed_table">
          <MaterialTable
            title=""
            options={{
              search: false,
              // paging: true,
              filtering: true,
              selection: true,
              showSelectAllCheckbox: false,
              pageSize: rowsPerPage,
              initialPage: page,
              pageSizeOptions: [25, 50, 100],
              loadingType: 'overlay',
              style: { textAlign: 'center' },
              sorting: true,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
            }}
            // onOrderChange={changeThisOrder}
            onOrderChange={handleOrderChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
            icons={tableIcons}
            columns={fixedColumns}
            data={searchObjectRedux.savedSearchResponse}
            tableRef={materialTableSearchRef}
            isLoading={loadTable}
            // onChangePage={handleChangePage}
            onSelectionChange={rows => handleSelectionChange(rows)}
            actions={[
              {
                position: 'row',
                icon: KeyboardArrowDownIcon,
                tooltip: 'Open options',
              },
            ]}
            components={{
              Action: props => (
                <FormControl>
                  <Select
                    id="search-options"
                    disableUnderline
                    value=""
                    onChange={event => handleSelectChange(event, props.data)}
                  >
                    <MenuItem value="file">Open File&nbsp;&nbsp;</MenuItem>
                    {authDetailsData &&
                      getAccessForRetrigger(authDetailsData) && (
                        <MenuItem value="retrigger">
                          WF Retrigger&nbsp;&nbsp;
                        </MenuItem>
                      )}
                    {authDetailsData && getAccessForDelete(authDetailsData) && (
                      <MenuItem value="delete">
                        Delete File&nbsp;&nbsp;
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              ),
              Toolbar: props => (
                <div style={{ padding: '8px' }}>
                  {/* <MTableToolbar {...props} /> */}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div>
                      <Tooltip title="Export ALL Records">
                        <Button
                          onClick={event => {
                            setExportDialogOpen(true)
                            setTableDataToBeExported(props.data)
                          }}
                          // startIcon={<AssignmentReturnedIcon />}
                          // variant="outlined"
                          className={classes.exportButton}
                          color="primary"
                        >
                          <AssignmentReturnedIcon />
                        </Button>
                      </Tooltip>
                    </div>
                    <div className={classes.progressWrapper}>
                      <Button
                        onClick={handleDownloadClickOpen}
                        color="primary"
                        disabled={!toggleDownload}
                      >
                        <Tooltip title="Download Multiple Records">
                          <GetAppIcon />
                        </Tooltip>
                      </Button>
                      {inProgress && (
                        <CircularProgress
                          size={34}
                          className={classes.fabProgress}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ),
            }}
            localization={{
              body: {
                emptyDataSourceMessage: '',
              },
            }}
          />
        </div>
        {/* )} */}
      </TabPanel>
      {showFiles()}
      {wfRetrigger === 'Active' ? (
        <Dialog
          open={openWorkflowRetrigger}
          onClose={handleRetriggerClose}
          disableBackdropClick="true"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ color: 'maroon' }}>
            Workflow Retrigger
          </DialogTitle>

          <DialogContent>
            <p>
              <h5> Do you want to proceed with retriggering the workflow? </h5>
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRetriggerClose} color="primary">
              No
            </Button>
            <Button onClick={workflowRetrigger} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      ) : wfRetrigger === 'Pending' || wfRetrigger === 'Unobtainable' ? (
        <Dialog
          open={openWorkflowRetrigger}
          onClose={handleRetriggerClose}
          disableBackdropClick="true"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ color: 'maroon' }}>
            Warning!!!
          </DialogTitle>

          <DialogContent>
            <p>
              {' '}
              <h5>
                Workflow can't be retriggered for pending/unobtainable document
                status{' '}
              </h5>
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRetriggerClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={openWorkflowRetrigger}
          onClose={handleRetriggerClose}
          disableBackdropClick="true"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ color: 'maroon' }}>
            Warning!!!
          </DialogTitle>

          <DialogContent>
            <p>
              {' '}
              <h5>Workflow can't be retriggered for PA Documents </h5>
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRetriggerClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {wfRetrigger === 'SkipPA' && (
        <Dialog
          open={openWorkflowRetrigger}
          onClose={handleRetriggerClose}
          disableBackdropClick="true"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ color: 'maroon' }}>
            Workflow Retrigger
          </DialogTitle>

          <DialogContent>
            <p>
              <h5> Do you want to proceed with retriggering the workflow? </h5>
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRetriggerClose} color="primary">
              No
            </Button>
            <Button onClick={e => workflowRetrigger(e, true)} color="primary">
              Yes- Skip PA
            </Button>
            <Button onClick={workflowRetrigger} color="primary">
              Yes- Don't Skip PA
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog
        open={openDeleteDoc}
        onClose={handleDeleteDocClose}
        disableBackdropClick="true"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ color: 'maroon' }}>
          Document Delete
        </DialogTitle>

        <DialogContent>
          <p>
            <h5> Do you want to proceed with deleting the document? </h5>
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDocClose} color="primary">
            No
          </Button>
          <Button onClick={deleteFile} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <ExportDialog {...exportProps} />
      <div>
        <Dialog
          // fullScreen={fullScreen}
          maxWidth="md"
          fullWidth={true}
          open={downloadDialogOpen}
          onClose={handleDownloadClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {'Multiple files Download'}
          </DialogTitle>
          <DialogContent dividers>
            <Typography>
              The files marked in red exceeded the maximum download size limit
              and wont be downloaded.
            </Typography>
            <Typography gutterBottom>
              To continue with the download, click on the download button.
              &nbsp;
            </Typography>
            <DownloadMultiDialog
              rowData={downloadData}
              size={sizeCheck}
              close={handleDownloadClose}
              maxSize={maxFileSize}
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  )
}
