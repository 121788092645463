import React, { useState } from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useToasts } from 'react-toast-notifications'
import apiConfig from '../../config/apiConfig'
import { makeStyles } from '@material-ui/core/styles'

const textStr =
  'For Entering multiple email addresses, separate each email address with a comma ","'

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: '#AC0000',
  },
}))

export default function EmailFormDialog({
  sessionData,
  filePathData,
  fileName,
  innerOpen,
  setInnerOpen,
}) {
  const [mail, setMail] = useState('')
  const [cc, setCC] = useState('')
  const [bcc, setBCC] = useState('')
  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('')
  const [errorText, setErrorText] = useState('')

  const { addToast } = useToasts()

  const handleFormClose = () => {
    setInnerOpen(false)
    setErrorText('')
    setMail('')
    setCC('')
    setBCC('')
    setBody('')
    setSubject('')
  }

  const handleAddMail = str => {
    var temp = str
    temp = temp.replace(/\s+/g, '')
    var arr = []
    if (str.includes(',')) {
      arr = temp.split(',')
    } else {
      arr.push(temp)
    }
    return arr
  }

  const handleAddCCMail = str => {
    var temp = str

    temp = temp.replace(/\s+/g, '')
    var arr = []
    if (str.length === 0) {
      return arr
    }
    if (str.includes(',')) {
      arr = temp.split(',')
    } else {
      arr.push(temp)
    }
    return arr
  }

  const handleEmailRequestSubmit = event => {
    event.preventDefault()
    if (mail.length === 0) {
      setErrorText('This field cannot be empty')
      return
    }
    setInnerOpen(false)
    let payload = {}
    payload['sender_name'] = sessionData.fullName
    payload['sender_mail_address'] = sessionData.email
    payload['send_mail_to'] = handleAddMail(mail)
    payload['send_mail_cc'] = handleAddCCMail(cc)
    payload['send_mail_bcc'] = handleAddCCMail(bcc)
    payload['mail_body'] = body
    payload['mail_subject'] = subject
    if (subject.length === 0) {
      payload['mail_subject'] = fileName
    }

    payload['source_toss_file_path'] = filePathData
    payload['file_doc_title'] = fileName

    let url = apiConfig.apiUrl.sendMailUrl
    handleFormClose()
    axios
      .post(url, payload)
      .then(res => {
        if (res.status === 200) {
          addToast('Email request added successfully', {
            appearance: 'success',
            autoDismiss: true,
          })
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {
          addToast(
            'Error! File exceeds the size requirement. Try using Email with a Link button',
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        } else if (err.response && err.response.status === 406) {
          addToast('Error! Email address format is invalid', {
            appearance: 'error',
            autoDismiss: true,
          })
        } else if (err.response && err.response.status === 417) {
          addToast('Error! Email address format (for BCC/CC) is invalid', {
            appearance: 'error',
            autoDismiss: true,
          })
        } else {
          addToast('Error! Email Request could not be submitted', {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
  }
  const helperTestClasses = helperTextStyles()
  return (
    <>
      <div>
        <Dialog
          open={innerOpen}
          onClose={handleFormClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Send Mail</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Send Email with attached file - {fileName} {<br />} {textStr}
            </DialogContentText>
            <TextField
              fullWidth
              required
              FormHelperTextProps={{ classes: helperTestClasses }}
              id="standard-subject"
              helperText={errorText}
              autoFocus
              placeholder="Email address"
              label="Send Mail To"
              value={mail}
              onChange={e => {
                setMail(e.target.value)
              }}
            />
            &nbsp;
            <TextField
              fullWidth
              id="standard-subject"
              label="Send CC"
              placeholder="Email address"
              value={cc}
              onChange={e => {
                setCC(e.target.value)
              }}
            />
            &nbsp;
            <TextField
              fullWidth
              id="standard-subject"
              label="Send BCC"
              placeholder="Email address"
              value={bcc}
              onChange={e => {
                setBCC(e.target.value)
              }}
            />
            &nbsp;
            <TextField
              id="standard-subject"
              defaultValue={fileName}
              label="Subject"
              fullWidth
              onChange={e => {
                setSubject(e.target.value)
              }}
            />
            &nbsp;
            <TextField
              p={3}
              id="standard-subject"
              label="Email Body"
              fullWidth
              defaultValue={body}
              multiline
              rows={5}
              rowsMax={10}
              onChange={e => {
                setBody(e.target.value)
              }}
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFormClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              onClick={handleEmailRequestSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div></div>
    </>
  )
}
