import React from 'react'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import EmailForm from './emailForm'
import IconButton from '@material-ui/core/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '@material-ui/core/Tooltip'
import apiConfig from '../../config/apiConfig'

export default function SendMail({
  sessionData,
  filePathData,
  fileName,
  fileUrl,
}) {
  const [innerOpen, setInnerOpen] = React.useState(false)

  // get body and get get Mail To Href With Subject can be used to edit the mail pop up template
  const getBody = () => {
    var str = ''
    str = fileUrl
    return str
  }

  const getFileName = () => {
    var res = fileName
    if (fileName.length > 100) {
      res = fileName.substring(0, 100)
      res += '...'
    }
    return res
  }

  const getMailToHrefWithSubject = () => {
    var str = apiConfig.mailToHref
    str += getFileName()
    return str
  }

  const [slideOptions, setSlideOptions] = React.useState(false)
  const handleMouseLeave = event => {
    event.persist()
    setSlideOptions(false)
  }

  const enableIn = () => {
    setInnerOpen(true)
  }

  return (
    <div className="email-button">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
        onMouseOver={() => setSlideOptions(true)}
        onMouseLeave={handleMouseLeave}
      >
        <Button
          color="primary"
          variant="contained"
          disableElevation={true}
          disableRipple={true}
        >
          Email
        </Button>
        &nbsp; &nbsp;
        <Tooltip title="File as Attachment">
          <IconButton
            aria-label="File as Attachment"
            disableRipple
            style={
              slideOptions
                ? { padding: '0' }
                : { display: 'none', padding: '2' }
            }
            // color="green"
            size="medium"
            onClick={enableIn}
          >
            <FontAwesomeIcon icon={faPaperclip} size="md" color="#ED553B" />
          </IconButton>
        </Tooltip>
        &nbsp;
        <Tooltip title="with a Link">
          <div style={{ marginTop: '5px' }}>
            <Link
              class="mailto"
              href={getMailToHrefWithSubject() + '&body=' + getBody()}
            >
              <IconButton
                aria-label="with a Link"
                style={
                  slideOptions
                    ? { padding: '0' }
                    : { display: 'none', padding: '2' }
                }
                size="large"
              >
                <FontAwesomeIcon icon={faLink} size="md" color="orange" />
              </IconButton>
            </Link>
          </div>
        </Tooltip>
        <EmailForm
          fileName={getFileName()}
          sessionData={sessionData}
          filePathData={filePathData}
          innerOpen={innerOpen}
          setInnerOpen={setInnerOpen}
        />
      </div>
    </div>
  )
}
