import React from 'react'
import ExcelViewer from './excelViewer'
import WordViewer from './wordViewer'

export const CustomFileViewer = props => {
  switch (props.fileType) {
    case 'csv':
    case 'xlsx':
      return <ExcelViewer {...props} />
    case 'docx':
      return <WordViewer {...props} />

    default:
      return null
  }
}
