import React from 'react'
import moment from 'moment'
import { formatDollarAmount } from '../common/commonFunctionalities'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'

export const columns = [
  {
    field: 'document_view',
    title: 'DocumentID',
    hidden: true,
    export: true,
    cellStyle: {
      whiteSpace: 'nowrap',
      //   textDecoration: 'underline',
      //   color: 'blue',
    },
  },
  {
    field: 'step',
    title: 'Step',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'location_number',
    title: 'TNumber#',
  },
  {
    field: 'physical_file_number',
    title: 'PhysicalFile#',
  },
  {
    field: 'region',
    title: 'Region/PrimaryUse',
  },
  {
    field: 'city',
    title: 'City',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'state',
    title: 'State',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'document_date',
    title: 'DocumentDate',
    customSort: (a, b) => {
      if (a.document_date === '' || typeof a.document_date === 'undefined') {
        return -1
      } else if (
        b.document_date === '' ||
        typeof b.document_date === 'undefined'
      ) {
        return 1
      }
      return (
        new Date(a.document_date).getTime() -
        new Date(b.document_date).getTime()
      )
    },
    render: rowData => (
      <span>
        {rowData.document_date === ''
          ? ''
          : moment(rowData.document_date).format('MM/DD/YYYY')}
      </span>
    ),
  },
  {
    field: 'creation_date',
    title: 'CreationDate',
    customSort: (a, b) => {
      if (a.creation_date === '' || typeof a.creation_date === 'undefined') {
        return -1
      } else if (
        b.creation_date === '' ||
        typeof b.creation_date === 'undefined'
      ) {
        return 1
      }
      return (
        new Date(a.creation_date).getTime() -
        new Date(b.creation_date).getTime()
      )
    },
  },
  {
    field: 'title',
    title: 'DocumentTitle',
    render: rowData =>
      rowData.title && rowData.title.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.title}
        </div>
      ) : (
        <div>{rowData.title}</div>
      ),
  },
  {
    field: 'document_type',
    title: 'DocumentType',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'document_status',
    title: 'Status',
  },
  {
    field: 'is_urgent',
    title: 'Urgent',
  },
  {
    field: 'document_notes',
    title: 'DocumentNotes',
    render: rowData =>
      rowData.document_notes && rowData.document_notes.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.document_notes}
        </div>
      ) : (
        <div>{rowData.document_notes}</div>
      ),
  },
  {
    field: 'retrigger_reason',
    title: 'RetriggerReason',
  },
  {
    field: 'file_path',
    title: 'FilePath',
    hidden: true,
  },
  {
    field: 'file_link',
    title: 'FileLink',
    render: rowData => (
      <a target="_blank" rel="noopener noreferrer" href={rowData.file_link}>
        {' '}
        {rowData.file_link}{' '}
      </a>
    ),
  },
]

export const easementColumns = [
  {
    field: 'document_view',
    title: 'Title',
    hidden: false,
    export: true,
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'location_number',
    title: 'LocationNumber',
  },
  {
    field: 'location_name',
    title: 'LocationName',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'document_title',
    title: 'DocumentTitle',
    render: rowData =>
      rowData.document_title && rowData.document_title.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.document_title}
        </div>
      ) : (
        <div>{rowData.document_title}</div>
      ),
  },
  {
    field: 'document_date',
    title: 'DocumentDate',
    render: rowData => (
      <span>
        {rowData.document_date === ''
          ? ''
          : moment(rowData.document_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
      </span>
    ),
  },
  {
    field: 'creation_date',
    title: 'CreationDate',
  },
  {
    field: 'document_type',
    title: 'DocumentType',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'document_purpose',
    title: 'DocumentPurpose',
    render: rowData =>
      rowData.document_purpose && rowData.document_purpose.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.document_purpose}
        </div>
      ) : (
        <div>{rowData.document_purpose}</div>
      ),
  },
]

export const leaseNoticeColumns = [
  {
    field: 'location_number',
    title: 'LocationNumber',
  },
  {
    field: 'location_name',
    title: 'LocationName',
  },
  {
    field: 'document_title',
    title: 'DocumentTitle',
    render: rowData =>
      rowData.document_title && rowData.document_title.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.document_title}
        </div>
      ) : (
        <div>{rowData.document_title}</div>
      ),
  },
  {
    field: 'document_date',
    title: 'DocumentDate',
    render: rowData => (
      <span>
        {rowData.document_date === ''
          ? ''
          : moment(rowData.document_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
      </span>
    ),
  },
  {
    field: 'creation_date',
    title: 'CreationDate',
  },
  {
    field: 'document_type',
    title: 'DocumentType',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'document_purpose',
    title: 'DocumentPurpose',
    render: rowData =>
      rowData.document_purpose && rowData.document_purpose.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.document_purpose}
        </div>
      ) : (
        <div>{rowData.document_purpose}</div>
      ),
  },
  // {
  //   field: 'file_path',
  //   title: 'FilePath',
  //   hidden: true,
  //   download: false,
  // },
  // {
  //   field: 'document_view',
  //   title: 'Title',
  //   hidden: true,
  //   export: true,
  //   cellStyle: {
  //     whiteSpace: 'nowrap',
  //   },
  // },
]

export const leasePendingColumns = [
  {
    field: 'document_view',
    title: 'Title',
    hidden: true,
    export: true,
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'location_number',
    title: 'Location',
  },
  {
    field: 'document_type',
    title: 'DocumentType',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'document_date',
    title: 'DocumentDate',
    customSort: (a, b) => {
      if (a.document_date === '' || typeof a.document_date === 'undefined') {
        return -1
      } else if (
        b.document_date === '' ||
        typeof b.document_date === 'undefined'
      ) {
        return 1
      }
      return (
        new Date(a.document_date).getTime() -
        new Date(b.document_date).getTime()
      )
    },
    render: rowData => (
      <span>
        {rowData.document_date === ''
          ? ''
          : moment(rowData.document_date).format('MM/DD/YYYY')}
      </span>
    ),
  },
  {
    field: 'creation_date',
    title: 'CreationDate',
    customSort: (a, b) => {
      if (a.creation_date === '' || typeof a.creation_date === 'undefined') {
        return -1
      } else if (
        b.creation_date === '' ||
        typeof b.creation_date === 'undefined'
      ) {
        return 1
      }
      return (
        new Date(a.creation_date).getTime() -
        new Date(b.creation_date).getTime()
      )
    },
  },
  {
    field: 'document_title',
    title: 'DocumentTitle',
    render: rowData =>
      rowData.document_title && rowData.document_title.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.document_title}
        </div>
      ) : (
        <div>{rowData.document_title}</div>
      ),
  },
  {
    field: 'physical_file_number',
    title: 'PhysicalFile#',
  },
  {
    field: 'current_bucket',
    title: 'WorkflowStep',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'recordable',
    title: 'Recordable?',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'is_urgent',
    title: 'Urgent',
  },
  {
    field: 'document_status',
    title: 'Status',
  },
  {
    field: 'bypass_pma',
    title: 'BypassPMA',
    hidden: true,
  },
  {
    field: 'document_purpose',
    title: 'DocumentPurpose',
    render: rowData =>
      rowData.document_purpose && rowData.document_purpose.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.document_purpose}
        </div>
      ) : (
        <div>{rowData.document_purpose}</div>
      ),
  },
  {
    field: 'file_letter',
    title: 'FileLetter',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'file_letter_description',
    title: 'FileLetterDescription',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'file_path',
    title: 'FilePath',
    hidden: true,
    download: false,
  },
]

export const leaseReviewcolumns = [
  {
    field: 'document_view',
    title: 'DocumentView',
    hidden: true,
    export: true,
    cellStyle: {
      whiteSpace: 'nowrap',
      //   textDecoration: 'underline',
      //   color: 'blue',
    },
  },
  {
    field: 'step',
    title: 'Step',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'location_number',
    title: 'TNumber#',
  },
  {
    field: 'physical_file_number',
    title: 'PhysicalFile#',
  },
  {
    field: 'region',
    title: 'Region/PrimaryUse',
  },
  {
    field: 'city',
    title: 'City',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'state',
    title: 'State',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'document_date',
    title: 'DocDate',
    customSort: (a, b) => {
      if (a.document_date === '' || typeof a.document_date === 'undefined') {
        return -1
      } else if (
        b.document_date === '' ||
        typeof b.document_date === 'undefined'
      ) {
        return 1
      }
      return (
        new Date(a.document_date).getTime() -
        new Date(b.document_date).getTime()
      )
    },
    render: rowData => (
      <span>
        {rowData.document_date === ''
          ? ''
          : moment(rowData.document_date).format('MM/DD/YYYY')}
      </span>
    ),
  },
  {
    field: 'creation_date',
    title: 'CreationDate',
    customSort: (a, b) => {
      if (a.creation_date === '' || typeof a.creation_date === 'undefined') {
        return -1
      } else if (
        b.creation_date === '' ||
        typeof b.creation_date === 'undefined'
      ) {
        return 1
      }
      return (
        new Date(a.creation_date).getTime() -
        new Date(b.creation_date).getTime()
      )
    },
  },
  {
    field: 'document_title',
    title: 'Title',
    render: rowData =>
      rowData.document_title && rowData.document_title.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.document_title}
        </div>
      ) : (
        <div>{rowData.document_title}</div>
      ),
  },
  {
    field: 'document_type',
    title: 'DocType',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'document_status',
    title: 'DocStatus',
  },
  {
    field: 'is_urgent',
    title: 'Urgent',
  },
  {
    field: 'document_purpose',
    title: 'DocumentPurpose',
    render: rowData =>
      rowData.document_purpose && rowData.document_purpose.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.document_purpose}
        </div>
      ) : (
        <div>{rowData.document_purpose}</div>
      ),
  },
  {
    field: 'document_notes',
    title: 'DocumentNotes',
    render: rowData =>
      rowData.document_notes && rowData.document_notes.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.document_notes}
        </div>
      ) : (
        <div>{rowData.document_notes}</div>
      ),
  },
  {
    field: 'retrigger_reason',
    title: 'RetriggerReason',
    hidden: true,
  },
  {
    field: 'file_path',
    title: 'FilePath',
    hidden: true,
    download: false,
  },
]

export const leaseQuarterlyInboxColumns = [
  {
    field: 'document_id',
    title: 'DocumentView',
    hidden: false,
    export: true,
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'location_number',
    title: 'LocationNumber',
  },
  {
    field: 'document_date',
    title: 'DocDate',
    customSort: (a, b) => {
      if (a.document_date === '' || typeof a.document_date === 'undefined') {
        return -1
      } else if (
        b.document_date === '' ||
        typeof b.document_date === 'undefined'
      ) {
        return 1
      }
      return (
        new Date(a.document_date).getTime() -
        new Date(b.document_date).getTime()
      )
    },
    render: rowData => (
      <span>
        {rowData.document_date === ''
          ? ''
          : moment(rowData.document_date).format('MM/DD/YYYY')}
      </span>
    ),
  },
  {
    field: 'creation_date',
    title: 'CreationDate',
    customSort: (a, b) => {
      if (a.creation_date === '' || typeof a.creation_date === 'undefined') {
        return -1
      } else if (
        b.creation_date === '' ||
        typeof b.creation_date === 'undefined'
      ) {
        return 1
      }
      return (
        new Date(a.creation_date).getTime() -
        new Date(b.creation_date).getTime()
      )
    },
  },
  {
    field: 'document_title',
    title: 'Title',
    render: rowData =>
      rowData.document_title && rowData.document_title.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.document_title}
        </div>
      ) : (
        <div>{rowData.document_title}</div>
      ),
  },
  {
    field: 'document_type',
    title: 'DocType',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'task_details[0].task_response_options',
    title: 'Actionable',
    // render: rowdata => {
    //   return <span>{rowdata.task_details[0].task_response_options}</span>
    // },
  },
  {
    field: 'document_purpose',
    title: 'DocumentPurpose',
    render: rowData =>
      rowData.document_purpose && rowData.document_purpose.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.document_purpose}
        </div>
      ) : (
        <div>{rowData.document_purpose}</div>
      ),
  },
  {
    field: 'task_details[1].task_response_options',
    title: 'Answer',
    render: rowData =>
      rowData.task_details[1].task_response_options.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.task_details[1].task_response_options}
        </div>
      ) : (
        <div>{rowData.task_details[1].task_response_options}</div>
      ),
  },
  {
    field: 'user_name',
    title: 'UserName',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'due_date',
    title: 'PMADueDate',
    customSort: (a, b) => {
      if (a.due_date === '' || typeof a.due_date === 'undefined') {
        return -1
      } else if (b.due_date === '' || typeof b.due_date === 'undefined') {
        return 1
      }
      return new Date(a.due_date).getTime() - new Date(b.due_date).getTime()
    },
  },

  {
    field: 'file_path',
    title: 'FilePath',
    hidden: true,
    download: false,
  },
]

export const camQuarterlyInboxColumns = [
  {
    field: 'location_number',
    title: 'LocationNumber',
  },
  {
    field: 'document_date',
    title: 'DocDate',
    customSort: (a, b) => {
      if (a.document_date === '' || typeof a.document_date === 'undefined') {
        return -1
      } else if (
        b.document_date === '' ||
        typeof b.document_date === 'undefined'
      ) {
        return 1
      }
      return (
        new Date(a.document_date).getTime() -
        new Date(b.document_date).getTime()
      )
    },
    render: rowData => (
      <span>
        {rowData.document_date === ''
          ? ''
          : moment(rowData.document_date).format('MM/DD/YYYY')}
      </span>
    ),
  },
  {
    field: 'creation_date',
    title: 'CreationDate',
    customSort: (a, b) => {
      if (a.creation_date === '' || typeof a.creation_date === 'undefined') {
        return -1
      } else if (
        b.creation_date === '' ||
        typeof b.creation_date === 'undefined'
      ) {
        return 1
      }
      return (
        new Date(a.creation_date).getTime() -
        new Date(b.creation_date).getTime()
      )
    },
  },
  {
    field: 'document_title',
    title: 'DocumentTitle',
    render: rowData =>
      rowData.document_title && rowData.document_title.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.document_title}
        </div>
      ) : (
        <div>{rowData.document_title}</div>
      ),
  },
  {
    field: 'document_type',
    title: 'DocumentType',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'document_purpose',
    title: 'DocumentPurpose',
    render: rowData =>
      rowData.document_purpose && rowData.document_purpose.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.document_purpose}
        </div>
      ) : (
        <div>{rowData.document_purpose}</div>
      ),
  },
  {
    field: 'task_details[4].task_response_options',
    title: 'Actionable',
    render: rowData => (
      <div>
        {rowData.task_details[4].task_response_options === 'Yes'
          ? 'No'
          : rowData.task_details[4].task_response_options === 'Blank'
          ? 'Yes'
          : ''}
      </div>
    ),
  },
  {
    field: 'task_details[0].task_response_options',
    title: 'Payable Obligation for New or Existing Location?',
    render: rowData => (
      <div style={{ textAlign: 'left' }}>
        {rowData.task_details[0].task_response_options === 'Yes' ? (
          <CheckIcon style={{ color: 'green' }} />
        ) : rowData.task_details[0].task_response_options === 'Blank' ? (
          <CloseIcon style={{ color: 'red' }} />
        ) : (
          ''
        )}
      </div>
    ),
  },
  {
    field: 'task_details[1].task_response_options',
    title: 'Payable Vendor or Address Change?',
    render: rowData => (
      <div style={{ textAlign: 'left' }}>
        {rowData.task_details[1].task_response_options === 'Yes' ? (
          <CheckIcon style={{ color: 'green' }} />
        ) : rowData.task_details[1].task_response_options === 'Blank' ? (
          <CloseIcon style={{ color: 'red' }} />
        ) : (
          ''
        )}
      </div>
    ),
  },
  {
    field: 'task_details[2].task_response_options',
    title: 'New Rebill Opportunity?',
    render: rowData => (
      <div style={{ textAlign: 'left' }}>
        {rowData.task_details[2].task_response_options === 'Yes' ? (
          <CheckIcon style={{ color: 'green' }} />
        ) : rowData.task_details[2].task_response_options === 'Blank' ? (
          <CloseIcon style={{ color: 'red' }} />
        ) : (
          ''
        )}
      </div>
    ),
  },
  {
    field: 'task_details[3].task_response_options',
    title: 'Rebill Customer or Address Change?',
    render: rowData => (
      <div style={{ textAlign: 'left' }}>
        {rowData.task_details[3].task_response_options === 'Yes' ? (
          <CheckIcon style={{ color: 'green' }} />
        ) : rowData.task_details[3].task_response_options === 'Blank' ? (
          <CloseIcon style={{ color: 'red' }} />
        ) : (
          ''
        )}
      </div>
    ),
  },
  {
    field: 'task_details[4].task_response_options',
    title: 'Previously Reviewed or No Financial Impact?',
    render: rowData => (
      <div style={{ textAlign: 'left' }}>
        {rowData.task_details[4].task_response_options === 'Yes' ? (
          <CheckIcon style={{ color: 'green' }} />
        ) : rowData.task_details[4].task_response_options === 'Blank' ? (
          <CloseIcon style={{ color: 'red' }} />
        ) : (
          ''
        )}
      </div>
    ),
  },
  {
    field: 'total_dollar_impact',
    title: 'TotalDollarImpact',
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'file_path',
    title: 'FilePath',
    hidden: true,
    download: false,
  },
]

export const camPayableBudgetColumns = [
  {
    field: 'step',
    title: 'Step',
  },
  {
    field: 'created_date',
    title: 'CreatedDate',
    customSort: (a, b) => {
      if (a.created_date === '' || typeof a.created_date === 'undefined') {
        return -1
      } else if (
        b.created_date === '' ||
        typeof b.created_date === 'undefined'
      ) {
        return 1
      }
      return (
        new Date(a.created_date).getTime() - new Date(b.created_date).getTime()
      )
    },
  },
  {
    field: 'location_number',
    title: 'LocationNumber',
  },
  {
    field: 'location_region',
    title: 'Region',
  },
  {
    field: 'operator',
    title: 'Operator',
  },
  {
    field: 'sap_contract_number',
    title: 'SAPContractNumber',
  },
  {
    field: 'user_name',
    title: 'User',
  },
  {
    field: 'document_type',
    title: 'DocumentType',
  },
  {
    field: 'cam_status',
    title: 'Status',
  },
  {
    field: 'fiscal_year',
    title: 'FiscalYear',
  },
  {
    field: 'total_budget_amount',
    title: 'SubmittedBudgetAmount',
    render: rowData => formatDollarAmount(rowData.total_budget_amount),
    customSort: (a, b) => {
      let a1 =
        typeof a.total_budget_amount === 'undefined' ||
        a.total_budget_amount === '' ||
        typeof a.total_budget_amount === 'object'
          ? 0
          : a.total_budget_amount
      let b1 =
        typeof b.total_budget_amount === 'undefined' ||
        b.total_budget_amount === '' ||
        typeof b.total_budget_amount === 'object'
          ? 0
          : b.total_budget_amount

      return a1 - b1
    },
  },
  {
    field: 'total_budget_amount_prioryear',
    title: 'BudgetPaymentsMade',
    render: rowData =>
      formatDollarAmount(rowData.total_budget_amount_prioryear),
    customSort: (a, b) => {
      let a1 =
        typeof a.total_budget_amount_prioryear === 'undefined' ||
        a.total_budget_amount_prioryear === '' ||
        typeof a.total_budget_amount_prioryear === 'object'
          ? 0
          : a.total_budget_amount_prioryear
      let b1 =
        typeof b.total_budget_amount_prioryear === 'undefined' ||
        b.total_budget_amount_prioryear === '' ||
        typeof b.total_budget_amount_prioryear === 'object'
          ? 0
          : b.total_budget_amount_prioryear

      return a1 - b1
    },
  },
  {
    field: 'approved_budget_amount',
    title: 'ApprovedTotalBudget',
    render: rowData => formatDollarAmount(rowData.approved_budget_amount),
    customSort: (a, b) => {
      let a1 =
        typeof a.approved_budget_amount === 'undefined' ||
        a.approved_budget_amount === '' ||
        typeof a.approved_budget_amount === 'object'
          ? 0
          : a.approved_budget_amount
      let b1 =
        typeof b.approved_budget_amount === 'undefined' ||
        b.approved_budget_amount === '' ||
        typeof b.approved_budget_amount === 'object'
          ? 0
          : b.approved_budget_amount

      return a1 - b1
    },
  },
  {
    field: 'document_title',
    title: 'DocumentTitle',
  },
  {
    field: 'location_city',
    title: 'City',
  },
  {
    field: 'location_state',
    title: 'State',
  },
  {
    field: 'lastyear_total_budget_amount',
    title: 'LastYearTotalBudgetAmount',
    render: rowData => formatDollarAmount(rowData.lastyear_total_budget_amount),
    customSort: (a, b) => {
      let a1 =
        typeof a.lastyear_total_budget_amount === 'undefined' ||
        a.lastyear_total_budget_amount === '' ||
        typeof a.lastyear_total_budget_amount === 'object'
          ? 0
          : a.lastyear_total_budget_amount
      let b1 =
        typeof b.lastyear_total_budget_amount === 'undefined' ||
        b.lastyear_total_budget_amount === '' ||
        typeof b.lastyear_total_budget_amount === 'object'
          ? 0
          : b.lastyear_total_budget_amount

      return a1 - b1
    },
  },
  {
    field: 'file_path',
    title: 'FilePath',
    hidden: true,
    download: false,
  },
]

export const camPayableBucketInflowColumns = [
  {
    field: 'bucket_name',
    title: 'Bucket Name',
  },
  {
    field: 'lastweek_opening_balance',
    title: 'Last Week Opening Balance',
  },
  {
    field: 'inflow',
    title: 'Inflow',
  },
  {
    field: 'inprogress',
    title: 'Inprogress',
  },
  {
    field: 'completed',
    title: 'Completed',
  },
]

export const camPayableUserInflowColumns = [
  {
    field: 'bucket_name',
    title: 'User Name',
    render: rowData =>
      rowData.bucket_name === '' || rowData.bucket_name === null
        ? (rowData.bucket_name = 'Unassigned')
        : rowData.bucket_name,
  },
  {
    field: 'lastweek_opening_balance',
    title: 'Last Week Opening Balance',
  },
  {
    field: 'inflow',
    title: 'Inflow',
  },
  {
    field: 'inprogress',
    title: 'Inprogress',
  },
  {
    field: 'completed',
    title: 'Completed',
  },
]

export const camReturnCountColumns = [
  {
    field: 'user_name',
    title: 'Returned By',
  },
  {
    field: 'count',
    title: 'Count',
  },
]

export const camPayableAndRecReturnedRecordsColumns = [
  {
    field: 'workflow_id',
    title: 'WorkflowID',
  },
  {
    field: 'content_type',
    title: 'ContentType',
  },
  {
    field: 'document_type',
    title: 'DocumentType',
  },
  {
    field: 'location_number',
    title: 'LocationNumber',
  },
  {
    field: 'region',
    title: 'LocationRegion',
  },
  {
    field: 'sap_contract_number',
    title: 'SAPContractNumber',
  },
  {
    field: 'document_title',
    title: 'DocumentTitle',
  },
  {
    field: 'fiscal_year',
    title: 'FiscalYear',
  },
  {
    field: 'task_details[4].task_label',
    title: 'ReviewType',
    render: rowData =>
      rowData.task_details[4].task_label === 'Site Ops Review Completed?'
        ? 'Site Ops Review'
        : 'Manager Review',
  },
  {
    field: 'returned_by',
    title: 'ReturnedBy',
  },
  {
    field: 'returned_bucket_name',
    title: 'ReturnedBucketName',
  },
  {
    field: 'task_details[6].task_response_options',
    title: 'ReturnedTo',
  },
  {
    field: 'task_details[5].task_response_options',
    title: 'NotApprovedReason',
  },
  {
    field: 'comments',
    title: 'Comments',
    render: rowData =>
      rowData.comments && rowData.comments.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.comments}
        </div>
      ) : (
        <div>{rowData.comments}</div>
      ),
  },
]

export const camReturnedRebillRecordsColumns = [
  {
    field: 'workflow_id',
    title: 'WorkflowID',
  },
  {
    field: 'content_type',
    title: 'ContentType',
  },
  {
    field: 'document_type',
    title: 'DocumentType',
  },
  {
    field: 'location_number',
    title: 'LocationNumber',
  },
  {
    field: 'region',
    title: 'LocationRegion',
  },
  {
    field: 'sap_contract_number',
    title: 'SAPContractNumber',
  },
  {
    field: 'document_title',
    title: 'DocumentTitle',
  },
  {
    field: 'fiscal_year',
    title: 'FiscalYear',
  },
  {
    field: 'task_details[2].task_label',
    title: 'ReviewType',
    render: rowData =>
      rowData.task_details[2].task_label === 'Site Ops Review Completed?'
        ? 'Site Ops Review'
        : 'Manager Review',
  },
  {
    field: 'returned_by',
    title: 'ReturnedBy',
  },
  {
    field: 'returned_bucket_name',
    title: 'ReturnedBucketName',
  },
  {
    field: 'task_details[4].task_response_options',
    title: 'ReturnedTo',
  },
  {
    field: 'task_details[3].task_response_options',
    title: 'NotApprovedReason',
  },
  {
    field: 'comments',
    title: 'Comments',
    render: rowData =>
      rowData.comments && rowData.comments.length > 100 ? (
        <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
          {rowData.comments}
        </div>
      ) : (
        <div>{rowData.comments}</div>
      ),
  },
]

export const camCompletedRecordsColumns = [
  {
    field: 'workflow_id',
    title: 'WorkflowID',
  },
  {
    field: 'content_type',
    title: 'ContentType',
  },
  {
    field: 'document_type',
    title: 'DocumentType',
  },
  {
    field: 'location_number',
    title: 'LocationNumber',
  },
  {
    field: 'region',
    title: 'LocationRegion',
  },
  {
    field: 'sap_contract_number',
    title: 'SAPContractNumber',
  },
  {
    field: 'document_title',
    title: 'DocumentTitle',
  },
  {
    field: 'fiscal_year',
    title: 'FiscalYear',
  },
  {
    field: 'create_date',
    title: 'IndexedDate',
  },
  {
    field: 'completed_date',
    title: 'CompletedDate',
  },
  {
    field: 'bucket_name',
    title: 'BucketName',
    // render: rowData =>
    //   rowData.content_type === 'PA CAM Payable Reconciliation'
    //     ? 'PAYABLE-RECONCILIATION-COMPLETE'
    //     : rowData.bucket_name,
  },
  {
    field: 'user_name',
    title: 'IndexedBy',
  },
  {
    field: 'preparer_name',
    title: 'PreparerName',
  },
  {
    field: 'total_dollar_impact',
    title: 'TotalDollarImpact',
  },
  {
    field: 'budget_amt_submitted_by_operator',
    title: 'TotalBudgetAmtByOperator',
  },
  {
    field: 'rec_amt_submitted_by_operator',
    title: 'TotalRecAmtByOperator',
  },
]

export const camBucketWiseColumns = [
  {
    field: 'workflow_id',
    title: 'Workflow ID',
  },
  {
    field: 'bucket_status',
    title: 'Status',
  },
  {
    field: 'content_type',
    title: 'Content Type',
  },
  {
    field: 'location_number',
    title: 'Location',
  },
  {
    field: 'sap_contract_number',
    title: 'SAP Contract Number',
  },
  {
    field: 'document_title',
    title: 'DocumentTitle',
  },
  {
    field: 'created_date',
    title: 'Created Date',
  },
  {
    field: 'bucket_name',
    title: 'Bucket Name',
  },
  {
    field: 'user_name',
    title: 'User Name',
  },
]
