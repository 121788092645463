import React, { useEffect, useState } from 'react'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { CustomFileViewer } from '../common/customFileViewer'
// import FileViewer from 'react-file-viewer'
import { useLocation } from 'react-router-dom'
import { getFileExtensionType } from '../common/commonFunctionalities'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
const FileViewerComponent = () => {
  const [fileExt, setFileExt] = useState(null)
  const [searchParams, setSearchParams] = useState(null)
  const location = useLocation()

  useEffect(() => {
    setSearchParams(location.search)
    let ext = getFileExtensionType(location.search)
    setFileExt(ext)
  }, [location])

  let showFileUrl = apiConfig.apiUrl.fileUrl

  useEffect(() => {
    if (searchParams) {
      let iFrameurl = `${showFileUrl}${searchParams}`
      try {
        var iframe = document.getElementById('file_iframe')
        axios
          .get(iFrameurl, {
            responseType: 'blob',
          })
          .then(response => {
            let blobURL = URL.createObjectURL(response.data)

            iframe.src = blobURL
          })
          .catch(error => {
            console.log(error)
          })
      } catch (err) {
        console.log(err)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  if (!fileExt) return <div>No file to display</div>
  return (
    <div className="file-container">
      {fileExt === 'pdf' ? (
        <div className="pdf">
          <SnackbarContent
            message="If you are having trouble viewing this document, download"
            action={
              <span style={{ textDecoration: 'underline' }}>
                <a
                  href={`${showFileUrl}${searchParams}&download=yes`}
                  onContextMenu={event => {
                    event.preventDefault()
                  }}
                >
                  HERE
                </a>
              </span>
            }
          />
          <iframe
            // src={`${showFileUrl}${searchParams}`}
            width="100%"
            height="100%"
            title="individualPdf"
            style={{ height: '90vh' }}
            id="file_iframe"
          />
        </div>
      ) : (
        <div className="doc">
          <div>
            <SnackbarContent
              message="If you are having trouble viewing this document, download"
              action={
                <span style={{ textDecoration: 'underline' }}>
                  <a
                    href={`${showFileUrl}${searchParams}&download=yes`}
                    onContextMenu={event => {
                      event.preventDefault()
                    }}
                  >
                    HERE
                  </a>
                </span>
              }
            />
          </div>
          {fileExt === 'csv' || fileExt === 'xlsx' || fileExt === 'docx' ? (
            <CustomFileViewer
              fileType={fileExt}
              filePath={`${showFileUrl}${searchParams}`}
              //   onError={onError}
              title="customeFile"
            />
          ) : (
            <p>This file is not supported</p>
            // <FileViewer
            //   fileType={fileExt}
            //   filePath={`${showFileUrl}${searchParams}`}
            // />
          )}
        </div>
      )}
    </div>
  )
}

export default FileViewerComponent
