import { properties } from '../Properties'

let initialState = {
  searchState: {
    isClicked: false,
    displaySearchResults: false,
    locationNumber: '',
    address: '',
    advancedSearchFieldsChanged: false,
    documentType: '',
    documentGroup: '',
    queryTerm: '',
    searchAccess: '',
  },
  openedTabs: {
    columns: properties.search.metadataViewFields,
    tab: [],
    files: [],
    value: 0,
    documentViewer: [], //to hold Iframes
  },
  searchMetaData: {},
  savedSearchResponse: [],
  searchPageInfo: {
    searchCurrentPage: 0,
    orderDirection: '',
    orderBy: -1,
  },
}

let tempState
export function SearchReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case 'GET_SEARCH_RESULT':
      return Object.assign({}, state, {
        getSearchResults: action.payload,
      })

    case 'SAVE_SEARCH_FORM':
      tempState = Object.assign({}, state.searchState, payload)
      return Object.assign({}, state, {
        searchState: tempState,
      })

    case 'SAVE_OPENED_TABS':
      return Object.assign({}, state, {
        openedTabs: { ...state.openedTabs, [payload.field]: payload.value },
      })

    case 'SAVE_SEARCH_METADATA':
      return Object.assign({}, state, { searchMetaData: action.payload })

    case 'SAVE_SEARCH_RESPONSE':
      return Object.assign({}, state, { savedSearchResponse: action.payload })

    case 'SAVE_CURRENT_SEARCH_PAGE_INFO': {
      let tempState = Object.assign({}, state.searchPageInfo, payload)
      return Object.assign({}, state, {
        searchPageInfo: tempState,
      })
    }

    default:
      return state
  }
}
