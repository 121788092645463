import React, { useState, useRef } from 'react'
import { BulkEditSearchStyle } from '../common/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import { useEffect } from 'react'
import NativeSelect from '@material-ui/core/NativeSelect'
import { useAuth } from '@praxis/component-auth'
import {
  validateRow,
  validateDateWorkflow,
  validateDate,
} from '../common/validation'
import { useSelector, useDispatch } from 'react-redux'
import MaterialTable from 'material-table'
import { tableIcons } from '../common/commonFunctionalities'
import BorderColorIcon from '@material-ui/icons/BorderColor'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import DoneAllIcon from '@material-ui/icons/DoneAll'
// import MenuItem from '@material-ui/core/MenuItem'
// import ListItemText from '@material-ui/core/ListItemText'
// import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Chip from '@material-ui/core/Chip'
import FormFields from './filterFields'
import './bulkEdit.css'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
// import Avatar from '@material-ui/core/Avatar'
import { useToasts } from 'react-toast-notifications'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { useHistory } from 'react-router-dom'

const dialogColumns = [
  {
    title: 'LocationNumber',
    field: 'locationNumber',
    // defaultSort: 'asc',
    editable: 'never',
  },

  {
    title: 'DocumentGroup *',
    field: 'documentGroup',
    lookup: {
      'Acquisition Document': 'Acquisition Document',
      'Condominium Document': 'Condominium Document',
      Correspondence: 'Correspondence',

      'Disposition Document': 'Disposition Document',

      'Internal Document': 'Internal Document',

      Multimedia: 'Multimedia',

      'Operation or Development Document': 'Operation or Development Document',

      'Public Partnership': 'Public Partnership',

      'Real Estate': 'Real Estate',

      'Lease Document': 'Lease Document',

      'Real Estate - Secure': 'Real Estate - Secure',

      'Internal Document - Secure': 'Internal Document - Secure',

      'Public Partnership - Secure': 'Public Partnership - Secure',

      'CAM Review': 'CAM Review',

      'Lease Review': 'Lease Review',
    },
  },
  {
    title: 'DocumentType *',
    field: 'documentType',
  },
  {
    title: 'DocumentTitle',
    field: 'documentTitle',
  },
  {
    title: 'DocumentDate',
    field: 'documentDate',
  },

  {
    title: 'DocumentPurpose',
    field: 'documentPurpose',
  },

  {
    title: 'FileLetter',
    field: 'fileLetter',
  },

  {
    title: 'FileLetterDescription',
    field: 'fileLetterDescription',
  },
  {
    title: 'PhysicalFileNumber',
    field: 'physicalFileNumber',
    type: 'numeric',
    cellStyle: { textAlign: 'left' },
  },
]
// const selectionFields = [
//   'LocationNumber',
//   'DocumentTitle',
//   'DocumentDate',
//   'DocumentGroup',
//   'DocumentType',
//   'FileLetter',
//   'FileLetterDescription',
//   'PhysicalFileNumber',
// ]

const makeSnakePayload = (data, session) => {
  return {
    id: data.id,
    location_number: data.locationNumber,
    document_title: data.documentTitle,
    document_group: data.documentGroup,
    document_type: data.documentType,
    document_date: data.documentDate,
    physical_file_number: data.physicalFileNumber,
    document_purpose: data.documentPurpose,
    file_letter: data.fileLetter,
    file_letter_description: data.fileLetterDescription,
    modified_userid: session.userInfo.lanId,
    modified_username: session.userInfo.fullName,
  }
}

const BulkEdit = () => {
  const classes = BulkEditSearchStyle()

  const documentGroupTypePayload = useSelector(
    state => state.common.documentGroupAndDocumentTypeBulkEdit
  )
  const dispatch = useDispatch()
  const searchFields = useSelector(state => state.bulkEdit.searchFields)
  const auth = useAuth()

  // let [selectAll, setSelectAll] = useState(false)
  const { addToast } = useToasts()

  const { session } = auth
  const materialTableRef = React.createRef()

  const [isLoading, setIsLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [isDialogLoading, setIsDialogLoading] = useState(false)
  const [dateError, setDateError] = useState(false)

  const [columns, setColumns] = useState([
    {
      title: 'LocationNumber',
      field: 'locationNumber',
      // defaultSort: 'asc',
      editable: 'never',
    },
    {
      title: 'DocumentGroup*',
      field: 'documentGroup',
    },
    {
      title: 'DocumentType*',
      field: 'documentType',
    },
    {
      title: 'DocumentTitle',
      field: 'documentTitle',
    },
    {
      title: 'DocumentDate',
      field: 'documentDate',
      customSort: (a, b) => {
        if (a.documentDate === '' || typeof a.documentDate === 'undefined') {
          return -1
        } else if (
          b.documentDate === '' ||
          typeof b.documentDate === 'undefined'
        ) {
          return 1
        }
        return (
          new Date(a.documentDate).getTime() -
          new Date(b.documentDate).getTime()
        )
      },
    },
    {
      title: 'DocumentPurpose',
      field: 'documentPurpose',
    },

    {
      title: 'FileLetter',
      field: 'fileLetter',
    },

    {
      title: 'FileLetterDescription',
      field: 'fileLetterDescription',
    },
    {
      title: 'PhysicalFileNumber',
      field: 'physicalFileNumber',
      headerStyle: { textAlign: 'left', flexDirection: 'row' },
      cellStyle: { textAlign: 'left' },
      type: 'numeric',
    },
  ])
  const tableData = useSelector(state => state.bulkEdit.tableData)

  const selectedRows = useSelector(state => state.bulkEdit.selectedRows)
  const mapSelectedRows = useSelector(state => state.bulkEdit.mapSelectedRows)
  const mapDialogRowsWithId = useSelector(
    state => state.bulkEdit.mapDialogRowsWithId
  )
  const successfulRecords = useSelector(
    state => state.bulkEdit.successfulRecords
  )
  const errRecords = useSelector(state => state.bulkEdit.errRecords)
  const selectAllCurrentPage = useSelector(
    state => state.bulkEdit.selectAllCurrentPage
  )
  const enableSelect = useSelector(state => state.bulkEdit.enableSelect)
  const page = useSelector(state => state.bulkEdit.page)
  const rowsPerPage = useSelector(state => state.bulkEdit.rowsPerPage)

  // const [tableData, setTableData] = useState([])
  // const [disableBulkEditIcon, setDisableBulkEditIcon] = useState(true)
  // const [selectedRows, setSelectedRows] = useState([])
  const [open, setOpen] = React.useState(false)
  const [isSearchClicked, setIsSearchClicked] = useState(false)
  // const [mapSelectedRows, setMapSelectedRows] = useState({})
  // const [mapDialogRowsWithId, setMapDialogRowsWithId] = useState({})

  // const [successfulRecords, setSuccessfulRecords] = useState(new Set())
  // const [errRecords, setErrRecords] = useState(new Set())
  // const [fieldsSelected, setFieldsSelected] = useState([])
  // const [selectAllCurrentPage, setSelectAllCurrentPage] = useState(false)
  // const [enableSelect, setEnableSelect] = useState(0)
  // const [searchFields, setSearchFields] = useState({
  //   location_number: '',
  //   document_title: '',
  //   document_date: '',
  //   document_group: '',
  //   document_type: '',
  //   document_purpose: '',
  //   file_letter: '',
  //   file_letter_description: '',
  //   physical_file_number: '',
  // })
  const [documentTypeMultiple, setDocumentTypeMultiple] = useState([])

  // const allValues = [
  //   'LocationNumber',
  //   'DocumentGroup',
  //   'DocumentType',
  //   'DocumentTitle',
  //   'DocumentDate',
  //   'DocumentPurpose',
  //   'FileLetter',
  //   'FileLetterDescription',
  //   'PhysicalFileNumber',
  // ]

  const documentTitleRef = useRef(null)
  const documentDateRef = useRef(null)
  const documentGroupRef = useRef(null)
  const documentTypeRef = useRef(null)
  const documentPurposeRef = useRef(null)
  const fileLetterRef = useRef(null)
  const fileLetterDescriptionRef = useRef(null)
  const physicalFileNumberRef = useRef(null)

  // const [page, setPage] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(100)

  const authDetails = useSelector(state => state.common.authDetails)

  const history = useHistory()

  useEffect(() => {
    if (authDetails && Object.keys(authDetails).length > 0) {
      // eslint-disable-next-line no-prototype-builtins
      if (!authDetails.hasOwnProperty('access_feature_location')) {
        history.push('/unauthorised')
      } else if (
        !authDetails['access_feature_location'].includes('BULK EDIT')
      ) {
        history.push('/unauthorised')
      }
    }
  }, [authDetails, history])

  useEffect(() => {
    if (isSearchClicked) {
      let currentRef = materialTableRef.current
      if (currentRef) {
        currentRef.onChangeOrder('-1', '')
        currentRef.onChangePage({}, 0)
      }
      setIsSearchClicked(false)
    }
  }, [tableData, isSearchClicked, materialTableRef])

  const handlePageChange = pageNumber => {
    // setPage(pageNumber)
    dispatch({ type: 'SET_PAGE', payload: pageNumber })
  }

  const handleRowsPerPage = rowsPerPg => {
    // setSelectAllCurrentPage(false)
    dispatch({ type: 'SET_SELECT_ALL', payload: false })
    // setRowsPerPage(rowsPerPg)
    dispatch({ type: 'SET_PAGE_ROWS', payload: rowsPerPg })
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    let docGroupIndex = columns.findIndex(val => val.field === 'documentGroup')
    let docTypeIndex = columns.findIndex(val => val.field === 'documentType')

    const copyOfColumns = [...columns]
    copyOfColumns[docGroupIndex] = {
      title: 'DocumentGroup *',
      field: 'documentGroup',
      editComponent: props => (
        <NativeSelect
          value={props.value}
          onChange={e => {
            let copyOfRowdata = props.rowData
            copyOfRowdata['documentType'] = ''
            copyOfRowdata['documentGroup'] = e.target.value
            props.onRowDataChange(copyOfRowdata)
          }}
        >
          {documentGroupTypePayload &&
            Object.keys(documentGroupTypePayload).map(value => {
              return (
                <option value={value} key={value}>
                  {value}
                </option>
              )
            })}
        </NativeSelect>
      ),
    }

    copyOfColumns[docTypeIndex] = {
      title: 'DocumentType *',
      field: 'documentType',
      editComponent: props => (
        <NativeSelect
          value={props.value}
          onChange={e => props.onChange(e.target.value)}
        >
          {documentGroupTypePayload &&
            documentGroupTypePayload[props.rowData.documentGroup] &&
            documentGroupTypePayload[props.rowData.documentGroup].map(value => {
              return (
                <option value={value} key={value}>
                  {value}
                </option>
              )
            })}
          <option value={props.value} key={props.value}>
            {props.value}
          </option>
          )
        </NativeSelect>
      ),
    }

    setColumns([...copyOfColumns])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentGroupTypePayload])

  useEffect(() => {
    let copyOfSelectedRows = JSON.parse(JSON.stringify(selectedRows))
    let tempObj = {}
    // eslint-disable-next-line no-unused-vars
    let mappedRows = copyOfSelectedRows.map((row, index) => {
      tempObj[row.id] = index
      return row
    })

    // setMapDialogRowsWithId(tempObj)
    dispatch({ type: 'MAP_DIALOG_ROWS', payload: tempObj })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows])

  const handleSelectedRows = data => {
    // setSelectedRows(data)
    dispatch({ type: 'SAVE_SELECTED_ROWS', payload: data })
    if (data.length > 0) {
      let tempObj = {}

      // eslint-disable-next-line array-callback-return
      data.map(row => {
        tempObj[row.id] = row.tableData.id
      })
      // setMapSelectedRows(tempObj)
      dispatch({ type: 'MAP_SELECTED_ROWS', payload: tempObj })
    } else {
      // setDisableBulkEditIcon(true)
      // setMapSelectedRows({})
      dispatch({ type: 'MAP_SELECTED_ROWS', payload: {} })
    }
  }

  const updateMultiple = (event, val, props) => {
    let field = val.current.id
    let value = val.current.value
    let tempObj = {}

    if (field === 'documentDate') {
      let notValid = validateDate(value)
      // setdia
      if (notValid) {
        addToast('Please enter date in correct format.For e.g. 09/23/2020', {
          appearance: 'error',
          autoDismiss: true,
        })
        return
      }
    }

    let copyOfSelectedRows = JSON.parse(JSON.stringify(selectedRows))

    if (field === 'documentGroup') {
      if (value) {
        let mappedRows = copyOfSelectedRows.map(row => {
          row[field] = value
          row['documentType'] = ''
          tempObj[row.id] = row.tableData.id
          return row
        })
        setDocumentTypeMultiple(documentGroupTypePayload[value])
        // setSelectedRows(mappedRows)
        dispatch({ type: 'SAVE_SELECTED_ROWS', payload: mappedRows })

        // setMapDialogRowsWithId(tempObj)
        dispatch({ type: 'MAP_DIALOG_ROWS', payload: tempObj })
      }
    } else {
      if (value) {
        let mappedRows = copyOfSelectedRows.map(row => {
          row[field] = value
          tempObj[row.id] = row.tableData.id
          return row
        })

        // setSelectedRows(mappedRows)
        dispatch({ type: 'SAVE_SELECTED_ROWS', payload: mappedRows })

        // setMapDialogRowsWithId(tempObj)
        dispatch({ type: 'MAP_DIALOG_ROWS', payload: tempObj })
      }
    }
  }

  // const handleFieldSelection = event => {
  //   setFieldsSelected(event.target.value)
  // }

  const searchWithFields = event => {
    // setSuccessfulRecords(new Set())
    dispatch({ type: 'SET_SUCCESSFUL_RECORDS', payload: new Set() })
    // setErrRecords(new Set())
    dispatch({ type: 'SET_ERR_RECORDS', payload: new Set() })

    // setSelectAllCurrentPage(false)
    dispatch({ type: 'SET_SELECT_ALL', payload: false })

    // setSelectedRows([])
    dispatch({ type: 'SAVE_SELECTED_ROWS', payload: [] })

    // setPage(0)

    let stateSet = new Set(Object.values(searchFields))
    if (stateSet.size === 1 && stateSet.has('')) {
      addToast('Please enter value for selected fields', {
        appearance: 'warning',
        autoDismiss: true,
      })
      return
    }
    setIsLoading(true)
    let payload = {}
    if (searchFields.document_group === '') {
      payload['document_group'] = Object.keys(documentGroupTypePayload)
      payload['document_type'] = Array.prototype.concat.apply(
        [],
        Object.values(documentGroupTypePayload)
      )
    } else {
      if (searchFields.document_type === '') {
        payload['document_type'] =
          documentGroupTypePayload[searchFields.document_group]
        payload['document_group'] = [searchFields.document_group]
      } else {
        payload['document_type'] = [searchFields.document_type]
        payload['document_group'] = [searchFields.document_group]
      }
    }
    payload = { ...searchFields, ...payload }
    let url = apiConfig.apiUrl.bulkEditSearch
    axios
      .post(url, payload)
      .then(response => {
        setIsLoading(false)
        if (response.status === 200) {
          if (response.data.length > 0) {
            // setTableData(response.data)
            dispatch({ type: 'SAVE_TABLE_DATA', payload: response.data })
            setIsSearchClicked(true)
            //dispatch({ type: 'SET_PAGE', payload: 0 })
          } else {
            addToast('No records found', {
              appearance: 'error',
              autoDismiss: true,
            })
            // setTableData([])
            dispatch({ type: 'SAVE_TABLE_DATA', payload: [] })
          }
        } else throw new Error('Bulk Edit search api error')
      })
      .catch(err => {
        setIsLoading(false)
        addToast('Error! Could not reach the server', {
          appearance: 'error',
          autoDismiss: true,
        })
        console.log(err)
      })
  }

  const selectRowsInCurrentPage = (event, props) => {
    // setSelectAllCurrentPage(event.target.checked)
    dispatch({ type: 'SET_SELECT_ALL', payload: event.target.checked })

    let mapSelectedRowsIndex = {}
    const copyOfSelectedRows = selectedRows.map((row, index) => {
      mapSelectedRowsIndex[row.id] = index
      return row
    })
    const dataUpdate = [...tableData]
    //to take sorted data instead of original data
    const selectedRowsBySelectAll = [...props.data]

    const startingPoint = page * rowsPerPage
    const endPoint = startingPoint + rowsPerPage

    const toBeSelectedRows = selectedRowsBySelectAll.slice(
      startingPoint,
      endPoint
    )
    // props.onSelectionChange(toBeSelectedRows)

    let modifiedArr = []

    if (event.target.checked) {
      // setEnableSelect(page)
      dispatch({ type: 'ENABLE_SELECT', payload: page }) //set page number where select all was clicked
      modifiedArr = toBeSelectedRows.filter(row => {
        let modifieldTableData = { id: row.tableData.id, checked: true }
        let modifiedRow = Object.assign({}, row)
        modifiedRow.tableData = modifieldTableData
        dataUpdate[row.tableData.id] = modifiedRow

        // eslint-disable-next-line no-prototype-builtins
        return !row.tableData.hasOwnProperty('checked')
        // return modifiedRow
      })
      let combinedArray = [...copyOfSelectedRows, ...modifiedArr]
      handleSelectedRows(combinedArray)
    } else {
      let removeIndex = []
      modifiedArr = toBeSelectedRows.map(row => {
        let modifieldTableData = { id: row.tableData.id }
        let modifiedRow = row
        modifiedRow.tableData = modifieldTableData
        dataUpdate[row.tableData.id] = modifiedRow
        removeIndex.push(mapSelectedRowsIndex[row.id])
        return modifiedRow
      })
      for (let i = removeIndex.length - 1; i >= 0; i--) {
        copyOfSelectedRows.splice(removeIndex[i], 1)
      }
      handleSelectedRows(copyOfSelectedRows)
    }
    // setTableData([...dataUpdate])
    dispatch({ type: 'SAVE_TABLE_DATA', payload: [...dataUpdate] })
  }

  const uncheckSelectedRows = data => {
    const dataUpdate = [...tableData]

    data.map(row => {
      let modifieldTableData = { id: row.tableData.id }
      let modifiedRow = row
      modifiedRow.tableData = modifieldTableData
      dataUpdate[row.tableData.id] = modifiedRow
      return modifiedRow
    })
    // setSelectedRows([])
    dispatch({ type: 'SAVE_SELECTED_ROWS', payload: [] })

    // setTableData(dataUpdate)
    dispatch({ type: 'SAVE_TABLE_DATA', payload: dataUpdate })
  }

  const handleConfirm = e => {
    let valid = true
    let copyOfSelectedRows = selectedRows.map(row => {
      let validRow = validateRow(row)
      if (!validRow) {
        valid = false
        // return
      }
      return makeSnakePayload(row, session)
    })
    if (!valid) {
      addToast('Please fill in value for required fields', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }
    setIsDialogLoading(true)
    const dataUpdate = [...tableData]

    let url = apiConfig.apiUrl.bulkEditUpdate

    let createPromises = copyOfSelectedRows.map(item => {
      let request = axios.post(url, item)
      return request
    })

    let tempSet = new Set(successfulRecords)

    let tempSetErr = new Set(errRecords)
    axios
      .all(createPromises)
      .then(
        axios.spread((...responses) => {
          // eslint-disable-next-line array-callback-return
          responses.map(eachResponse => {
            if (eachResponse.status === 200) {
              let responseId = eachResponse.data.id

              let index = mapSelectedRows[responseId]
              dataUpdate[index] = selectedRows[mapDialogRowsWithId[responseId]]
              tempSet.add(index)
            } else {
              console.log(eachResponse)
            }
          })

          // setTableData([...dataUpdate])
          dispatch({ type: 'SAVE_TABLE_DATA', payload: [...dataUpdate] })

          setIsDialogLoading(false)
          // setSuccessfulRecords(tempSet)
          dispatch({ type: 'SET_SUCCESSFUL_RECORDS', payload: tempSet })

          // setErrRecords(tempSetErr)
          dispatch({ type: 'SET_ERR_RECORDS', payload: tempSetErr })

          // setSelectAllCurrentPage(false)
          dispatch({ type: 'SET_SELECT_ALL', payload: false })

          // setSelectedRows([])
          dispatch({ type: 'SAVE_SELECTED_ROWS', payload: [] })

          handleClose()
        })
      )
      .catch(errors => {
        console.log(errors)
        setIsDialogLoading(false)
      })
  }

  // const handleAllSelect = event => {
  //   setSelectAll(event.target.checked)
  //   if (event.target.checked) {
  //     setFieldsSelected(allValues)
  //   } else {
  //     setFieldsSelected([])
  //   }
  // }

  const updateRecord = (oldDataIndex, newData) => {
    let url = apiConfig.apiUrl.bulkEditUpdate

    setIsLoading(true)
    const dataUpdate = [...tableData]
    delete newData[tableData]
    let payload = makeSnakePayload(newData, session)

    axios
      .post(url, payload)
      .then(response => {
        if (response.status === 200) {
          dataUpdate[oldDataIndex] = newData
          // setTableData([...dataUpdate])
          dispatch({ type: 'SAVE_TABLE_DATA', payload: [...dataUpdate] })

          let tempSet = new Set(successfulRecords)
          tempSet.add(oldDataIndex)
          // setSuccessfulRecords(tempSet)
          dispatch({ type: 'SET_SUCCESSFUL_RECORDS', payload: tempSet })
        } else throw new Error('Unable to update record in BulkEdit')
      })
      .catch(err => {
        console.log(err)
        let tempSet = new Set(errRecords)
        tempSet.add(oldDataIndex)
        // setErrRecords(tempSet)
        dispatch({ type: 'SET_ERR_RECORDS', payload: tempSet })

        setIsLoading(false)
      })
  }

  const handleOrderChange = () => {
    if (selectAllCurrentPage) {
      // setSelectAllCurrentPage(false)
      dispatch({ type: 'SET_SELECT_ALL', payload: false })

      uncheckSelectedRows(selectedRows)
      addToast(
        'Please do not sort after checking Select-All.Clearing selected rows...',
        {
          appearance: 'error',
        }
      )
    }
  }

  const settingSearchFields = payload => {
    dispatch({ type: 'SET_SEARCH_FIELDS', payload: payload })
  }
  return (
    <div className={classes.root}>
      <Paper>
        {/* <div className={classes.searchArea}></div> */}

        <FormFields
          classes={classes}
          // fieldsSelected={fieldsSelected}
          documentGroupTypePayload={documentGroupTypePayload}
          searchFields={searchFields}
          setSearchFields={settingSearchFields}
          dateError={dateError}
          setDateError={setDateError}
          searchWithFields={searchWithFields}
        />
      </Paper>
      <br />
      {tableData && tableData.length > 0 && (
        <div className="fixed_table">
          <MaterialTable
            title=""
            options={{
              search: false,
              toolbar: true,
              selection: true,
              paging: true,
              // filtering: true,

              pageSize: rowsPerPage,
              initialPage: page,
              pageSizeOptions: [10, 25, 50, 100],
              loadingType: 'overlay',
              // style: { textAlign: 'center' },
              // doubleHorizontalScroll: true,
              sorting: true,
              rowStyle: rowData => ({
                border: successfulRecords.has(rowData.tableData.id)
                  ? '2px solid #4caf50'
                  : errRecords.has(rowData.tableData.id)
                  ? '2px solid #f44336'
                  : '#FFF',
              }),
              showSelectAllCheckbox: false,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
            }}
            tableRef={materialTableRef}
            onOrderChange={handleOrderChange}
            onChangePage={handlePageChange}
            icons={tableIcons}
            columns={columns}
            data={tableData}
            onChangeRowsPerPage={handleRowsPerPage}
            // onSelectionChange={(rows,props) => console.log(props)}

            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  let valid = validateRow(newData)
                  let validDate = validateDateWorkflow(newData.documentDate)

                  if (!valid) {
                    addToast('Please fill in value for required fields', {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                    reject()
                  } else if (!validDate) {
                    addToast(
                      'Please enter date in correct format(mm/dd/yyyy).For e.g. 09/23/2020',
                      {
                        appearance: 'error',
                        autoDismiss: true,
                      }
                    )
                    reject()
                  } else updateRecord(oldData.tableData.id, newData)
                  setIsLoading(false)
                  resolve()
                  // }, 1000)
                }),
            }}
            isLoading={isLoading}
            onSelectionChange={data => handleSelectedRows(data)}
            components={{
              Toolbar: props => (
                <div style={{ padding: '8px' }}>
                  {/* <MTableToolbar {...props} /> */}
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div style={{ marginLeft: 'auto' }}></div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectAllCurrentPage}
                          onChange={event => {
                            selectRowsInCurrentPage(event, props)
                          }}
                          disabled={
                            selectAllCurrentPage && enableSelect !== page
                          }
                        />
                      }
                      label="Select all in current page"
                      className={classes.selectAll}
                    />

                    <div>
                      {' '}
                      <Button
                        onClick={event => {
                          if (selectedRows.length > 100) {
                            addToast(
                              'Error!You cannot edit more than 100 records at a time',
                              {
                                appearance: 'error',
                                autoDismiss: true,
                              }
                            )
                          } else setOpen(true)
                        }}
                        color="primary"
                        disabled={selectedRows.length <= 1}
                      >
                        <Tooltip title="Edit Multiple Records">
                          <BorderColorIcon />
                        </Tooltip>
                      </Button>
                    </div>
                  </div>
                </div>
              ),
            }}
            localization={{
              body: {
                emptyDataSourceMessage: '',
              },
            }}
          />
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">Columns Edit</DialogTitle>
        <DialogContent>
          <div className="fixed_dialog_table">
            <MaterialTable
              title=""
              options={{
                toolbar: false,

                pageSize: 100,
                // initialPage: page,
                pageSizeOptions: [10, 25, 50],
                loadingType: 'overlay',
                style: { textAlign: 'center' },
                sorting: false,
                emptyRowsWhenPaging: false,
                // sorting: true,
              }}
              icons={tableIcons}
              columns={dialogColumns}
              isLoading={isDialogLoading}
              data={selectedRows}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    const copyOfSelectedRows = JSON.parse(
                      JSON.stringify(selectedRows)
                    )
                    const index = oldData.tableData.id
                    copyOfSelectedRows[index] = newData
                    // setSelectedRows(copyOfSelectedRows)
                    dispatch({
                      type: 'SAVE_SELECTED_ROWS',
                      payload: copyOfSelectedRows,
                    })

                    resolve()
                  }),
              }}
              components={{
                Header: props => (
                  <TableHead>
                    <TableRow key="editColumn">
                      <TableCell>Edit</TableCell>
                      <TableCell>LocationNumber</TableCell>

                      <TableCell style={{ paddingBottom: '8px' }}>
                        <div>DocumentGroup *</div>
                        <div style={{ marginBottom: '8px' }}></div>
                        <div style={{ display: 'flex' }}>
                          <FormControl className={classes.dialogDropdown}>
                            <NativeSelect
                              id="documentGroup"
                              margin="normal"
                              inputRef={documentGroupRef}
                              required
                            >
                              <option value="" key="empty"></option>
                              {documentGroupTypePayload &&
                                Object.keys(documentGroupTypePayload).map(
                                  value => {
                                    return (
                                      <option value={value} key={value}>
                                        {value}
                                      </option>
                                    )
                                  }
                                )}
                            </NativeSelect>
                          </FormControl>
                          <IconButton
                            size="small"
                            onClick={e => updateMultiple(e, documentGroupRef)}
                          >
                            <DoneAllIcon color="primary" />
                          </IconButton>
                        </div>
                      </TableCell>
                      <TableCell style={{ paddingBottom: '8px' }}>
                        <div>DocumentType *</div>
                        <div style={{ marginBottom: '8px' }}></div>
                        <div style={{ display: 'flex' }}>
                          <FormControl className={classes.dialogDropdown}>
                            <NativeSelect
                              id="documentType"
                              margin="normal"
                              inputRef={documentTypeRef}
                              required
                            >
                              {documentTypeMultiple &&
                                documentTypeMultiple.length > 0 &&
                                documentTypeMultiple.map(value => {
                                  return (
                                    <option value={value} key={value}>
                                      {value}
                                    </option>
                                  )
                                })}
                            </NativeSelect>
                          </FormControl>
                          <IconButton
                            size="small"
                            onClick={e => updateMultiple(e, documentTypeRef)}
                          >
                            <DoneAllIcon color="primary" />
                          </IconButton>
                        </div>
                      </TableCell>
                      <TableCell key="docTitleCell">
                        DocumentTitle
                        <div style={{ display: 'flex' }}>
                          <TextField
                            id="documentTitle"
                            type={String}
                            margin="normal"
                            inputRef={documentTitleRef}
                          />
                          <IconButton
                            size="small"
                            onClick={e =>
                              updateMultiple(e, documentTitleRef, props)
                            }
                          >
                            <DoneAllIcon color="primary" />
                          </IconButton>
                        </div>
                      </TableCell>
                      <TableCell>
                        DocumentDate
                        <div style={{ display: 'flex' }}>
                          <TextField
                            id="documentDate"
                            margin="normal"
                            inputRef={documentDateRef}
                          />
                          <IconButton
                            size="small"
                            onClick={e => updateMultiple(e, documentDateRef)}
                            // disabled={dialogDateError}
                          >
                            <DoneAllIcon color="primary" />
                          </IconButton>
                        </div>
                      </TableCell>
                      <TableCell>
                        DocumentPurpose
                        <div style={{ display: 'flex' }}>
                          <TextField
                            id="documentPurpose"
                            margin="normal"
                            inputRef={documentPurposeRef}
                          />
                          <IconButton
                            size="small"
                            onClick={e => updateMultiple(e, documentPurposeRef)}
                          >
                            <DoneAllIcon color="primary" />
                          </IconButton>
                        </div>
                      </TableCell>
                      <TableCell>
                        FileLetter
                        <div style={{ display: 'flex' }}>
                          <TextField
                            id="fileLetter"
                            margin="normal"
                            inputRef={fileLetterRef}
                          />
                          <IconButton
                            size="small"
                            onClick={e => updateMultiple(e, fileLetterRef)}
                          >
                            <DoneAllIcon color="primary" />
                          </IconButton>
                        </div>
                      </TableCell>
                      <TableCell>
                        FileLetterDescription
                        <div style={{ display: 'flex' }}>
                          <TextField
                            id="fileLetterDescription"
                            margin="normal"
                            inputRef={fileLetterDescriptionRef}
                          />
                          <IconButton
                            size="small"
                            onClick={e =>
                              updateMultiple(e, fileLetterDescriptionRef)
                            }
                          >
                            <DoneAllIcon color="primary" />
                          </IconButton>
                        </div>
                      </TableCell>
                      <TableCell>
                        PhysicalFileNumber
                        <div style={{ display: 'flex' }}>
                          <TextField
                            id="physicalFileNumber"
                            margin="normal"
                            inputRef={physicalFileNumberRef}
                            type="number"
                          />
                          <IconButton
                            size="small"
                            onClick={e =>
                              updateMultiple(e, physicalFileNumberRef)
                            }
                          >
                            <DoneAllIcon color="primary" />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                ),
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: '',
                },
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" onClick={handleConfirm} color="primary">
            Confirm Changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default BulkEdit
