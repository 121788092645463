/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth } from '@praxis/component-auth'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
  root: {
    fontSize: '17px',
  },
  welcome: {
    float: 'left',
    paddingTop: '5px',
  },
})

export default function Login(props) {
  const classes = useStyles()

  const auth = useAuth()
  const { isAuthorized, login, logout, session } = auth
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isAuthorized()) {
      localStorage.removeItem('authDetails')
      dispatch({ type: 'USER_LOGOUT' })
    }
  }, [isAuthorized()])

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.welcome}>
          {isAuthorized() ? ` Welcome ${session.userInfo.firstName}` : ``}
        </div>
        <Button
          style={{ color: 'white' }}
          onClick={isAuthorized() ? logout : login}
        >
          {isAuthorized() ? <ExitToAppIcon /> : 'login'}
        </Button>
      </div>
      {/* </AuthProvider> */}
    </div>
  )
}
