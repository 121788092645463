/* eslint-disable no-prototype-builtins */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useEffect } from 'react'
// import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import axios from 'axios'
import NativeSelect from '@material-ui/core/NativeSelect'
import RefreshIcon from '@material-ui/icons/Refresh'
import { useDispatch, useSelector } from 'react-redux'
import './WorkfloStyle.css'
import '../search/MetaDataStyle.css'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
// import { WorkflowData } from './workflowData'
// import { TabContainer } from 'react-bootstrap'
import { WorkflowData } from './workflowData'
import apiConfig from '../../config/apiConfig'
// import FileViewer from 'react-file-viewer'
import { getContentTypeWorkflowDropdown } from '../common/validation'
import { workflowMain, BootstrapInput } from '../common/styles'
import { TableColumns } from './workflowTableMapping'
import TextField from '@material-ui/core/TextField'
import { returnMetadataSearchFields } from '../common/returnMetadataSearchFields'
import MaterialTable from 'material-table'
import {
  tableIcons,
  getFieldValueWorkflow,
} from '../common/commonFunctionalities'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { useAuth } from '@praxis/component-auth'
import MetaData from '../search/MetaData'
import CheckFile from '../search/CheckFile'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import NearMeIcon from '@material-ui/icons/NearMe'
import { SupportingDocuments } from '../search/supportingDocuments'
import Box from '@material-ui/core/Box'
import { CsvBuilder } from 'filefy'
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned'
import { ExportDialog } from '../common/exportDialog'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import { CustomFileViewer } from '../common/customFileViewer'
import { useToasts } from 'react-toast-notifications'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Tooltip from '@material-ui/core/Tooltip'
import {
  TabPanel,
  a11yProps,
  replaceUrl,
} from '../common/commonFunctionalities'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import SendMail from '../email/sendMail'
import { useHistory } from 'react-router-dom'

export const Workflow = () => {
  // const openedTabs = useSelector(state => state.index.openedTabsIndex)
  const workflow = useSelector(state => state.workflow)
  const adgroups = useSelector(state => state.common)
  const authDetailsData = adgroups.authDetails

  const [workflowList, setWorkflowList] = useState([])
  const [contentType, setContentType] = useState(null)
  const [mappedContent, setMappedContent] = useState(null)
  const [loadIndexForm, setLoadIndexForm] = useState(new Set())
  const [bucketNamesSelected, setBucketNamesSelected] = useState([])
  const iframeTriger = useSelector(state => state.common.triggerIframeRerender)
  //export functionality state
  const [selectedColumns, setSelectedColumns] = useState([])
  const [tableDataToBeExported, setTableDataToBeExported] = useState(null)
  const [filename, setFilename] = useState('')
  const [exportSelectAll, setExportSelectAll] = useState(true)
  //......
  const [isLoading, setIsLoading] = useState([true])
  const [isUrgentCount, setIsUrgentCount] = useState(0)
  const [unAssignedCount, setUnAssignedCount] = useState(0)
  const [inProgressCount, setInProgreeCount] = useState(0)
  const [notStartedCount, setNotStartedCount] = useState(0)
  const [pendingInfoInternal, setPendingInfoInternal] = useState(0)
  const [pendingInfoOperator, setPendingInfoOperator] = useState(0)
  const [completeApproved, setCompleteApproved] = useState(0)
  const [completeDenied, setCompleteDenied] = useState(0)
  const { addToast } = useToasts()
  const materialTableRef = React.createRef()

  const auth = useAuth()
  const { session } = auth

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const dispatch = useDispatch()

  const classes = workflowMain()
  const history = useHistory()

  React.useEffect(() => {
    let splitBucketName
    let authDetails = adgroups.authDetails
    let ctType

    if (authDetails && Object.keys(authDetails).length > 0) {
      // eslint-disable-next-line no-prototype-builtins
      if (!authDetails.hasOwnProperty('access_feature_workflow')) {
        history.push('/unauthorised')
        return
      }
    }

    if (Object.keys(adgroups.authDetails).length !== 0) {
      ctType = getContentTypeWorkflowDropdown(authDetails)
      setMappedContent(ctType)
    }
    if (workflow.bucketName.length !== 0) {
      splitBucketName = workflow.bucketName
      setContentType(workflow.contentType)
      setBucketNamesSelected(splitBucketName)

      fetchWorkflowsForBucket(splitBucketName, workflow.contentType)
    } else if (Object.keys(adgroups.authDetails).length !== 0) {
      let allKeys = Object.keys(ctType)
      setContentType(allKeys[0])
      let bucketListData

      if (authDetails.hasOwnProperty('access_feature_workflow')) {
        bucketListData = ctType[allKeys[0]]

        dispatch({
          type: 'SAVE_BUCKET_LIST',
          payload: bucketListData,
        })
      }

      if (bucketListData) splitBucketName = [bucketListData[0]]
      dispatch({
        type: 'SAVE_CONTENT_TYPE',
        payload: allKeys[0],
      })
      dispatch({
        type: 'SAVE_CONTENT_TYPE_LIST',
        payload: allKeys,
      })

      setBucketNamesSelected(splitBucketName)
      fetchWorkflowsForBucket(splitBucketName, allKeys[0])
    }
  }, [adgroups.authDetails])

  React.useEffect(() => {
    if (adgroups.allAdgroupList) {
      // adGroupName=
      let adGroupSet = adgroups.allAdgroupList
      if (adGroupSet.has('APP-RDMP-WF-PA-REBILL-STAGE')) {
        workflowRouteTo('APP-RDMP-WF-PA-REBILL-STAGE')
      }
      if (adGroupSet.has('APP-RDMP-WF-PA-PROD')) {
        workflowRouteTo('APP-RDMP-WF-PA-PROD')
      }
    }
  }, [adgroups.allAdgroupList])

  //To reload document pane after a new document has been checked in
  useEffect(() => {
    if (iframeTriger !== '') {
      let tempFiles = [...workflow.openedWorkflowFiles]
      let tabIndex = tempFiles.findIndex(tab => {
        return tab.value === workflow.currentTabValue
      })
      let tempIframeHolder = [...workflow.openedDocumentViewer]
      // let tempMetada = Object.assign({}, searchMetadata)

      let newMetadata
      let copyOfMetadata = Object.assign({}, tempFiles[tabIndex].metaData)
      fetchBatchMetadataFields(
        copyOfMetadata.ContentType,
        copyOfMetadata.LocationId
      )
        .then(res => {
          newMetadata = returnMetadataSearchFields(res)
          tempFiles[tabIndex].metaData = newMetadata
          tempFiles[tabIndex].unmodifiedData = res
          tempFiles[tabIndex].fileName = newMetadata.FileName

          let newTabElement = createTabElement(
            res.documentId,
            newMetadata.FileName,
            newMetadata
          )
          tempIframeHolder[tabIndex] = newTabElement
          // tempMetada[]
          // tempFiles[tabIndex].value = newMetadata.FileName

          function dispatchEvents() {
            dispatch({
              type: 'SAVE_OPENED_WORKFLOW_FILES',
              payload: tempFiles,
            })

            dispatch({
              type: 'TRIGGER_IFRAME_COUNTER',
              payload: '',
            })

            dispatch({
              type: 'SAVE_OPENED_WORKFLOW_DOCUMENT_VIEWER',
              payload: tempIframeHolder,
            })
          }

          iframeTriger === 'reloadWithDelay'
            ? setTimeout(() => {
                dispatchEvents()
              }, 5000)
            : dispatchEvents()
        })
        .catch(err =>
          addToast('There was an error connecting to the server', {
            appearance: 'error',
            autoDismiss: true,
          })
        )
    }
    // else {
    //   dispatch({
    //     type: 'TRIGGER_IFRAME_COUNTER',
    //     payload: '',
    //   })
    // }
  }, [iframeTriger])

  const workflowRouteTo = adgroupName => {
    let url =
      apiConfig.apiUrl.adGroupFirtsPart +
      adgroupName +
      apiConfig.apiUrl.adGroupSecondPart
    axios
      .get(url)
      .then(response => {
        if (response.status === 200) {
          let responseList = response.data.display_name_list
          let namesList = Object.keys(responseList).sort()
          namesList = ['NA', ...namesList]
          dispatch({
            type: 'SAVE_ROUTE_TO_LIST',
            payload: namesList,
          })
        } else throw new Error('unable to get adgroup List')
      })
      .catch(err => console.log(err))
  }

  const removeFileFromView = (e, filename) => {
    let tempSet = new Set(loadIndexForm)
    tempSet.delete(filename)
    setLoadIndexForm(tempSet)
  }

  const copyUrl = async (e, fileUrl) => {
    let modifiedUrl = replaceUrl(fileUrl)

    try {
      await navigator.clipboard.writeText(modifiedUrl)
      addToast('File URL copied', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (err) {
      addToast('File URL could not be copied', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const createTabElement = (identifier, fileName, metaData) => {
    let fileExt = getFileExtensionType(fileName)

    //File URL authentication code oncontextmenu

    const tabelement = (
      <div id={identifier} key={identifier} type="file">
        <div key={identifier + new Date()}>
          {fileExt === 'pdf' ? (
            <div className="pdf">
              <SnackbarContent
                message="If you are having trouble viewing this document, download"
                action={
                  <Fragment>
                    <span style={{ textDecoration: 'underline' }}>
                      <a
                        download={fileName}
                        href={
                          apiConfig.apiUrl.getFileUrl +
                          metaData.tossFilePath +
                          '&download=yes'
                        }
                        onContextMenu={event => {
                          event.preventDefault()
                        }}
                      >
                        HERE
                      </a>
                    </span>
                    <span className={classes.copyColor}>&nbsp;or&nbsp;</span>
                    <Tooltip title="Copy Url">
                      <IconButton
                        size="small"
                        onClick={e =>
                          copyUrl(
                            e,
                            apiConfig.apiUrl.getFileUrl + metaData.tossFilePath
                          )
                        }
                      >
                        <FileCopyIcon
                          fontSize="small"
                          style={{ color: 'white' }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Fragment>
                }
              />
              <embed
                src={apiConfig.apiUrl.getFileUrl + metaData.tossFilePath}
                key={fileName}
                width="100%"
                height="100%"
                style={{ height: '90vh' }}
              />
            </div>
          ) : (
            <div className="doc" key={fileName}>
              <div>
                <SnackbarContent
                  message="If you are having trouble viewing this document, download"
                  action={
                    <Fragment>
                      <span style={{ textDecoration: 'underline' }}>
                        <a
                          download={fileName}
                          href={
                            apiConfig.apiUrl.getFileUrl +
                            metaData.tossFilePath +
                            '&download=yes'
                          }
                          onContextMenu={event => {
                            event.preventDefault()
                          }}
                        >
                          HERE
                        </a>
                      </span>
                      <span className={classes.copyColor}>&nbsp;or&nbsp;</span>
                      <Tooltip title="Copy Url">
                        <IconButton
                          size="small"
                          onClick={e =>
                            copyUrl(
                              e,
                              apiConfig.apiUrl.getFileUrl +
                                metaData.tossFilePath
                            )
                          }
                        >
                          <FileCopyIcon
                            fontSize="small"
                            style={{ color: 'white' }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Fragment>
                  }
                />
              </div>
              {fileExt === 'csv' || fileExt === 'xlsx' || fileExt === 'docx' ? (
                <CustomFileViewer
                  fileType={fileExt}
                  filePath={apiConfig.apiUrl.getFileUrl + metaData.tossFilePath}
                  onError={onError}
                  title={fileName}
                  key={fileName + iframeTriger}
                />
              ) : (
                <div>File type is not supported</div>
                // <FileViewer
                //   // fileType={getFileExtensionType(file.fileName)}
                //   // fileType={fileExt === 'csv' ? 'xlsx' : fileExt}
                //   fileType={fileExt}
                //   filePath={apiConfig.apiUrl.getFileUrl + metaData.tossFilePath}
                //   onError={onError}
                //   key={fileName + iframeTriger}
                // />
              )}
            </div>
          )}
        </div>
      </div>
    )
    return tabelement
  }

  function showMetaData(metaData) {
    if (metaData) {
      let metadata = JSON.parse(JSON.stringify(metaData))
      ;[
        'FileName',
        'tossFilePath',
        'CreatedDate',
        'CreatedBy',
        'ModifiedDate',
        'ModifiedBy',
        'LocationId',
        'FileUrl',
      ].forEach(prop => {
        delete metadata[prop]
      })
      let temp = Object.keys(metadata)
      return temp.map(tem => {
        if (tem !== 'tableData') {
          if (
            tem === 'TargetPrsBudgetAmount' ||
            tem === 'TargetPrsTotalRecAmount' ||
            tem === 'TotalDollarImpact'
          ) {
            return (
              <TextField
                id={`outlined-name-${tem}`}
                key={tem}
                label={tem}
                className={classes.centralize}
                value={
                  !metadata[tem] || metadata[tem] === undefined
                    ? '-'
                    : new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(metadata[tem])
                }
                margin="normal"
                variant="outlined"
              />
            )
          }
          return (
            <TextField
              id={`outlined-name-${metadata[tem]}`}
              key={tem}
              label={tem}
              className={classes.centralize}
              value={
                !metadata[tem] || metadata[tem] === undefined
                  ? '-'
                  : metadata[tem]
              }
              margin="normal"
              variant="outlined"
            />
          )
        }
        return null
      })
    }
  }

  const fetchBatchMetadataFields = (contentType, locationId) => {
    return new Promise((resolve, reject) => {
      let url = `${apiConfig.apiUrl.get_batch_metadata}${locationId}&contentType=${contentType}${apiConfig.apiUrl.keyUrl}`
      axios.get(url).then(res => {
        if (res.status === 200) return resolve(res.data)
        return reject('The server responded with the status  ' + res.status)
      })
    })
  }

  const fetchWorkflowsForBucket = (
    bucketName,
    savedContentType = contentType,
    changePage = true
  ) => {
    dispatch({
      type: 'SAVE_BUCKET_NAME',
      payload: bucketName,
    })
    dispatch({
      type: 'SAVE_CURRENT_TAB',
      payload: 0,
    })
    if (changePage) {
      handlePageChange(0) //on changing buckets,page should reset to 0 so that current count is displayed
      let currentRef = materialTableRef.current
      if (currentRef) currentRef.onChangePage({}, 0)
    }

    let sendBucketList = [...bucketName]

    //for handing Metadata Defect and Image defect selection
    if (contentType === 'Real Estate') {
      if (bucketName.includes('RE Metadata Defect')) {
        sendBucketList.push('RE DEFECT BOTH')
      }
      if (bucketName.includes('RE Image Defect')) {
        sendBucketList.push('RE IMAGE DEFECT')
      }
    }

    let url =
      apiConfig.apiUrl.get_workflows +
      `/${savedContentType}?buckets=${sendBucketList.join(',')}`
    axios
      .get(url)
      .then(res => {
        if (res.status === 200) {
          setIsLoading(false)
          let response = res.data
          // This is done so that currentBucket=RE-DEFECT-BOTH is replaced by specific bucket
          if (bucketName.length === 1) {
            if (
              bucketName[0] === 'RE Image Defect' ||
              bucketName[0] === 'RE Metadata Defect'
            ) {
              let splitBucket = bucketName[0].split(' ')
              response = response.map(item => {
                item['current_bucket'] = splitBucket.join('-').toUpperCase()
                return item
              })
            }
          } else if (
            bucketName.includes('RE Image Defect') &&
            bucketName.includes('RE Metadata Defect')
          ) {
            let splitBucket = 'RE Metadata Defect'.split(' ')

            response = response.map(item => {
              if (item['current_bucket'] === 'RE-DEFECT-BOTH') {
                // item['current_bucket'] = splitBucket.join('-').toUpperCase()
                item['task_bucket'] = splitBucket.join('-').toUpperCase()
              }
              return item
            })
          } else if (
            bucketName.includes('RE Image Defect') ||
            bucketName.includes('RE Metadata Defect')
          ) {
            let tempBucketname = bucketName.includes('RE Image Defect')
              ? 'RE-IMAGE-DEFECT'
              : 'RE-METADATA-DEFECT'
            // let splitBucket = 'RE Metadata Defect'.split(' ')

            response = response.map(item => {
              if (item['current_bucket'] === 'RE-DEFECT-BOTH') {
                item['current_bucket'] = tempBucketname
              }
              return item
            })
          }
          let isUrgent = 0
          let unassigned = 0
          let inProgress = 0
          let notStarted = 0
          let pendingInternal = 0
          let pendingOperator = 0
          let compApproved = 0
          let compDenied = 0
          if (contentType === 'Real Estate') {
            // eslint-disable-next-line array-callback-return
            response.map(content => {
              if (content.is_urgent === 'Yes') isUrgent++
              if (
                content.assigned_to === null ||
                content.assigned_to === '' ||
                content.assigned_to === 'NA'
              ) {
                unassigned++
              }
            })
            setIsUrgentCount(isUrgent)
          } else if (
            contentType === 'PA CAM Payable' ||
            contentType === 'PA CAM Rebill'
          ) {
            // eslint-disable-next-line array-callback-return
            response.map(content => {
              if (content.cam_status === 'In Progress') inProgress++
              if (content.cam_status === 'Not Started') notStarted++
              if (content.cam_status === 'Pending Information - Internal') {
                pendingInternal++
              }
              if (content.cam_status === 'Pending Information - Operator') {
                pendingOperator++
              }
              if (content.cam_status === 'Complete - Approved') {
                compApproved++
              }
              if (content.cam_status === 'Complete - Denied') {
                compDenied++
              }

              if (
                content.assigned_to === null ||
                content.assigned_to === '' ||
                content.assigned_to === 'NA'
              ) {
                unassigned++
              }
            })
            setInProgreeCount(inProgress)
            setNotStartedCount(notStarted)
            setPendingInfoInternal(pendingInternal)
            setPendingInfoOperator(pendingOperator)
            setCompleteApproved(compApproved)
            setCompleteDenied(compDenied)
          } else {
            // eslint-disable-next-line array-callback-return
            response.map(content => {
              if (
                content.assigned_to === null ||
                content.assigned_to === '' ||
                content.assigned_to === 'NA'
              ) {
                unassigned++
              }
            })
          }
          setUnAssignedCount(unassigned)
          setWorkflowList(response)
        } else throw new Error('Could not retrieve Workflows')
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }

  React.useEffect(() => {
    dispatch({
      type: 'SAVE_WORKFLOW_DATA',
      payload: workflowList,
    })
  }, [workflowList])

  // const handleChangePage = (_event, newPage) => {
  //   console.log(page)
  //   setPage(newPage)
  // }
  const handlePageChange = pageNumber => {
    // setCurrentPage(pageNumber)
    setPage(pageNumber)
    // let currentRef = materialTableRef.current
    // console.log(currentRef)
    // currentRef.onChangePage(event,pageNumber)
    dispatch({
      type: 'SAVE_CURRENT_PAGE',
      payload: pageNumber,
    })
  }

  const handleChangeRowsPerPage = pageSize => {
    setRowsPerPage(pageSize)
    setPage(0)
  }

  const handleTabChange = (event, newValue) => {
    if (
      event.target.tagName === 'path' ||
      (event.target.getAttribute('id') &&
        event.target.getAttribute('id').includes('delete'))
    ) {
      let tempTab = [...workflow.openedWorkflowTabs]
      let tempFiles = [...workflow.openedWorkflowFiles]
      let tempWorkflowTasks = Object.assign({}, workflow.workflowTasks)
      let tempWorkflowMetadata = Object.assign({}, workflowMetadata)
      let tempIframeHolder = [...workflow.openedDocumentViewer]

      let twoTabsClosed = false

      let fileToBeRemoved = tempFiles.findIndex(val => val.value === newValue)
      let fileData = tempFiles.find(val => val.value === newValue) //data of file to be removed,not necessarily current opened file
      let indexOfCurrentDocFile = tempFiles.findIndex(
        val => val.value === workflow.currentTabValue
      )

      if (
        fileData.type === 'workflow' &&
        tempFiles[fileToBeRemoved + 1] &&
        tempFiles[fileToBeRemoved + 1].type === 'file'
      ) {
        tempTab.splice(fileToBeRemoved, 2) //for closing workflow tab as well as document tab opened
        tempFiles.splice(fileToBeRemoved, 2)
        tempIframeHolder.splice(fileToBeRemoved, 2)

        twoTabsClosed = true
      } else {
        tempTab.splice(fileToBeRemoved, 1)
        tempFiles.splice(fileToBeRemoved, 1)
        tempIframeHolder.splice(fileToBeRemoved, 1)
      }
      delete tempWorkflowTasks[newValue]
      delete tempWorkflowMetadata[newValue]

      dispatch({
        type: 'SAVE_WORKFLOW_TASKS',
        payload: tempWorkflowTasks,
      })
      dispatch({
        type: 'SAVE_OPENED_WORKFLOW_TABS',
        payload: tempTab,
      })
      dispatch({
        type: 'SAVE_OPENED_WORKFLOW_FILES',
        payload: tempFiles,
      })
      dispatch({
        type: 'SAVE_WORKFLOW_METADATA',
        payload: tempWorkflowMetadata,
      })
      dispatch({
        type: 'SAVE_OPENED_WORKFLOW_DOCUMENT_VIEWER',
        payload: tempIframeHolder,
      })

      if (newValue === workflow.currentTabValue) {
        //for opening previous document after closing tab

        if (fileToBeRemoved - 1 < 0) {
          dispatch({
            type: 'SAVE_CURRENT_TAB',
            payload: 0,
          })
        } else {
          dispatch({
            type: 'SAVE_CURRENT_TAB',
            payload: tempFiles[fileToBeRemoved - 1].value,
          })
        }
      } else if (
        twoTabsClosed &&
        indexOfCurrentDocFile === fileToBeRemoved + 1
      ) {
        //for case when current tab was document tab and its corresponding workflow tab is closed

        if (indexOfCurrentDocFile - 2 < 0) {
          dispatch({
            type: 'SAVE_CURRENT_TAB',
            payload: 0,
          })
        } else {
          dispatch({
            type: 'SAVE_CURRENT_TAB',
            payload: tempFiles[indexOfCurrentDocFile - 2].value,
          })
        }
      }
      if (event.target.hasOwnProperty('hasSubmitted')) {
        //only for refrest on submit
        fetchWorkflowsForBucket(workflow.bucketName, contentType, false)
      }
    } else {
      dispatch({
        type: 'SAVE_CURRENT_TAB',
        payload: newValue,
      })
    }
  }

  const openTab = (rowData, type) => {
    if (workflow.openedWorkflowTabs.length === 10) {
      addToast('Warning!You can open maximum 10 tabs', {
        appearance: 'warning',
        autoDismiss: true,
      })
      return
    }
    if (type === 'workflow') {
      openFile(rowData, type)
    } else if (type === 'file') {
      let contentTypeToBeSent = rowData.content_type
      fetchBatchMetadataFields(contentTypeToBeSent, rowData.metadata_id)
        .then(res => {
          openFile(rowData, type, returnMetadataSearchFields(res), res)
        })
        .catch(err => console.log(err))
    }
  }

  const openFile = (rowData, type, metaData = {}, unmodifiedData = {}) => {
    let tempTab = [...workflow.openedWorkflowTabs]
    let tempFiles = [...workflow.openedWorkflowFiles]
    let tempWorkflowMetadata = Object.assign({}, workflowMetadata)
    let tempIframeHolder = [...workflow.openedDocumentViewer]

    let isTabOpen
    let tabContainerData
    // let receivedMetadata
    let displayDocumentTitle =
      rowData.document_title.length > 100
        ? rowData.document_title.slice(0, 99) + '...'
        : rowData.document_title

    if (type === 'workflow') {
      isTabOpen = tempFiles.find(tab => {
        return tab.value === rowData.workflow_id
      })

      tabContainerData = {
        value: rowData.workflow_id,
        type: type,
        displayName:
          'WF_' + rowData.location_number + '_' + displayDocumentTitle,
        fullTitle:
          'WF_' + rowData.location_number + '_' + rowData.document_title,
        ...rowData,
      }
    } else {
      isTabOpen = tempFiles.find(tab => {
        return tab.value === rowData.document_id
      })
      tabContainerData = {
        // value: openedTabs.files.length + 1,
        value: rowData.document_id,
        url: apiConfig.apiUrl.getFileURL + rowData.document_id,
        // fileName: rowData.document_id,
        fileName: metaData.FileName,
        type: type,
        filePath: rowData.file_path,
        displayName: rowData.location_number + '_' + displayDocumentTitle,
        fullTitle: rowData.location_number + '_' + rowData.document_title,
        unmodifiedData: unmodifiedData,
        metaData: metaData,
      }
    }
    const TabElement = (
      <Tab
        key={tabContainerData.value}
        value={tabContainerData.value}
        label={
          <div style={{ display: 'flex' }}>
            <Tooltip title={tabContainerData.fullTitle}>
              <Button color="primary"> {tabContainerData.displayName}</Button>
            </Tooltip>
            {/* <div> */}
            {/* {tabContainerData.displayName} */}
            <IconButton id={'delete' + tabContainerData.value}>
              <ClearIcon id={'delete' + tabContainerData.value} />
            </IconButton>
          </div>
        }
        {...a11yProps(tabContainerData.value)}
      />
    )

    if (isTabOpen === undefined) {
      //to open document right beside the corresponding workflow

      if (type === 'file') {
        let indexOfWorkflow = tempFiles.findIndex(tab => {
          return tab.value === rowData.workflow_id
        })
        tempTab.splice(indexOfWorkflow + 1, 0, TabElement)
        tempFiles.splice(indexOfWorkflow + 1, 0, tabContainerData)
        let tabPanelElement = createTabElement(
          tabContainerData.value,
          tabContainerData.fileName,
          metaData
        )
        tempIframeHolder.splice(indexOfWorkflow + 1, 0, tabPanelElement)
      } else {
        tempTab.push(TabElement)
        tempFiles.push(tabContainerData)
        const workflowTabPanel = (
          <div
            key={tabContainerData.value}
            id={tabContainerData.value}
            type="workflow"
          ></div>
        )
        tempIframeHolder.push(workflowTabPanel)
      }
      tempWorkflowMetadata[tabContainerData.value] = unmodifiedData

      // tempFiles.push(tabContainerData)

      dispatch({
        type: 'SAVE_OPENED_WORKFLOW_TABS',
        payload: tempTab,
      })
      dispatch({
        type: 'SAVE_OPENED_WORKFLOW_FILES',
        payload: tempFiles,
      })
      dispatch({
        type: 'SAVE_CURRENT_TAB',
        payload: tabContainerData.value,
      })
      dispatch({
        type: 'SAVE_OPENED_WORKFLOW_DOCUMENT_VIEWER',
        payload: tempIframeHolder,
      })

      dispatch({
        type: 'SAVE_WORKFLOW_METADATA',
        payload: tempWorkflowMetadata,
      })
    } else {
      dispatch({
        type: 'SAVE_CURRENT_TAB',
        payload: isTabOpen.value,
      })
    }
  }
  function onError(e) {
    console.log('error')
  }
  function getFileExtensionType(filename) {
    return filename.split('.').pop()
  }
  const [open, setOpen] = React.useState(false)
  const [copyOfWorkflowMetadata, setCopyOfWorkflowMetadata] = React.useState(
    null
  )
  const workflowMetadata = useSelector(state => state.workflow.workflowMetadata)

  //for re-rendering <Checkfile> when redux state changes
  React.useEffect(() => {
    setCopyOfWorkflowMetadata(workflowMetadata)
  }, [workflowMetadata])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getAccessForEditMetadata = (authDetailsData, file) => {
    if (file.metaData.ContentType === 'Real Estate') {
      if (
        adgroups.allAdgroupList.has('APP-RDMP-SCANINDEX-RE-STAGE') ||
        adgroups.allAdgroupList.has('APP-RDMP-SCANINDEX-RE-PROD')
      ) {
        return true
      } else return false
    }
    // return true
    // }
    else if (
      authDetailsData.access_feature_edit &&
      authDetailsData.access_feature_edit.indexOf('EDIT')
    ) {
      return true
    }
    return false
  }

  // function showFiles() {
  //   return (
  //     workflow.openedWorkflowFiles
  //       .filter(file => workflow.currentTabValue === file.value)
  //       // eslint-disable-next-line array-callback-return
  //       .map(file => {
  //         if (file.type === 'workflow') {
  //           return (
  //             <WorkflowData
  //               workflowInfo={file}
  //               openDocument={openTab}
  //               workflowReduxState={workflow}
  //               handleTabChange={handleTabChange}
  //               contentType={contentType}
  //             />
  //           )
  //         }
  //         if (file.type === 'file') {
  //           let fileExt = getFileExtensionType(file.filePath)
  //           return (
  //             <div className="outer-index-container">
  //               <Box
  //                 display="flex"
  //                 flexDirection="row"
  //                 p={1}
  //                 m={1}
  //                 bgcolor="background.paper"
  //                 justifyContent="flex-end"
  //               >
  //                 <Box p={1}>
  //                   <Button
  //                     color="primary"
  //                     variant="contained"
  //                     onClick={handleClickOpen}
  //                   >
  //                     Supporting Documents
  //                   </Button>
  //                   <SupportingDocuments
  //                     open={open}
  //                     handleClose={handleClose}
  //                     unmodifiedData={file.unmodifiedData}
  //                   />
  //                 </Box>
  //                 <Box p={1}>
  //                   {authDetailsData &&
  //                     getAccessForEditMetadata(authDetailsData, file) && (
  //                       <CheckFile
  //                         fileNameSearch={file.value}
  //                         unmodifiedData={file.unmodifiedData}
  //                         type="workflow"
  //                         metadata={copyOfWorkflowMetadata}
  //                       />
  //                     )}{' '}
  //                 </Box>
  //               </Box>
  //               <div
  //                 className="main-index-container"
  //                 id={file.value}
  //                 key={file.fileName + iframeTriger}
  //               >
  //                 {fileExt === 'pdf' ? (
  //                   <div className="pdf">
  //                     <SnackbarContent
  //                       message="If you are having trouble viewing this document, download"
  //                       action={
  //                         <a
  //                           download={file.fileName}
  //                           href={
  //                             apiConfig.apiUrl.getFileUrl +
  //                             file.filePath +
  //                             '&download=yes'
  //                           }
  //                         >
  //                           HERE
  //                         </a>
  //                       }
  //                     />
  //                     <embed
  //                       src={apiConfig.apiUrl.getFileUrl + file.filePath}
  //                       key={file.fileName + iframeTriger}
  //                       width="100%"
  //                       height="100%"
  //                       style={{ height: '90vh' }}
  //                     />
  //                   </div>
  //                 ) : (
  //                   <div className="doc" key={file.fileName + iframeTriger}>
  //                     {/* <FileViewer
  //                       fileType={getFileExtensionType(file.filePath)}
  //                       filePath={apiConfig.apiUrl.getFileUrl + file.filePath}
  //                       onError={onError}
  //                     /> */}
  //                     <div>
  //                       <SnackbarContent
  //                         message="If you are having trouble viewing this document, download"
  //                         action={
  //                           <a
  //                             download={file.fileName}
  //                             href={
  //                               apiConfig.apiUrl.getFileUrl +
  //                               file.filePath +
  //                               '&download=yes'
  //                             }
  //                           >
  //                             HERE
  //                           </a>
  //                         }
  //                       />
  //                     </div>
  //                     {fileExt === 'csv' ||
  //                     fileExt === 'xlsx' ||
  //                     fileExt === 'docx' ? (
  //                       <CustomFileViewer
  //                         fileType={fileExt}
  //                         filePath={apiConfig.apiUrl.getFileUrl + file.filePath}
  //                         onError={onError}
  //                         title={file.fileName}
  //                         key={file.fileName + iframeTriger}
  //                       />
  //                     ) : (
  //                       <FileViewer
  //                         // fileType={getFileExtensionType(file.fileName)}
  //                         // fileType={fileExt === 'csv' ? 'xlsx' : fileExt}
  //                         fileType={fileExt}
  //                         filePath={apiConfig.apiUrl.getFileUrl + file.filePath}
  //                         onError={onError}
  //                         key={file.fileName + iframeTriger}
  //                       />
  //                     )}
  //                   </div>
  //                 )}

  //                 {loadIndexForm.has(file.value) ? (
  //                   <div className="metadata-outer-container">
  //                     <MetaData
  //                       key={file.value}
  //                       setPendingDocumentCounter={``}
  //                       type="workflow"
  //                       disableLoadIndexForm={removeFileFromView}
  //                       metaData={file.metaData}
  //                       unmodifiedData={file.unmodifiedData}
  //                       accountName={session.userInfo.lanId}
  //                       fileNameSearch={file.value}
  //                     />
  //                   </div>
  //                 ) : (
  //                   <div className="metadata-outer-container">
  //                     <div className="metadata-edit-container">
  //                       <br />
  //                       <br />
  //                       {authDetailsData &&
  //                         getAccessForEditMetadata(authDetailsData, file) && (
  //                           <div className="metadata-edit-submit">
  //                             <Button
  //                               style={{ width: '200px' }}
  //                               variant="contained"
  //                               className={classes.button}
  //                               onClick={() => {
  //                                 // setLoadIndexForm(true)
  //                                 let setOfFiles = new Set(loadIndexForm)
  //                                 setOfFiles.add(file.value)
  //                                 setLoadIndexForm(setOfFiles)
  //                               }}
  //                               // eslint-disable-next-line react/jsx-no-duplicate-props
  //                               className="edit-button"
  //                               id={file.fileName}
  //                             >
  //                               Edit Metadata
  //                             </Button>
  //                           </div>
  //                         )}
  //                       {showMetaData(file.metaData)}
  //                     </div>
  //                   </div>
  //                 )}
  //               </div>
  //             </div>
  //           )
  //         }
  //       })
  //   )
  // }

  function showWorkflowFiles() {
    return (
      workflow.openedWorkflowFiles
        .filter(file => workflow.currentTabValue === file.value)
        // eslint-disable-next-line array-callback-return
        .map(file => {
          if (file.type === 'workflow') {
            return (
              <WorkflowData
                workflowInfo={file}
                openDocument={openTab}
                workflowReduxState={workflow}
                handleTabChange={handleTabChange}
                contentType={contentType}
              />
            )
          }
        })
    )
  }

  function showFiles() {
    let selectedFile = workflow.openedWorkflowFiles.filter(
      file => workflow.currentTabValue === file.value
    )

    return (
      // .map(file => {
      workflow.openedDocumentViewer &&
      // eslint-disable-next-line array-callback-return
      workflow.openedDocumentViewer.map(file => {
        // if (selectedFile && selectedFile.length>0 && selectedFile[0].type === 'workflow') {
        //   return (
        //     <TabPanel index={file.key} value={workflow.currentTabValue}>
        //       {selectedFile && selectedFile.length > 0 && (
        //         <WorkflowData
        //           workflowInfo={selectedFile[0]}
        //           openDocument={openTab}
        //           workflowReduxState={workflow}
        //           handleTabChange={handleTabChange}
        //           contentType={contentType}
        //         />
        //       )}
        //     </TabPanel>
        //   )
        // }
        if (file.props.type === 'file') {
          return (
            <TabPanel index={file.key} value={workflow.currentTabValue}>
              <div className="outer-index-container">
                {selectedFile &&
                  selectedFile.length > 0 &&
                  selectedFile[0].type === 'file' && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      p={1}
                      m={1}
                      bgcolor="background.paper"
                      justifyContent="flex-end"
                    >
                      <Box p={1}>
                        <SendMail
                          fileUrl={
                            apiConfig.apiUrl.getFileDownloadUrl +
                            selectedFile[0].metaData.tossFilePath
                          }
                          fileName={
                            selectedFile[0].metaData.LocationNumber +
                            '_' +
                            selectedFile[0].metaData.DocumentTitle
                          }
                          sessionData={session.userInfo}
                          filePathData={selectedFile[0].metaData.tossFilePath}
                        />
                      </Box>
                      <Box p={1}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleClickOpen}
                        >
                          Supporting Documents
                        </Button>
                        <SupportingDocuments
                          currentTab={file.key}
                          fileValue={selectedFile[0].value}
                          open={open}
                          handleClose={handleClose}
                          unmodifiedData={selectedFile[0].unmodifiedData}
                        />
                      </Box>
                      <Box p={1}>
                        {authDetailsData &&
                          getAccessForEditMetadata(
                            authDetailsData,
                            selectedFile[0]
                          ) && (
                            <CheckFile
                              currentTab={file.key}
                              fileNameSearch={selectedFile[0].value}
                              unmodifiedData={selectedFile[0].unmodifiedData}
                              type="workflow"
                              metadata={copyOfWorkflowMetadata}
                            />
                          )}{' '}
                      </Box>
                    </Box>
                  )}
                <div
                  className="main-index-container"
                  // id={file.value}
                  // key={file.fileName + iframeTriger}
                >
                  <Fragment>{file}</Fragment>
                  {selectedFile &&
                    selectedFile.length > 0 &&
                    selectedFile[0].type === 'file' && (
                      <Fragment>
                        {loadIndexForm.has(selectedFile[0].value) ? (
                          <div className="metadata-outer-container">
                            <MetaData
                              key={selectedFile[0].value}
                              setPendingDocumentCounter={``}
                              type="workflow"
                              disableLoadIndexForm={removeFileFromView}
                              metaData={selectedFile[0].metaData}
                              unmodifiedData={selectedFile[0].unmodifiedData}
                              accountName={session.userInfo.lanId}
                              fileNameSearch={selectedFile[0].value}
                            />
                          </div>
                        ) : (
                          <div className="metadata-outer-container">
                            <div className="metadata-edit-container">
                              <br />
                              <br />
                              {authDetailsData &&
                                getAccessForEditMetadata(
                                  authDetailsData,
                                  selectedFile[0]
                                ) && (
                                  <div className="metadata-edit-submit">
                                    <Button
                                      style={{ width: '200px' }}
                                      variant="contained"
                                      className={classes.button}
                                      onClick={() => {
                                        // setLoadIndexForm(true)
                                        let setOfFiles = new Set(loadIndexForm)
                                        setOfFiles.add(selectedFile[0].value)
                                        setLoadIndexForm(setOfFiles)
                                      }}
                                      // eslint-disable-next-line react/jsx-no-duplicate-props
                                      className="edit-button"
                                      id={selectedFile[0].fileName}
                                    >
                                      Edit Metadata
                                    </Button>
                                  </div>
                                )}
                              {showMetaData(selectedFile[0].metaData)}
                            </div>
                          </div>
                        )}
                      </Fragment>
                    )}
                </div>
              </div>
            </TabPanel>
          )
        }
      })
    )
  }

  const handleContentTypeChanges = value => {
    setContentType(value)
    dispatch({
      type: 'SAVE_CONTENT_TYPE',
      payload: value,
    })

    //now useeffect to be called so that correct state is taken for making api call for fetchWorkflows
  }

  // This useEffect has been added to be called only after contentType state is updated with latest value
  React.useEffect(() => {
    if (contentType) {
      if (typeof mappedContent[contentType] !== 'undefined') {
        dispatch({
          type: 'SAVE_BUCKET_LIST',
          payload: mappedContent[contentType],
        })

        let bucketListSet = new Set(mappedContent[contentType])

        //this is done to load correct value when switching between screens
        if (
          workflow.bucketName.length > 0 &&
          bucketListSet.has(workflow.bucketName[0])
        ) {
          setBucketNamesSelected(workflow.bucketName)
          fetchWorkflowsForBucket(workflow.bucketName)
          handleOrderChange(workflow.wfOrderBy, workflow.wfOrderDirection)
        } else {
          //to reset order because columns change on changing content type
          contentType === 'Real Estate'
            ? handleOrderChange(3, 'desc')
            : handleOrderChange(0, 'desc')

          let bucket = mappedContent[contentType][0]
          setBucketNamesSelected([bucket])

          fetchWorkflowsForBucket([bucket])
          // ,contentType,true,true)
        }
      }

      let tempList = TableColumns[contentType]
      // tempList = tempList.map(item => item.title)
      let filteredList = tempList.filter(column => !column.hidden)
      setSelectedColumns(filteredList)
      setFilename(`WF-${contentType}-Report`)
    }
  }, [contentType])

  const handleBucketChange = value => {
    setBucketNamesSelected(value)
    setIsLoading(false)
    dispatch({
      type: 'SAVE_BUCKET_NAME',
      payload: value,
    })
    if (value.length <= 1) {
      fetchWorkflowsForBucket(value)
      setIsLoading(true)
    }
  }

  const sendBucketList = () => {
    fetchWorkflowsForBucket(bucketNamesSelected)
    setIsLoading(true)
  }

  // const authDetails = useSelector(state => state.common.authDetails)
  // const history = useHistory()

  // if (
  //   Object.keys(authDetails).length === 0 &&
  //   !Object.prototype.hasOwnProperty.call(
  //     authDetails,
  //     'access_feature_workflow'
  //   )
  // ) {
  //   history.push('/unauthorised')
  // }

  let [selectAll, setSelectAll] = useState(false)
  const [exportDialogOpen, setExportDialogOpen] = useState(false)

  const handleExportAllSelect = event => {
    setExportSelectAll(event.target.checked)

    if (event.target.checked) {
      let tempList = TableColumns[contentType]
      let filteredList = tempList.filter(column => !column.hidden)
      setSelectedColumns(filteredList)
    } else {
      setSelectedColumns([])
    }
  }

  const handleAllSelect = event => {
    setSelectAll(event.target.checked)
    if (event.target.checked) {
      setBucketNamesSelected([...workflow.bucketList])
      dispatch({
        type: 'SAVE_BUCKET_NAME',
        payload: [...workflow.bucketList],
      })
    } else {
      setBucketNamesSelected([])
      dispatch({
        type: 'SAVE_BUCKET_NAME',
        payload: [...workflow.bucketList],
      })
      fetchWorkflowsForBucket([])
    }
  }

  const handleCloseExportDialog = () => {
    setExportDialogOpen(false)
    setExportSelectAll(true)
    setFilename(`WF-${contentType}-Report`)
    let tempList = TableColumns[contentType]
    let filteredList = tempList.filter(column => !column.hidden)
    setSelectedColumns(filteredList)
  }

  const isColumnSelected = title => {
    let index = selectedColumns.findIndex(item => item.title === title)
    return index !== -1
  }

  const handleColumnSelectChange = event => {
    let tempList = [...selectedColumns]

    if (event.target.checked) {
      let tableCol = TableColumns[contentType].filter(column => !column.hidden)

      let fieldToBePushed = tableCol.filter(
        item => item.title === event.target.name
      )
      tempList.push(fieldToBePushed[0])
    } else {
      tempList = tempList.filter(item => item.title !== event.target.name)
      setExportSelectAll(false)
    }
    setSelectedColumns(tempList)
  }

  const exportCsv = () => {
    let columns = [...selectedColumns]
    let dataToExport = tableDataToBeExported
    const data = dataToExport.map(rowData =>
      columns.map(columnDef => {
        return getFieldValueWorkflow(rowData, columnDef)
      })
    )

    const builder = new CsvBuilder((filename || 'Report') + '.csv')
    builder
      .setColumns(columns.map(columnDef => columnDef.title))
      .addRows(data)
      .exportFile()

    handleCloseExportDialog()
  }

  const [tableOrderBy, setTableOrderBy] = useState(-1)
  const [tableOrderDirection, setTableOrderDirection] = useState('')

  React.useEffect(() => {
    if (workflow.currentTabValue === 0) {
      setOrder(tableOrderBy, tableOrderDirection, workflow.workflowCurrentPage)
    }
  }, [tableOrderBy, tableOrderDirection, workflow.currentTabValue])

  //to reset sorting order on switching between different screens
  const setOrder = (orderBy, orderDir, page) => {
    let currentRef = materialTableRef.current
    if (currentRef) {
      currentRef.onChangeOrder(orderBy, orderDir)
      currentRef.onChangePage({}, page)
    }
  }

  const handleOrderChange = (orderBy, orderDir) => {
    if (contentType && orderBy === -1) {
      if (contentType === 'Real Estate') {
        orderBy = 3
        orderDir = 'desc'
      } else {
        orderBy = 0
        orderDir = 'desc'
      }
    }

    setTableOrderBy(orderBy)
    setTableOrderDirection(orderDir)
    dispatch({
      type: 'SAVE_ORDER_DIRECTION',
      payload: orderDir,
    })
    dispatch({
      type: 'SAVE_ORDER_BY',
      payload: orderBy,
    })
  }
  const filteredColumns =
    contentType && TableColumns[contentType].filter(column => !column.hidden)

  const exportProps = {
    open: exportDialogOpen,
    handleClose: handleCloseExportDialog,
    exportCsv,
    classes,
    filename,
    setFilename,
    isColumnSelected,
    handleColumnSelectChange,
    allColumns: filteredColumns,
    exportSelectAll,
    handleExportAllSelect,
    selectedColumns,
    title: 'Workflow Report',
  }

  return (
    <div className={classes.root}>
      <div className="workflow-dropdown-container">
        <div>
          <FormControl className={classes.margin} id="formcontrol-contentType">
            <NativeSelect
              id="content-type-select-native"
              value={workflow.contentType}
              onChange={e => handleContentTypeChanges(e.target.value)}
              input={<BootstrapInput />}
            >
              {workflow.contentTypeList &&
                workflow.contentTypeList.map(value => {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  )
                })}
            </NativeSelect>
          </FormControl>
          &nbsp;&nbsp;&nbsp;
          <FormControl id="formcontrol-bucket">
            <Select
              id="bucket-select-native"
              multiple
              value={workflow.bucketName}
              onChange={e => handleBucketChange(e.target.value)}
              input={<BootstrapInput />}
              // input={<Input />}
              variant="outlined"
              renderValue={selected =>
                selected.length <= 1 ? selected : 'Multiple Buckets'
              }
              autoWidth={true}
              classes={classes.multipleSelect}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      selectAll &&
                      bucketNamesSelected.length === workflow.bucketList.length
                    }
                    onChange={handleAllSelect}
                  />
                }
                label="Select All"
                className={classes.selectAll}
              />
              <hr style={{ margin: '0px' }} />
              {workflow.bucketList &&
                workflow.bucketList.map(value => (
                  <MenuItem key={value} value={value}>
                    <Checkbox
                      checked={bucketNamesSelected.indexOf(value) > -1}
                    />
                    <ListItemText primary={value} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={sendBucketList}
            className={classes.bucketButton}
            disabled={bucketNamesSelected.length <= 1}
          >
            <NearMeIcon fontSize="medium" />
          </Button>
          <span
            className="refresh-logo"
            style={
              workflow.currentTabValue === 0
                ? { display: 'block' }
                : { display: 'none' }
            }
          >
            <RefreshIcon
              color="primary"
              onClick={() => {
                fetchWorkflowsForBucket(workflow.bucketName)
                setIsLoading(true)
              }}
            />
          </span>
          {/* )} */}
        </div>
      </div>
      <div>
        <div className={classes.root}>
          <Tabs
            value={workflow.currentTabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Workflows" {...a11yProps(0)} />
            {workflow.openedWorkflowTabs}
          </Tabs>
          {/* {workflow.currentTabValue === 0 && ( */}
          {/* <TabContainer> */}
          <TabPanel value={workflow.currentTabValue} index={0}>
            <Paper className={classes.paper}>
              <div className="fixed_table">
                <MaterialTable
                  title=""
                  options={{
                    search: false,
                    paging: true,
                    filtering: true,

                    pageSize: rowsPerPage,
                    initialPage: page,
                    pageSizeOptions: [10, 25, 50],
                    loadingType: 'overlay',
                    style: { textAlign: 'center' },
                    sorting: true,
                    thirdSortClick: false,
                    emptyRowsWhenPaging: false,
                  }}
                  tableRef={materialTableRef}
                  onOrderChange={handleOrderChange}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  onChangePage={handlePageChange}
                  icons={tableIcons}
                  columns={TableColumns[contentType]}
                  data={workflowList}
                  isLoading={isLoading}
                  actions={[
                    {
                      icon: OpenInNewIcon,
                      tooltip: 'Open Workflow',
                      iconProps: { fontSize: 'small' },
                      onClick: (event, rowData) => openTab(rowData, 'workflow'),
                    },
                  ]}
                  components={{
                    Toolbar: props => (
                      <div style={{ padding: '8px' }}>
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          <div style={{ marginLeft: 'auto' }}></div>
                          {contentType === 'Real Estate' && (
                            <div>
                              <BottomNavigationAction
                                label="URGENT"
                                disabled={true}
                                icon={
                                  <span className={classes.badgeStyle}>
                                    {isUrgentCount}
                                  </span>
                                }
                              />
                            </div>
                          )}
                          {(contentType === 'PA CAM Payable' ||
                            contentType === 'PA CAM Rebill') && (
                            <Fragment>
                              <div>
                                <BottomNavigationAction
                                  label="IN PROGRESS"
                                  disabled={true}
                                  icon={
                                    <span className={classes.badgeStyle}>
                                      {inProgressCount}
                                    </span>
                                  }
                                />
                              </div>
                              &nbsp;
                              <div>
                                <BottomNavigationAction
                                  label="NOT STARTED"
                                  disabled={true}
                                  icon={
                                    <span className={classes.badgeStyle}>
                                      {notStartedCount}
                                    </span>
                                  }
                                />
                              </div>
                              &nbsp;
                              <div>
                                <BottomNavigationAction
                                  label="PENDING_INFO(OPERATOR)"
                                  disabled={true}
                                  icon={
                                    <span className={classes.badgeStyle}>
                                      {pendingInfoOperator}
                                    </span>
                                  }
                                />
                              </div>
                              &nbsp;
                              <div>
                                <BottomNavigationAction
                                  label="PENDING_INFO(INTERNAL)"
                                  disabled={true}
                                  icon={
                                    <span className={classes.badgeStyle}>
                                      {pendingInfoInternal}
                                    </span>
                                  }
                                />
                              </div>
                              &nbsp;
                              <div>
                                <BottomNavigationAction
                                  label="COMPLETE - APPROVED"
                                  disabled={true}
                                  icon={
                                    <span className={classes.badgeStyle}>
                                      {completeApproved}
                                    </span>
                                  }
                                />
                              </div>
                              &nbsp;
                              <div>
                                <BottomNavigationAction
                                  label="COMPLETE - DENIED"
                                  disabled={true}
                                  icon={
                                    <span className={classes.badgeStyle}>
                                      {completeDenied}
                                    </span>
                                  }
                                />
                              </div>
                            </Fragment>
                          )}
                          &nbsp;
                          <div style={{ marginRight: 'auto' }}>
                            <BottomNavigationAction
                              label="UNASSIGNED"
                              disabled={true}
                              icon={
                                <span className={classes.badgeStyle}>
                                  {unAssignedCount}
                                </span>
                              }
                            />
                          </div>
                          &nbsp;
                          <div>
                            {' '}
                            <Button
                              onClick={event => {
                                setExportDialogOpen(true)
                                setTableDataToBeExported(props.data)
                              }}
                              // startIcon={<AssignmentReturnedIcon />}
                              // variant="outlined"
                              color="primary"
                            >
                              <AssignmentReturnedIcon />
                            </Button>
                          </div>
                        </div>
                      </div>
                    ),
                  }}
                  // }
                  localization={{
                    body: {
                      emptyDataSourceMessage: '',
                    },
                  }}
                />
              </div>
            </Paper>
            {/* </TabContainer> */}
            {/* )} */}
          </TabPanel>
        </div>
        {showWorkflowFiles()}
        {showFiles()}
        {contentType && <ExportDialog {...exportProps} />}
      </div>
    </div>
  )
}
