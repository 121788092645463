import React from 'react'

export const ErrorPage = () => {
  return (
    <div>
      <center>
        <img
          alt="notauthorized"
          src="https://i.ibb.co/SwhKQ9h/Not-Authorized.jpg"
        />
      </center>
    </div>
  )
}
