/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSelector } from 'react-redux'
import apiConfig from '../../config/apiConfig'
import { NumberFormatCustomPrefix } from '../common/commonFunctionalities'
// import InputAdornment from '@material-ui/core/InputAdornment'

const PACAMPayableBudget = props => {
  return (
    <div>
      {props.type === apiConfig.apiUrl.INDEX ? (
        <PACAMPayableBudgetIndex {...props} />
      ) : (
        <PACAMPayableBudgetSearch {...props} />
      )}
    </div>
  )
}

const PACAMPayableBudgetSearch = props => {
  const [, setParentObj] = props.metaDataObj
  const [
    paCAMPayableBudgetSearchObj,
    setPaCAMPayableBudgetSearchObj,
  ] = useState({})

  useEffect(() => {
    if (
      (props.savedMetaData &&
        Object.entries(props.savedMetaData).length === 0) ||
      props.savedMetaData === undefined
    ) {
      setPaCAMPayableBudgetSearchObj(props.unmodifiedData)
    } else {
      let obj = returnREObj({ ...props.savedMetaData })
      let newObj = { ...obj, id: props.unmodifiedData.id }
      setPaCAMPayableBudgetSearchObj(newObj)
    }
  }, [])

  useEffect(() => {
    setParentObj(paCAMPayableBudgetSearchObj)
  }, [paCAMPayableBudgetSearchObj])

  return (
    <PACAMPayableBudgetForm
      {...props}
      pacamopayablebudget={[
        paCAMPayableBudgetSearchObj,
        setPaCAMPayableBudgetSearchObj,
      ]}
    />
  )
}

const PACAMPayableBudgetIndex = props => {
  const [parentObj, setParentObj] = props.metaDataObj
  const [paCAMPayableBudgetIndexObj, setPaCAMPayableBudgetIndexObj] = useState(
    {}
  )

  useEffect(() => {
    setParentObj(paCAMPayableBudgetIndexObj)
  }, [paCAMPayableBudgetIndexObj])

  useEffect(() => {
    if (Object.keys(parentObj).length > 0) {
      let newObj = { ...parentObj, contentType: props.contentType }
      setPaCAMPayableBudgetIndexObj(newObj)
    }
  }, [])

  //called only when indexing status = complete
  useEffect(() => {
    if (props.refreshCompleteMetadata) {
      // let obj = refactoredObject({ ...parentObj })
      let newObj = { ...parentObj, contentType: props.contentType }
      setPaCAMPayableBudgetIndexObj(newObj)
    }
  }, [props.refreshCompleteMetadata])

  //For clearing indexing data on switch of content type
  useEffect(() => {
    if (props.clearOnContentTypeChange) {
      let newObj = { contentType: props.contentType }
      setPaCAMPayableBudgetIndexObj(newObj)
    }
  }, [props.clearOnContentTypeChange])

  return (
    <PACAMPayableBudgetForm
      {...props}
      pacamopayablebudget={[
        paCAMPayableBudgetIndexObj,
        setPaCAMPayableBudgetIndexObj,
      ]}
    />
  )
}

const returnREObj = data => {
  return {
    locationNumber: data.location_number || '',
    locationName: data.location_name || '',
    locationState: data.location_state || '',
    locationCity: data.location_city || '',
    locationRegion: data.location_region || '',
    documentTitle: data.document_title || '',
    documentGroup: data.document_group || '',
    documentType: data.document_type || '',
    contentType: data.content_type || '',
    fiscalYear: data.fiscal_year || '',
    expiryYear: data.expiry_year || '',
    sapContractNumber: data.sap_contract_number || '',
    operator: data.operator || '',
    // targetPrsTotalRecAmount: data.target_prs_total_rec_amount || '',
    targetPrsBudgetAmount: data.target_prs_budget_amount || '',
    proRataShare: data.pro_rata_share || '',
  }
}

const PACAMPayableBudgetForm = props => {
  const [
    paCAMPayableBudgetObj,
    setPaCAMPayableBudgetObj,
  ] = props.pacamopayablebudget
  const BootstrapInput = props.bootStrapInput
  const classes = props.useStyleClass
  const metadataObj = useSelector(state => state.common.locationData)
  const [locationData, setLocationData] = useState(metadataObj)
  const [docTitleError, setDocTitleError] = useState(false)

  const [docArray, setDocArray] = useState([])
  const { docGroupDocType, contentType } = props

  useEffect(() => {
    setDocArray(
      docGroupDocType[contentType][paCAMPayableBudgetObj.documentGroup]
    )
  }, [paCAMPayableBudgetObj.documentGroup])

  useEffect(() => {
    if (locationData) {
      setPaCAMPayableBudgetObj({
        ...paCAMPayableBudgetObj,
        locationNumber: locationData.location_number,
        locationName: locationData.location_name,
        locationCity: locationData.location_city,
        locationState: locationData.location_state,
        locationRegion: locationData.location_region,
      })
    } else {
      setPaCAMPayableBudgetObj({
        ...paCAMPayableBudgetObj,
        locationNumber: '',
        locationName: '',
        locationCity: '',
        locationState: '',
        locationRegion: '',
      })
    }
  }, [locationData])

  useEffect(() => {
    if (
      paCAMPayableBudgetObj.fiscalYear &&
      paCAMPayableBudgetObj.fiscalYear.length === 4
    ) {
      setPaCAMPayableBudgetObj({
        ...paCAMPayableBudgetObj,
        expiryYear: (Number(paCAMPayableBudgetObj.fiscalYear) + 6).toString(),
      })
    }
  }, [paCAMPayableBudgetObj.fiscalYear])

  return (
    <div className="contenttype-container">
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="pacampayablebudget-documentgroup">
          Document Group *
        </InputLabel>
        <NativeSelect
          id="pacampayablebudget-documentgroup"
          value={paCAMPayableBudgetObj.documentGroup}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_group
              : paCAMPayableBudgetObj.documentGroup
          }
          onChange={e =>
            setPaCAMPayableBudgetObj({
              ...paCAMPayableBudgetObj,
              documentGroup: e.target.value,
            })
          }
          required
          input={<BootstrapInput />}
        >
          {Object.keys(docGroupDocType[contentType]).map(value => {
            return (
              <option value={value} key={value}>
                {value}
              </option>
            )
          })}
        </NativeSelect>
      </FormControl>
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="pacampayablebudget-documenttype">
          Document Type *
        </InputLabel>
        <NativeSelect
          id="pacampayablebudget-documenttype"
          value={paCAMPayableBudgetObj.documentType}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_type
              : paCAMPayableBudgetObj.documentType
          }
          onChange={e =>
            setPaCAMPayableBudgetObj({
              ...paCAMPayableBudgetObj,
              documentType: e.target.value,
            })
          }
          input={<BootstrapInput />}
          required
        >
          {docArray &&
            docArray.map(value => {
              return (
                <option value={value} key={value}>
                  {value}
                </option>
              )
            })}
        </NativeSelect>
      </FormControl>
      <Autocomplete
        options={metadataObj}
        autoHighlight={true}
        getOptionLabel={option =>
          option.location_number ? option.location_number : ''
        }
        // autoHighlight={true}
        className="autocomplete"
        // getOptionLabel={option => option.location_number}
        onChange={(index, obj) => setLocationData(obj)}
        value={{
          location_number: paCAMPayableBudgetObj.locationNumber,
          location_name: paCAMPayableBudgetObj.locationName,
          location_city: paCAMPayableBudgetObj.locationCity,
          location_state: paCAMPayableBudgetObj.locationState,
          location_region: paCAMPayableBudgetObj.locationRegion,
        }}
        type={Number}
        disabled={Object.entries(metadataObj).length ? false : true}
        renderInput={params => (
          <FormControl>
            <InputLabel shrink className={classes.textfieldLabel}>
              Location Number{' *'}
            </InputLabel>
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              required
              // label="Location Number"
              className={classes.textField}
              variant="outlined"
            />
          </FormControl>
        )}
      />
      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacampayablebudget-locationname"
        >
          Location Name{' *'}
        </InputLabel>
        <TextField
          required
          id="pacampayablebudget-locationname"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          style={{ color: 'black' }}
          disabled={true}
          value={paCAMPayableBudgetObj.locationName || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_name
              : paCAMPayableBudgetObj.locationName
          }
          onChange={e =>
            setPaCAMPayableBudgetObj({
              ...paCAMPayableBudgetObj,
              locationName: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacampayablebudget-state"
        >
          State{' *'}
        </InputLabel>
        <TextField
          required
          id="pacampayablebudget-state"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paCAMPayableBudgetObj.locationState || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_state
              : paCAMPayableBudgetObj.locationState
          }
          onChange={e =>
            setPaCAMPayableBudgetObj({
              ...paCAMPayableBudgetObj,
              locationState: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacampayablebudget-city"
        >
          City{' *'}
        </InputLabel>
        <TextField
          required
          id="pacampayablebudget-city"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paCAMPayableBudgetObj.locationCity || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_city
              : paCAMPayableBudgetObj.locationCity
          }
          onChange={e =>
            setPaCAMPayableBudgetObj({
              ...paCAMPayableBudgetObj,
              locationCity: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacampayablebudget-region"
        >
          Region
        </InputLabel>
        <TextField
          required
          id="pacampayablebudget-region"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paCAMPayableBudgetObj.locationRegion || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_region
              : paCAMPayableBudgetObj.locationRegion
          }
          onChange={e =>
            setPaCAMPayableBudgetObj({
              ...paCAMPayableBudgetObj,
              locationRegion: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacampayablebudget-documenttitle"
        >
          Document Title{' *'}
        </InputLabel>
        <TextField
          required
          id="pacampayablebudget-documenttitle"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ maxLength: '500' }}
          type={String}
          multiline
          rowsMax="4"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableBudgetObj.documentTitle || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_title
              : paCAMPayableBudgetObj.documentTitle
          }
          onChange={e => {
            if (e.target.value.length === 500) {
              setDocTitleError(true)
            } else setDocTitleError(false)
            setPaCAMPayableBudgetObj({
              ...paCAMPayableBudgetObj,
              documentTitle: e.target.value,
            })
          }}
          error={docTitleError}
          helperText={
            docTitleError ? 'You have reached the limit of 500 characters' : ''
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacampayablebudget-fiscalyear"
        >
          Fiscal Year{' '}
        </InputLabel>
        <TextField
          id="pacampayablebudget-fiscalyear"
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableBudgetObj.fiscalYear || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.fiscal_year
              : paCAMPayableBudgetObj.fiscalYear
          }
          onChange={e => {
            if (e.target.value.length > 4) {
              return false
            } else {
              setPaCAMPayableBudgetObj({
                ...paCAMPayableBudgetObj,
                fiscalYear: e.target.value,
              })
            }
          }}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="paCAMPayableBudgetObj-expiryyear"
        >
          Expiry Year{' '}
        </InputLabel>
        <TextField
          type="number"
          id="paCAMPayableBudgetObj-expiryyear"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableBudgetObj.expiryYear || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.expiry_year
              : paCAMPayableBudgetObj.expiryYear
          }
          onChange={e => {
            if (e.target.value.length > 4) return false
            else {
              setPaCAMPayableBudgetObj({
                ...paCAMPayableBudgetObj,
                expiryYear: e.target.value,
              })
            }
          }}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacampayablebudget-sapcontractnumber"
        >
          SAP Contract Number{' '}
        </InputLabel>
        <TextField
          id="pacampayablebudget-sapcontractnumber"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableBudgetObj.sapContractNumber || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.sap_contract_number
              : paCAMPayableBudgetObj.sapContractNumber
          }
          onChange={e =>
            setPaCAMPayableBudgetObj({
              ...paCAMPayableBudgetObj,
              sapContractNumber: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="pacampayable-operator">
          Operator
        </InputLabel>
        <NativeSelect
          id="pacampayable-operator"
          value={paCAMPayableBudgetObj.operator || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.operator
              : paCAMPayableBudgetObj.operator
          }
          onChange={e =>
            setPaCAMPayableBudgetObj({
              ...paCAMPayableBudgetObj,
              operator: e.target.value,
            })
          }
          input={<BootstrapInput />}
        >
          <option value={''}></option>
          <option value={'Site Centers Corporation'}>
            Site Centers Corporation
          </option>
          <option value={'Kimco Realty Corporation'}>
            Kimco Realty Corporation
          </option>
          <option value={'Brixmor Property Group'}>
            Brixmor Property Group
          </option>
          <option value={'Regency Centers'}>Regency Centers</option>
          <option value={'Brookfield Properties'}>Brookfield Properties</option>
          <option value={'Vestar'}>Vestar</option>
          <option value={'Ryan Companies'}>Ryan Companies</option>
          <option value={'Westwood Financial Corporation'}>
            Westwood Financial Corporation
          </option>
          <option value={'Kite Realty Group'}>Kite Realty Group</option>
          <option value={'CBRE'}>CBRE</option>
          <option value={'RPAI'}>RPAI</option>
          <option value={'Simon Property Group'}>Simon Property Group</option>
          <option value={'COR Property Services'}>COR Property Services</option>
          <option value={'Weingarten Realty'}>Weingarten Realty</option>
          <option value={'Mid-America Asset Management, Inc.'}>
            Mid-America Asset Management, Inc.
          </option>
          <option value={'Merlone Geiers'}>Merlone Geier</option>
          <option value={'The Related Companies'}>The Related Companies</option>
          <option value={'Blumenfeld Development Group'}>
            Blumenfeld Development Group
          </option>
          <option value={'Benderson Development Company'}>
            Benderson Development Company
          </option>
          <option value={'Irvine Company LLC'}>Irvine Company LLC</option>
          <option value={'Edens Inc'}>Edens Inc</option>
          <option value={'Federal Realtyc'}>Federal Realty</option>
          <option value={'Sunbelt'}>Sunbelt</option>
          <option value={'Donahue Schriber'}>Donahue Schriber</option>
          <option value={'Other'}>Other</option>
        </NativeSelect>
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacampayablebudget-targetprstotalamount"
        >
          Tgt Total Budget Amt From Operator
        </InputLabel>
        <TextField
          id="pacampayable-targetprstotalamount"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableBudgetObj.targetPrsBudgetAmount || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.target_prs_budget_amount
              : paCAMPayableBudgetObj.targetPrsBudgetAmount
          }
          onChange={e =>
            setPaCAMPayableBudgetObj({
              ...paCAMPayableBudgetObj,
              targetPrsBudgetAmount: e.target.value,
            })
          }
          InputProps={{
            inputComponent: NumberFormatCustomPrefix,
            // startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacampayablebudget-proratashare"
        >
          ProRata Share{' '}
        </InputLabel>
        <TextField
          id="pacampayable-proratashare"
          type={String}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableBudgetObj.proRataShare || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.pro_rata_share
              : paCAMPayableBudgetObj.proRataShare
          }
          onChange={e =>
            setPaCAMPayableBudgetObj({
              ...paCAMPayableBudgetObj,
              proRataShare: e.target.value,
            })
          }
        />
      </FormControl>
    </div>
  )
}

export default PACAMPayableBudget
