import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import { useToasts } from 'react-toast-notifications'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import { useAuth } from '@praxis/component-auth'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

export default function FormDialog({
  open,
  setOpen,
  setValue,
  addIncident,
  setAddIncident,
}) {
  const auth = useAuth()
  const { session } = auth
  //   const [open, setOpen] = React.useState(false)
  // const [addIncident, setAddIncident] = useState(false)
  const [shortDescError, setShortDescError] = useState(false)
  const [longDescError, setLongDescError] = useState(false)
  const classes = useStyles()
  const { addToast } = useToasts()
  const [obj, setObj] = useState({
    shortDescription: '',
    workNotes: '',
    priority: '',
  })

  const createServiceNowIncident = event => {
    let payload = {}
    payload['short_description'] = obj.shortDescription
    payload['assignment_group'] = 'Solution Portfolio - Corp Systems - RDMPlus'
    payload['caller'] = session.userInfo.lanId
    payload['category'] = 'Incident'
    payload['impact_to_user'] = 'CI07179317'
    payload['work_notes'] = obj.workNotes
    payload['priority'] = obj.priority

    let url = apiConfig.apiUrl.createIncident

    axios
      .post(url, payload)
      .then(res => {
        if (res.status === 200) {
          setObj('')
          handleClose()
          setAddIncident(true)
        }
        setValue(1)
        addToast(
          `ServiceNow incident - ${res.data.incident_number} has been created successfully. 
          Technical team will check on the issue.`,
          {
            appearance: 'success',
            autoDismiss: true,
          }
        )
      })
      .catch(err => {
        console.log(err)
        addToast('Error!Failed to submit an Incident. Please try again!!', {
          appearance: 'error',
          autoDismiss: true,
        })
        handleClose()
      })
  }

  const handleClose = () => {
    setOpen(false)
    setObj('')
    setShortDescError(false)
    setLongDescError(false)
  }

  const handleLocationSubmit = e => {
    e.preventDefault()
    createServiceNowIncident()
  }

  return (
    <div style={{ textAlign: 'Left' }}>
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick="true"
        aria-labelledby="form-dialog-title"
      >
        <form validate autoComplete="off" onSubmit={handleLocationSubmit}>
          <DialogTitle id="support-dialog-title">Submit an Issue</DialogTitle>
          <DialogContent>
            <p style={{ color: 'red', margin: '0px 0px 0px 24px' }}>
              **Please use this option to report <b>technical issues</b>. For
              enhancements/new features, please contact Product owner.
            </p>
            <div className="metadata-container">
              <div className="metadata-textfield">
                <TextField
                  required
                  placeholder="Describe the issue in short(max 100 characters)"
                  id="outlined-search"
                  label={'Short Description'}
                  type={String}
                  inputProps={{ maxLength: '100' }}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.shortDescription}
                  onChange={e => {
                    if (e.target.value.length === 100) {
                      setShortDescError(true)
                    } else setShortDescError(false)
                    setObj({
                      ...obj,
                      shortDescription: e.target.value,
                    })
                  }}
                  error={shortDescError}
                  helperText={
                    shortDescError
                      ? 'You have reached the limit of 100 characters'
                      : ''
                  }
                />
                <br />
                <TextField
                  required
                  multiline
                  rows={5}
                  placeholder="Please elaborate the issue in detail(min 100 characters)"
                  id="outlined-search"
                  label={'Long Description'}
                  type={String}
                  inputProps={{ minLength: '100' }}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.workNotes}
                  onChange={e => {
                    if (e.target.value.length < 100) {
                      setLongDescError(true)
                    } else setLongDescError(false)
                    setObj({
                      ...obj,
                      workNotes: e.target.value,
                    })
                  }}
                  error={longDescError}
                  helperText={
                    longDescError ? 'Minimum 100 characters required*' : ''
                  }
                />{' '}
                <br />
              </div>
              <div className="metadata-dropdowns">
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Priority*
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.priority}
                    onChange={e => setObj({ ...obj, priority: e.target.value })}
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'4 - Low'}>Low</option>
                    <option value={'3 - Moderate'}>High</option>
                  </NativeSelect>
                </FormControl>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
