/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSelector } from 'react-redux'
import apiConfig from '../../config/apiConfig'
import { NumberFormatCustomPrefix } from '../common/commonFunctionalities'

const PACAMPayableUnexpected = props => {
  return (
    <div>
      {props.type === apiConfig.apiUrl.INDEX ? (
        <PACAMPayableUnexpectedIndex {...props} />
      ) : (
        <PACAMPayableUnexpectedSearch {...props} />
      )}
    </div>
  )
}

const PACAMPayableUnexpectedSearch = props => {
  const [, setParentObj] = props.metaDataObj
  const [
    paCAMPayableUnexpectedSearchObj,
    setPaCAMPayableUnexpectedSearchObj,
  ] = useState({})

  useEffect(() => {
    if (
      (props.savedMetaData &&
        Object.entries(props.savedMetaData).length === 0) ||
      props.savedMetaData === undefined
    ) {
      setPaCAMPayableUnexpectedSearchObj(props.unmodifiedData)
    } else {
      let obj = returnREObj({ ...props.savedMetaData })
      let newObj = { ...obj, id: props.unmodifiedData.id }
      setPaCAMPayableUnexpectedSearchObj(newObj)
    }
  }, [])

  useEffect(() => {
    setParentObj(paCAMPayableUnexpectedSearchObj)
  }, [paCAMPayableUnexpectedSearchObj])

  return (
    <PACAMPayableUnexpectedForm
      {...props}
      pacampuObj={[
        paCAMPayableUnexpectedSearchObj,
        setPaCAMPayableUnexpectedSearchObj,
      ]}
    />
  )
}

const PACAMPayableUnexpectedIndex = props => {
  const [parentObj, setParentObj] = props.metaDataObj
  const [
    paCAMPayableUnexpectedIndexObj,
    setPaCAMPayableUnexpectedIndexObj,
  ] = useState({})

  useEffect(() => {
    setParentObj(paCAMPayableUnexpectedIndexObj)
  }, [paCAMPayableUnexpectedIndexObj])

  useEffect(() => {
    if (Object.keys(parentObj).length > 0) {
      // let obj = refactoredObject({ ...parentObj })
      let newObj = { ...parentObj, contentType: props.contentType }
      setPaCAMPayableUnexpectedIndexObj(newObj)
    }
  }, [])

  //called only when indexing status = complete
  useEffect(() => {
    if (props.refreshCompleteMetadata) {
      // let obj = refactoredObject({ ...parentObj })
      let newObj = { ...parentObj, contentType: props.contentType }
      setPaCAMPayableUnexpectedIndexObj(newObj)
    }
  }, [props.refreshCompleteMetadata])

  //For clearing indexing data on switch of content type
  useEffect(() => {
    if (props.clearOnContentTypeChange) {
      let newObj = { contentType: props.contentType }
      setPaCAMPayableUnexpectedIndexObj(newObj)
    }
  }, [props.clearOnContentTypeChange])

  return (
    <PACAMPayableUnexpectedForm
      {...props}
      pacampuObj={[
        paCAMPayableUnexpectedIndexObj,
        setPaCAMPayableUnexpectedIndexObj,
      ]}
    />
  )
}

const returnREObj = data => {
  return {
    locationNumber: data.location_number || '',
    locationName: data.location_name || '',
    locationState: data.location_state || '',
    locationCity: data.location_city || '',
    locationRegion: data.location_region || '',
    documentTitle: data.document_title || '',
    documentGroup: data.document_group || '',
    documentType: data.document_type || '',
    contentType: data.content_type || '',
    fiscalYear: data.fiscal_year || '',
    expiryYear: data.expiry_year || '',
    sapContractNumber: data.sap_contract_number || '',
    totalDollarImpact: data.total_dollar_impact || '',
  }
}

const PACAMPayableUnexpectedForm = props => {
  const [
    paCAMPayableUnexpectedObj,
    setPaCAMPayableUnexpectedObj,
  ] = props.pacampuObj
  const BootstrapInput = props.bootStrapInput
  const classes = props.useStyleClass
  const metadataObj = useSelector(state => state.common.locationData)
  const [locationData, setLocationData] = useState(metadataObj)
  const [docArray, setDocArray] = useState([])
  const [docTitleError, setDocTitleError] = useState(false)

  const { docGroupDocType, contentType } = props

  useEffect(() => {
    setDocArray(
      docGroupDocType[contentType][paCAMPayableUnexpectedObj.documentGroup]
    )
  }, [paCAMPayableUnexpectedObj.documentGroup])

  useEffect(() => {
    if (locationData) {
      setPaCAMPayableUnexpectedObj({
        ...paCAMPayableUnexpectedObj,
        locationNumber: locationData.location_number,
        locationName: locationData.location_name,
        locationCity: locationData.location_city,
        locationState: locationData.location_state,
        locationRegion: locationData.location_region,
      })
    } else {
      setPaCAMPayableUnexpectedObj({
        ...paCAMPayableUnexpectedObj,
        locationNumber: '',
        locationName: '',
        locationCity: '',
        locationState: '',
        locationRegion: '',
      })
    }
  }, [locationData])

  useEffect(() => {
    if (
      paCAMPayableUnexpectedObj.fiscalYear &&
      paCAMPayableUnexpectedObj.fiscalYear.length === 4
    ) {
      setPaCAMPayableUnexpectedObj({
        ...paCAMPayableUnexpectedObj,
        expiryYear: (
          Number(paCAMPayableUnexpectedObj.fiscalYear) + 6
        ).toString(),
      })
    }
  }, [paCAMPayableUnexpectedObj.fiscalYear])

  return (
    <div className="contenttype-container">
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="pacamunexpected-documentgroup">
          Document Group *
        </InputLabel>
        <NativeSelect
          id="pacamunexpected-documentgroup"
          value={paCAMPayableUnexpectedObj.documentGroup}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_group
              : paCAMPayableUnexpectedObj.documentGroup
          }
          onChange={e =>
            setPaCAMPayableUnexpectedObj({
              ...paCAMPayableUnexpectedObj,
              documentGroup: e.target.value,
            })
          }
          required
          input={<BootstrapInput />}
        >
          {Object.keys(docGroupDocType[contentType]).map(value => {
            return (
              <option value={value} key={value}>
                {value}
              </option>
            )
          })}
        </NativeSelect>
      </FormControl>
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="pacamunexpected-documenttype">
          Document Type *
        </InputLabel>
        <NativeSelect
          id="pacamunexpected-documenttype"
          value={paCAMPayableUnexpectedObj.documentType}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_type
              : paCAMPayableUnexpectedObj.documentType
          }
          onChange={e =>
            setPaCAMPayableUnexpectedObj({
              ...paCAMPayableUnexpectedObj,
              documentType: e.target.value,
            })
          }
          input={<BootstrapInput />}
          required
        >
          {docArray &&
            docArray.map(value => {
              return (
                <option value={value} key={value}>
                  {value}
                </option>
              )
            })}
        </NativeSelect>
      </FormControl>
      <Autocomplete
        required
        autoHighlight={true}
        getOptionLabel={option =>
          option.location_number ? option.location_number : ''
        }
        options={metadataObj}
        className="autocomplete"
        // getOptionLabel={option => option.location_number}
        onChange={(index, obj) => setLocationData(obj)}
        // blurOnSelect={true}
        value={{
          location_number: paCAMPayableUnexpectedObj.locationNumber,
          location_name: paCAMPayableUnexpectedObj.locationName,
          location_city: paCAMPayableUnexpectedObj.locationCity,
          location_state: paCAMPayableUnexpectedObj.locationState,
          location_region: paCAMPayableUnexpectedObj.locationRegion,
        }}
        type={Number}
        disabled={Object.entries(metadataObj).length ? false : true}
        renderInput={params => (
          <FormControl>
            <InputLabel
              shrink
              className={classes.textfieldLabel}
              htmlFor="pacamunexpected-autopopulate"
            >
              Location Number{' *'}
            </InputLabel>
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              required
              className={classes.textField}
              variant="outlined"
            />
          </FormControl>
        )}
      />
      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamunexpected-locationname"
        >
          Location Name{' *'}
        </InputLabel>
        <TextField
          required
          id="pacamunexpected-locationname"
          type={String}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          style={{ color: 'black' }}
          disabled={true}
          value={paCAMPayableUnexpectedObj.locationName || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_name
              : paCAMPayableUnexpectedObj.locationName
          }
          onChange={e =>
            setPaCAMPayableUnexpectedObj({
              ...paCAMPayableUnexpectedObj,
              locationName: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamunexpected-state"
        >
          State{' *'}
        </InputLabel>
        <TextField
          required
          id="pacamunexpected-state"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paCAMPayableUnexpectedObj.locationState || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_state
              : paCAMPayableUnexpectedObj.locationState
          }
          onChange={e =>
            setPaCAMPayableUnexpectedObj({
              ...paCAMPayableUnexpectedObj,
              locationState: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamunexpected-city"
        >
          City{' *'}
        </InputLabel>
        <TextField
          required
          id="pacamunexpected-city"
          type={String}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paCAMPayableUnexpectedObj.locationCity || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_city
              : paCAMPayableUnexpectedObj.locationCity
          }
          onChange={e =>
            setPaCAMPayableUnexpectedObj({
              ...paCAMPayableUnexpectedObj,
              locationCity: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamunexpected-region"
        >
          Region
        </InputLabel>
        <TextField
          required
          id="pacamunexpected-region"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paCAMPayableUnexpectedObj.locationRegion || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_region
              : paCAMPayableUnexpectedObj.locationRegion
          }
          onChange={e =>
            setPaCAMPayableUnexpectedObj({
              ...paCAMPayableUnexpectedObj,
              locationRegion: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamunexpected-documenttitle"
        >
          Document Title{' *'}
        </InputLabel>
        <TextField
          required
          id="pacamunexpected-documenttitle"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          inputProps={{ maxLength: '500' }}
          multiline
          rowsMax="4"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableUnexpectedObj.documentTitle || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_title
              : paCAMPayableUnexpectedObj.documentTitle
          }
          onChange={e => {
            if (e.target.value.length === 500) {
              setDocTitleError(true)
            } else setDocTitleError(false)
            setPaCAMPayableUnexpectedObj({
              ...paCAMPayableUnexpectedObj,
              documentTitle: e.target.value,
            })
          }}
          error={docTitleError}
          helperText={
            docTitleError ? 'You have reached the limit of 500 characters' : ''
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamunexpected-fiscalyear"
        >
          Fiscal Year{' '}
        </InputLabel>
        <TextField
          id="pacamunexpected-fiscalyear"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableUnexpectedObj.fiscalYear || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.fiscal_year
              : paCAMPayableUnexpectedObj.fiscalYear
          }
          onChange={e =>
            setPaCAMPayableUnexpectedObj({
              ...paCAMPayableUnexpectedObj,
              fiscalYear: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="paCAMPayableUnexpectedObj-expiryyear"
        >
          Expiry Year{' '}
        </InputLabel>
        <TextField
          type="number"
          id="paCAMPayableUnexpectedObj-expiryyear"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableUnexpectedObj.expiryYear || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.expiry_year
              : paCAMPayableUnexpectedObj.expiryYear
          }
          onChange={e => {
            if (e.target.value.length > 4) return false
            else {
              setPaCAMPayableUnexpectedObj({
                ...paCAMPayableUnexpectedObj,
                expiryYear: e.target.value,
              })
            }
          }}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamunexpected-sapcontractnumber"
        >
          SAP Contract Number{' '}
        </InputLabel>
        <TextField
          id="pacamunexpected-sapcontractnumber"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableUnexpectedObj.sapContractNumber || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.sap_contract_number
              : paCAMPayableUnexpectedObj.sapContractNumber
          }
          onChange={e =>
            setPaCAMPayableUnexpectedObj({
              ...paCAMPayableUnexpectedObj,
              sapContractNumber: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamunexpected-totaldollarimpact"
        >
          Total Dollar Impact{' '}
        </InputLabel>
        <TextField
          id="pacamunexpected-totaldollarimpact"
          type={String}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableUnexpectedObj.totalDollarImpact || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.total_dollar_impact
              : paCAMPayableUnexpectedObj.totalDollarImpact
          }
          onChange={e =>
            setPaCAMPayableUnexpectedObj({
              ...paCAMPayableUnexpectedObj,
              totalDollarImpact: e.target.value,
            })
          }
          InputProps={{
            inputComponent: NumberFormatCustomPrefix,
          }}
        />
      </FormControl>
    </div>
  )
}

export default PACAMPayableUnexpected
