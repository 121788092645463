import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import { useToasts } from 'react-toast-notifications'
import './AddLocationDataStyle.css'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import { validateDate } from '../common/validation'
import { useAuth } from '@praxis/component-auth'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

export default function FormDialog({
  locId,
  handleClosed,
  isEdit,
  setReloadTable,
  ...props
  // setRefreshLeasedData,
  // setRefreshOwnedData,
}) {
  const auth = useAuth()
  const { session } = auth
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const [dateOpeningError, setDateOpeningError] = useState(false)
  const [dateClosingError, setDateClosingError] = useState(false)
  const [dateAcquisitionError, setDateAcquisitionError] = useState(false)
  const [dateDispositionError, setDateDispositionError] = useState(false)
  const { addToast } = useToasts()
  const [checklist, setChecklist] = useState()
  const [obj, setObj] = useState({
    locationId: '',
    locationAddress: '',
    locationName: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    primaryUse: '',
    region: '',
    group: '',
    district: '',
    openingDate: '',
    closingDate: '',
    acquisitionDate: '',
    dispositionDate: '',
    checklistType: '',
    locationSource: '',
  })
  useEffect(() => {
    if (locId !== undefined && isEdit === true) {
      getLocationDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locId, isEdit])
  // console.log(setReloadTable, setRefreshLeasedData)
  const getLocationDetails = () => {
    let url = apiConfig.apiUrl.getlocation + `${locId}`

    axios
      .get(url)
      .then(response => {
        setObj({
          locationId: response.data.locid,
          locationAddress: response.data.address,
          locationName: response.data.name,
          city: response.data.city,
          state: response.data.state,
          postalCode: response.data.postal_code,
          country: response.data.country,
          primaryUse: response.data.primary_use,
          region: response.data.region,
          group: response.data.group,
          district: response.data.district,
          openingDate: response.data.open_date,
          closingDate: response.data.close_date,
          acquisitionDate: response.data.acquisition_date,
          dispositionDate: response.data.disposition_date,
          checklistType: response.data.leased_or_owned,
          locationSource: response.data.location_source,
          createdBy: response.data.created_by,
          createdDate: response.data.created_timestamp,
        })
        setChecklist(response.data.leased_or_owned)
        setOpen(true)
      })
      .catch(err => console.log(err))
  }

  const updateLocationSubmit = event => {
    let payload = {}
    let locid = obj.locationId
    payload['location_number'] = obj.locationId
    payload['location_name'] = obj.locationName
    payload['location_address'] = obj.locationAddress
    payload['location_city'] = obj.city
    payload['location_state'] = obj.state
    payload['location_postal_code'] = obj.postalCode
    payload['location_country'] = obj.country
    payload['location_primary_use'] = obj.primaryUse
    payload['location_region'] = obj.region
    payload['location_group'] = obj.group
    payload['location_district'] = obj.district
    payload['location_open_date'] = obj.openingDate
    payload['location_close_date'] = obj.closingDate
    payload['location_acquisition_date'] = obj.acquisitionDate
    payload['location_disposition_date'] = obj.dispositionDate
    payload['location_leased_or_owned'] = obj.checklistType
    payload['location_source'] = obj.locationSource
    payload['created_by'] = obj.createdBy
    payload['created_timestamp'] = obj.createdDate
    payload['modified_by'] = session.userInfo.fullName
    let url = apiConfig.apiUrl.update_location + `/${locid}`

    axios
      .put(url, payload)
      .then(res => {
        addToast('Location saved successfully', {
          appearance: 'success',
          autoDismiss: true,
        })
        if (obj.checklistType === 'Owned') {
          props.setRefreshOwnedData(true)
        } else if (obj.checklistType === 'Leased') {
          props.setRefreshLeasedData(true)
        }
        //props.setRefreshDispositionData(true)
        setReloadTable(true)
        handleClose()
      })
      .catch(err => {
        console.log(err)
        addToast('Error!Location could not be saved.', {
          appearance: 'error',
        })

        handleClose()
      })
  }

  const handleClose = () => {
    setOpen(false)
    setObj('')
    handleClosed(false)
  }

  const handleSaveEdit = e => {
    e.preventDefault()
    if (isEdit) {
      updateLocationSubmit()
    }
  }

  return (
    <div style={{ marginLeft: '90%' }}>
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick="true"
        aria-labelledby="form-dialog-title"
      >
        <form validate autoComplete="off" onSubmit={handleSaveEdit}>
          <DialogTitle id="form-dialog-title">Edit Location</DialogTitle>
          <DialogContent>
            <div className="metadata-container">
              <div className="metadata-textfield">
                <TextField
                  required
                  disabled
                  id="outlined-search"
                  label={'Location ID'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.locationId}
                  onChange={e => setObj({ ...obj, locationId: e.target.value })}
                />
                <br />
                <TextField
                  required
                  disabled
                  id="outlined-search"
                  label={'Location Name'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.locationName}
                  onChange={e =>
                    setObj({ ...obj, locationName: e.target.value })
                  }
                />{' '}
                <br />
                <TextField
                  required
                  disabled
                  id="outlined-search"
                  label={'Address'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.locationAddress}
                  onChange={e =>
                    setObj({ ...obj, locationAddress: e.target.value })
                  }
                />
                <br />
                <TextField
                  required
                  disabled
                  id="outlined-search"
                  label={'City'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.city}
                  onChange={e => setObj({ ...obj, city: e.target.value })}
                />
                <br />
                <TextField
                  required
                  disabled
                  id="outlined-search"
                  label={'State'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.state}
                  onChange={e => setObj({ ...obj, state: e.target.value })}
                />
                <br />
                <TextField
                  required
                  disabled
                  id="outlined-search"
                  label={'Postal Code'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.postalCode}
                  onChange={e => setObj({ ...obj, postalCode: e.target.value })}
                />
                <br />
                <TextField
                  required
                  disabled
                  id="outlined-search"
                  label={'Country'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.country}
                  onChange={e => setObj({ ...obj, country: e.target.value })}
                />
                <br />
                <TextField
                  disabled
                  id="outlined-search"
                  label={'Primary Use'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.primaryUse}
                  onChange={e => setObj({ ...obj, primaryUse: e.target.value })}
                />
                <br />
                <TextField
                  disabled
                  id="outlined-search"
                  label={'Region'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.region}
                  onChange={e => setObj({ ...obj, region: e.target.value })}
                />
                <br />
                <TextField
                  disabled
                  id="outlined-search"
                  label={'Group'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.group}
                  onChange={e => setObj({ ...obj, group: e.target.value })}
                />
                <br />
                <TextField
                  disabled
                  id="outlined-search"
                  label={'District'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.district}
                  onChange={e => setObj({ ...obj, district: e.target.value })}
                />
                <br />
              </div>
              <div className="metadata-textfield">
                <TextField
                  disabled
                  id="realestate-documentdate"
                  label="Opening Date"
                  type={String}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={obj.openingDate || undefined}
                  onChange={event => {
                    let valid = validateDate(event.target.value)
                    setDateOpeningError(valid)

                    setObj({
                      ...obj,
                      openingDate: event.target.value,
                    })
                  }}
                  placeholder="mm/dd/yyyy"
                  error={dateOpeningError}
                  helperText={
                    dateOpeningError
                      ? 'Enter in correct format.For e.g. 09/23/2020'
                      : ''
                  }
                />
                <TextField
                  disabled
                  id="realestate-documentdate"
                  label="Closing Date"
                  type={String}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={obj.closingDate || undefined}
                  onChange={event => {
                    let valid = validateDate(event.target.value)
                    setDateClosingError(valid)

                    setObj({
                      ...obj,
                      closingDate: event.target.value,
                    })
                  }}
                  placeholder="mm/dd/yyyy"
                  error={dateClosingError}
                  helperText={
                    dateClosingError
                      ? 'Enter in correct format.For e.g. 09/23/2020'
                      : ''
                  }
                />
                <TextField
                  id="realestate-documentdate"
                  label="Acquisition Date"
                  type={String}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={obj.acquisitionDate || undefined}
                  onChange={event => {
                    let valid = validateDate(event.target.value)
                    setDateAcquisitionError(valid)

                    setObj({
                      ...obj,
                      acquisitionDate: event.target.value,
                    })
                  }}
                  placeholder="mm/dd/yyyy"
                  error={dateAcquisitionError}
                  helperText={
                    dateAcquisitionError
                      ? 'Enter in correct format.For e.g. 09/23/2020'
                      : ''
                  }
                />
                <TextField
                  disabled
                  id="realestate-documentdate"
                  label="Disposition Date"
                  type={String}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={obj.dispositionDate || undefined}
                  onChange={event => {
                    let valid = validateDate(event.target.value)
                    setDateDispositionError(valid)

                    setObj({
                      ...obj,
                      dispositionDate: event.target.value,
                    })
                  }}
                  placeholder="mm/dd/yyyy"
                  error={dateDispositionError}
                  helperText={
                    dateDispositionError
                      ? 'Enter in correct format.For e.g. 09/23/2020'
                      : ''
                  }
                />
                <br />
                <br />
              </div>
              <div className="metadata-dropdowns">
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Checklist Type
                  </InputLabel>
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={obj.checklistType}
                    onChange={e => {
                      setObj({ ...obj, checklistType: e.target.value })
                    }}
                    //  disabled={isEdit}
                    disabled={checklist !== null}
                    input={<BootstrapInput />}
                  >
                    <option
                      value={''}
                      // disabled={isEdit}
                    ></option>
                    <option
                      value={'Owned'}
                      //  disabled={isEdit}
                    >
                      Owned
                    </option>
                    <option
                      value={'Leased'}
                      //  disabled={isEdit}
                    >
                      Leased
                    </option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Location Source
                  </InputLabel>
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={obj.locationSource}
                    onChange={e =>
                      setObj({ ...obj, locationSource: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'RDM'}>RDM</option>
                    <option value={'LDM'}>LDM</option>
                  </NativeSelect>
                </FormControl>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
