/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputBase from '@material-ui/core/InputBase'
import RealEstate from '../contenttypes/RealEstate'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import apiConfig from '../../config/apiConfig'
import PACAMPayableBudget from '../contenttypes/PACAMPayableBudget'
import PACAMPayableReconciliation from '../contenttypes/PACAMPayableReconciliation'
import PACAMPayableUnexpected from '../contenttypes/PACAMPayableUnexpected'
import PACAMRebillBudget from '../contenttypes/PACAMRebillBudget'
import PACAMRebillReconciliation from '../contenttypes/PACAMRebillReconciliation'
import PACAMRebillUnexpected from '../contenttypes/PACAMRebillUnexpected'
import { useSelector } from 'react-redux'
import PALease from '../contenttypes/PALease'
import { refactoredObject } from '../common/fetchObjectForContentType'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
    marginTop: 24,
    backgroundColor: 'white',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  metadataHeader: {
    marginTop: 15,
    fontWeight: 'bold',
    fontSize: 20,
    color: '#830300',
    letterSpacing: 1.2,
  },
  metadataBody: {
    marginTop: 25,
  },
  metadataSubmitButton: {
    marginTop: 25,
    marginBottom: 25,
  },
  contentTypeStyle: {
    width: 300,
  },
  //Sets styling to all the input, selects fields in the child components
  selects: {
    width: 300,
    marginTop: 7.5,
    marginBottom: 7.5,
  },
  textfieldLabel: {
    marginLeft: 7,
  },
}))

const IndexMetaData = props => {
  const classes = useStyles()
  const [contentType, setContentType] = useState(null)
  const [metadataObj, setMetadataObj] = props.metaDataObj
  const [refreshCompleteMetadata, setRefreshCompleteMetadata] = useState(false)
  const [clearOnContentTypeChange, setClearOnContentTypeChange] = useState(
    false
  )

  const dispatch = useDispatch()
  const docGroupDocType = useSelector(
    state => state.common.contentTypeAndDocumentData
  )

  useEffect(() => {
    let contentTypeValues = Object.keys(docGroupDocType)
    if (contentTypeValues.length > 0) setContentType(contentTypeValues[0])
  }, [docGroupDocType])

  useEffect(() => {
    setMetadataObj({ ...metadataObj, contentType: contentType })
    //For clearing data in indexing when content type is changed but not on switching tabs
    if (metadataObj.contentType && metadataObj.contentType !== contentType) {
      setClearOnContentTypeChange(true)
    }
  }, [contentType])

  const fetchCompleteMetadata = () => {
    if (props.indexingStatus === apiConfig.apiUrl.COMPLETEINDEXINGSTATUS) {
      let url =
        apiConfig.apiUrl.fetch_complete_batch_metadata +
        props.recordId +
        apiConfig.apiUrl.key_url
      axios.get(url).then(res => {
        if (res.status === 200) {
          let newObject = refactoredObject(res.data.metadata)

          setMetadataObj(newObject)

          let metaDataCopy = Object.assign({}, props.metaDataReduxState)
          metaDataCopy[props.fileNameSelected] = res.data.metadata
          setContentType(res.data.metadata.content_type)
          dispatch({
            type: 'SAVE_INDEX_METADATA',
            payload: metaDataCopy,
          })
          setRefreshCompleteMetadata(true)
        }
      })
    }
  }

  React.useEffect(() => {
    // File has no redux storage
    if (
      props.savedMetaData &&
      props.type === apiConfig.apiUrl.INDEX &&
      props.indexingStatus === apiConfig.apiUrl.COMPLETEINDEXINGSTATUS &&
      Object.entries(props.savedMetaData).length === 0
    ) {
      fetchCompleteMetadata()
      return
    }
    //File has redux storage( COMPLETE FILES)
    if (
      props.savedMetaData &&
      props.type === apiConfig.apiUrl.INDEX &&
      props.indexingStatus === apiConfig.apiUrl.COMPLETEINDEXINGSTATUS &&
      Object.entries(props.savedMetaData).length > 0
    ) {
      setContentType(props.savedMetaData.content_type)
      let newObject = refactoredObject(props.savedMetaData)
      setMetadataObj(newObject)
      return
    }
    // File has redux storage normal files
    if (props.savedMetaData && Object.entries(props.savedMetaData).length > 0) {
      setContentType(props.savedMetaData.content_type)
      // setMetadataObj(props.savedMetaData) No need to set metadataObj as the content type components will handle it
      let newObject = refactoredObject(props.savedMetaData)

      setMetadataObj(newObject)
      return
    }
  }, [])

  const renderContentType = content_type => {
    switch (content_type) {
      case apiConfig.apiUrl.REALESTATE:
        return (
          <RealEstate
            {...props}
            bootStrapInput={BootstrapInput}
            useStyleClass={classes}
            contentType={contentType}
            docGroupDocType={docGroupDocType}
            refreshCompleteMetadata={refreshCompleteMetadata}
            clearOnContentTypeChange={clearOnContentTypeChange}
          />
        )

      case apiConfig.apiUrl.PACAMPAYABLEBUDGET:
        return (
          <PACAMPayableBudget
            {...props}
            bootStrapInput={BootstrapInput}
            useStyleClass={classes}
            contentType={contentType}
            docGroupDocType={docGroupDocType}
            refreshCompleteMetadata={refreshCompleteMetadata}
            clearOnContentTypeChange={clearOnContentTypeChange}
          />
        )
      case apiConfig.apiUrl.PACAMPAYABLERECONCILIATION:
        return (
          <PACAMPayableReconciliation
            {...props}
            bootStrapInput={BootstrapInput}
            useStyleClass={classes}
            contentType={contentType}
            docGroupDocType={docGroupDocType}
            refreshCompleteMetadata={refreshCompleteMetadata}
            clearOnContentTypeChange={clearOnContentTypeChange}
          />
        )
      case apiConfig.apiUrl.PACAMPAYABLEUNEXPECTED:
        return (
          <PACAMPayableUnexpected
            {...props}
            bootStrapInput={BootstrapInput}
            useStyleClass={classes}
            contentType={contentType}
            docGroupDocType={docGroupDocType}
            refreshCompleteMetadata={refreshCompleteMetadata}
            clearOnContentTypeChange={clearOnContentTypeChange}
          />
        )
      case apiConfig.apiUrl.PACAMREBILLBUDGET:
        return (
          <PACAMRebillBudget
            {...props}
            bootStrapInput={BootstrapInput}
            useStyleClass={classes}
            contentType={contentType}
            docGroupDocType={docGroupDocType}
            refreshCompleteMetadata={refreshCompleteMetadata}
            clearOnContentTypeChange={clearOnContentTypeChange}
          />
        )
      case apiConfig.apiUrl.PACAMREBILLRECONCILIATION:
        return (
          <PACAMRebillReconciliation
            {...props}
            bootStrapInput={BootstrapInput}
            useStyleClass={classes}
            contentType={contentType}
            docGroupDocType={docGroupDocType}
            refreshCompleteMetadata={refreshCompleteMetadata}
            clearOnContentTypeChange={clearOnContentTypeChange}
          />
        )
      case apiConfig.apiUrl.PACAMREBILLUNEXPECTED:
        return (
          <PACAMRebillUnexpected
            {...props}
            bootStrapInput={BootstrapInput}
            useStyleClass={classes}
            contentType={contentType}
            docGroupDocType={docGroupDocType}
            refreshCompleteMetadata={refreshCompleteMetadata}
            clearOnContentTypeChange={clearOnContentTypeChange}
          />
        )

      case apiConfig.apiUrl.PALEASE:
        return (
          <PALease
            {...props}
            bootStrapInput={BootstrapInput}
            useStyleClass={classes}
            contentType={contentType}
            docGroupDocType={docGroupDocType}
            refreshCompleteMetadata={refreshCompleteMetadata}
            clearOnContentTypeChange={clearOnContentTypeChange}
          />
        )

      default:
        return null
    }
  }

  return (
    <div className="metadata-contentype-form">
      <FormControl className={classes.contentTypeStyle}>
        <InputLabel htmlFor="content-type">Content Type</InputLabel>
        <NativeSelect
          id="content-type"
          value={contentType}
          onChange={e => setContentType(e.target.value)}
          input={<BootstrapInput />}
        >
          {docGroupDocType &&
            Object.keys(docGroupDocType).map(value => {
              return (
                <option value={value} key={value}>
                  {value}
                </option>
              )
            })}
        </NativeSelect>
      </FormControl>
      {renderContentType(contentType)}
    </div>
  )
}

export default IndexMetaData
