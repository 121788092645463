import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { workflowMain } from '../common/styles'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import Notes from './AddEditLocation'
import NewLocation from '../location/AddnewLocation'
import EditIcon from '@material-ui/icons/Edit'
import MaterialTable from 'material-table'
import { tableIcons } from '../common/commonFunctionalities'
import { useSelector, useDispatch } from 'react-redux'

export const Location = props => {
  const classes = workflowMain()
  // const [locationData, setLocationData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [locId, setLocId] = useState()
  const [editLoc, setEditLoc] = useState()
  const [addLocation, setAddLocation] = useState(false)
  const [reloadTable, setReloadTable] = useState(false)
  const savedLocationData = useSelector(state => state.location.locationData)
  const dispatch = useDispatch()

  const columns = [
    {
      field: 'locationId',
      title: 'LocationNumber',
      defaultSort: 'asc',
    },
    {
      field: 'name',
      title: 'LocationName',
    },
    {
      field: 'address',
      title: 'LocationAddress',
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      field: 'city',
      title: 'LocationCity',
    },
    {
      field: 'state',
      title: 'LocationState',
    },
    {
      field: 'postal_code',
      title: 'PostalCode',
    },
    {
      field: 'country',
      title: 'LocationCountry',
    },
    // {
    //   field: 'type',
    //   title: 'LocationType',
    // },
    {
      field: 'primary_use',
      title: 'PrimaryUse',
    },
    {
      field: 'region',
      title: 'LocationRegion',
    },
    {
      field: 'group',
      title: 'LocationGroup',
    },
    {
      field: 'district',
      title: 'LocationDistrict',
    },
    {
      field: 'open_date',
      title: 'OpeningDate',
    },
    {
      field: 'close_date',
      title: 'ClosingDate',
    },
    {
      field: 'acquisition_date',
      title: 'AcquisitionDate',
    },
    {
      field: 'disposition_date',
      title: 'DispositionDate',
    },
    {
      field: 'leased_or_owned',
      title: 'ChecklistType',
    },

    {
      field: 'location_source',
      title: 'LocationSource',
    },
  ]

  const getAllLocationData = () => {
    setIsLoading(true)
    let url = apiConfig.apiUrl.location_table

    axios
      .get(url)
      .then(response => {
        let data = response.data
        let tempList = []
        if (response.status === 200) {
          for (const item in data) {
            tempList.push({ ...data[item], locationId: item })
          }
        }
        // setLocationData(tempList)
        dispatch({ type: 'SAVE_LOCATION_DATA', payload: tempList })
        setIsLoading(false)
        setAddLocation(false)
        setReloadTable(false)
        props.setRefreshLocationData(false)
        props.setRefreshDispositionData(true)
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (savedLocationData && savedLocationData.length === 0) {
      getAllLocationData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (addLocation || reloadTable || props.refreshLocationData) {
      getAllLocationData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadTable, addLocation, props])

  return (
    <div>
      <Paper className={classes.paper}>
        <NewLocation flag={setAddLocation} {...props} />
        <br />
        <MaterialTable
          options={{
            showTitle: false,
            search: true,
            toolbar: true,
            filtering: false,
            exportButton: true,
            exportAllData: true,
            exportFileName: 'LocationData',
            pageSizeOptions: [10, 25, 50, 100],
            loadingType: 'overlay',
            pageSize: 100,
            headerStyle: { position: 'sticky', top: 0 },
            maxBodyHeight: '68vh',
            emptyRowsWhenPaging: false,
          }}
          columns={columns}
          data={savedLocationData}
          icons={tableIcons}
          isLoading={isLoading}
          actions={[
            {
              icon: EditIcon,
              tooltip: 'Edit Location',
              onClick: (event, rowData) => {
                setLocId(rowData.locid)
                setEditLoc(true)
              },
            },
          ]}
          localization={{
            body: {
              emptyDataSourceMessage: '',
            },
          }}
        />
        {editLoc ? (
          <Notes
            locId={locId}
            isEdit={editLoc}
            handleClosed={setEditLoc}
            setReloadTable={setReloadTable}
            setRefreshLeasedData={props.setRefreshLeasedData}
            setRefreshOwnedData={props.setRefreshOwnedData}
          />
        ) : null}
      </Paper>
    </div>
  )
}
