import apiConfig from '../../config/apiConfig'

/*This file is made to convert snake case to camel case because 
form state is saved that way for documents whose Indexingstatus=Complete
*/
export const refactoredObject = data => {
  switch (data.content_type) {
    case apiConfig.apiUrl.REALESTATE:
      return {
        documentGroup: data.document_group || '',
        documentType: data.document_type || '',
        locationNumber: data.location_number || '',
        locationName: data.location_name || '',
        locationCity: data.location_city || '',
        locationState: data.location_state || '',
        locationRegion: data.location_region || '',
        documentTitle: data.document_title || '',
        documentDate: data.document_date || '',
        expirationDate: data.expiration_date || '',
        // ? new Date(data.expiration_date)
        // : null,
        isUrgent: data.is_urgent, //|| 'No',
        documentStatus: data.document_status, // || 'Pending',
        recordingStatus: data.recording_status, // || 'Unrecordable',
        // docFollowUpNumber: data.doc_follow_up_number || '',
        legalFollowUpName: data.legal_follow_up_name || '',
        businessFollowUpName: data.business_follow_up_name || '',
        fileLetter: data.file_letter || '',
        fileLetterDescription: data.file_letter_description || '',
        physicalFileNumber: data.physical_file_number || '',
        documentPurpose: data.document_purpose || '',
        documentNotes: data.document_notes || '',
        contentType: data.content_type || '',
        // sapContractNumber: data.sap_contract_number || '',
        // codes: data.codes || '',
      }

    case apiConfig.apiUrl.PACAMPAYABLEBUDGET:
      return {
        locationNumber: data.location_number || '',
        locationName: data.location_name || '',
        locationState: data.location_state || '',
        locationCity: data.location_city || '',
        locationRegion: data.location_region || '',
        documentTitle: data.document_title || '',
        documentGroup: data.document_group || '',
        documentType: data.document_type || '',
        contentType: data.content_type || '',
        fiscalYear: data.fiscal_year || '',
        expiryYear: data.expiryYear || '',
        sapContractNumber: data.sap_contract_number || '',
        operator: data.operator || '',
        // targetPrsTotalRecAmount: data.target_prs_total_rec_amount || '',
        targetPrsBudgetAmount: data.target_prs_budget_amount || '',

        proRataShare: data.pro_rata_share || '',
      }

    case apiConfig.apiUrl.PACAMPAYABLERECONCILIATION:
      return {
        locationNumber: data.location_number || '',
        locationName: data.location_name || '',
        locationState: data.location_state || '',
        locationCity: data.location_city || '',
        locationRegion: data.location_region || '',
        documentTitle: data.document_title || '',
        documentGroup: data.document_group || '',
        documentType: data.document_type || '',
        contentType: data.content_type || '',
        fiscalYear: data.fiscal_year || '',
        expiryYear: data.expiryYear || '',
        sapContractNumber: data.sap_contract_number || '',
        operator: data.operator || '',
        // targetPrsBudgetAmount: data.target_prs_budget_amount || '',
        targetPrsTotalRecAmount: data.target_prs_total_rec_amount || '',

        proRataShare: data.pro_rata_share || '',
      }

    case apiConfig.apiUrl.PACAMPAYABLEUNEXPECTED:
      return {
        locationNumber: data.location_number || '',
        locationName: data.location_name || '',
        locationState: data.location_state || '',
        locationCity: data.location_city || '',
        locationRegion: data.location_region || '',
        documentTitle: data.document_title || '',
        documentGroup: data.document_group || '',
        documentType: data.document_type || '',
        contentType: data.content_type || '',
        fiscalYear: data.fiscal_year || '',
        expiryYear: data.expiryYear || '',
        sapContractNumber: data.sap_contract_number || '',
        totalDollarImpact: data.total_dollar_impact || '',
      }
    case apiConfig.apiUrl.PACAMREBILLBUDGET:
      return {
        locationNumber: data.location_number || '',
        locationName: data.location_name || '',
        locationState: data.location_state || '',
        locationCity: data.location_city || '',
        locationRegion: data.location_region || '',
        documentTitle: data.document_title || '',
        documentGroup: data.document_group || '',
        documentType: data.document_type || '',
        contentType: data.content_type || '',
        fiscalYear: data.fiscal_year || '',
        expiryYear: data.expiryYear || '',
        sapContractNumber: data.sap_contract_number || '',
        sapCustomerNumber: data.sap_customer_number || '',
        totalCustomerBudgetAmount: data.total_customer_budget_amount || '',
      }
    case apiConfig.apiUrl.PACAMREBILLRECONCILIATION:
      return {
        locationNumber: data.location_number || '',
        locationName: data.location_name || '',
        locationState: data.location_state || '',
        locationCity: data.location_city || '',
        locationRegion: data.location_region || '',
        documentTitle: data.document_title || '',
        documentGroup: data.document_group || '',
        documentType: data.document_type || '',
        contentType: data.content_type || '',
        fiscalYear: data.fiscal_year || '',
        expiryYear: data.expiryYear || '',
        sapContractNumber: data.sap_contract_number || '',
        sapCustomerNumber: data.sap_customer_number || '',
        totalCustomerRecAmount: data.total_customer_rec_amount || '',
      }
    case apiConfig.apiUrl.PACAMREBILLUNEXPECTED:
      return {
        locationNumber: data.location_number || '',
        locationName: data.location_name || '',
        locationState: data.location_state || '',
        locationCity: data.location_city || '',
        locationRegion: data.location_region || '',
        documentTitle: data.document_title || '',
        documentGroup: data.document_group || '',
        documentType: data.document_type || '',
        contentType: data.content_type || '',
        fiscalYear: data.fiscal_year || '',
        expiryYear: data.expiryYear || '',
        sapContractNumber: data.sap_contract_number || '',
        sapCustomerNumber: data.sap_customer_number || '',
        totalDollarImpact: data.total_dollar_impact || '',
      }

    case apiConfig.apiUrl.PALEASE:
      return {
        locationNumber: data.location_number || '',
        locationName: data.location_name || '',
        locationState: data.location_state || '',
        locationCity: data.location_city || '',
        locationRegion: data.location_region || '',
        documentTitle: data.document_title || '',
        // documentDate: data.document_date || '',
        contentType: data.content_type || '',
        documentGroup: data.document_group || '',
        documentType: data.document_type || '',
        // documentStatus: data.document_status, // || 'Pending',
        // documentPurpose: data.document_purpose || '',
        // agreementType: data.agreement_type || '',
        documentNotes: data.document_notes || '',
        sapContractNumber: data.sap_contract_number || '',
        fiscalYear: data.fiscal_year || '',
        expiryYear: data.expiryYear || '',
        // finance: data.finance || '',
      }

    default:
      return {}
  }
}
