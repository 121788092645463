let initialState = {
  searchFields: {
    location_number: '',
    document_title: '',
    document_date: '',
    document_group: '',
    document_type: '',
    document_purpose: '',
    file_letter: '',
    file_letter_description: '',
    physical_file_number: '',
  },
  tableData: [],
  selectedRows: [],
  mapSelectedRows: {},
  mapDialogRowsWithId: {},
  successfulRecords: new Set(),
  errRecords: new Set(),
  selectAllCurrentPage: false,
  enableSelect: 0,
  page: 0,
  rowsPerPage: 100,
}

export function BulkEditReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case 'SET_SEARCH_FIELDS':
      return Object.assign({}, state, {
        searchFields: { ...state.searchFields, ...payload },
      })

    case 'SAVE_TABLE_DATA':
      return Object.assign({}, state, {
        tableData: payload,
      })

    case 'SAVE_SELECTED_ROWS':
      return Object.assign({}, state, {
        selectedRows: payload,
      })

    case 'MAP_SELECTED_ROWS':
      return Object.assign({}, state, {
        mapSelectedRows: payload,
      })

    case 'MAP_DIALOG_ROWS':
      return Object.assign({}, state, {
        mapDialogRowsWithId: payload,
      })

    case 'SET_SUCCESSFUL_RECORDS':
      return Object.assign({}, state, {
        successfulRecords: payload,
      })

    case 'SET_ERR_RECORDS':
      return Object.assign({}, state, {
        errRecords: payload,
      })

    case 'SET_SELECT_ALL':
      return Object.assign({}, state, {
        selectAllCurrentPage: payload,
      })

    case 'ENABLE_SELECT':
      return Object.assign({}, state, {
        enableSelect: payload,
      })

    case 'SET_PAGE':
      return Object.assign({}, state, {
        page: payload,
      })

    case 'SET_PAGE_ROWS':
      return Object.assign({}, state, {
        rowsPerPage: payload,
      })

    default:
      return state
  }
}
