import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
// import './SearchStyle.css'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { useEffect } from 'react'
import NativeSelect from '@material-ui/core/NativeSelect'
import './bulkEdit.css'
import ClearAllIcon from '@material-ui/icons/ClearAll'
import Tooltip from '@material-ui/core/Tooltip'
import { validateDate } from '../common/validation'
import SearchIcon from '@material-ui/icons/Search'

const FormFields = ({
  classes,
  fieldsSelected,
  searchWithFields,
  documentGroupTypePayload,
  searchFields,
  setSearchFields,
  dateError,
  setDateError,
}) => {
  // const selectedFields = new Set(fieldsSelected)
  const [docTypeArr, setDocTypeArr] = useState([])

  const prevFormState = {
    location_number: '',
    document_title: '',
    document_date: '',
    document_group: '',
    document_type: '',
    document_purpose: '',
    file_letter: '',
    file_letter_description: '',
    physical_file_number: '',
  }

  useEffect(() => {
    let showValues = documentGroupTypePayload[searchFields.document_group]
    setDocTypeArr(showValues)
  }, [documentGroupTypePayload, searchFields.document_group])

  return (
    <div>
      <div className={classes.container}>
        {/* {selectedFields.has('LocationNumber') && ( */}
        {/* <div> */}
        <TextField
          id="LocationNumberSearch"
          type={String}
          value={searchFields.location_number}
          margin="normal"
          label="LocationNumber"
          className="shiftMargin"
          onChange={e => {
            setSearchFields({
              location_number: e.target.value,
            })
          }}
          onKeyPress={event => {
            if (event.key === 'Enter' && !dateError) {
              searchWithFields()
            }
          }}
        />
        &nbsp;
        {/* )} */}
        {/* {selectedFields.has('DocumentGroup') && ( */}
        <FormControl className={classes.dropdown}>
          <InputLabel id="bulk-documentgroup-dropdown-label">
            DocumentGroup
          </InputLabel>
          <NativeSelect
            id="bulk-documentgroup-dropdown"
            value={searchFields.document_group}
            onChange={e =>
              setSearchFields({
                document_group: e.target.value,
                document_type: '',
              })
            }
            //   placeholder="DocumentGroup"
          >
            <option value={''} key="empty"></option>
            {documentGroupTypePayload &&
              Object.keys(documentGroupTypePayload).map(value => {
                return (
                  <option value={value} key={value}>
                    {value}
                  </option>
                )
              })}
          </NativeSelect>
          &nbsp;
        </FormControl>
        {/* )} */}
        {/* {selectedFields.has('DocumentType') && ( */}
        <FormControl className={classes.dropdown}>
          <InputLabel id="bulk-documenttype-dropdown-label">
            DocumentType
          </InputLabel>
          <NativeSelect
            id="bulk-documenttype-dropdown"
            value={searchFields.document_type}
            onChange={e =>
              setSearchFields({
                document_type: e.target.value,
              })
            }
          >
            {docTypeArr &&
              docTypeArr.length > 0 &&
              docTypeArr.map(value => {
                return (
                  <option value={value} key={value}>
                    {value}
                  </option>
                )
              })}
          </NativeSelect>
          &nbsp;
        </FormControl>
        {/* )} */}
        {/* {selectedFields.has('DocumentTitle') && ( */}
        <TextField
          id="DocumentTitleSearch"
          type={String}
          value={searchFields.document_title}
          margin="normal"
          label="DocumentTitle"
          className="shiftMargin"
          onChange={e =>
            setSearchFields({
              document_title: e.target.value,
            })
          }
        />
        &nbsp;
        {/* )} */}
        {/* {selectedFields.has('DocumentDate') && ( */}
        <TextField
          id="DocumentDateSearch"
          type={String}
          margin="normal"
          value={searchFields.document_date}
          label="DocumentDate"
          className="shiftMargin"
          onChange={e => {
            let valid = validateDate(e.target.value)
            e.target.value === '' ? setDateError(false) : setDateError(valid)
            setSearchFields({
              document_date: e.target.value,
            })
          }}
          placeholder="mm/dd/yyyy"
          error={dateError}
          helperText={
            dateError ? 'Enter in correct format.For e.g. 09/23/2020' : ''
          }
          onKeyPress={event => {
            if (event.key === 'Enter' && !dateError) {
              searchWithFields()
            }
          }}
        />
        &nbsp;
        {/* </div> */}
        {/* ///////////// */}
        {/* <div> */}
        {/* )} */}
        {/* {selectedFields.has('DocumentPurpose') && ( */}
        <TextField
          id="DocumentPurposeSearch"
          type={String}
          value={searchFields.document_purpose}
          margin="normal"
          label="DocumentPurpose"
          className="shiftMargin"
          onChange={e =>
            setSearchFields({
              document_purpose: e.target.value,
            })
          }
          onKeyPress={event => {
            if (event.key === 'Enter' && !dateError) {
              searchWithFields()
            }
          }}
        />
        &nbsp;
        {/* )} */}
        {/* {selectedFields.has('FileLetter') && ( */}
        <TextField
          id="FileLetterSearch"
          type={String}
          margin="normal"
          value={searchFields.file_letter}
          label="FileLetter"
          className="shiftMargin"
          onChange={e =>
            setSearchFields({
              file_letter: e.target.value,
            })
          }
          onKeyPress={event => {
            if (event.key === 'Enter' && !dateError) {
              searchWithFields()
            }
          }}
        />
        &nbsp;
        {/* )} */}
        {/* {selectedFields.has('FileLetterDescription') && ( */}
        <TextField
          id="FileLetterDescriptionSearch"
          type={String}
          margin="normal"
          label="FileLetterDescription"
          value={searchFields.file_letter_description}
          className="shiftMargin"
          onChange={e =>
            setSearchFields({
              file_letter_description: e.target.value,
            })
          }
          onKeyPress={event => {
            if (event.key === 'Enter' && !dateError) {
              searchWithFields()
            }
          }}
        />
        &nbsp;
        {/* )} */}
        {/* {selectedFields.has('PhysicalFileNumber') && ( */}
        <TextField
          id="PhysicalFileNumberSearch"
          type="number"
          margin="normal"
          label="PhysicalFileNumber"
          value={searchFields.physical_file_number}
          className="shiftMargin"
          onChange={e =>
            setSearchFields({
              physical_file_number: e.target.value,
            })
          }
          onKeyPress={event => {
            if (event.key === 'Enter' && !dateError) {
              searchWithFields()
            }
          }}
        />
        &nbsp;
        {/* )} */}
        {/* {selectedFields.size > 0 && ( */}
        <div style={{ display: 'flex' }}>
          <div style={{ marginTop: '15px' }}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={searchWithFields}
              size="small"
              disabled={dateError}
            >
              <SearchIcon />
            </Button>
          </div>
          <div style={{ marginTop: '24px' }}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={e => {
                setSearchFields(prevFormState)
                setDateError(false)
              }}
            >
              <Tooltip title="Clear All">
                <ClearAllIcon />
              </Tooltip>
            </Button>
          </div>
        </div>
        {/* )} */}
        {/* </div> */}
      </div>
    </div>
  )
}

export default FormFields
