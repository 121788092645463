import React from 'react'
import Button from '@material-ui/core/Button'
import { tableIcons } from '../common/commonFunctionalities'
import DialogActions from '@material-ui/core/DialogActions'
import { useToasts } from 'react-toast-notifications'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import MaterialTable from 'material-table'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useAuth } from '@praxis/component-auth'
import moment from 'moment'

export default function DownloadMultiDialog({ rowData, size, close, maxSize }) {
  const { addToast } = useToasts()
  const FileDownload = require('js-file-download')
  const auth = useAuth()
  const { session } = auth
  const handleFileDownload = async () => {
    let payload = []
    let rows = rowData
    rows.forEach(element => {
      if (!maxSize.includes(element.fileSize)) {
        let tempObj = {}
        tempObj['file_name'] =
          element.LocationNumber + '_' + element.DocumentTitle + '.pdf'
        tempObj['toss_file_path'] = element.tossFilePath
        payload.push(tempObj)
      }
    })
    close()
    if (payload.length > 0) {
      addToast('Your Dowload would start automatically', {
        appearance: 'success',
        autoDismiss: true,
      })
      let url = apiConfig.apiUrl.multipleDownloadUrl
      let zipFileName =
        session.userInfo.lanId +
        '_RDMPLUS_' +
        moment().format('MMMM Do YYYY, HH:mm') +
        '.zip'
      await axios
        .post(url, payload, {
          responseType: 'arraybuffer',
        })
        .then(res => {
          FileDownload(res.data, zipFileName)
          if (res.status === '200') {
            console.log('success downloading')
          }
        })
        .catch(err => console.log(err.response))
    }
  }
  const columnsData = [
    {
      title: 'Location Number',
      field: 'LocationNumber',
    },
    {
      title: 'DocumentTitle',
      field: 'DocumentTitle',
      render: rowData =>
        rowData.DocumentTitle.length > 100 ? (
          <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
            {rowData.DocumentTitle}
          </div>
        ) : (
          <div>{rowData.DocumentTitle}</div>
        ),
    },
    {
      title: 'File Size (kb)',
      field: 'fileSize',
      defaultSort: 'desc',
    },
  ]
  return (
    <div>
      <MaterialTable
        title=""
        columns={columnsData}
        data={rowData}
        icons={tableIcons}
        options={{
          rowStyle: rowData => ({
            color: maxSize.includes(rowData.fileSize) ? 'red' : 'black',
          }),
          sorting: true,
          paging: false,
          toolbar: false,
          search: false,
        }}
      />
      <DialogActions>
        <Button onClick={close} color="primary" autoFocus>
          Cancel
        </Button>
        <Button
          onClick={handleFileDownload}
          color="primary"
          endIcon={<GetAppIcon />}
        >
          Download
        </Button>
      </DialogActions>
    </div>
  )
}
