import React, { useEffect, useState } from 'react'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import MaterialTable from 'material-table'
import { tableIcons } from '../common/commonFunctionalities'
import moment from 'moment'
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned'
import { getFieldValueWorkflow } from '../common/commonFunctionalities'
import { CsvBuilder } from 'filefy'
import Button from '@material-ui/core/Button'
import RefreshIcon from '@material-ui/icons/Refresh'

export const IncidentList = ({ flag, setFlag }) => {
  const [activeIncidents, setActiveIncidents] = useState([])
  const [isLoading, setIsLoading] = useState([true])
  const columns = [
    {
      field: 'incident_number',
      title: 'Incident Number',
      render: rowData => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={rowData.incident_url}
        >
          {' '}
          {rowData.incident_number}{' '}
        </a>
      ),
    },
    {
      field: 'created_date',
      title: 'Created Date',
      render: rowData => (
        <span>
          {rowData.created_date === ''
            ? ''
            : moment(rowData.created_date).format('MM/DD/YYYY HH:mm:ss')}
        </span>
      ),
      defaultSort: 'desc',
    },
    {
      field: 'caller.name',
      title: 'Caller',
    },
    {
      field: 'short_description',
      title: 'Short Description',
    },
    {
      field: 'priority',
      title: 'Priority',
      render: rowData => (
        <span>{rowData.priority === '4 - Low' ? 'Low' : 'High'}</span>
      ),
    },
    {
      field: 'state',
      title: 'State',
    },
    {
      field: 'assigned_to',
      title: 'Assigned To',
    },
  ]

  const getIncidents = () => {
    setIsLoading(true)
    let url = apiConfig.apiUrl.getIncident
    axios
      .get(url)
      .then(response => {
        setActiveIncidents(response.data)
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }
  useEffect(() => {
    getIncidents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (flag) {
      setIsLoading(true)

      let url = apiConfig.apiUrl.getIncident

      axios
        .get(url)
        .then(response => {
          setActiveIncidents(response.data)
          setIsLoading(false)
          setFlag(false)
        })
        .catch(err => {
          console.log(err)
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag])

  const exportCsv = dataToExport => {
    const data = dataToExport.map(rowData =>
      columns.map(columnDef => {
        return getFieldValueWorkflow(rowData, columnDef)
      })
    )

    const builder = new CsvBuilder('IncidentReport.csv')
    builder
      .setColumns(columns.map(columnDef => columnDef.title))
      .addRows(data)
      .exportFile()
  }

  return (
    <div>
      <MaterialTable
        options={{
          showTitle: false,
          search: false,
          // toolbar: true,
          filtering: true,
          // exportButton: true,
          // exportAllData: true,
          // exportFileName: 'ActiveIncidents',
          pageSizeOptions: [10, 25, 50, 100],
          loadingType: 'overlay',
          pageSize: 100,
          headerStyle: {
            position: 'sticky',
            top: 0,
            // backgroundColor: 'rgb(231, 231, 231)',
            backgroundColor: 'lightgrey',
            height: '50px',
          },
          maxBodyHeight: '68vh',
          emptyRowsWhenPaging: false,
        }}
        columns={columns}
        data={activeIncidents}
        icons={tableIcons}
        isLoading={isLoading}
        components={{
          Toolbar: props => (
            <div style={{ padding: '8px' }}>
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div style={{ marginLeft: 'auto' }}></div>
                <div>
                  <Button onClick={getIncidents}>
                    <RefreshIcon color="primary" />
                  </Button>
                </div>
                <div>
                  {' '}
                  <Button
                    onClick={event => {
                      exportCsv(props.data)
                    }}
                    color="primary"
                  >
                    <AssignmentReturnedIcon />
                  </Button>
                </div>
              </div>
            </div>
          ),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: '',
          },
        }}
      />
    </div>
  )
}
