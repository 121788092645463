/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import './PALeaseStyle.css'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSelector } from 'react-redux'
import apiConfig from '../../config/apiConfig'

const PALease = props => {
  return (
    <div>
      {props.type === apiConfig.apiUrl.INDEX ? (
        <PALeaseIndex {...props} />
      ) : (
        <PALeaseSearch {...props} />
      )}
    </div>
  )
}

const PALeaseSearch = props => {
  const [, setParentObj] = props.metaDataObj
  const [paSearchObj, setPaSearchObj] = useState({})

  useEffect(() => {
    if (
      (props.savedMetaData &&
        Object.entries(props.savedMetaData).length === 0) ||
      props.savedMetaData === undefined
    ) {
      setPaSearchObj(props.unmodifiedData)
    } else {
      let obj = returnPALeaseObj({ ...props.savedMetaData })
      let newObj = { ...obj, id: props.unmodifiedData.id }
      setPaSearchObj(newObj)
    }
  }, [])

  useEffect(() => {
    setParentObj(paSearchObj)
  }, [paSearchObj])

  return (
    <PALeaseForm
      {...props}
      plObj={[paSearchObj, setPaSearchObj]}
      // dateObj={[paObjDate, setPaObjDate]}
    />
  )
}

const PALeaseIndex = props => {
  const [parentObj, setParentObj] = props.metaDataObj
  const [paLeaseIndexObj, setPaLeaseIndexObj] = useState({})

  useEffect(() => {
    setParentObj(paLeaseIndexObj)
  }, [paLeaseIndexObj])

  useEffect(() => {
    if (Object.keys(parentObj).length > 0) {
      // let obj = refactoredObject({ ...parentObj })
      let newObj = { ...parentObj, contentType: props.contentType }
      setPaLeaseIndexObj(newObj)
    }
  }, [])

  //called only when indexing status = complete
  useEffect(() => {
    if (props.refreshCompleteMetadata) {
      // let obj = refactoredObject({ ...parentObj })
      let newObj = { ...parentObj, contentType: props.contentType }
      setPaLeaseIndexObj(newObj)
    }
  }, [props.refreshCompleteMetadata])

  //For clearing indexing data on switch of content type
  useEffect(() => {
    if (props.clearOnContentTypeChange) {
      let newObj = { contentType: props.contentType }
      setPaLeaseIndexObj(newObj)
    }
  }, [props.clearOnContentTypeChange])

  return (
    <PALeaseForm
      {...props}
      plObj={[paLeaseIndexObj, setPaLeaseIndexObj]}
      // dateObj={[plOjDate, setPlObjDate]}
    />
  )
}

const returnPALeaseObj = data => {
  return {
    locationNumber: data.location_number || '',
    locationName: data.location_name || '',
    locationState: data.location_state || '',
    locationCity: data.location_city || '',
    locationRegion: data.location_region || '',
    documentTitle: data.document_title || '',
    contentType: data.content_type || '',
    documentGroup: data.document_group || '',
    documentType: data.document_type || '',

    documentNotes: data.document_notes || '',
    sapContractNumber: data.sap_contract_number || '',
    fiscalYear: data.fiscal_year || '',
    expiryYear: data.expiry_year || '',
  }
}

const PALeaseForm = props => {
  const [paLeaseObj, setPaLeaseObj] = props.plObj
  // const [objDate, setObjDate] = props.dateObj
  const BootstrapInput = props.bootStrapInput
  const classes = props.useStyleClass
  const metadataObj = useSelector(state => state.common.locationData)
  const [locationData, setLocationData] = useState(metadataObj)
  const [docArray, setDocArray] = useState([])
  const [docTitleError, setDocTitleError] = useState(false)

  const { docGroupDocType, contentType } = props

  useEffect(() => {
    setDocArray(docGroupDocType[contentType][paLeaseObj.documentGroup])
  }, [paLeaseObj.documentGroup])

  useEffect(() => {
    if (locationData) {
      setPaLeaseObj({
        ...paLeaseObj,
        locationNumber: locationData.location_number,
        locationName: locationData.location_name,
        locationCity: locationData.location_city,
        locationState: locationData.location_state,
        locationRegion: locationData.location_region,
      })
    } else {
      setPaLeaseObj({
        ...paLeaseObj,
        locationNumber: '',
        locationName: '',
        locationCity: '',
        locationState: '',
        locationRegion: '',
      })
    }
  }, [locationData])

  return (
    <div className="contenttype-container">
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="palease-documentgroup">
          Document Group *
        </InputLabel>
        <NativeSelect
          id="palease-documentgroup"
          value={paLeaseObj.documentGroup}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.document_group
              : paLeaseObj.documentGroup
          }
          onChange={e =>
            setPaLeaseObj({ ...paLeaseObj, documentGroup: e.target.value })
          }
          input={<BootstrapInput />}
          required
        >
          {Object.keys(docGroupDocType[contentType]).map(value => {
            return (
              <option value={value} key={value}>
                {value}
              </option>
            )
          })}
        </NativeSelect>
      </FormControl>
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="palease-documenttype">
          Document Type *
        </InputLabel>
        <NativeSelect
          id="palease-documenttype"
          value={paLeaseObj.documentType}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.document_type
              : paLeaseObj.documentType
          }
          onChange={e =>
            setPaLeaseObj({ ...paLeaseObj, documentType: e.target.value })
          }
          input={<BootstrapInput />}
          required
        >
          {docArray &&
            docArray.map(value => {
              return (
                <option value={value} key={value}>
                  {value}
                </option>
              )
            })}
        </NativeSelect>
      </FormControl>
      {/* <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="palease-documentstatus">
          Document Status
        </InputLabel>
        <NativeSelect
          id="palease-documentstatus"
          value={paLeaseObj.documentStatus}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.document_status
              : paLeaseObj.documentStatus
          }
          onChange={e =>
            setPaLeaseObj({ ...paLeaseObj, documentStatus: e.target.value })
          }
          input={<BootstrapInput />}
          required
        >
          <option value=""></option>
          <option value={'Pending'}>Pending</option>
          <option value={'Active'}>Active</option>
        </NativeSelect>
      </FormControl> */}
      <Autocomplete
        id="palease-autopopulate"
        options={metadataObj}
        autoHighlight={true}
        getOptionLabel={option =>
          option.location_number ? option.location_number : ''
        }
        className="autocomplete"
        // getOptionLabel={option => option.location_number}
        onChange={(index, obj) => setLocationData(obj)}
        // blurOnSelect={true}
        value={{
          location_number: paLeaseObj.locationNumber,
          location_name: paLeaseObj.locationName,
          location_city: paLeaseObj.locationCity,
          location_state: paLeaseObj.locationState,
          location_region: paLeaseObj.locationRegion,
        }}
        type={Number}
        disabled={Object.entries(metadataObj).length ? false : true}
        renderInput={params => (
          <FormControl>
            <InputLabel
              shrink
              className={classes.textfieldLabel}
              htmlFor="palease-autopopulate"
            >
              Location Number{' *'}
            </InputLabel>
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              required
              // label="Location Number"
              className={classes.textField}
              variant="outlined"
            />
          </FormControl>
        )}
      />

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="palease-locationname"
        >
          Location Name{' *'}
        </InputLabel>
        <TextField
          required
          id="palease-locationname"
          // label={'Location Name'}
          type={String}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paLeaseObj.locationName || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.location_name
              : paLeaseObj.locationName
          }
          onChange={e =>
            setPaLeaseObj({ ...paLeaseObj, locationName: e.target.value })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="palease-state"
        >
          State{' *'}
        </InputLabel>
        <TextField
          required
          id="palease-state"
          // label={'State'}
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paLeaseObj.locationState || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.location_state
              : paLeaseObj.locationState
          }
          onChange={e =>
            setPaLeaseObj({ ...paLeaseObj, locationState: e.target.value })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="palease-city"
        >
          City{' *'}
        </InputLabel>
        <TextField
          required
          id="palease-city"
          // label={'City'}
          type={String}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paLeaseObj.locationCity || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.location_city
              : paLeaseObj.locationCity
          }
          onChange={e =>
            setPaLeaseObj({ ...paLeaseObj, locationCity: e.target.value })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="palease-region"
        >
          Region
        </InputLabel>
        <TextField
          required
          id="palease-region"
          // label="Region"
          type={String}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paLeaseObj.locationRegion || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.location_region
              : paLeaseObj.locationRegion
          }
          onChange={e =>
            setPaLeaseObj({ ...paLeaseObj, locationRegion: e.target.value })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="palease-documenttitle"
        >
          Document Title{' *'}
        </InputLabel>
        <TextField
          required
          id="palease-documenttitle"
          InputLabelProps={{
            shrink: true,
          }}
          // label={'Document Title'}
          type={String}
          inputProps={{ maxLength: '500' }}
          multiline
          rowsMax="4"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          // value={paLeaseObj.documentTitle || paLeaseObj.document_title}
          value={paLeaseObj.documentTitle || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.document_title
              : paLeaseObj.documentTitle
          }
          onChange={e => {
            if (e.target.value.length === 500) {
              setDocTitleError(true)
            } else setDocTitleError(false)
            setPaLeaseObj({ ...paLeaseObj, documentTitle: e.target.value })
          }}
          error={docTitleError}
          helperText={
            docTitleError ? 'You have reached the limit of 500 characters' : ''
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="palease-fiscalyear"
        >
          Fiscal Year{' '}
        </InputLabel>
        <TextField
          // label={'Fiscal Year'}
          type="number"
          id="palease-fiscalyear"
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paLeaseObj.fiscalYear || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.fiscal_year
              : paLeaseObj.fiscalYear
          }
          // onChange={e =>
          //   setPaLeaseObj({ ...paLeaseObj, fiscalYear: e.target.value })
          // }

          onChange={e => {
            if (e.target.value.length > 4) {
              return false
            } else {
              setPaLeaseObj({
                ...paLeaseObj,
                fiscalYear: e.target.value,
              })
            }
          }}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="palease-expiryyear"
        >
          Expiry Year{' *'}
        </InputLabel>
        <TextField
          type="number"
          id="palease-expiryyear"
          InputLabelProps={{
            shrink: true,
          }}
          required
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paLeaseObj.expiryYear || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.expiry_year
              : paLeaseObj.expiryYear
          }
          onChange={e => {
            if (e.target.value.length > 4) return false
            else {
              setPaLeaseObj({ ...paLeaseObj, expiryYear: e.target.value })
            }
          }}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="palease-sapcontractnumber"
        >
          SAP Contract Number{' '}
        </InputLabel>
        <TextField
          id="palease-sapcontractnumber"
          // label={'SAP Contract Number'}
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paLeaseObj.sapContractNumber || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.sap_contract_number
              : paLeaseObj.sapContractNumber
          }
          onChange={e =>
            setPaLeaseObj({ ...paLeaseObj, sapContractNumber: e.target.value })
          }
        />
      </FormControl>
      {/* <TextField
        id="palease-documentpurpose"
        label={'Document Purpose'}
        type={String}
        className={classes.textField}
        margin="normal"
        variant="outlined"
        value={paLeaseObj.documentPurpose || ''}
        defaultValue={
          props.savedMetaData
            ? props.savedMetaData.document_purpose
            : paLeaseObj.documentPurpose
        }
        onChange={e =>
          setPaLeaseObj({ ...paLeaseObj, documentPurpose: e.target.value })
        }
      /> */}
    </div>
  )
}
export default PALease
