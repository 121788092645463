let initialState = {
  files: [],
  uploadPercentage: 0,
  isUploading: false,
}

export function UploadReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case 'UPLOAD_FILES':
      return Object.assign({}, state, {
        files: payload,
      })

    case 'SET_UPLOAD_PERCENTAGE':
      return Object.assign({}, state, {
        uploadPercentage: payload,
      })

    case 'SET_IS_UPLOADING':
      return Object.assign({}, state, {
        isUploading: payload,
      })
    default:
      return state
  }
}
