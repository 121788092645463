import apiConfig from '../../config/apiConfig'

export const fetchContentTypeObj = (obj, userInfo, type, tossFilePath) => {
  if (
    obj.contentType === apiConfig.apiUrl.REALESTATE ||
    obj.content_type === apiConfig.apiUrl.REALESTATE
  ) {
    let tempJson = {
      location_number: obj.locationNumber || '',
      location_name: obj.locationName || '',
      location_state: obj.locationState || '',
      location_city: obj.locationCity || '',
      location_region: obj.locationRegion || '',
      document_title: obj.documentTitle || '',
      document_date: obj.documentDate || '',
      expiration_date: obj.expirationDate || '',
      is_urgent: obj.isUrgent || '',
      physical_file_number: obj.physicalFileNumber || '',
      // doc_follow_up_number: obj.docFollowUpNumber || '',
      legal_follow_up_name: obj.legalFollowUpName || '',
      business_follow_up_name: obj.businessFollowUpName || '',
      document_group: obj.documentGroup || '',
      document_type: obj.documentType || '',
      content_type: obj.contentType || '',
      document_status: obj.documentStatus || '',
      recording_status: obj.recordingStatus || '',
      document_purpose: obj.documentPurpose || '',
      file_letter: obj.fileLetter || '',
      file_letter_description: obj.fileLetterDescription || '',
      document_notes: obj.documentNotes || '',
      // sap_contract_number: obj.sapContractNumber || '',
      // codes: obj.codes || '',
    }
    if (type === apiConfig.apiUrl.INDEX) {
      tempJson.created_user_id = userInfo.lanId.toUpperCase()
      tempJson.created_user_name = userInfo.firstName + '.' + userInfo.lastName
      tempJson.source_toss_file_path = tossFilePath
      tempJson.content_type = apiConfig.apiUrl.REALESTATE
      return JSON.stringify(tempJson)
    }
    tempJson.modified_user_id = userInfo.lanId.toUpperCase()
    tempJson.modified_user_name = userInfo.firstName + '.' + userInfo.lastName
    return JSON.stringify(tempJson)
  }
  if (
    obj.contentType === apiConfig.apiUrl.PACAMPAYABLEBUDGET ||
    obj.content_type === apiConfig.apiUrl.PACAMPAYABLEBUDGET
  ) {
    let tempJson = {
      location_number: obj.locationNumber || '',
      location_name: obj.locationName || '',
      location_state: obj.locationState || '',
      location_city: obj.locationCity || '',
      location_region: obj.locationRegion || '',
      document_date: obj.documentDate || '',
      document_group: obj.documentGroup || '',
      document_type: obj.documentType || '',
      content_type: obj.contentType || '',
      document_title: obj.documentTitle || '',
      fiscal_year: obj.fiscalYear || '',
      expiry_year: obj.expiryYear || '',
      sap_contract_number: obj.sapContractNumber || '',
      operator: obj.operator || '',
      // target_prs_total_rec_amount: obj.targetprsTotalRecAmount || '',
      // target_prs_total_rec_amount: obj.targetPrsTotalRecAmount || '',
      target_prs_budget_amount: obj.targetPrsBudgetAmount || '',

      pro_rata_share: obj.proRataShare || '',
    }
    if (type === apiConfig.apiUrl.INDEX) {
      tempJson.created_user_id = userInfo.lanId.toUpperCase()
      tempJson.created_user_name = userInfo.firstName + '.' + userInfo.lastName
      tempJson.source_toss_file_path = tossFilePath
      tempJson.content_type = apiConfig.apiUrl.PACAMPAYABLEBUDGET

      return JSON.stringify(tempJson)
    }
    tempJson.modified_user_id = userInfo.lanId.toUpperCase()
    tempJson.modified_user_name = userInfo.firstName + '.' + userInfo.lastName
    return JSON.stringify(tempJson)
  }
  if (
    obj.contentType === apiConfig.apiUrl.PACAMPAYABLERECONCILIATION ||
    obj.content_type === apiConfig.apiUrl.PACAMPAYABLERECONCILIATION
  ) {
    let tempJson = {
      location_number: obj.locationNumber || '',
      location_name: obj.locationName || '',
      location_state: obj.locationState || '',
      location_city: obj.locationCity || '',
      location_region: obj.locationRegion || '',
      document_group: obj.documentGroup || '',
      document_type: obj.documentType || '',
      content_type: obj.contentType || '',
      document_title: obj.documentTitle || '',
      fiscal_year: obj.fiscalYear || '',
      expiry_year: obj.expiryYear || '',
      sap_contract_number: obj.sapContractNumber || '',
      operator: obj.operator || '',
      // target_prs_budget_amount: obj.targetPrsBudgetAmount || '',
      target_prs_total_rec_amount: obj.targetPrsTotalRecAmount || '',

      pro_rata_share: obj.proRataShare || '',
    }
    if (type === apiConfig.apiUrl.INDEX) {
      tempJson.created_user_id = userInfo.lanId.toUpperCase()
      tempJson.created_user_name = userInfo.firstName + '.' + userInfo.lastName
      tempJson.source_toss_file_path = tossFilePath
      tempJson.content_type = apiConfig.apiUrl.PACAMPAYABLERECONCILIATION
      return JSON.stringify(tempJson)
    }
    tempJson.modified_user_id = userInfo.lanId.toUpperCase()
    tempJson.modified_user_name = userInfo.firstName + '.' + userInfo.lastName
    return JSON.stringify(tempJson)
  }
  if (
    obj.contentType === apiConfig.apiUrl.PACAMPAYABLEUNEXPECTED ||
    obj.content_type === apiConfig.apiUrl.PACAMPAYABLEUNEXPECTED
  ) {
    let tempJson = {
      location_number: obj.locationNumber || '',
      location_name: obj.locationName || '',
      location_state: obj.locationState || '',
      location_city: obj.locationCity || '',
      location_region: obj.locationRegion || '',
      document_group: obj.documentGroup || '',
      document_type: obj.documentType || '',
      content_type: obj.contentType || '',
      document_title: obj.documentTitle || '',
      fiscal_year: obj.fiscalYear || '',
      expiry_year: obj.expiryYear || '',
      sap_contract_number: obj.sapContractNumber || '',
      total_dollar_impact: obj.totalDollarImpact || '',
    }
    if (type === apiConfig.apiUrl.INDEX) {
      tempJson.created_user_id = userInfo.lanId.toUpperCase()
      tempJson.created_user_name = userInfo.firstName + '.' + userInfo.lastName
      tempJson.source_toss_file_path = tossFilePath
      tempJson.content_type = apiConfig.apiUrl.PACAMPAYABLEUNEXPECTED
      return JSON.stringify(tempJson)
    }
    tempJson.modified_user_id = userInfo.lanId.toUpperCase()
    tempJson.modified_user_name = userInfo.firstName + '.' + userInfo.lastName
    return JSON.stringify(tempJson)
  }
  if (
    obj.contentType === apiConfig.apiUrl.PACAMREBILLBUDGET ||
    obj.content_type === apiConfig.apiUrl.PACAMREBILLBUDGET
  ) {
    let tempJson = {
      location_number: obj.locationNumber || '',
      location_name: obj.locationName || '',
      location_state: obj.locationState || '',
      location_city: obj.locationCity || '',
      location_region: obj.locationRegion || '',
      document_group: obj.documentGroup || '',
      document_type: obj.documentType || '',
      content_type: obj.contentType || '',
      document_title: obj.documentTitle || '',
      fiscal_year: obj.fiscalYear || '',
      expiry_year: obj.expiryYear || '',
      sap_contract_number: obj.sapContractNumber || '',
      sap_customer_number: obj.sapCustomerNumber || '',
      total_customer_budget_amount: obj.totalCustomerBudgetAmount || '',
    }
    if (type === `index`) {
      tempJson.created_user_id = userInfo.lanId.toUpperCase()
      tempJson.created_user_name = userInfo.firstName + '.' + userInfo.lastName
      tempJson.source_toss_file_path = tossFilePath
      tempJson.content_type = apiConfig.apiUrl.PACAMREBILLBUDGET
      return JSON.stringify(tempJson)
    }
    tempJson.modified_user_id = userInfo.lanId.toUpperCase()
    tempJson.modified_user_name = userInfo.firstName + '.' + userInfo.lastName
    return JSON.stringify(tempJson)
  }
  if (
    obj.contentType === apiConfig.apiUrl.PACAMREBILLRECONCILIATION ||
    obj.content_type === apiConfig.apiUrl.PACAMREBILLRECONCILIATION
  ) {
    let tempJson = {
      location_number: obj.locationNumber || '',
      location_name: obj.locationName || '',
      location_state: obj.locationState || '',
      location_city: obj.locationCity || '',
      location_region: obj.locationRegion || '',
      document_group: obj.documentGroup || '',
      document_type: obj.documentType || '',
      content_type: obj.contentType || '',
      document_title: obj.documentTitle || '',
      fiscal_year: obj.fiscalYear || '',
      expiry_year: obj.expiryYear || '',
      sap_contract_number: obj.sapContractNumber || '',
      sap_customer_number: obj.sapCustomerNumber || '',
      total_customer_rec_amount: obj.totalCustomerRecAmount || '',
    }
    if (type === apiConfig.apiUrl.INDEX) {
      tempJson.created_user_id = userInfo.lanId.toUpperCase()
      tempJson.created_user_name = userInfo.firstName + '.' + userInfo.lastName
      tempJson.source_toss_file_path = tossFilePath
      tempJson.content_type = apiConfig.apiUrl.PACAMREBILLRECONCILIATION
      return JSON.stringify(tempJson)
    }
    tempJson.modified_user_id = userInfo.lanId.toUpperCase()
    tempJson.modified_user_name = userInfo.firstName + '.' + userInfo.lastName
    return JSON.stringify(tempJson)
  }

  if (
    obj.contentType === apiConfig.apiUrl.PACAMREBILLUNEXPECTED ||
    obj.content_type === apiConfig.apiUrl.PACAMREBILLUNEXPECTED
  ) {
    let tempJson = {
      location_number: obj.locationNumber || '',
      location_name: obj.locationName || '',
      location_state: obj.locationState || '',
      location_city: obj.locationCity || '',
      location_region: obj.locationRegion || '',
      document_group: obj.documentGroup || '',
      document_type: obj.documentType || '',
      content_type: obj.contentType || '',
      document_title: obj.documentTitle || '',
      fiscal_year: obj.fiscalYear || '',
      expiry_year: obj.expiryYear || '',
      sap_contract_number: obj.sapContractNumber || '',
      sap_customer_number: obj.sapCustomerNumber || '',
      total_dollar_impact: obj.totalDollarImpact || '',
    }
    if (type === apiConfig.apiUrl.INDEX) {
      tempJson.created_user_id = userInfo.lanId.toUpperCase()
      tempJson.created_user_name = userInfo.firstName + '.' + userInfo.lastName
      tempJson.source_toss_file_path = tossFilePath
      tempJson.content_type = apiConfig.apiUrl.PACAMREBILLUNEXPECTED
      return JSON.stringify(tempJson)
    }
    tempJson.modified_user_id = userInfo.lanId.toUpperCase()
    tempJson.modified_user_name = userInfo.firstName + '.' + userInfo.lastName
    return JSON.stringify(tempJson)
  }
  // PA Lease
  if (
    obj.contentType === apiConfig.apiUrl.PALEASE ||
    obj.content_type === apiConfig.apiUrl.PALEASE
  ) {
    let tempJson = {
      location_number: obj.locationNumber || '',
      location_name: obj.locationName || '',
      location_state: obj.locationState || '',
      location_city: obj.locationCity || '',
      location_region: obj.locationRegion || '',
      document_title: obj.documentTitle || '',
      document_group: obj.documentGroup || '',
      document_type: obj.documentType || '',
      content_type: obj.contentType || '',

      document_notes: obj.documentNotes || '',
      sap_contract_number: obj.sapContractNumber || '',
      fiscal_year: obj.fiscalYear || '',
      expiry_year: obj.expiryYear || '',
    }

    if (type === apiConfig.apiUrl.INDEX) {
      tempJson.created_user_id = userInfo.lanId.toUpperCase()
      tempJson.created_user_name = userInfo.firstName + '.' + userInfo.lastName
      tempJson.source_toss_file_path = tossFilePath
      tempJson.content_type = apiConfig.apiUrl.PALEASE
      return JSON.stringify(tempJson)
    }
    tempJson.modified_user_id = userInfo.lanId.toUpperCase()
    tempJson.modified_user_name = userInfo.firstName + '.' + userInfo.lastName
    return JSON.stringify(tempJson)
  }
  let tempJson = {
    location_number: obj.locationNumber || '',
    location_name: obj.locationName || '',
    location_state: obj.locationState || '',
    location_city: obj.locationCity || '',
    location_region: obj.locationRegion || '',
    document_date: obj.documentDate || '',
    document_group: obj.documentGroup || '',
    document_type: obj.documentType || '',
    content_type: obj.contentType || '',
    document_status: obj.documentStatus || '',
    document_purpose: obj.documentPurpose || '',
    agreement_type: obj.agreementType || '',
    document_notes: obj.documentNotes || '',
    sap_contract_number: obj.sapContractNumber || '',
    fiscal_year: obj.fiscalYear || '',
    expiry_year: obj.expiryYear || '',
    finance: obj.finance || '',
    target_prs_total_rec_amount: obj.targetPrsTotalRecAmount || '',
  }
  if (type === apiConfig.apiUrl.INDEX) {
    tempJson.created_user_id = userInfo.lanId.toUpperCase()
    tempJson.created_user_name = userInfo.firstName + '.' + userInfo.lastName
    tempJson.source_toss_file_path = tossFilePath
    return JSON.stringify(tempJson)
  }
  tempJson.modified_user_id = userInfo.lanId.toUpperCase()
  tempJson.modified_user_name = userInfo.firstName + '.' + userInfo.lastName
  return JSON.stringify(tempJson)
}
