let initialState = {
  openedTabsIndex: {
    // columns: properties.search.metadataViewFields,
    data: [],
    documentViewer: [],
    tab: [], //opened Tab
    files: [], //opened Tabpanel
    value: 0, //current value of tab opened
  },
  indexMetaData: {},
  adGroup: {
    adGroupUserNamesArray: [],
    adGroupUserIdArray: [],
    adGroupUserIdNameMap: null,
    adGroupNameUserIdMap: null,
  },
  userSelected: {
    selectedUserName: '',
    toBeSelectedUserName: '',
    selectedUserId: '',
  },
  batch: {
    selectedBatch: '',
    toBeSelectedBatch: '',
  },
  batchesList: [],
}

let tempState

export function IndexReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case 'SAVE_OPENED_TABS_INDEX':
      return Object.assign({}, state, {
        openedTabsIndex: {
          ...state.openedTabsIndex,
          [payload.field]: payload.value,
        },
      })

    case 'SET_USER_AD_GROUP':
      return Object.assign({}, state, {
        adGroup: {
          ...state.adGroup,
          [payload.field]: payload.value,
        },
      })

    case 'SAVE_SELECTED_USER':
      tempState = Object.assign({}, state.userSelected, payload)

      return Object.assign({}, state, {
        userSelected: tempState,
      })

    case 'SAVE_SELECTED_BATCH':
      tempState = Object.assign({}, state.batch, payload)

      return Object.assign({}, state, {
        batch: tempState,
      })
    case 'SAVE_BATCH_LIST':
      return Object.assign({}, state, {
        batchesList: payload,
      })

    case 'SAVE_INDEX_METADATA':
      return Object.assign({}, state, { indexMetaData: action.payload })

    // case 'USER_LOGOUT':
    //   return initialState

    default:
      return state
  }
}
