import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import download from 'downloadjs'
// import JSZipUtils from 'jszip-utils'
import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useToasts } from 'react-toast-notifications'
import { IconButton } from '@material-ui/core'
import { ProgressBar } from 'react-bootstrap'
import { useAuth } from '@praxis/component-auth'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { validateGeneralUser } from '../common/validation'
import { useSelector } from 'react-redux'
const FileDownload = require('js-file-download')

const headCells = [
  {
    id: 'document_name',
    numeric: false,
    disablePadding: true,
    label: 'Filename',
  },
  {
    id: 'upload_date',
    numeric: true,
    disablePadding: false,
    label: 'Upload Date',
  },
  {
    id: 'upload_user',
    numeric: false,
    disablePadding: true,
    label: 'Upload User',
  },
  {
    id: 'download',
    numeric: false,
    disablePadding: false,
    label: 'Download',
  },
]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export const SupportingDocuments = ({
  open,
  handleClose,
  unmodifiedData,
  ...props
}) => {
  const [documentList, setDocumentList] = useState([])
  const [selected, setSelected] = React.useState([])
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadPercentCompleted, setDownloadPercentageComplete] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const { addToast } = useToasts()
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [fileName, setFileName] = useState([])
  //for including parent file
  const [checked1, setChecked1] = React.useState(false)
  const auth = useAuth()
  const { session } = auth
  const adgroups = useSelector(state => state.common.allAdgroupList)

  const loadDocumentList = () => {
    let url =
      apiConfig.apiUrl.get_supporting_documents_list +
      `/${unmodifiedData.documentId}${apiConfig.apiUrl.searchKey}`
    axios
      .get(url)
      .then(response => {
        setDocumentList(response.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (open) {
      loadDocumentList()
    } else {
      //resetting dialog on closing
      setSelected([])
      setDocumentList([])
      setChecked1(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, unmodifiedData.documentId])

  const downloadFiles = (event, selected) => {
    let fileFolderName =
      unmodifiedData.locationNumber + '_' + unmodifiedData.documentTitle
    let totalFiles = selected.length
    if (totalFiles === 0) {
      addToast('Please select files', {
        appearance: 'warning',
        autoDismiss: true,
      })
    } else {
      setIsDownloading(true)
      let count = 0
      let zip = require('jszip')()
      let percentCompleted = Math.round((count * 100) / totalFiles)
      setDownloadPercentageComplete(percentCompleted)

      if (checked1 === true) {
        selected.push({
          name: unmodifiedData.fileName,
          filepath: unmodifiedData.destTossFilePath,
        })
      }
      let fileUrl =
        apiConfig.auth.domainName.split('.')[0] === 'https://rdmplus'
          ? apiConfig.apiUrl.getFileUrlExternal
          : apiConfig.apiUrl.getFileUrl
      // eslint-disable-next-line array-callback-return
      selected.map(eachFile => {
        let encodedFilename = encodeURIComponent(eachFile.filepath)
        console.log(fileUrl + encodedFilename)
        axios
          .get(fileUrl + encodedFilename, {
            responseType: 'arraybuffer',
            headers: {
              Authorization: localStorage.access_token,
            },
          })
          .then(res => {
            zip.file(eachFile.name, res.data, { binary: true })
            count++
            let percentCompleted = Math.round((count * 100) / totalFiles)
            setDownloadPercentageComplete(percentCompleted)
            if (count === selected.length) {
              zip
                .generateAsync({ type: 'blob', compression: 'DEFLATE' })
                .then(function(content) {
                  download(content, fileFolderName)
                  setIsDownloading(false)
                  addToast('Files downloaded successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                  })
                  setSelected([])
                })
            }
          })
          .catch(err => {
            setIsDownloading(false)
            // throw err // or handle err
            addToast('Error!Files could not be downloaded', {
              appearance: 'error',
              autoDismiss: true,
            })
          })
      })
    }
  }

  const handleChange = event => {
    setChecked1(event.target.checked)
  }

  const deleteFiles = (event, selected) => {
    if (selected.length === 0) {
      addToast('Please select files', {
        appearance: 'warning',
        autoDismiss: true,
      })
    } else {
      setIsDeleting(true)
      let filename = selected.map(file => encodeURIComponent(file.name))
      let url =
        apiConfig.apiUrl.delete_supporting_documents_list +
        `${unmodifiedData.documentId}&fileNames=${filename.join(',')}${
          apiConfig.apiUrl.keyUrl
        }`
      axios
        .delete(url)
        .then(response => {
          if (response.status !== 200) throw Error()
          setSelected([])
          setIsDeleting(false)
          addToast('Files deleted successfully', {
            appearance: 'success',
            autoDismiss: true,
          })
          loadDocumentList()
        })
        .catch(err => {
          console.log(err)
          setSelected([])
          setIsDeleting(false)
          addToast('Error!Files could not be deleted', {
            appearance: 'error',
            autoDismiss: true,
          })
        })
    }
  }

  const uploadFile = async ({ target: { files } }) => {
    setIsUploading(true)
    let userData = session.userInfo
    let uploadUser = userData.fullName
    let url =
      apiConfig.apiUrl.upload_supporting_documents_list +
      `${unmodifiedData.documentId}&documentUser=${uploadUser}${apiConfig.apiUrl.keyUrl}`
    let numberOfFiles = files.length
    const filelist = Object.values(files)
    setFileName(filelist)
    if (numberOfFiles) {
      for (let i = 0; i < numberOfFiles; i++) {
        let data = new FormData()
        data.append('file', files[i])
        const options = {
          onUploadProgress: progressEvent => {
            const { loaded, total } = progressEvent
            let percent = Math.floor((loaded * 100) / total)
            if (percent < 100) {
              setUploadPercentage(percent)
            }
          },
        }

        await axios
          .put(url, data, options)
          // eslint-disable-next-line no-loop-func
          .then(res => {
            setUploadPercentage(100)
            setFileName(files => files.slice(1))
            loadDocumentList()
            setTimeout(() => {
              setUploadPercentage(0)
            }, 1000)
          })
          .catch(err => {
            console.warn(err)
            setUploadPercentage(0)
            setIsUploading(false)
            addToast('Error!Issue in uploading the file', {
              appearance: 'error',
              autoDismiss: true,
            })
            setFileName(files => files.slice(1))
          })
      }
      setFileName([])
      setIsUploading(false)
    }
  }

  const validateVendorSiteAndGeneralUser = () => {
    let isGeneralUser = validateGeneralUser(adgroups)
    let isVendorSite =
      apiConfig.auth.domainName.split('.')[0] === 'https://rdmplus'
        ? true
        : false

    if (isGeneralUser || isVendorSite) return false
    return true
  }

  return (
    <div>
      <Dialog
        open={open && props.currentTab === props.fileValue}
        onClose={handleClose}
        disableBackdropClick="true"
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        key={props.currentTab}
      >
        <DialogContent>
          <b>
            {fileName.map(item => {
              return (
                <div>
                  {' '}
                  <li> {item.name} </li>
                </div>
              )
            })}
          </b>
          <br />
          {uploadPercentage > 0 && (
            <ProgressBar
              striped
              now={uploadPercentage}
              active
              label={`${uploadPercentage}%`}
            />
          )}
          {documentList && (
            <EnhancedTable
              documentList={documentList}
              selected={selected}
              setSelected={setSelected}
            />
          )}

          <div style={{ marginLeft: '19px' }}>
            <FormControlLabel
              value="start"
              control={
                <Checkbox
                  style={{ color: '#AC0000' }}
                  checked={checked1}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Include Parent File"
              labelPlacement="end"
            />
            <div style={{ textAlign: 'right', marginTop: '-45px' }}>
              {validateVendorSiteAndGeneralUser() && (
                <label htmlFor="upload-file">
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    id="upload-file"
                    onClick={e => (e.target.value = null)}
                    multiple
                    onChange={uploadFile}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    disabled={
                      isDeleting ||
                      isDownloading ||
                      isUploading ||
                      selected.length > 0
                    }
                  >
                    {isUploading ? 'Uploading...' : 'Upload'}
                  </Button>
                </label>
              )}
              &nbsp;&nbsp;&nbsp;
              {validateVendorSiteAndGeneralUser() && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={event => deleteFiles(event, selected)}
                  disabled={isDeleting || isDownloading || isUploading}
                >
                  {isDeleting ? 'Deleting...' : 'Delete'}
                </Button>
              )}
              &nbsp;&nbsp;&nbsp;
              <Button
                color="primary"
                variant="contained"
                onClick={event => downloadFiles(event, selected)}
                disabled={isDownloading || isDeleting || isUploading}
              >
                {isDownloading ? (
                  <CircularProgress
                    variant="static"
                    // className={classes.commonIconStyle}
                    value={downloadPercentCompleted}
                    size="2rem"
                  />
                ) : (
                  'Download'
                )}
              </Button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}))

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles()
  const { numSelected } = props

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} files selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Supporting Documents
        </Typography>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

const EnhancedTable = ({ documentList, selected, setSelected }) => {
  const classes = useStyles()
  const { addToast } = useToasts()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('upload_date')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = documentList.map(n => {
        return { name: n.document_name, filepath: n.document_filepath }
      })
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const downloadSingleFile = (e, url, filename) => {
    axios
      .get(url, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: localStorage.access_token,
        },
      })
      .then(res => {
        FileDownload(res.data, filename)
        if (res.status === 200) {
          console.log('download succesful')
        } else if (res.status === 500) {
          addToast('Download failed', {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(err => console.log(err.response))
  }

  const handleClick = (event, name, filepath) => {
    const selectedFile = { name: name, filepath: filepath }
    let selectedIndex = selected.findIndex(item => item.name === name)

    // const selectedIndex = selected.indexOf(selectedFile)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, selectedFile)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = name => {
    let index = selected.findIndex(item => item.name === name)
    return index !== -1
  }
  let fileUrl =
    apiConfig.auth.domainName.split('.')[0] === 'https://rdmplus'
      ? apiConfig.apiUrl.getFileUrlExternal
      : apiConfig.apiUrl.getFileUrl

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={documentList.length}
            />
            <TableBody>
              {stableSort(documentList, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(
                    row.document_name
                    // row.document_filepath
                  )
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      onClick={event =>
                        handleClick(
                          event,
                          row.document_name,
                          row.document_filepath
                        )
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.document_filepath}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          on
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.document_name}
                      </TableCell>
                      <TableCell>{row.document_date}</TableCell>
                      <TableCell>{row.document_user}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          color="primary"
                          aria-label="Download file"
                          onClick={e =>
                            downloadSingleFile(
                              e,
                              fileUrl +
                                `${encodeURIComponent(
                                  row.document_filepath
                                )}&download=yes`,
                              row.document_name
                            )
                          }
                        >
                          <GetAppIcon />
                        </IconButton>{' '}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={documentList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
