import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import { useToasts } from 'react-toast-notifications'
import './AddLocationDataStyle.css'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import { validateDate } from '../common/validation'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

export default function FormDialog({
  locId,
  handleClosed,
  isEdit,
  setReloadTable,
}) {
  const [open, setOpen] = React.useState(false)
  const [dateError, setDateError] = useState(false)
  const [userName, setUserName] = useState([])
  const classes = useStyles()
  const { addToast } = useToasts()
  const [obj, setObj] = useState({
    locationId: '',
    region: '',
    validLocation: '',
    leaseActive: '',
    altaSurvey: '',
    plat: '',
    csp: '',
    condOfApproval: '',
    cpr: '',
    agreementToLease: '',
    lease: '',
    memoOfLease: '',
    westwoodReview: '',
    titlePolicy: '',
    titleExceptions: '',
    acquisitionNotice: '',
    transMemoChecklist: '',
    siteDevAgreement: '',
    operationsEasementAgreement: '',
    letterOfCredit: '',
    rentCommencement: '',
    holdUntil: '',
    // checklistStatus: 'Pending',
    assignedTo: '',
  })
  useEffect(() => {
    if (locId !== undefined && isEdit === true) {
      getLeasedDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locId, isEdit])

  const getLeasedDetails = () => {
    let url = apiConfig.apiUrl.getleasedloction + `${locId}`

    axios
      .get(url)
      .then(response => {
        setObj({
          locationId: response.data.locid,
          region: response.data.region,
          validLocation:
            response.data.valid_location === null
              ? ''
              : response.data.valid_location,
          leaseActive:
            response.data.lease_active === null
              ? ''
              : response.data.lease_active,
          altaSurvey:
            response.data.alta_survey === null ? '' : response.data.alta_survey,
          plat: response.data.plat === null ? '' : response.data.plat,
          csp:
            response.data.civil_site_plan === null
              ? ''
              : response.data.civil_site_plan,
          condOfApproval:
            response.data.conditions_of_approval === null
              ? ''
              : response.data.conditions_of_approval,
          cpr:
            response.data.capital_project_request === null
              ? ''
              : response.data.capital_project_request,
          agreementToLease:
            response.data.agreement_to_lease === null
              ? ''
              : response.data.agreement_to_lease,
          lease: response.data.lease === null ? '' : response.data.lease,
          memoOfLease:
            response.data.memorandum_of_lease === null
              ? ''
              : response.data.memorandum_of_lease,
          westwoodReview:
            response.data.westwood_review === null
              ? ''
              : response.data.westwood_review,
          titlePolicy:
            response.data.title_policy === null
              ? ''
              : response.data.title_policy,
          titleExceptions:
            response.data.title_exceptions === null
              ? ''
              : response.data.title_exceptions,
          acquisitionNotice:
            response.data.acquisition_notice === null
              ? ''
              : response.data.acquisition_notice,
          transMemoChecklist:
            response.data.transaction_memo_checklist === null
              ? ''
              : response.data.transaction_memo_checklist,
          siteDevAgreement:
            response.data.site_develop_agreement === null
              ? ''
              : response.data.site_develop_agreement,
          operationsEasementAgreement:
            response.data.operation_easement_agreement === null
              ? ''
              : response.data.operation_easement_agreement,
          letterOfCredit:
            response.data.letter_of_credit === null
              ? ''
              : response.data.letter_of_credit,
          rentCommencement:
            response.data.rent_commencement === null
              ? ''
              : response.data.rent_commencement,
          holdUntil: response.data.hold_until,
          // checklistStatus:
          //   response.data.checklist_status === null
          //     ? ''
          //     : response.data.checklist_status,
          assignedTo:
            response.data.assignedTo === null ? '' : response.data.assigned_to,
        })

        setOpen(true)
      })
      .catch(err => console.log(err))
  }

  const updateLeasedLocationSubmit = event => {
    let payload = {}
    let locid = obj.locationId
    let check_list =
      (obj.validLocation === 'Yes' || obj.validLocation === 'N/A') &&
      (obj.leaseActive === 'Yes' || obj.leaseActive === 'N/A') &&
      (obj.altaSurvey === 'Yes' || obj.altaSurvey === 'N/A') &&
      (obj.plat === 'Yes' || obj.plat === 'N/A') &&
      (obj.csp === 'Yes' || obj.csp === 'N/A') &&
      (obj.condOfApproval === 'Yes' || obj.condOfApproval === 'N/A') &&
      (obj.cpr === 'Yes' || obj.cpr === 'N/A') &&
      (obj.agreementToLease === 'Yes' || obj.agreementToLease === 'N/A') &&
      (obj.lease === 'Yes' || obj.lease === 'N/A') &&
      (obj.memoOfLease === 'Yes' || obj.memoOfLease === 'N/A') &&
      (obj.westwoodReview === 'Yes' || obj.westwoodReview === 'N/A') &&
      (obj.titlePolicy === 'Yes' || obj.titlePolicy === 'N/A') &&
      (obj.titleExceptions === 'Yes' || obj.titleExceptions === 'N/A') &&
      (obj.acquisitionNotice === 'Yes' || obj.acquisitionNotice === 'N/A') &&
      (obj.transMemoChecklist === 'Yes' || obj.transMemoChecklist === 'N/A') &&
      (obj.siteDevAgreement === 'Yes' || obj.siteDevAgreement === 'N/A') &&
      (obj.operationsEasementAgreement === 'Yes' ||
        obj.operationsEasementAgreement === 'N/A') &&
      (obj.letterOfCredit === 'Yes' || obj.letterOfCredit === 'N/A') &&
      (obj.rentCommencement === 'Yes' || obj.rentCommencement === 'N/A')
        ? 'Complete'
        : 'Pending'
    payload['location_number'] = obj.locationId
    payload['region'] = obj.region
    payload['valid_location'] = obj.validLocation
    payload['lease_active'] = obj.leaseActive
    payload['alta_survey'] = obj.altaSurvey
    payload['plat'] = obj.plat
    payload['civil_site_plan'] = obj.csp
    payload['conditions_of_approval'] = obj.condOfApproval
    payload['capital_project_request'] = obj.cpr
    payload['agreement_to_lease'] = obj.agreementToLease
    payload['lease'] = obj.lease
    payload['memorandum_of_lease'] = obj.memoOfLease
    payload['westwood_review'] = obj.westwoodReview
    payload['title_policy'] = obj.titlePolicy
    payload['title_exceptions'] = obj.titleExceptions
    payload['acquisition_notice'] = obj.acquisitionNotice
    payload['transaction_memo_checklist'] = obj.transMemoChecklist
    payload['site_develop_agreement'] = obj.siteDevAgreement
    payload['operation_easement_agreement'] = obj.operationsEasementAgreement
    payload['letter_of_credit'] = obj.letterOfCredit
    payload['rent_commencement'] = obj.rentCommencement
    payload['hold_until'] = obj.holdUntil
    payload['checklist_status'] = check_list
    payload['assigned_to'] = obj.assignedTo

    let url = apiConfig.apiUrl.update_leasedlocation + `/${locid}`

    axios
      .put(url, payload)
      .then(res => {
        addToast('Leased Checklist saved successfully', {
          appearance: 'success',
          autoDismiss: true,
        })
        setReloadTable(true)
        handleClose()
      })
      .catch(err => {
        console.log(err)
        addToast('Error!Leased checklist could not be saved.', {
          appearance: 'error',
        })

        handleClose()
      })
  }

  const handleClose = () => {
    setOpen(false)
    setObj('')
    handleClosed(false)
  }

  const handleSaveEdit = e => {
    e.preventDefault()
    if (isEdit) {
      updateLeasedLocationSubmit()
    }
  }

  useEffect(() => {
    let url = `${apiConfig.apiUrl.location_adgroup_url}`
    axios.get(url).then(res => {
      if (res.status === 200 && Object.entries(res.data).length > 0) {
        let usernameArray = Object.keys(res.data.display_name_list)
        let tempArray = ['']
        tempArray = tempArray.concat(usernameArray)
        setUserName(tempArray)
        return
      }
      if (res.status !== 200) {
        throw new Error(`The server responded with the status  ` + res.status)
      }
    })
  }, [])

  return (
    <div style={{ marginLeft: '90%' }}>
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick="true"
        aria-labelledby="form-dialog-title"
      >
        <form validate autoComplete="off" onSubmit={handleSaveEdit}>
          <DialogTitle id="form-dialog-title">
            Edit Leased Checklist Data
          </DialogTitle>
          <DialogContent>
            <div className="metadata-container">
              <div className="metadata-textfield">
                <TextField
                  required
                  disabled
                  id="outlined-search"
                  label={'Location ID'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.locationId}
                  onChange={e => setObj({ ...obj, locationId: e.target.value })}
                />
                <br />
                <TextField
                  required
                  id="outlined-search"
                  label={'Region'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.region}
                  onChange={e => setObj({ ...obj, region: e.target.value })}
                />{' '}
                <br />
                <br />
              </div>
              <div className="metadata-dropdowns">
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Valid Location?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.validLocation}
                    onChange={e =>
                      setObj({ ...obj, validLocation: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Lease Active?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.leaseActive}
                    onChange={e =>
                      setObj({ ...obj, leaseActive: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Alta Survey?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.altaSurvey}
                    onChange={e =>
                      setObj({ ...obj, altaSurvey: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    PLAT?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.plat}
                    onChange={e => setObj({ ...obj, plat: e.target.value })}
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Civil Site Plan?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.csp}
                    onChange={e => setObj({ ...obj, csp: e.target.value })}
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Conditions of Approval?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.condOfApproval}
                    onChange={e =>
                      setObj({ ...obj, condOfApproval: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Capital Project Request?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.cpr}
                    onChange={e => setObj({ ...obj, cpr: e.target.value })}
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Agreement to Lease?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.agreementToLease}
                    onChange={e =>
                      setObj({ ...obj, agreementToLease: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Lease?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.lease}
                    onChange={e => setObj({ ...obj, lease: e.target.value })}
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Memorandum of Lease?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.memoOfLease}
                    onChange={e =>
                      setObj({ ...obj, memoOfLease: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Acreage Review?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.westwoodReview}
                    onChange={e =>
                      setObj({ ...obj, westwoodReview: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Title Policy?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.titlePolicy}
                    onChange={e =>
                      setObj({ ...obj, titlePolicy: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Title Exceptions?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.titleExceptions}
                    onChange={e =>
                      setObj({ ...obj, titleExceptions: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Acquistion Notice?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.acquisitionNotice}
                    onChange={e =>
                      setObj({ ...obj, acquisitionNotice: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Transaction memo checklist?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.transMemoChecklist}
                    onChange={e =>
                      setObj({ ...obj, transMemoChecklist: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Site Development Agreement?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.siteDevAgreement}
                    onChange={e =>
                      setObj({ ...obj, siteDevAgreement: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Operation & Easement Agreement?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.operationsEasementAgreement}
                    onChange={e =>
                      setObj({
                        ...obj,
                        operationsEasementAgreement: e.target.value,
                      })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Letter of Credit?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.letterOfCredit}
                    onChange={e =>
                      setObj({ ...obj, letterOfCredit: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Rent Commencement?
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.rentCommencement}
                    onChange={e =>
                      setObj({ ...obj, rentCommencement: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    <option value={'N/A'}>N/A</option>
                  </NativeSelect>
                </FormControl>
                {/* <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Checklist Status
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.checklistStatus}
                    onChange={e =>
                      setObj({ ...obj, checklistStatus: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    <option value={''}></option>
                    <option value={'Complete'}>Complete</option>
                    <option value={'Pending'}>Pending</option>
                  </NativeSelect>
                </FormControl> */}
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="demo-customized-select-native">
                    Assigned To
                  </InputLabel>
                  <NativeSelect
                    required
                    id="demo-customized-select-native"
                    value={obj.assignedTo}
                    onChange={e =>
                      setObj({ ...obj, assignedTo: e.target.value })
                    }
                    input={<BootstrapInput />}
                  >
                    {userName.map(value => {
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      )
                    })}
                  </NativeSelect>
                </FormControl>
              </div>
              <br />
              <div className="metadata-textfield">
                <TextField
                  required
                  id="realestate-documentdate"
                  label="Hold Until?"
                  type={String}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={obj.holdUntil || undefined}
                  onChange={event => {
                    let valid = validateDate(event.target.value)
                    setDateError(valid)

                    setObj({
                      ...obj,
                      holdUntil: event.target.value,
                    })
                  }}
                  placeholder="mm/dd/yyyy"
                  error={dateError}
                  helperText={
                    dateError
                      ? 'Enter in correct format.For e.g. 09/23/2020'
                      : ''
                  }
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* {console.log(obj)} */}
    </div>
  )
}
