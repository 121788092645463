import { withStyles, makeStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import TextField from '@material-ui/core/TextField'

export const commonStyle = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  information: {
    padding: '24px 12px 0px 24px',
    fontSize: '16px',
    lineHeight: '2rem',
    border: '0',
    borderRight: '2px solid rgba(0,0,0,.1)',
  },
  outerContainer: {
    backgroundColor: 'grey',
  },
  textField: {
    color: 'red',

    width: '100%',
    marginTop: 15,
    backgroundColor: 'white',
  },
  heading: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  contentTypeStyle: {
    width: '100%',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  paper: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    marginTop: '1px',
    marginBottom: '1px',
  },
  buttonDiv: {
    border: '0',
    borderRight: '2px solid rgba(0,0,0,.1)',
  },
  horizontalLine: {
    borderTop: '2px solid rgba(0,0,0,.1)',
  },
  table: {
    minWidth: 750,
  },
  centerButton: {
    margin: 'auto',
    display: 'block',
  },
  actionButtonDiv: {
    padding: '8px',
  },
  button: {
    marginLeft: '15px',
  },
  taskPane: {
    boxShadow: 'rgba(0, 0, 0, 0.2) 5px 5px 15px 0px',
    margin: '32px',
    padding: '16px',
    border: '1px solid #ced4da',
    minHeight: '50vh',
  },
  // b8b5b5
  buttonContainer: {
    padding: '8px 16px 8px 8px',
    textAlign: 'end',
  },
  label: {
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  labelText: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  buttonSection: {
    textAlign: 'center',
  },
  errorMessage: {
    color: 'red',
    marginLeft: '2px',
  },
  metadataOuterContainer: {
    width: '30%',
    display: 'inline-block',
    backgroundColor: '#e6e6e6',
    height: '95vh',
    overflowY: 'auto',
  },
  datePicker: {
    '& div': {
      '&:first-of-type': {
        width: '100%',
        backgroundColor: 'white',
      },
    },
    '& p': {
      fontSize: '1rem',
      color: 'rgba(0, 0, 0, 0.54)',
      fontWeight: 'bold',
      marginLeft: '8px',
      marginTop: '8px',
    },
  },
  multipleSelect: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 16,
  },
}))

export const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    fontWeight: 'bold',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

export const workflowMain = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  img: {
    width: '90%',
    display: 'flex',
  },
  textField: {
    color: 'red',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  button: {
    padding: '15px 4px',
    margin: theme.spacing(1),
    display: 'inline-block',
    width: 150,
    color: 'white',
    backgroundColor: '#AC0000',
    '&:hover': {
      backgroundColor: '#AC0000',
    },
    cursor: 'pointer',
  },
  container: {
    width: '100%',
    padding: '2px',
    border: '0.5px solid #ced4da',
    height: '100vh',
  },
  centralize: {
    textAlign: 'center',
    marginLeft: '16px',
  },
  selectAll: {
    marginLeft: '16px',
    marginBottom: '0px',
  },
  exportTextfield: {
    display: 'flex',
  },
  checkboxGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-evenly',
    justifyContent: 'space-between',
  },
  individualCheckbox: {
    width: 250,
  },
  exportLabel: {
    alignItems: 'center',
    paddingTop: '2rem',
    fontSize: '1rem',
  },
  exportText: {
    flexGrow: 2,
  },
  bucketButton: {
    marginTop: '3px',
  },
  badgeStyle: {
    color: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(172, 0, 0)',
    borderRadius: '10%',
    width: '50px',
    height: '25%',
    fontSize: '0.95rem',
    fontWeight: '800',
    textAlign: 'center',
  },
}))

export const checkFileStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  commonIconStyle: {
    marginTop: 15,
    '&:hover': {
      color: 'white',
      backgroundColor: 'darkred',
    },
    marginBottom: 10,
  },
  checkedOutButtonStyle: {
    color: 'darkred',
    fontWeight: 'bold',
    // marginTop: 10,
    '&:hover': {
      color: 'white',
      backgroundColor: 'darkred',
    },
  },
  checkoutButtonLink: {
    display: 'none',
    pointerEvents: 'none',
  },
}))

//Bulk Edit style
export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'grey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#AC0000',
      },
      '&:hover fieldset': {
        borderColor: '#AC0000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#AC0000',
      },
    },
  },
})(TextField)

export const BulkEditSearchStyle = makeStyles(theme => ({
  root: {
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    display: 'inline-block',
  },
  margin: {
    margin: theme.spacing(1),
    width: '200px',
  },
  margin1: {
    margin: theme.spacing(1),
    width: '430px',
    marginTop: '1px',
  },
  margin2: {
    margin: theme.spacing(1),
    width: '100px',
    marginTop: '-6px',
  },
  button: {
    margin: theme.spacing(1),
    display: 'inline-block',
    color: 'white',
    backgroundColor: '#AC0000',
    '&:hover': {
      backgroundColor: '#AC0000',
    },
  },
  fab: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundColor: '#AC0000',
    '&:hover': {
      backgroundColor: '#AC0000',
    },
  },
  searchSection: {
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 10px',
    flexFlow: 'wrap',
  },
  dropdown: {
    margin: theme.spacing(1),
    minWidth: 125,
    maxWidth: 150,
  },
  dialogDropdown: {
    marginTop: theme.spacing(1),
    minWidth: 150,
  },
  searchArea: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 16,
    justifyContent: 'center',
  },
  avatarColor: {
    color: '#fff',
    backgroundColor: '#AC0000',
  },
  placeholderStyle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: 'none',
    fontWeight: 500,
  },
  selectAll: {
    marginLeft: '16px',
    marginBottom: '0px',
  },
}))

export const HealthCheckStyle = makeStyles(theme => ({
  card: {
    width: 300,
    // margin: 'auto',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    // '&:hover': {
    //   boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    // },
    border: '1px solid gray',
  },
  loader: {
    marginLeft: '45%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 20,
    color: 'dimgray',
  },
  pos: {
    marginBottom: 12,
  },
  targetButton: {
    width: 80,
    height: 80,
    marginLeft: '45%',
    paddingTop: '1%',
  },
  circularButton: {
    backgroundColor: 'white',
    outline: 'red',
    borderRadius: '100%',
    width: 80,
    height: 80,
    cursor: 'pointer',
    alignItems: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.2) 5px 5px 15px 0px',
    paddingTop: 30,
    fontWeight: 800,
    color: 'brown',
    backgroundImage:
      'linear-gradient(to right bottom,#d16ba5, #f17188, #fc8567, #f2a24b, #d5c243, #b9d153, #98de6d, #6fe98e, #56e8a3, #3ce7b6, #26e5c8, #1fe2d6)',
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    height: '90vh',
    // paddingTop: '5%',
  },
  cardBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  innerContainer: {
    maxWidth: 480,
    marginLeft: '30%',
    boxShadow: 'rgba(0, 0, 0, 0.2) 5px 5px 15px 0px',
    paddingTop: '2%',
    border: '1px solid #ced4da',
  },
  progress: {
    // color: '#22c1c3',
    color: '#1aa32c',
  },
  failure: {
    color: '#fc5646',
  },
}))
