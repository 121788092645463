import React from 'react'
import MaterialTable from 'material-table'
import { tableIcons } from '../common/commonFunctionalities'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'

const columns = [
  {
    field: 'fileLetter',
    title: 'File Letter',
    defaultSort: 'asc',
    editable: 'never',
  },
  {
    field: 'fileLetterDesc',
    title: 'File Letter Description',
    editable: 'never',
  },
  {
    field: 'fileType',
    title: 'File Type',
    editable: 'never',
  },

  {
    field: 'operation',
    title: 'Operation',
  },

  {
    field: 'checkedOutFiles',
    title: 'CheckedOut Files',
    editable: 'never',
  },
  {
    field: 'checkedInFiles',
    title: 'CheckedIn Files',
  },

  {
    field: 'checkOutBy',
    title: 'CheckOut By',
  },
  {
    field: 'checkInBy',
    title: 'CheckIn By',
  },
  {
    field: 'checkOutDate',
    title: 'CheckOut Date',
  },
  {
    field: 'checkInDate',
    title: 'CheckIn Date',
  },

  {
    field: 'totalFiles',
    title: 'Total Files',
    editable: 'never',
  },
  {
    field: 'internalRequester',
    title: 'Internal Requester',
  },
  {
    field: 'externalRequester',
    title: 'External Requester',
  },
]
const CheckoutHistory = ({ open, data, handleClose, loading }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        // disableBackdropClick="true"
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogContent>
          <MaterialTable
            title={'Checkout History'}
            options={{
              search: false,
            }}
            columns={columns}
            data={data}
            icons={tableIcons}
            isLoading={loading}
            localization={{
              body: {
                emptyDataSourceMessage: '',
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default CheckoutHistory
