let initialState = {
  authDetails: {},
  locationData: {},
  documentGroupAndDocumentType: {},
  contentTypeAndDocumentData: {},
  triggerIframeRerender: '',
  allAdgroupList: null,
  documentGroupAndDocumentTypeBulkEdit: {},
}

export function CommonReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case 'SAVE_AUTHDETAILS':
      return Object.assign({}, state, {
        authDetails: payload,
      })
    case 'SAVE_LOCATIONDATA':
      return Object.assign({}, state, {
        // locationData: [payload],
        locationData: payload,
      })
    case 'SAVE_DOCUMENT_GROUP_DOCUMENT_TYPE':
      return Object.assign({}, state, {
        documentGroupAndDocumentType: payload,
      })

    case 'SAVE_CONTENT_TYPE_DOCUMENT_GROUP_DOCUMENT_TYPE':
      return Object.assign({}, state, {
        contentTypeAndDocumentData: payload,
      })

    case 'SAVE_ALL_ADGROUPS':
      return Object.assign({}, state, {
        allAdgroupList: payload,
      })

    case 'TRIGGER_IFRAME_COUNTER':
      return Object.assign({}, state, {
        triggerIframeRerender: payload,
      })

    case 'SAVE_DOCUMENT_GROUP_DOCUMENT_TYPE_BULK_EDIT': {
      return Object.assign({}, state, {
        documentGroupAndDocumentTypeBulkEdit: payload,
      })
    }
    default:
      return state
  }
}
