import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    bottom: 'auto',
    padding: '10px',
    paddingRight: '0px',
  },
  plus: {
    bottom: 'auto',
    padding: '15px',
    fontStyle: 'italic',
    paddingLeft: '0px',

    fontWeight: 'bolder',
  },
}))

export default function AppBarRDM() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="Menu"
      >
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 21 21"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs />
          <g
            id="Welcome"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="atoms---pattern-library"
              transform="translate(-485.000000, -1609.000000)"
              fill="#fdffff"
            >
              <g
                id="bullseye-copy-5"
                transform="translate(485.000000, 1609.000000)"
              >
                <g id="Group">
                  <path
                    d="M10.5,0 C4.7,0 0,4.702 0,10.5 C0,16.3 4.7,21 10.5,21 C16.303,21 21,16.3 21,10.5 C21,4.702 16.302,0 10.5,0 M10.5,17.55 C6.606,17.55 3.45,14.394 3.45,10.5 C3.45,6.606 6.606,3.45 10.5,3.45 C14.395,3.45 17.55,6.606 17.55,10.5 C17.55,14.394 14.396,17.55 10.5,17.55"
                    id="Shape"
                  />
                  <path
                    d="M10.5,6.9 C8.512,6.9 6.9,8.51 6.9,10.5 C6.9,12.488 8.512,14.1 10.5,14.1 C12.488,14.1 14.1,12.49 14.1,10.5 C14.1,8.51 12.488,6.9 10.5,6.9"
                    id="Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </IconButton>
      <Typography variant="h6" className={classes.title}>
        RDMP
      </Typography>
      <Typography className={classes.plus}>LUS</Typography>
    </div>
  )
}
