import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
// import PropTypes from 'prop-types'
// import Typography from '@material-ui/core/Typography'
import { OwnedLocation } from '../location/OwnedLocationData'
import { LeasedLocation } from '../location/LeasedLocationData'
import { Location } from './LocationData'
import Notes from './AddEditLocation'
import { FileLetter } from '../location/FileLetter'
import { DispositionDate } from '../location/DispositionDate'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { TabPanel } from '../common/commonFunctionalities'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
})

// function TabPanel(props) {
//   const { children, value, index, ...other } = props
//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Typography>{children}</Typography>}
//     </div>
//   )
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// }

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function CenteredTabs() {
  const classes = useStyles()
  // const [value, setValue] = React.useState(0)
  const authDetails = useSelector(state => state.common.authDetails)
  const tabValue = useSelector(state => state.location.tabValue)
  const [refreshLocationData, setRefreshLocationData] = useState(false)
  const [refreshDispositionData, setRefreshDispositionData] = useState(false)
  const [refreshOwnedData, setRefreshOwnedData] = useState(false)
  const [refreshLeasedData, setRefreshLeasedData] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    if (authDetails && Object.keys(authDetails).length > 0) {
      // eslint-disable-next-line no-prototype-builtins
      if (!authDetails.hasOwnProperty('access_feature_location')) {
        history.push('/unauthorised')
      }
    }
  }, [authDetails, history])

  const handleChange = (event, newValue) => {
    // setValue(newValue)
    dispatch({ type: 'SET_TAB_VALUE', payload: newValue })
  }

  return (
    <Paper className={classes.root}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Location Data" {...a11yProps(0)} />
        <Tab label="Update Disposition Date" {...a11yProps(1)} />
        <Tab label="Owned checklist Data" {...a11yProps(2)} />
        <Tab label="Leased checklist Data" {...a11yProps(3)} />
        <Tab label="File Letter & File Letter Description" {...a11yProps(4)} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <Notes
          setRefreshLeasedData={setRefreshLeasedData}
          setRefreshOwnedData={setRefreshOwnedData}
          setRefreshDispositionData={setRefreshDispositionData}
        />
        <br />
        <Location
          refreshLocationData={refreshLocationData}
          setRefreshLocationData={setRefreshLocationData}
          setRefreshDispositionData={setRefreshDispositionData}
          setRefreshLeasedData={setRefreshLeasedData}
          setRefreshOwnedData={setRefreshOwnedData}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <DispositionDate
          setRefreshLocationData={setRefreshLocationData}
          refreshDispositionData={refreshDispositionData}
          setRefreshDispositionData={setRefreshDispositionData}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <OwnedLocation
          refreshOwnedData={refreshOwnedData}
          setRefreshOwnedData={setRefreshOwnedData}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <LeasedLocation
          refreshLeasedData={refreshLeasedData}
          setRefreshLeasedData={setRefreshLeasedData}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <FileLetter />
      </TabPanel>
    </Paper>
  )
}
