/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSelector } from 'react-redux'
import apiConfig from '../../config/apiConfig'
import { NumberFormatCustomPrefix } from '../common/commonFunctionalities'
// import InputAdornment from '@material-ui/core/InputAdornment'

const PACAMPayableReconciliation = props => {
  return (
    <div>
      {props.type === apiConfig.apiUrl.INDEX ? (
        <PACAMPayableReconciliationIndex {...props} />
      ) : (
        <PACAMPayableReconciliationSearch {...props} />
      )}
    </div>
  )
}

const PACAMPayableReconciliationSearch = props => {
  const [, setParentObj] = props.metaDataObj
  const [
    paCAMPayableReconciliationSearchObj,
    setPACAMPayableReconciliationSearchObj,
  ] = useState({})

  useEffect(() => {
    if (
      (props.savedMetaData &&
        Object.entries(props.savedMetaData).length === 0) ||
      props.savedMetaData === undefined
    ) {
      setPACAMPayableReconciliationSearchObj(props.unmodifiedData)
    } else {
      let obj = returnREObj({ ...props.savedMetaData })
      let newObj = { ...obj, id: props.unmodifiedData.id }
      setPACAMPayableReconciliationSearchObj(newObj)
    }
  }, [])

  useEffect(() => {
    setParentObj(paCAMPayableReconciliationSearchObj)
  }, [paCAMPayableReconciliationSearchObj])

  return (
    <PACAMPayableReconciliationForm
      {...props}
      pacamprObj={[
        paCAMPayableReconciliationSearchObj,
        setPACAMPayableReconciliationSearchObj,
      ]}
    />
  )
}

const PACAMPayableReconciliationIndex = props => {
  const [parentObj, setParentObj] = props.metaDataObj
  const [
    paCAMPayableReconciliationIndexObj,
    setPACAMPayableReconciliationIndexObj,
  ] = useState({})

  useEffect(() => {
    setParentObj(paCAMPayableReconciliationIndexObj)
  }, [paCAMPayableReconciliationIndexObj])

  useEffect(() => {
    if (Object.keys(parentObj).length > 0) {
      let newObj = { ...parentObj, contentType: props.contentType }
      setPACAMPayableReconciliationIndexObj(newObj)
    }
  }, [])

  //called only when indexing status = complete
  useEffect(() => {
    if (props.refreshCompleteMetadata) {
      // let obj = refactoredObject({ ...parentObj })
      let newObj = { ...parentObj, contentType: props.contentType }
      setPACAMPayableReconciliationIndexObj(newObj)
    }
  }, [props.refreshCompleteMetadata])

  //For clearing indexing data on switch of content type
  useEffect(() => {
    if (props.clearOnContentTypeChange) {
      let newObj = { contentType: props.contentType }
      setPACAMPayableReconciliationIndexObj(newObj)
    }
  }, [props.clearOnContentTypeChange])

  return (
    <PACAMPayableReconciliationForm
      {...props}
      pacamprObj={[
        paCAMPayableReconciliationIndexObj,
        setPACAMPayableReconciliationIndexObj,
      ]}
    />
  )
}

const returnREObj = data => {
  return {
    locationNumber: data.location_number || '',
    locationName: data.location_name || '',
    locationState: data.location_state || '',
    locationCity: data.location_city || '',
    locationRegion: data.location_region || '',
    documentTitle: data.document_title || '',
    documentGroup: data.document_group || '',
    documentType: data.document_type || '',
    contentType: data.content_type || '',
    fiscalYear: data.fiscal_year || '',
    expiryYear: data.expiry_year || '',
    sapContractNumber: data.sap_contract_number || '',
    operator: data.operator || '',
    // targetPrsBudgetAmount: data.target_prs_budget_amount || '',
    targetPrsTotalRecAmount: data.target_prs_total_rec_amount || '',

    proRataShare: data.pro_rata_share || '',
  }
}

const PACAMPayableReconciliationForm = props => {
  const [
    paCAMPayableReconciliationObj,
    setPaCAMPayableReconciliationObj,
  ] = props.pacamprObj
  const BootstrapInput = props.bootStrapInput
  const classes = props.useStyleClass
  const metadataObj = useSelector(state => state.common.locationData)
  const [locationData, setLocationData] = useState(metadataObj)
  const [docTitleError, setDocTitleError] = useState(false)
  const { docGroupDocType, contentType } = props
  const [docArray, setDocArray] = useState([])

  useEffect(() => {
    setDocArray(
      docGroupDocType[contentType][paCAMPayableReconciliationObj.documentGroup]
    )
  }, [paCAMPayableReconciliationObj.documentGroup])

  useEffect(() => {
    if (locationData) {
      setPaCAMPayableReconciliationObj({
        ...paCAMPayableReconciliationObj,
        locationNumber: locationData.location_number,
        locationName: locationData.location_name,
        locationCity: locationData.location_city,
        locationState: locationData.location_state,
        locationRegion: locationData.location_region,
      })
    } else {
      setPaCAMPayableReconciliationObj({
        ...paCAMPayableReconciliationObj,
        locationNumber: '',
        locationName: '',
        locationCity: '',
        locationState: '',
        locationRegion: '',
      })
    }
  }, [locationData])

  useEffect(() => {
    if (
      paCAMPayableReconciliationObj.fiscalYear &&
      paCAMPayableReconciliationObj.fiscalYear.length === 4
    ) {
      setPaCAMPayableReconciliationObj({
        ...paCAMPayableReconciliationObj,
        expiryYear: (
          Number(paCAMPayableReconciliationObj.fiscalYear) + 6
        ).toString(),
      })
    }
  }, [paCAMPayableReconciliationObj.fiscalYear])

  return (
    <div className="contenttype-container">
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="pacamrecon-documentgroup">
          Document Group *
        </InputLabel>
        <NativeSelect
          id="pacamrecon-documentgroup"
          value={paCAMPayableReconciliationObj.documentGroup}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_group
              : paCAMPayableReconciliationObj.documentGroup
          }
          onChange={e =>
            setPaCAMPayableReconciliationObj({
              ...paCAMPayableReconciliationObj,
              documentGroup: e.target.value,
            })
          }
          required
          input={<BootstrapInput />}
        >
          {/* {Object.keys(documentPayload).map(value => {
            return (
              <option value={value} key={value}>
                {value}
              </option>
            )
          })} */}
          {Object.keys(docGroupDocType[contentType]).map(value => {
            return (
              <option value={value} key={value}>
                {value}
              </option>
            )
          })}
        </NativeSelect>
      </FormControl>
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="pacamrecon-documenttype">
          Document Type *
        </InputLabel>
        <NativeSelect
          id="pacamrecon-documenttype"
          value={paCAMPayableReconciliationObj.documentType}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_type
              : paCAMPayableReconciliationObj.documentType
          }
          onChange={e =>
            setPaCAMPayableReconciliationObj({
              ...paCAMPayableReconciliationObj,
              documentType: e.target.value,
            })
          }
          input={<BootstrapInput />}
          required
        >
          {docArray &&
            docArray.map(value => {
              return (
                <option value={value} key={value}>
                  {value}
                </option>
              )
            })}
        </NativeSelect>
      </FormControl>
      <Autocomplete
        options={metadataObj}
        autoHighlight={true}
        getOptionLabel={option =>
          option.location_number ? option.location_number : ''
        }
        className="autocomplete"
        // getOptionLabel={option => option.location_number}
        onChange={(index, obj) => setLocationData(obj)}
        // blurOnSelect={true}
        value={{
          location_number: paCAMPayableReconciliationObj.locationNumber,
          location_name: paCAMPayableReconciliationObj.locationName,
          location_city: paCAMPayableReconciliationObj.locationCity,
          location_state: paCAMPayableReconciliationObj.locationState,
          location_region: paCAMPayableReconciliationObj.locationRegion,
        }}
        type={Number}
        disabled={Object.entries(metadataObj).length ? false : true}
        renderInput={params => (
          <FormControl>
            <InputLabel
              shrink
              className={classes.textfieldLabel}
              htmlFor="pacampayablerecon-autopopulate"
            >
              Location Number{' *'}
            </InputLabel>
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              required
              // label="Location Number"
              className={classes.textField}
              variant="outlined"
            />
          </FormControl>
        )}
      />

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrecon-locationname"
        >
          Location Name{' *'}
        </InputLabel>
        <TextField
          required
          id="pacamrecon-locationname"
          // label={'Location Name'}
          type={String}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          style={{ color: 'black' }}
          disabled={true}
          value={paCAMPayableReconciliationObj.locationName || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_name
              : paCAMPayableReconciliationObj.locationName
          }
          onChange={e =>
            setPaCAMPayableReconciliationObj({
              ...paCAMPayableReconciliationObj,
              locationName: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrecon-state"
        >
          State{' *'}
        </InputLabel>
        <TextField
          required
          id="pacamrecon-state"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paCAMPayableReconciliationObj.locationState || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_state
              : paCAMPayableReconciliationObj.locationState
          }
          onChange={e =>
            setPaCAMPayableReconciliationObj({
              ...paCAMPayableReconciliationObj,
              locationState: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrecon-city"
        >
          City{' *'}
        </InputLabel>
        <TextField
          required
          id="pacamrecon-city"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paCAMPayableReconciliationObj.locationCity || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_city
              : paCAMPayableReconciliationObj.locationCity
          }
          onChange={e =>
            setPaCAMPayableReconciliationObj({
              ...paCAMPayableReconciliationObj,
              locationCity: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrecon-region"
        >
          Region
        </InputLabel>
        <TextField
          required
          id="pacamrecon-region"
          type={String}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          disabled={true}
          value={paCAMPayableReconciliationObj.locationRegion || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.location_region
              : paCAMPayableReconciliationObj.locationRegion
          }
          onChange={e =>
            setPaCAMPayableReconciliationObj({
              ...paCAMPayableReconciliationObj,
              locationRegion: e.target.value,
            })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrecon-documenttitle"
        >
          Document Title{' *'}
        </InputLabel>
        <TextField
          required
          id="pacamrecon-documenttitle"
          InputLabelProps={{
            shrink: true,
          }}
          type={String}
          inputProps={{ maxLength: '500' }}
          multiline
          rowsMax="4"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableReconciliationObj.documentTitle || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.document_title
              : paCAMPayableReconciliationObj.documentTitle
          }
          onChange={e => {
            if (e.target.value.length === 500) {
              setDocTitleError(true)
            } else setDocTitleError(false)
            setPaCAMPayableReconciliationObj({
              ...paCAMPayableReconciliationObj,
              documentTitle: e.target.value,
            })
          }}
          error={docTitleError}
          helperText={
            docTitleError ? 'You have reached the limit of 500 characters' : ''
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrecon-fiscalyear"
        >
          Fiscal Year{' '}
        </InputLabel>
        <TextField
          id="pacamrecon-fiscalyear"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableReconciliationObj.fiscalYear || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.fiscal_year
              : paCAMPayableReconciliationObj.fiscalYear
          }
          onChange={e => {
            if (e.target.value.length > 4) {
              return false
            } else {
              setPaCAMPayableReconciliationObj({
                ...paCAMPayableReconciliationObj,
                fiscalYear: e.target.value,
              })
            }
          }}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="paCAMPayableReconciliationObj-expiryyear"
        >
          Expiry Year{' '}
        </InputLabel>
        <TextField
          type="number"
          id="paCAMPayableReconciliationObj-expiryyear"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableReconciliationObj.expiryYear || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.expiry_year
              : paCAMPayableReconciliationObj.expiryYear
          }
          onChange={e => {
            if (e.target.value.length > 4) return false
            else {
              setPaCAMPayableReconciliationObj({
                ...paCAMPayableReconciliationObj,
                expiryYear: e.target.value,
              })
            }
          }}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrecon-sapcontractnumber"
        >
          SAP Contract Number{' '}
        </InputLabel>
        <TextField
          id="pacamrecon-sapcontractnumber"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableReconciliationObj.sapContractNumber || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.sap_contract_number
              : paCAMPayableReconciliationObj.sapContractNumber
          }
          onChange={e =>
            setPaCAMPayableReconciliationObj({
              ...paCAMPayableReconciliationObj,
              sapContractNumber: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl className={classes.selects}>
        <InputLabel shrink htmlFor="pacamrecon-operator">
          Operator
        </InputLabel>
        <NativeSelect
          id="pacamrecon-operator"
          value={paCAMPayableReconciliationObj.operator || ''}
          defaultValue={
            props.savedMetaData
              ? props.savedMetaData.operator
              : paCAMPayableReconciliationObj.operator
          }
          onChange={e =>
            setPaCAMPayableReconciliationObj({
              ...paCAMPayableReconciliationObj,
              operator: e.target.value,
            })
          }
          input={<BootstrapInput />}
        >
          <option value={''}></option>
          <option value={'Site Centers Corporation'}>
            Site Centers Corporation
          </option>
          <option value={'Kimco Realty Corporation'}>
            Kimco Realty Corporation
          </option>
          <option value={'Brixmor Property Group'}>
            Brixmor Property Group
          </option>
          <option value={'Regency Centers'}>Regency Centers</option>
          <option value={'Brookfield Properties'}>Brookfield Properties</option>
          <option value={'Vestar'}>Vestar</option>
          <option value={'Ryan Companies'}>Ryan Companies</option>
          <option value={'Westwood Financial Corporation'}>
            Westwood Financial Corporation
          </option>
          <option value={'Kite Realty Group'}>Kite Realty Group</option>
          <option value={'CBRE'}>CBRE</option>
          <option value={'RPAI'}>RPAI</option>
          <option value={'Simon Property Group'}>Simon Property Group</option>
          <option value={'COR Property Services'}>COR Property Services</option>
          <option value={'Weingarten Realty'}>Weingarten Realty</option>
          <option value={'Mid-America Asset Management, Inc.'}>
            Mid-America Asset Management, Inc.
          </option>
          <option value={'Merlone Geiers'}>Merlone Geier</option>
          <option value={'The Related Companies'}>The Related Companies</option>
          <option value={'Blumenfeld Development Group'}>
            Blumenfeld Development Group
          </option>
          <option value={'Benderson Development Company'}>
            Benderson Development Company
          </option>
          <option value={'Irvine Company LLC'}>Irvine Company LLC</option>
          <option value={'Edens Inc'}>Edens Inc</option>
          <option value={'Federal Realtyc'}>Federal Realty</option>
          <option value={'Sunbelt'}>Sunbelt</option>
          <option value={'Donahue Schriber'}>Donahue Schriber</option>
          <option value={'Other'}>Other</option>
        </NativeSelect>
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrecon-prstotalbudgetamount"
        >
          Tgt Total Rec Amt From Operator{' '}
        </InputLabel>
        <TextField
          id="pacamrecon-prstotalbudgetamount"
          type={String}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableReconciliationObj.targetPrsTotalRecAmount || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.target_prs_total_rec_amount
              : paCAMPayableReconciliationObj.targetPrsTotalRecAmount
          }
          onChange={e =>
            setPaCAMPayableReconciliationObj({
              ...paCAMPayableReconciliationObj,
              targetPrsTotalRecAmount: e.target.value,
            })
          }
          InputProps={{
            inputComponent: NumberFormatCustomPrefix,
            // startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          shrink
          className={classes.textfieldLabel}
          htmlFor="pacamrecon-proratashare"
        >
          Pro Rata Share{' '}
        </InputLabel>
        <TextField
          id="pacamrecon-proratashare"
          type={String}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={paCAMPayableReconciliationObj.proRataShare || ''}
          defaultValue={
            typeof props.savedMetaData !== 'undefined'
              ? props.savedMetaData.pro_rata_share
              : paCAMPayableReconciliationObj.proRataShare
          }
          onChange={e =>
            setPaCAMPayableReconciliationObj({
              ...paCAMPayableReconciliationObj,
              proRataShare: e.target.value,
            })
          }
        />
      </FormControl>
    </div>
  )
}

export default PACAMPayableReconciliation
