import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import 'react-datepicker/dist/react-datepicker.css'
import apiConfig from '../../config/apiConfig'
import Button from '@material-ui/core/Button'
import { camQuarterlyInboxColumns } from '../reporting/reportingTableMapping'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import moment from 'moment'
import { tableIcons } from '../common/commonFunctionalities'
import MaterialTable from 'material-table'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}))

export const CamQuarterlyDocs = ({
  start,
  end,
  camQuarterlyDocs,
  isLoading,
  getCamQuarterlyDocs,
}) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [fileName, setFileName] = React.useState()
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    getCamQuarterlyDocs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.root}>
      <MaterialTable
        title={
          // eslint-disable-next-line no-useless-concat
          'PA CAM - Quarterly Inbox Report:' +
          ' ' +
          moment(start).format('MM/DD/YYYY') +
          '  -  ' +
          moment(end).format('MM/DD/YYYY')
        }
        options={{
          showTitle: true,
          search: false,
          toolbar: true,
          filtering: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'PACamQuarterlyInboxDocs',
          columnsButton: true,
          pageSizeOptions: [10, 25, 50, 100],
          loadingType: 'overlay',
          pageSize: 100,
          headerStyle: {
            position: 'sticky',
            top: 0,
            backgroundColor: 'rgb(231, 231, 231)',
            height: '50px',
          },
          maxBodyHeight: '65vh',
          emptyRowsWhenPaging: false,
        }}
        columns={camQuarterlyInboxColumns}
        data={camQuarterlyDocs}
        icons={tableIcons}
        isLoading={isLoading}
        actions={[
          {
            icon: PictureAsPdfIcon,
            tooltip: 'View Document',
            onClick: (event, rowData) => {
              let url = apiConfig.apiUrl.getFileUrl + rowData.file_path
              setFileName(url)
              setOpen(true)
            },
          },
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: '',
          },
          header: {
            actions: 'Document',
          },
        }}
      />
      <Dialog
        fullWidth="true"
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        open={open}
      >
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <CloseIcon />
          </Button>
        </DialogActions>
        <DialogContent>
          <iframe
            src={fileName}
            key={fileName}
            width="100%"
            title={fileName}
            style={{ height: '80vh' }}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
