import React, { useEffect, useState } from 'react'
import { workflowMain } from '../common/styles'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import EditIcon from '@material-ui/icons/Edit'
import MaterialTable, { MTableToolbar } from 'material-table'
import { tableIcons } from '../common/commonFunctionalities'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import EditFileLetter from '../location/EditFileletter'
import AddFileLetter from '../location/AddFileLetter'
import { useToasts } from 'react-toast-notifications'
import CheckInOut from './CheckInOut'
import DescriptionIcon from '@material-ui/icons/Description'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import CheckoutHistory from './CheckoutHistory'
import { useAuth } from '@praxis/component-auth'

export const FileLetter = () => {
  const classes = workflowMain()
  const { addToast } = useToasts()
  const auth = useAuth()

  const { session } = auth
  const [locationNumberData, setLocationNumberData] = useState([])
  const [fileLetterData, setFileLetterData] = useState([])
  const [disableSearch, setDisableSearch] = useState(true)
  const [status, setStatus] = useState()
  const [locId, setLocId] = useState()
  const [editLoc, setEditLoc] = useState()
  const [historyLock, setHistoryLock] = useState(false)
  const [loadData, setLoadData] = useState(false)
  const [checkoutHistoryData, setCheckoutHistoryData] = useState([])
  const [addFileLetter, setAddFileLetter] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [operation, setOperation] = useState('')
  const [tableOpen, setTableOpen] = React.useState(false)

  const columns = [
    {
      field: 'file_letter_checkout_history',
      title: 'Checkout History',
      render: rowData => (
        <Tooltip title="View Checkout History">
          <IconButton onClick={e => handleCheckoutHistoryView(e, rowData)}>
            <DescriptionIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: 'location_number',
      title: 'Location Number',
    },
    {
      field: 'location_name',
      title: 'Location Name',
    },
    {
      field: 'file_letter',
      title: 'File Letter',
      defaultSort: 'asc',
    },
    {
      field: 'file_letter_desc',
      title: 'File Letter Description',
    },
    {
      field: 'file_type',
      title: 'File Type',
    },
    {
      field: 'tenant_name',
      title: 'Tenant Name',
    },
    {
      field: 'location_suite',
      title: 'Location Suite',
    },
    {
      field: 'expiration_date',
      title: 'Lease Expiration Date',
    },
    {
      field: 'sold_to',
      title: 'Sold To',
    },
    {
      field: 'acreage',
      title: 'How much Acreage?',
    },
    {
      field: 'total_files',
      title: 'Total Files',
    },
    {
      field: 'checked_out_files',
      title: 'Checkedout Files',
    },
    {
      field: 'checkout_by',
      title: 'Checkedout By',
    },
    {
      field: 'checkout_date',
      title: 'Checkout Date',
    },
    {
      field: 'internal_requester',
      title: 'Internal Requester',
    },
    {
      field: 'external_requester',
      title: 'External Requester',
    },
    {
      field: 'status',
      title: 'Status',
    },
  ]

  const handleClickOpen = operationType => {
    setOperation(operationType)

    let hasFiles = selectedRows.find(
      item => item.total_files === 0 || item.total_files === null
    )
    if (hasFiles) {
      addToast(
        `Error!Selected File letter ${hasFiles.file_letter} has no files`,
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
      return
    }
    let record = selectedRows.find(
      item =>
        item.checkout_by &&
        item.checkout_by !== '' &&
        item.checkout_by !== session.userInfo.email.split('@')[0]
    )
    if (operationType === 'CHECKOUT') {
      record
        ? addToast(
            `Error!Cannot checkout File letter ${record.file_letter} as it is already checked out by ${record.checkout_by}`,
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        : setTableOpen(true)
    } else {
      record
        ? addToast(
            `Error!Cannot checkin File letter ${record.file_letter} as it is checked out by ${record.checkout_by}`,
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        : setTableOpen(true)
    }
  }

  const handleTableClose = () => {
    setTableOpen(false)
  }

  const getFileLetter = () => {
    let url =
      apiConfig.apiUrl.getfileletter + `${locationNumberData.toUpperCase()}`
    axios
      .get(url)
      .then(response => {
        if (response.data.length === 0) {
          addToast('No Data to Display', {
            appearance: 'warning',
            autoDismiss: true,
          })
        }
        setFileLetterData(response.data)
        setStatus(response.status)
        setSelectedRows([])
      })
      .catch(err => {})
  }

  useEffect(() => {
    if (locationNumberData.length <= 4 || locationNumberData.length > 5) {
      setDisableSearch(true)
    } else setDisableSearch(false)
  }, [locationNumberData.length])

  useEffect(() => {
    if (locationNumberData.length === 5) {
      let url =
        apiConfig.apiUrl.getfileletter + `${locationNumberData.toUpperCase()}`
      axios
        .get(url)
        .then(response => {
          setFileLetterData(response.data)
          setAddFileLetter(false)
          setSelectedRows([])
        })
        .catch(err => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editLoc, addFileLetter])

  const handleClose = () => {
    setHistoryLock(false)
    setCheckoutHistoryData([])
  }

  const handleCheckoutHistoryView = (e, { id }) => {
    setHistoryLock(true)
    setLoadData(true)
    setLocId(id)
    let url = apiConfig.apiUrl.getCheckoutHistory + id
    axios
      .get(url)
      .then(response => {
        setCheckoutHistoryData(response.data)
        setLoadData(false)
      })
      .catch(err => {
        setLoadData(false)
      })
  }

  const fileCheckInOut = rows => {
    let url = apiConfig.apiUrl.checkInOutFileLetter

    let payload = {}
    payload['operation'] = operation

    let modifiedRows = rows.map(row => {
      return {
        checked_out_files:
          operation === 'CHECKOUT'
            ? Number(row.check_out_files)
            : Number(row.checked_out_files - row.check_out_files),
        checkout_by: session.userInfo.email.split('@')[0],
        file_comments: row.file_comments,
        file_letter: row.file_letter,
        file_letter_desc: row.file_letter_desc,
        file_type: row.file_type,
        id: row.id,
        location_number: row.location_number,
        internal_requester: row.internal_requester,
        external_requester: row.external_requester,
        total_files: row.total_files,
      }
    })

    payload['records'] = [...modifiedRows]

    axios
      .put(url, payload)
      .then(response => {
        setAddFileLetter(true)

        // setCheckoutHistoryData(response.data)
        // setLoadData(false)
        addToast('CheckIn/CheckOut details updated successfully', {
          appearance: 'success',
          autoDismiss: true,
        })
        handleTableClose()
      })
      .catch(err => {
        addToast('Error!Failed to update checkin/checkout details', {
          appearance: 'error',
          autoDismiss: true,
        })
        // setLoadData(false)
      })
  }
  const handleSelectedRows = rows => {
    let data = JSON.parse(JSON.stringify(rows))
    let modifiedData = []
    modifiedData = data.map(item => {
      delete item.tableData
      return item
    })
    setSelectedRows(modifiedData)
  }

  return (
    <div>
      <br />
      <br />
      <center>
        <TextField
          className={classes.text}
          required
          id="standard-full-width"
          placeholder="Location Number"
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={e => setLocationNumberData(e.target.value)}
          onKeyPress={event => {
            if (event.key === 'Enter') getFileLetter()
          }}
        />
        <Button
          style={{ marginTop: '16px' }}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={getFileLetter}
          disabled={disableSearch}
        >
          Search
        </Button>
      </center>
      {status === 200 && fileLetterData.length > 0 ? (
        <div>
          <br />
          <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              // alignItems: 'cennter',
            }}
          >
            <AddFileLetter
              locationNum={locationNumberData}
              flag={setAddFileLetter}
            />
            <CheckInOut
              selectedRows={selectedRows}
              fileCheckInOut={fileCheckInOut}
              operation={operation}
              setOperation={setOperation}
              handleTableClose={handleTableClose}
              handleClickOpen={handleClickOpen}
              tableOpen={tableOpen}
            />
          </div>
          <br />
          <MaterialTable
            title={'Listing File Letter & File Letter Descriptions:'}
            options={{
              showTitle: true,
              search: true,
              toolbar: true,
              filtering: false,
              exportButton: true,
              exportAllData: true,
              selection: true,
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              exportFileName: 'FileLetter',
              pageSizeOptions: [10, 25, 50, 100],
              loadingType: 'overlay',
              pageSize: 100,
              headerStyle: {
                position: 'sticky',
                top: 0,
                backgroundColor: 'rgb(231, 231, 231)',
                height: '50px',
              },
              maxBodyHeight: '55vh',
              emptyRowsWhenPaging: false,
            }}
            onSelectionChange={handleSelectedRows}
            columns={columns}
            data={fileLetterData}
            icons={tableIcons}
            actions={[
              {
                position: 'row',
                icon: EditIcon,
                tooltip: 'Edit FileLetter',
                onClick: (event, rowData) => {
                  setLocId(rowData.id)
                  setEditLoc(true)
                  setSelectedRows([])
                },
              },
            ]}
            components={{
              Toolbar: props => (
                <>
                  <MTableToolbar {...props} />
                </>
              ),
            }}
            localization={{
              body: {
                emptyDataSourceMessage: '',
              },
            }}
          />
          {editLoc ? (
            <EditFileLetter
              locId={locId}
              isEdit={editLoc}
              handleClosed={setEditLoc}
            />
          ) : null}{' '}
        </div>
      ) : (
        ''
      )}
      {historyLock && (
        <CheckoutHistory
          open={historyLock}
          data={checkoutHistoryData}
          handleClose={handleClose}
          loading={loadData}
        />
      )}
    </div>
  )
}
