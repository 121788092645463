import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import apiConfig from '../../config/apiConfig'

export const ExportDialog = ({
  open,
  handleClose,
  exportCsv,
  classes,
  filename,
  setFilename,
  isColumnSelected,
  handleColumnSelectChange,
  allColumns,
  exportSelectAll,
  handleExportAllSelect,
  selectedColumns,
  title,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick="true"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <div className={classes.exportTextfield}>
          <div className={classes.exportLabel}>Download File As : &nbsp;</div>
          <TextField
            required
            id="file-name"
            type={String}
            className={classes.exportText}
            margin="normal"
            variant="outlined"
            defaultValue={filename}
            onChange={e => setFilename(e.target.value)}
          />
        </div>
        <div className={classes.exportLabel}>Column Selection :</div>
        <br />
        <FormControlLabel
          control={
            <Checkbox
              checked={exportSelectAll}
              onChange={handleExportAllSelect}
              name="selectExportAll"
            />
          }
          label="Select All"
          //   className={classes.selectAll}
        />
        <hr style={{ margin: '0px' }} />
        <div className={classes.checkboxGroup}>
          {allColumns.map(column => {
            const isSelected = isColumnSelected(column.title)
            if (
              column.title === 'FileUrl' &&
              apiConfig.auth.domainName.split('.')[0] === 'https://rdmplus'
            ) {
              return null
            }
            return (
              <FormControlLabel
                className={classes.individualCheckbox}
                key={column.title}
                control={
                  <Checkbox
                    checked={isSelected}
                    onChange={handleColumnSelectChange}
                    name={column.title}
                  />
                }
                label={column.title}
              />
            )
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={exportCsv}
          disabled={selectedColumns.length === 0}
        >
          Export
        </Button>
      </DialogActions>
    </Dialog>
  )
}
