import React, { Fragment } from 'react'
import { BootstrapInput } from '../common/styles'
// import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
// import Button from '@material-ui/core/Button'
// import axios from 'axios'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import {
  validateTasksDropdown,
  validateDateWorkflow,
  validateDueDate,
} from '../common/validation'
// import DatePicker from 'react-date-picker'
import { Controller } from 'react-hook-form'
import { NumberFormatCustomPrefix } from '../common/commonFunctionalities'

// import moment from 'moment'
// import Paper from '@material-ui/core/Paper'

const formatBucketName = name => {
  let tempName = name.split('-')
  tempName = tempName.join(' ')
  return tempName
}

const CustomisedSelect = form => (
  <NativeSelect input={<BootstrapInput />} required>
    {form.task_response_options.map(value => {
      return (
        <option value={value} key={value}>
          {value === 'Blank' ? ' ' : value}
        </option>
      )
    })}
  </NativeSelect>
)

const CustomisedSelectRoute = namesList => (
  <NativeSelect input={<BootstrapInput />} required>
    {namesList.map(value => {
      return (
        <option value={value} key={value}>
          {value === 'NA' ? ' ' : value}
        </option>
      )
    })}
  </NativeSelect>
)

export const TaskPane = ({
  taskData,
  classes,
  handleFieldsChange,
  bucketName,
  register,
  errors,
  control,
  routeToList,
}) => {
  const isShow = data => {
    if (bucketName === 'CAM-PAYABLE-PREPARER') return true
    else if (data === 'Not Approved Reason') return true
    else if (data === 'Reason For Pending') return true
    return false
  }

  return (
    <div className={classes.taskPane}>
      {taskData ? (
        <h6 className={classes.heading}>
          {formatBucketName(bucketName)} TASKS
        </h6>
      ) : (
        <h6 className={classes.heading}>No task found</h6>
      )}
      {taskData &&
        // eslint-disable-next-line array-callback-return
        Object.values(taskData).map(form => {
          if (form.task_type === 'Textbox') {
            return (
              <Fragment>
                <TextField
                  id={form.task_label}
                  label={form.task_label}
                  name={form.task_label}
                  variant="outlined"
                  className={classes.textField}
                  defaultValue={
                    form.default_option === 'NA' ? '' : form.default_option
                  }
                  onChange={handleFieldsChange}
                  inputRef={register()}
                />
                <span className={classes.errorMessage}>
                  {errors[form.task_label] && 'Required'}
                </span>
              </Fragment>
            )
          }
          if (form.task_type === 'Dropdown' && form.task_label === 'Route to') {
            return (
              <FormControl
                variant="outlined"
                className={classes.contentTypeStyle}
              >
                <InputLabel
                  id={form.task_label}
                  className={classes.labelText}
                  shrink
                >
                  {form.task_label}
                </InputLabel>
                <Controller
                  as={CustomisedSelectRoute(routeToList)}
                  name={form.task_label}
                  control={control}
                  id={form.task_label}
                  onChange={([selected]) => {
                    handleFieldsChange(selected)
                    return selected
                  }}
                  defaultValue={form.default_option}
                />
                <span className={classes.errorMessage}>
                  {errors[form.task_label] &&
                    errors[form.task_label].type === 'notMatch' &&
                    'Please select appropriate value'}
                </span>
              </FormControl>
            )
          }
          if (form.task_type === 'Dropdown') {
            return (
              <FormControl
                variant="outlined"
                className={classes.contentTypeStyle}
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={classes.labelText}
                  // error={form.default_option === 'Blank'}
                >
                  {form.task_label}

                  {isShow(form.task_label) ? null : (
                    <span className={classes.errorMessage}>*</span>
                  )}
                </InputLabel>
                {isShow(form.task_label) ? (
                  <Controller
                    as={CustomisedSelect(form)}
                    name={form.task_label}
                    control={control}
                    id={form.task_label}
                    onChange={([selected]) => {
                      handleFieldsChange(selected)
                      return selected
                    }}
                    defaultValue={form.default_option}
                  />
                ) : (
                  <Controller
                    as={CustomisedSelect(form)}
                    name={form.task_label}
                    control={control}
                    rules={{
                      required: true,
                      validate: value =>
                        validateTasksDropdown(value, bucketName),
                    }}
                    id={form.task_label}
                    onChange={([selected]) => {
                      handleFieldsChange(selected)
                      return selected
                    }}
                    defaultValue={form.default_option}
                  />
                )}
                <span className={classes.errorMessage}>
                  {errors[form.task_label] &&
                    errors[form.task_label].type === 'required' &&
                    'This is required'}
                  {errors[form.task_label] &&
                    errors[form.task_label].type === 'validate' &&
                    'Please select appropriate value'}
                  {errors[form.task_label] &&
                    errors[form.task_label].type === 'notMatch' &&
                    'Please select appropriate value'}
                </span>
              </FormControl>
            )
          }
          if (form.task_type === 'Textbox-Disabled') {
            return (
              <Fragment>
                <TextField
                  id={form.task_label}
                  label={form.task_label}
                  name={form.task_label}
                  variant="outlined"
                  className={classes.textField}
                  defaultValue={
                    form.default_option === 'NA' ? '' : form.default_option
                  }
                  disabled={true}
                  inputRef={register()}
                />
              </Fragment>
            )
          }
          if (form.task_type === 'Textbox-Disabled-Dollar') {
            return (
              <Fragment>
                <TextField
                  id={form.task_label}
                  label={form.task_label}
                  name={form.task_label}
                  variant="outlined"
                  className={classes.textField}
                  defaultValue={
                    form.default_option === 'NA'
                      ? ''
                      : new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(
                          Number(form.default_option.replace(/[^0-9.-]+/g, ''))
                        )
                  }
                  disabled={true}
                  inputRef={register()}
                />
              </Fragment>
            )
          }
          if (form.task_type === 'Textbox-Dollar') {
            return (
              <Fragment>
                <TextField
                  id={form.task_label}
                  label={form.task_label}
                  name={form.task_label}
                  variant="outlined"
                  className={classes.textField}
                  defaultValue={
                    form.default_option === 'NA' ? '' : form.default_option
                    // new Intl.NumberFormat('en-US', {
                    //     style: 'currency',
                    //     currency: 'USD',
                    //   }).format(
                    //     Number(form.default_option.replace(/[^0-9.-]+/g, ''))
                    //   )
                  }
                  InputProps={{
                    inputComponent: NumberFormatCustomPrefix,
                    // startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  inputRef={register()}
                  onChange={handleFieldsChange}
                />
              </Fragment>
            )
          }
          if (form.task_type === 'Date') {
            //This is a quick fix.It should be removed after going live.
            if (form.task_label === 'Due Date') {
              return (
                <Fragment>
                  <TextField
                    id={form.task_label}
                    label={form.task_label}
                    name={form.task_label}
                    variant="outlined"
                    className={classes.textField}
                    value={
                      form.default_option === 'NA' ? '' : form.default_option
                    }
                    onChange={handleFieldsChange}
                    inputRef={register({
                      validate: value => validateDueDate(value),
                    })}
                    placeholder="Qtr YYYY"
                  />
                  <span className={classes.errorMessage}>
                    {errors[form.task_label] &&
                      errors[form.task_label].type === 'validate' &&
                      'Enter in correct format.For e.g. Q3 2020'}
                  </span>
                </Fragment>
              )
            }
            return (
              <Fragment>
                <TextField
                  // rules={{}}
                  id={form.task_label}
                  label={form.task_label}
                  name={form.task_label}
                  variant="outlined"
                  className={classes.textField}
                  value={
                    form.default_option === 'NA' ? '' : form.default_option
                  }
                  onChange={handleFieldsChange}
                  inputRef={register({
                    validate: value => validateDateWorkflow(value),
                  })}
                  placeholder="mm/dd/yyyy"
                />
                <span className={classes.errorMessage}>
                  {errors[form.task_label] &&
                    errors[form.task_label].type === 'validate' &&
                    'Enter in correct format.For e.g. 09/23/2020'}
                </span>
              </Fragment>
            )
          }
        })}
    </div>
  )
}
