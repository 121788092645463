import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import { useToasts } from 'react-toast-notifications'
import './AddLocationDataStyle.css'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import { validateDate } from '../common/validation'
import { useAuth } from '@praxis/component-auth'

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

export default function FormDialog({
  locId,
  handleClosed,
  isEdit,
  setRefreshLocationData,
  setReloadTable,
  setRefreshDispositionData,
}) {
  const auth = useAuth()
  const { session } = auth
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const [dateDispositionError, setDateDispositionError] = useState(false)
  const { addToast } = useToasts()
  const [obj, setObj] = useState({
    locationId: '',
    locationAddress: '',
    locationName: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    primaryUse: '',
    region: '',
    group: '',
    district: '',
    openingDate: '',
    closingDate: '',
    acquisitionDate: '',
    dispositionDate: '',
    checklistType: '',
    locationSource: '',
  })
  useEffect(() => {
    if (locId !== undefined && isEdit === true) {
      getLocationDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locId, isEdit])

  const getLocationDetails = () => {
    let url = apiConfig.apiUrl.getlocation + `${locId}`

    axios
      .get(url)
      .then(response => {
        setObj({
          locationId: response.data.locid,
          locationAddress: response.data.address,
          locationName: response.data.name,
          city: response.data.city,
          state: response.data.state,
          postalCode: response.data.postal_code,
          country: response.data.country,
          primaryUse: response.data.primary_use,
          region: response.data.region,
          group: response.data.group,
          district: response.data.district,
          openingDate: response.data.open_date,
          closingDate: response.data.close_date,
          acquisitionDate: response.data.acquisition_date,
          dispositionDate: response.data.disposition_date,
          checklistType: response.data.leased_or_owned,
          locationSource: response.data.location_source,
          createdBy: response.data.created_by,
          createdDate: response.data.created_timestamp,
        })

        setOpen(true)
      })
      .catch(err => console.log(err))
  }

  const updateLocationSubmit = event => {
    let payload = {}
    let locid = obj.locationId
    payload['location_number'] = obj.locationId
    payload['location_name'] = obj.locationName
    payload['location_address'] = obj.locationAddress
    payload['location_city'] = obj.city
    payload['location_state'] = obj.state
    payload['location_postal_code'] = obj.postalCode
    payload['location_country'] = obj.country
    payload['location_primary_use'] = obj.primaryUse
    payload['location_region'] = obj.region
    payload['location_group'] = obj.group
    payload['location_district'] = obj.district
    payload['location_open_date'] = obj.openingDate
    payload['location_close_date'] = obj.closingDate
    payload['location_acquisition_date'] = obj.acquisitionDate
    payload['location_disposition_date'] = obj.dispositionDate
    payload['location_leased_or_owned'] = obj.checklistType
    payload['location_source'] = obj.locationSource
    payload['created_by'] = obj.createdBy
    payload['created_timestamp'] = obj.createdDate
    payload['modified_by'] = session.userInfo.fullName

    let url = apiConfig.apiUrl.update_dispositiondate + `/${locid}`

    axios
      .put(url, payload)
      .then(res => {
        addToast('Disposition date saved successfully', {
          appearance: 'success',
          autoDismiss: true,
        })
        setRefreshLocationData(true)
        setRefreshDispositionData(true)
        setReloadTable(true)
        handleClose()
      })
      .catch(err => {
        addToast('Error!Disposition date could not be saved.', {
          appearance: 'error',
        })

        handleClose()
      })
  }

  const handleClose = () => {
    setOpen(false)
    setObj('')
    handleClosed(false)
  }

  const handleSaveEdit = e => {
    e.preventDefault()
    if (isEdit) {
      updateLocationSubmit()
    }
  }

  return (
    <div style={{ marginLeft: '90%' }}>
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick="true"
        aria-labelledby="form-dialog-title"
      >
        <form validate autoComplete="off" onSubmit={handleSaveEdit}>
          <DialogTitle id="form-dialog-title">
            Update Disposition Date
          </DialogTitle>
          <DialogContent>
            <div className="metadata-textfield">
              <TextField
                required
                disabled
                id="outlined-search"
                label={'Location ID'}
                type={String}
                margin="normal"
                variant="outlined"
                className={classes.textField}
                value={obj.locationId}
                onChange={e => setObj({ ...obj, locationId: e.target.value })}
              />
              <br />
            </div>
            <div className="metadata-container">
              <div className="metadata-textfield">
                <TextField
                  required
                  id="realestate-documentdate"
                  label="Disposition Date"
                  type={String}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={obj.dispositionDate || undefined}
                  onChange={event => {
                    let valid = validateDate(event.target.value)
                    setDateDispositionError(valid)

                    setObj({
                      ...obj,
                      dispositionDate: event.target.value,
                    })
                  }}
                  placeholder="mm/dd/yyyy"
                  error={dateDispositionError}
                  helperText={
                    dateDispositionError
                      ? 'Enter in correct format.For e.g. 09/23/2020'
                      : ''
                  }
                />
                <br />
                <br />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
