import React, { Fragment } from 'react'
import TableCell from '@material-ui/core/TableCell'
import CheckIcon from '@material-ui/icons/Check'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { formatDollarAmount } from '../common/commonFunctionalities'

/* Hidden cloumns are added here so that 
all the content types have same number of columns 
which prevents UI error on switching between different 
content types while sorting on last column */

export const TableColumns = {
  'Real Estate': [
    {
      title: 'WorkflowId',
      field: 'workflow_id',
    },
    {
      field: 'current_bucket',
      numeric: true,
      disablePadding: false,
      title: 'BucketName',
    },
    {
      title: 'IsUrgent',
      field: 'is_urgent',
      render: rowData => (
        <span>
          {rowData.is_urgent === 'Yes' ? (
            <CheckIcon style={{ color: 'red' }} />
          ) : (
            ''
          )}
        </span>
      ),
      cellStyle: { textAlign: 'center' },
    },
    {
      title: 'CreatedDate',
      field: 'created_date',
      customSort: (a, b) => {
        if (a.created_date === '' || typeof a.created_date === 'undefined') {
          return -1
        } else if (
          b.created_date === '' ||
          typeof b.created_date === 'undefined'
        ) {
          return 1
        }
        return (
          new Date(a.created_date).getTime() -
          new Date(b.created_date).getTime()
        )
      },
    },

    {
      field: 'assigned_to',
      numeric: true,
      disablePadding: false,
      title: 'AssignedTo',
      render: rowData => (
        <span>{rowData.assigned_to === 'NA' ? '' : rowData.assigned_to}</span>
      ),
    },
    {
      field: 'location_number',

      title: 'LocationNumber',
    },
    {
      field: 'location_name',
      numeric: true,
      disablePadding: false,
      title: 'LocationName',
    },
    {
      field: 'location_state',
      numeric: true,
      disablePadding: false,
      title: 'State',
    },

    {
      field: 'location_region',
      numeric: true,
      disablePadding: false,
      title: 'Region',
    },
    {
      field: 'document_title',
      numeric: true,
      disablePadding: false,
      title: 'DocumentTitle',
      render: rowData =>
        rowData.document_title.length > 100 ? (
          <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
            {rowData.document_title}
          </div>
        ) : (
          <div>{rowData.document_title}</div>
        ),
    },
    {
      field: 'document_date',
      numeric: true,
      disablePadding: false,
      title: 'DocumentDate',
      customSort: (a, b) => {
        if (a.document_date === '' || typeof a.document_date === 'undefined') {
          return -1
        } else if (
          b.document_date === '' ||
          typeof b.document_date === 'undefined'
        ) {
          return 1
        }
        return (
          new Date(a.document_date).getTime() -
          new Date(b.document_date).getTime()
        )
      },
    },
    {
      field: 'document_type',
      numeric: true,
      disablePadding: false,
      title: 'DocumentType',
    },
    {
      field: 'document_status',
      numeric: true,
      disablePadding: false,
      title: 'DocumentStatus',
    },

    {
      field: 'recording_status',
      numeric: true,
      disablePadding: false,
      title: 'RecordingStatus',
    },
    {
      field: 'physical_file_number',
      numeric: true,
      disablePadding: false,
      title: 'PhysicalFileNumber',
      customSort: (a, b) => {
        if (
          a.physical_file_number === '' ||
          typeof a.physical_file_number === 'undefined' ||
          typeof a.physical_file_number === 'object'
        ) {
          return -1
        } else if (
          b.physical_file_number === '' ||
          typeof b.physical_file_number === 'undefined' ||
          typeof b.physical_file_number === 'object'
        ) {
          return 1
        }
        return (
          parseFloat(a.physical_file_number) -
          parseFloat(b.physical_file_number)
        )
      },
    },
    {
      field: 'file_letter',
      numeric: true,
      disablePadding: false,
      title: 'FileLetter',
    },
    {
      field: 'file_letter_description',
      numeric: true,
      disablePadding: false,
      title: 'FileLetterDescription',
    },
    {
      field: 'business_follow_up_name',
      numeric: true,
      disablePadding: false,
      title: 'BusinessFollowUp',
    },
    {
      field: 'legal_follow_up_name',
      numeric: true,
      disablePadding: false,
      title: 'LegalFollowUp',
    },
    {
      title: 'WorkflowId',
      field: 'workflow_id',
      hidden: true,
    },
    {
      title: 'WorkflowId',
      field: 'workflow_id',
      hidden: true,
    },
  ],

  'PA CAM Payable': [
    {
      field: 'created_date',
      numeric: true,
      disablePadding: false,
      title: 'CreatedDate',
      customSort: (a, b) => {
        if (a.created_date === '' || typeof a.created_date === 'undefined') {
          return -1
        } else if (
          b.created_date === '' ||
          typeof b.created_date === 'undefined'
        ) {
          return 1
        }
        return (
          new Date(a.created_date).getTime() -
          new Date(b.created_date).getTime()
        )
      },
    },
    {
      field: 'indexed_on',
      numeric: true,
      disablePadding: false,
      title: 'IndexedDate',
      customSort: (a, b) => {
        if (a.indexed_on === '' || typeof a.indexed_on === 'undefined') {
          return -1
        } else if (b.indexed_on === '' || typeof b.indexed_on === 'undefined') {
          return 1
        }
        return (
          new Date(a.indexed_on).getTime() - new Date(b.indexed_on).getTime()
        )
      },
    },
    {
      field: 'fiscal_year',
      numeric: true,
      disablePadding: false,
      title: 'FiscalYear',
    },
    {
      field: 'sap_contract_number',
      numeric: true,
      disablePadding: false,
      title: 'SAPContractNumber',
      customSort: (a, b) => {
        if (
          a.sap_contract_number === '' ||
          typeof a.sap_contract_number === 'undefined' ||
          typeof a.sap_contract_number === 'object'
        ) {
          return -1
        } else if (
          b.sap_contract_number === '' ||
          typeof b.sap_contract_number === 'undefined' ||
          typeof b.sap_contract_number === 'object'
        ) {
          return 1
        }
        return parseInt(a.sap_contract_number) - parseInt(b.sap_contract_number)
      },
    },
    {
      field: 'location_number',
      numeric: true,
      disablePadding: false,
      title: 'LocationNumber',
    },
    {
      field: 'location_name',
      numeric: true,
      disablePadding: false,
      title: 'LocationName',
    },
    {
      field: 'location_state',
      numeric: true,
      disablePadding: false,
      title: 'State',
    },

    {
      field: 'location_region',
      numeric: true,
      disablePadding: false,
      title: 'Region',
    },

    {
      field: 'assigned_to',
      numeric: true,
      disablePadding: false,
      title: 'AssignedTo',
      render: rowData => (
        <span>{rowData.assigned_to === 'NA' ? '' : rowData.assigned_to}</span>
      ),
    },
    {
      field: 'document_type',
      numeric: true,
      disablePadding: false,
      title: 'DocumentType',
    },
    {
      field: 'document_title',
      numeric: true,
      disablePadding: false,
      title: 'DocumentTitle',
      render: rowData =>
        rowData.document_title.length > 100 ? (
          <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
            {rowData.document_title}
          </div>
        ) : (
          <div>{rowData.document_title}</div>
        ),
    },
    {
      field: 'cam_status',
      numeric: true,
      disablePadding: false,
      title: 'CAMStatus',

      filterComponent: props => (
        <FormControl>
          <Select
            id="search-options"
            // multiple
            // disableUnderline
            // value=""
            // label="Select"
            style={{ width: '80px' }}
            placeholder="select"
            onChange={event => {
              props.onFilterChanged(
                props.columnDef.tableData.id,
                event.target.value
              )
            }}
            autoWidth={true}
          >
            <MenuItem value="">&nbsp;</MenuItem>

            <MenuItem value="Not Started">Not started</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Pending Information - Operator">
              Pending Information - Operator
            </MenuItem>
            <MenuItem value="Pending Information - Internal">
              Pending Information - Internal
            </MenuItem>
            <MenuItem value="Complete - Approved">Complete - Approved</MenuItem>
            <MenuItem value="Complete - Denied">Complete - Denied</MenuItem>
          </Select>
        </FormControl>
      ),
      customFilterAndSearch: (value, rowData) => {
        return value === rowData.cam_status
      },
    },

    {
      field: 'operator',
      numeric: true,
      disablePadding: false,
      title: 'Operator',
    },

    {
      field: 'pro_rata_share',
      numeric: true,
      disablePadding: false,
      title: 'ProRataShare',
      customSort: (a, b) => {
        if (
          a.pro_rata_share === '' ||
          typeof a.pro_rata_share === 'undefined' ||
          typeof a.pro_rata_share === 'object'
        ) {
          return -1
        } else if (
          b.pro_rata_share === '' ||
          typeof b.pro_rata_share === 'undefined' ||
          typeof b.pro_rata_share === 'object'
        ) {
          return 1
        }
        return parseFloat(a.pro_rata_share) - parseFloat(b.pro_rata_share)
      },
    },
    {
      field: 'total_dollar_impact',
      // numeric: true,
      disablePadding: false,
      title: 'TotalDollarImpact',
      // type: 'currency',
      render: rowData => formatDollarAmount(rowData.total_dollar_impact),

      customSort: (a, b) => {
        let a1 =
          typeof a.total_dollar_impact === 'undefined' ||
          a.total_dollar_impact === 'NA' ||
          typeof a.total_dollar_impact === 'object'
            ? 0
            : Number(a.total_dollar_impact.replace(/[^0-9.-]+/g, ''))
        let b1 =
          typeof b.total_dollar_impact === 'undefined' ||
          b.total_dollar_impact === 'NA' ||
          typeof b.total_dollar_impact === 'object'
            ? 0
            : Number(b.total_dollar_impact.replace(/[^0-9.-]+/g, ''))

        return a1 - b1
      },
    },
    {
      field: 'target_prs_budget_amount',
      // numeric: true,
      disablePadding: false,
      title: 'BudgetAmountSubmittedByOperator',
      render: rowData => formatDollarAmount(rowData.target_prs_budget_amount),

      customSort: (a, b) => {
        let a1 =
          typeof a.target_prs_budget_amount === 'undefined' ||
          a.target_prs_budget_amount === 'NA' ||
          typeof a.target_prs_budget_amount === 'object'
            ? 0
            : Number(a.target_prs_budget_amount.replace(/[^0-9.-]+/g, ''))
        let b1 =
          typeof b.target_prs_budget_amount === 'undefined' ||
          b.target_prs_budget_amount === 'NA' ||
          typeof b.target_prs_budget_amount === 'object'
            ? 0
            : Number(b.target_prs_budget_amount.replace(/[^0-9.-]+/g, ''))

        return a1 - b1
      },
    },
    {
      field: 'target_prs_total_rec_amount',
      // numeric: true,
      disablePadding: false,
      title: 'RecAmountSubmittedbyOperator',
      render: rowData =>
        formatDollarAmount(rowData.target_prs_total_rec_amount),

      customSort: (a, b) => {
        let a1 =
          typeof a.target_prs_total_rec_amount === 'undefined' ||
          a.target_prs_total_rec_amount === 'NA' ||
          typeof a.target_prs_total_rec_amount === 'object'
            ? 0
            : Number(a.target_prs_total_rec_amount.replace(/[^0-9.-]+/g, ''))
        let b1 =
          typeof b.target_prs_total_rec_amount === 'undefined' ||
          b.target_prs_total_rec_amount === 'NA' ||
          typeof b.target_prs_total_rec_amount === 'object'
            ? 0
            : Number(b.target_prs_total_rec_amount.replace(/[^0-9.-]+/g, ''))

        return a1 - b1
      },
    },
    {
      field: 'current_bucket',
      numeric: true,
      disablePadding: false,
      title: 'BucketName',
    },
    {
      field: 'content_type',
      numeric: true,
      disablePadding: false,
      title: 'ContentType',
    },

    {
      field: 'workflow_id',
      numeric: false,
      disablePadding: false,
      title: 'WorkflowId',
    },

    {
      title: 'WorkflowId',
      field: 'workflow_id',
      hidden: true,
    },
    {
      title: 'WorkflowId',
      field: 'workflow_id',
      hidden: true,
    },
    {
      title: 'WorkflowId',
      field: 'workflow_id',
      hidden: true,
    },
  ],

  'PA CAM Rebill': [
    //  Reconciliation': [
    // { field: 'sNo', numeric: false, disablePadding: false, title: 'S.no.' },

    {
      field: 'created_date',
      numeric: true,
      disablePadding: false,
      title: 'CreatedDate',
      customSort: (a, b) => {
        if (a.created_date === '' || typeof a.created_date === 'undefined') {
          return -1
        } else if (
          b.created_date === '' ||
          typeof b.created_date === 'undefined'
        ) {
          return 1
        }
        return (
          new Date(a.created_date).getTime() -
          new Date(b.created_date).getTime()
        )
      },
    },
    {
      field: 'indexed_on',
      numeric: true,
      disablePadding: false,
      title: 'IndexedDate',
      customSort: (a, b) => {
        if (a.indexed_on === '' || typeof a.indexed_on === 'undefined') {
          return -1
        } else if (b.indexed_on === '' || typeof b.indexed_on === 'undefined') {
          return 1
        }
        return (
          new Date(a.indexed_on).getTime() - new Date(b.indexed_on).getTime()
        )
      },
    },
    {
      field: 'fiscal_year',
      numeric: true,
      disablePadding: false,
      title: 'FiscalYear',
    },

    {
      field: 'sap_contract_number',
      numeric: true,
      disablePadding: false,
      title: 'SAPContractNumber',
      customSort: (a, b) => {
        if (
          a.sap_contract_number === '' ||
          typeof a.sap_contract_number === 'undefined' ||
          typeof a.sap_contract_number === 'object'
        ) {
          return -1
        } else if (
          b.sap_contract_number === '' ||
          typeof b.sap_contract_number === 'undefined' ||
          typeof b.sap_contract_number === 'object'
        ) {
          return 1
        }
        return parseInt(a.sap_contract_number) - parseInt(b.sap_contract_number)
      },
    },

    {
      field: 'sap_customer_number',
      numeric: true,
      disablePadding: false,
      title: 'SAPCustomerNumber',
      customSort: (a, b) => {
        if (
          a.sap_customer_number === '' ||
          typeof a.sap_customer_number === 'undefined' ||
          typeof a.sap_customer_number === 'object'
        ) {
          return -1
        } else if (
          b.sap_customer_number === '' ||
          typeof b.sap_customer_number === 'undefined' ||
          typeof b.sap_customer_number === 'object'
        ) {
          return 1
        }
        return parseInt(a.sap_customer_number) - parseInt(b.sap_customer_number)
      },
    },
    {
      field: 'location_number',
      numeric: true,
      disablePadding: false,
      title: 'LocationNumber',
    },
    {
      field: 'location_name',
      numeric: true,
      disablePadding: false,
      title: 'LocationName',
    },
    {
      field: 'location_state',
      numeric: true,
      disablePadding: false,
      title: 'State',
    },

    {
      field: 'location_region',
      numeric: true,
      disablePadding: false,
      title: 'Region',
    },

    {
      field: 'assigned_to',
      numeric: true,
      disablePadding: false,
      title: 'AssignedTo',
      render: rowData => (
        <span>{rowData.assigned_to === 'NA' ? '' : rowData.assigned_to}</span>
      ),
    },
    {
      field: 'document_type',
      numeric: true,
      disablePadding: false,
      title: 'DocumentType',
    },
    {
      field: 'document_title',
      numeric: true,
      disablePadding: false,
      title: 'DocumentTitle',
      render: rowData =>
        rowData.document_title.length > 100 ? (
          <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
            {rowData.document_title}
          </div>
        ) : (
          <div>{rowData.document_title}</div>
        ),
    },
    {
      field: 'cam_status',
      numeric: true,
      disablePadding: false,
      title: 'CAMStatus',

      filterComponent: props => (
        <FormControl>
          <Select
            id="search-options"
            style={{ width: '80px' }}
            onChange={event => {
              props.onFilterChanged(
                props.columnDef.tableData.id,
                event.target.value
              )
            }}
            autoWidth={true}
          >
            <MenuItem value="">&nbsp;</MenuItem>

            <MenuItem value="Not Started">Not started</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Pending Information - Operator">
              Pending Information - Operator
            </MenuItem>
            <MenuItem value="Pending Information - Internal">
              Pending Information - Internal
            </MenuItem>
            <MenuItem value="Complete - Approved">Complete - Approved</MenuItem>
            <MenuItem value="Complete - Denied">Complete - Denied</MenuItem>
          </Select>
        </FormControl>
      ),
      customFilterAndSearch: (value, rowData) => {
        return value === rowData.cam_status
      },
    },

    {
      field: 'total_dollar_impact',
      disablePadding: false,
      title: 'TotalDollarImpact',
      render: rowData => formatDollarAmount(rowData.total_dollar_impact),
      customSort: (a, b) => {
        let a1 =
          typeof a.total_dollar_impact === 'undefined' ||
          a.total_dollar_impact === 'NA' ||
          typeof a.total_dollar_impact === 'object'
            ? 0
            : Number(a.total_dollar_impact.replace(/[^0-9.-]+/g, ''))
        let b1 =
          typeof b.total_dollar_impact === 'undefined' ||
          b.total_dollar_impact === 'NA' ||
          typeof b.total_dollar_impact === 'object'
            ? 0
            : Number(b.total_dollar_impact.replace(/[^0-9.-]+/g, ''))

        return a1 - b1
      },
    },
    {
      field: 'total_customer_budget_amount',
      numeric: true,
      disablePadding: false,
      title: 'TotalCustomerBudgetAmount',
      render: rowData =>
        formatDollarAmount(rowData.total_customer_budget_amount),

      customSort: (a, b) => {
        let a1 =
          typeof a.total_customer_budget_amount === 'undefined' ||
          a.total_customer_budget_amount === 'NA' ||
          typeof a.total_customer_budget_amount === 'object'
            ? 0
            : Number(a.total_customer_budget_amount.replace(/[^0-9.-]+/g, ''))
        let b1 =
          typeof b.total_customer_budget_amount === 'undefined' ||
          b.total_customer_budget_amount === 'NA' ||
          typeof b.total_customer_budget_amount === 'object'
            ? 0
            : Number(b.total_customer_budget_amount.replace(/[^0-9.-]+/g, ''))

        return a1 - b1
      },
    },
    {
      field: 'total_customer_rec_amount',
      numeric: true,
      disablePadding: false,
      title: 'TotalCustomerRecAmount',
      render: rowData => formatDollarAmount(rowData.total_customer_rec_amount),

      customSort: (a, b) => {
        let a1 =
          typeof a.total_customer_rec_amount === 'undefined' ||
          a.total_customer_rec_amount === 'NA' ||
          typeof a.total_customer_rec_amount === 'object'
            ? 0
            : Number(a.total_customer_rec_amount.replace(/[^0-9.-]+/g, ''))
        let b1 =
          typeof b.total_customer_rec_amount === 'undefined' ||
          b.total_customer_rec_amount === 'NA' ||
          typeof b.total_customer_rec_amount === 'object'
            ? 0
            : Number(b.total_customer_rec_amount.replace(/[^0-9.-]+/g, ''))

        return a1 - b1
      },
    },
    {
      field: 'current_bucket',
      numeric: true,
      disablePadding: false,
      title: 'BucketName',
    },
    {
      field: 'content_type',
      numeric: true,
      disablePadding: false,
      title: 'ContentType',
    },

    {
      field: 'workflow_id',
      numeric: false,
      disablePadding: false,
      title: 'WorkflowId',
    },
    {
      title: 'WorkflowId',
      field: 'workflow_id',
      hidden: true,
    },
    {
      title: 'WorkflowId',
      field: 'workflow_id',
      hidden: true,
    },
    {
      title: 'WorkflowId',
      field: 'workflow_id',
      hidden: true,
    },
    {
      title: 'WorkflowId',
      field: 'workflow_id',
      hidden: true,
    },

    // {
    //   field: 'total_customer_rec_amount',
    //   numeric: true,
    //   disablePadding: false,
    //   title: 'TotalCustomerRecAmount',
    //   type: 'currency',
    // },
  ],

  'PA Lease': [
    {
      field: 'created_date',
      numeric: true,
      disablePadding: false,
      title: 'CreatedDate',
      customSort: (a, b) => {
        if (a.created_date === '' || typeof a.created_date === 'undefined') {
          return -1
        } else if (
          b.created_date === '' ||
          typeof b.created_date === 'undefined'
        ) {
          return 1
        }
        return (
          new Date(a.created_date).getTime() -
          new Date(b.created_date).getTime()
        )
      },
    },
    {
      field: 'indexed_on',
      numeric: true,
      disablePadding: false,
      title: 'IndexedDate',
      customSort: (a, b) => {
        if (a.indexed_on === '' || typeof a.indexed_on === 'undefined') {
          return -1
        } else if (b.indexed_on === '' || typeof b.indexed_on === 'undefined') {
          return 1
        }
        return (
          new Date(a.indexed_on).getTime() - new Date(b.indexed_on).getTime()
        )
      },
    },
    {
      field: 'location_number',
      numeric: true,
      disablePadding: false,
      title: 'LocationNumber',
    },
    {
      field: 'location_name',
      numeric: true,
      disablePadding: false,
      title: 'LocationName',
    },
    {
      field: 'location_state',
      numeric: true,
      disablePadding: false,
      title: 'State',
    },

    {
      field: 'location_region',
      numeric: true,
      disablePadding: false,
      title: 'Region',
    },

    {
      field: 'assigned_to',
      numeric: true,
      disablePadding: false,
      title: 'AssignedTo',
      render: rowData => (
        <span>{rowData.assigned_to === 'NA' ? '' : rowData.assigned_to}</span>
      ),
    },
    {
      field: 'document_type',
      numeric: true,
      disablePadding: false,
      title: 'DocumentType',
    },
    {
      field: 'review_type',
      numeric: true,
      disablePadding: false,
      title: 'ReviewType',
    },
    {
      field: 'document_title',
      numeric: true,
      disablePadding: false,
      title: 'DocumentTitle',
      render: rowData =>
        rowData.document_title.length > 100 ? (
          <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
            {rowData.document_title}
          </div>
        ) : (
          <div>{rowData.document_title}</div>
        ),
    },
    {
      title: 'DocumentPurpose',
      field: 'document_purpose',
      render: rowData =>
        rowData.document_purpose && rowData.document_purpose.length > 100 ? (
          <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
            {rowData.document_purpose}
          </div>
        ) : (
          <div>{rowData.document_purpose}</div>
        ),
    },

    {
      field: 'document_date',
      // numeric: true,
      disablePadding: false,
      title: 'DocumentDate',
      customSort: (a, b) => {
        if (a.document_date === '' || typeof a.document_date === 'undefined') {
          return -1
        } else if (
          b.document_date === '' ||
          typeof b.document_date === 'undefined'
        ) {
          return 1
        }
        return (
          new Date(a.document_date).getTime() -
          new Date(b.document_date).getTime()
        )
      },
    },

    {
      field: 'sap_contract_number',
      numeric: true,
      disablePadding: false,
      title: 'SAPContractNumber',
      customSort: (a, b) => {
        if (
          a.sap_contract_number === '' ||
          typeof a.sap_contract_number === 'undefined' ||
          typeof a.sap_contract_number === 'object'
        ) {
          return -1
        } else if (
          b.sap_contract_number === '' ||
          typeof b.sap_contract_number === 'undefined' ||
          typeof b.sap_contract_number === 'object'
        ) {
          return 1
        }
        return parseInt(a.sap_contract_number) - parseInt(b.sap_contract_number)
      },
    },

    {
      field: 'lease_type',
      numeric: true,
      disablePadding: false,
      title: 'LeaseType',
    },
    {
      field: 'checklist_status',
      disablePadding: false,
      title: 'ChecklistStatus',
    },
    {
      field: 'due_date',
      // numeric: true,
      disablePadding: false,
      title: 'DueDate',
      customSort: (a, b) => {
        if (a.due_date === '' || typeof a.due_date === 'undefined') {
          return -1
        } else if (b.due_date === '' || typeof b.due_date === 'undefined') {
          return 1
        }
        return new Date(a.due_date).getTime() - new Date(b.due_date).getTime()
      },
    },

    {
      field: 'net_present_value',
      numeric: true,
      disablePadding: false,
      title: 'NetPresentValue',
      render: rowData => formatDollarAmount(rowData.net_present_value),
      customSort: (a, b) => {
        let a1 =
          a.net_present_value === '' ||
          a.net_present_value === 'NA' ||
          typeof a.net_present_value === 'undefined' ||
          typeof a.net_present_value === 'object'
            ? 0
            : Number(a.net_present_value.replace(/[^0-9.-]+/g, ''))
        let b1 =
          b.net_present_value === '' ||
          b.net_present_value === 'NA' ||
          typeof b.net_present_value === 'undefined' ||
          typeof b.net_present_value === 'object'
            ? 0
            : Number(b.net_present_value.replace(/[^0-9.-]+/g, ''))
        return a1 - b1

        // if (
        //   a.net_present_value === '' ||
        //   a.min_lease_payment === 'NA' ||
        //   typeof a.net_present_value === 'undefined' ||
        //   typeof a.net_present_value === 'object'
        // ) {
        //   return -1
        // } else if (
        //   b.net_present_value === '' ||
        //   b.min_lease_payment === 'NA' ||
        //   typeof b.net_present_value === 'undefined' ||
        //   typeof b.net_present_value === 'object'
        // ) {
        //   return 1
        // }
      },
    },
    {
      field: 'min_lease_payment',
      numeric: true,
      disablePadding: false,
      title: 'MinimumLeasePayments',
      render: rowData => formatDollarAmount(rowData.min_lease_payment),
      customSort: (a, b) => {
        let a1 =
          a.min_lease_payment === '' ||
          a.min_lease_payment === 'NA' ||
          typeof a.min_lease_payment === 'undefined' ||
          typeof a.min_lease_payment === 'object'
            ? 0
            : Number(a.min_lease_payment.replace(/[^0-9.-]+/g, ''))
        let b1 =
          b.min_lease_payment === '' ||
          b.min_lease_payment === 'NA' ||
          typeof b.min_lease_payment === 'undefined' ||
          typeof b.min_lease_payment === 'object'
            ? 0
            : Number(b.min_lease_payment.replace(/[^0-9.-]+/g, ''))
        return a1 - b1
      },
    },

    {
      field: 'current_bucket',
      numeric: true,
      disablePadding: false,
      title: 'BucketName',
    },
    {
      field: 'content_type',
      numeric: true,
      disablePadding: false,
      title: 'ContentType',
    },

    {
      field: 'workflow_id',
      numeric: false,
      disablePadding: false,
      title: 'WorkflowId',
    },
  ],
  'PA CAM From Real Estate': [
    {
      field: 'created_date',
      numeric: true,
      disablePadding: false,
      title: 'CreatedDate',
      customSort: (a, b) => {
        if (a.created_date === '' || typeof a.created_date === 'undefined') {
          return -1
        } else if (
          b.created_date === '' ||
          typeof b.created_date === 'undefined'
        ) {
          return 1
        }
        return (
          new Date(a.created_date).getTime() -
          new Date(b.created_date).getTime()
        )
      },
    },
    {
      field: 'indexed_on',
      numeric: true,
      disablePadding: false,
      title: 'IndexedDate',
      customSort: (a, b) => {
        if (a.indexed_on === '' || typeof a.indexed_on === 'undefined') {
          return -1
        } else if (b.indexed_on === '' || typeof b.indexed_on === 'undefined') {
          return 1
        }
        return (
          new Date(a.indexed_on).getTime() - new Date(b.indexed_on).getTime()
        )
      },
    },
    {
      field: 'location_number',
      numeric: true,
      disablePadding: false,
      title: 'LocationNumber',
    },
    {
      field: 'location_name',
      numeric: true,
      disablePadding: false,
      title: 'LocationName',
    },
    {
      field: 'location_state',
      numeric: true,
      disablePadding: false,
      title: 'State',
    },

    {
      field: 'location_region',
      numeric: true,
      disablePadding: false,
      title: 'Region',
    },
    {
      field: 'assigned_to',
      numeric: true,
      disablePadding: false,
      title: 'AssignedTo',
      render: rowData => (
        <span>{rowData.assigned_to === 'NA' ? '' : rowData.assigned_to}</span>
      ),
    },
    {
      field: 'document_type',
      numeric: true,
      disablePadding: false,
      title: 'DocumentType',
    },
    {
      field: 'document_title',
      numeric: true,
      disablePadding: false,
      title: 'DocumentTitle',
      render: rowData =>
        rowData.document_title.length > 100 ? (
          <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
            {rowData.document_title}
          </div>
        ) : (
          <div>{rowData.document_title}</div>
        ),
    },

    {
      title: 'DocumentPurpose',
      field: 'document_purpose',
      render: rowData =>
        rowData.document_purpose && rowData.document_purpose.length > 100 ? (
          <div style={{ whiteSpace: 'break-spaces', width: '40rem' }}>
            {rowData.document_purpose}
          </div>
        ) : (
          <div>{rowData.document_purpose}</div>
        ),
    },
    {
      field: 'document_date',
      numeric: true,
      disablePadding: false,
      title: 'DocumentDate',
      customSort: (a, b) => {
        if (a.document_date === '' || typeof a.document_date === 'undefined') {
          return -1
        } else if (
          b.document_date === '' ||
          typeof b.document_date === 'undefined'
        ) {
          return 1
        }
        return (
          new Date(a.document_date).getTime() -
          new Date(b.document_date).getTime()
        )
      },
    },
    {
      field: 'cam_status',
      numeric: true,
      disablePadding: false,
      title: 'CAMStatus',

      filterComponent: props => (
        <FormControl>
          <Select
            id="search-options"
            style={{ width: '80px' }}
            placeholder="select"
            onChange={event => {
              props.onFilterChanged(
                props.columnDef.tableData.id,
                event.target.value
              )
            }}
            autoWidth={true}
          >
            <MenuItem value="">&nbsp;</MenuItem>

            <MenuItem value="Not Started">Not started</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Pending Information - Operator">
              Pending Information - Operator
            </MenuItem>
            <MenuItem value="Pending Information - Internal">
              Pending Information - Internal
            </MenuItem>
            <MenuItem value="Complete - Approved">Complete - Approved</MenuItem>
            <MenuItem value="Complete - Denied">Complete - Denied</MenuItem>
          </Select>
        </FormControl>
      ),
      customFilterAndSearch: (value, rowData) => {
        return value === rowData.cam_status
      },
    },
    {
      field: 'total_dollar_impact',
      disablePadding: false,
      title: 'TotalDollarImpact',
      render: rowData => formatDollarAmount(rowData.total_dollar_impact),

      customSort: (a, b) => {
        let a1 =
          typeof a.total_dollar_impact === 'undefined' ||
          a.total_dollar_impact === 'NA' ||
          typeof a.total_dollar_impact === 'object'
            ? 0
            : Number(a.total_dollar_impact.replace(/[^0-9.-]+/g, ''))
        let b1 =
          typeof b.total_dollar_impact === 'undefined' ||
          b.total_dollar_impact === 'NA' ||
          typeof b.total_dollar_impact === 'object'
            ? 0
            : Number(b.total_dollar_impact.replace(/[^0-9.-]+/g, ''))

        return a1 - b1
      },
    },
    {
      field: 'current_bucket',
      numeric: true,
      disablePadding: false,
      title: 'BucketName',
    },
    {
      field: 'content_type',
      numeric: true,
      disablePadding: false,
      title: 'ContentType',
    },

    {
      field: 'workflow_id',
      numeric: false,
      disablePadding: false,
      title: 'WorkflowId',
    },
    {
      title: 'WorkflowId',
      field: 'workflow_id',
      hidden: true,
    },
    {
      title: 'WorkflowId',
      field: 'workflow_id',
      hidden: true,
    },
    {
      title: 'WorkflowId',
      field: 'workflow_id',
      hidden: true,
    },
    {
      title: 'WorkflowId',
      field: 'workflow_id',
      hidden: true,
    },
  ],
}

//ContentTypes mentioned in this function are workflow content groups. ContentType of document can be accessed from workflowInfo
export const WorkflowDetails = ({ contentType, classes, workflowInfo }) => {
  // switch (contentType) {
  //   case 'Real Estate':
  if (contentType === 'Real Estate') {
    return (
      <Fragment>
        <div>
          <span className={classes.label}>Document Title: </span>{' '}
          {workflowInfo.document_title}
        </div>
        <div>
          <span className={classes.label}>Document Date: </span>{' '}
          {workflowInfo.document_date}
        </div>
        <div>
          <span className={classes.label}>Document Status: </span>{' '}
          {workflowInfo.document_status}
        </div>
        <div>
          <span className={classes.label}>Recording Status: </span>{' '}
          {workflowInfo.recording_status}
        </div>
        <div>
          <span className={classes.label}>Physical File Number: </span>{' '}
          {workflowInfo.physical_file_number}
        </div>
        <div>
          <span className={classes.label}>Business Follow Up: </span>{' '}
          {workflowInfo.business_follow_up_name}
        </div>
        <div>
          <span className={classes.label}>Legal Follow Up: </span>{' '}
          {workflowInfo.legal_follow_up_name}
        </div>
      </Fragment>
    )
  }
  //     case 'PA CAM Payable':

  if (contentType === 'PA CAM Payable') {
    if (workflowInfo.content_type === 'PA CAM Payable Budget') {
      return (
        <Fragment>
          <div>
            <span className={classes.label}>Fiscal Year: </span>{' '}
            {workflowInfo.fiscal_year}
          </div>
          <div>
            <span className={classes.label}>Sap Contract Number: </span>{' '}
            {workflowInfo.sap_contract_number}
          </div>
          <div>
            <span className={classes.label}>Operator: </span>{' '}
            {workflowInfo.operator}
          </div>
          <div>
            <span className={classes.label}>Pro Rata Share: </span>{' '}
            {workflowInfo.pro_rata_share}
          </div>
          <div>
            <span className={classes.label}>
              Target Total Budget Amount Submitted by Operator:{' '}
            </span>{' '}
            {formatDollarAmount(workflowInfo.target_prs_budget_amount)}
          </div>
        </Fragment>
      )
    } else if (workflowInfo.content_type === 'PA CAM Payable Reconciliation') {
      return (
        <Fragment>
          <div>
            <span className={classes.label}>Fiscal Year: </span>{' '}
            {workflowInfo.fiscal_year}
          </div>
          <div>
            <span className={classes.label}>Sap Contract Number: </span>{' '}
            {workflowInfo.sap_contract_number}
          </div>
          <div>
            <span className={classes.label}>Operator: </span>{' '}
            {workflowInfo.operator}
          </div>
          <div>
            <span className={classes.label}>Pro Rata Share: </span>{' '}
            {workflowInfo.pro_rata_share}
          </div>
          <div>
            <span className={classes.label}>
              Target Total Rec Amount Submitted by Operator:{' '}
            </span>{' '}
            {formatDollarAmount(workflowInfo.target_prs_total_rec_amount)}
          </div>
        </Fragment>
      )
    } else if (workflowInfo.content_type === 'PA CAM Payable Unexpected') {
      return (
        <Fragment>
          <div>
            <span className={classes.label}>Fiscal Year: </span>{' '}
            {workflowInfo.fiscal_year}
          </div>
          <div>
            <span className={classes.label}>Sap Contract Number: </span>{' '}
            {workflowInfo.sap_contract_number}
          </div>
          <div>
            <span className={classes.label}>Total Dollar Impact: </span>{' '}
            {formatDollarAmount(workflowInfo.total_dollar_impact)}
          </div>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <Fragment>
            <div>
              <span className={classes.label}>Fiscal Year: </span>{' '}
              {workflowInfo.fiscal_year}
            </div>
            <div>
              <span className={classes.label}>Sap Contract Number: </span>{' '}
              {workflowInfo.sap_contract_number}
            </div>
            <div>
              <span className={classes.label}>Total Dollar Impact: </span>{' '}
              {formatDollarAmount(workflowInfo.total_dollar_impact)}
            </div>
          </Fragment>
        </Fragment>
      )
    }
  }

  // case 'PA CAM Rebill':
  else if (contentType === 'PA CAM Rebill') {
    if (workflowInfo.content_type === 'PA CAM Rebill Budget') {
      return (
        <Fragment>
          <div>
            <span className={classes.label}>Fiscal Year: </span>{' '}
            {workflowInfo.fiscal_year}
          </div>
          <div>
            <span className={classes.label}>Sap Contract Number: </span>{' '}
            {workflowInfo.sap_contract_number}
          </div>
          <div>
            <span className={classes.label}>Sap Customer Number: </span>{' '}
            {workflowInfo.sap_customer_number}
          </div>
          <div>
            <span className={classes.label}>
              Total Customer Budget Amount:{' '}
            </span>{' '}
            {formatDollarAmount(workflowInfo.total_customer_budget_amount)}
          </div>
        </Fragment>
      )
    } else if (workflowInfo.content_type === 'PA CAM Rebill Reconciliation') {
      return (
        <Fragment>
          <div>
            <span className={classes.label}>Fiscal Year: </span>{' '}
            {workflowInfo.fiscal_year}
          </div>
          <div>
            <span className={classes.label}>Sap Contract Number: </span>{' '}
            {workflowInfo.sap_contract_number}
          </div>
          <div>
            <span className={classes.label}>Sap Customer Number: </span>{' '}
            {workflowInfo.sap_customer_number}
          </div>
          <div>
            <span className={classes.label}>Total Customer Rec Amount: </span>{' '}
            {formatDollarAmount(workflowInfo.total_customer_rec_amount)}
          </div>
        </Fragment>
      )
    } else if (workflowInfo.content_type === 'PA CAM Rebill Unexpected') {
      return (
        <Fragment>
          <div>
            <span className={classes.label}>Fiscal Year: </span>{' '}
            {workflowInfo.fiscal_year}
          </div>
          <div>
            <span className={classes.label}>Sap Contract Number: </span>{' '}
            {workflowInfo.sap_contract_number}
          </div>
          <div>
            <span className={classes.label}>Sap Customer Number: </span>{' '}
            {workflowInfo.sap_customer_number}
          </div>

          <div>
            <span className={classes.label}>Total Dollar Impact: </span>{' '}
            {formatDollarAmount(workflowInfo.total_dollar_impact)}
          </div>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <div>
            <span className={classes.label}>Fiscal Year: </span>{' '}
            {workflowInfo.fiscal_year}
          </div>
          <div>
            <span className={classes.label}>Sap Contract Number: </span>{' '}
            {workflowInfo.sap_contract_number}
          </div>

          <div>
            <span className={classes.label}>Total Dollar Impact: </span>{' '}
            {formatDollarAmount(workflowInfo.total_dollar_impact)}
          </div>
        </Fragment>
      )
    }
  } else if (contentType === 'PA Lease') {
    return (
      <Fragment>
        <div>
          <span className={classes.label}>Fiscal Year: </span>{' '}
          {workflowInfo.fiscal_year}
        </div>
        <div>
          <span className={classes.label}>Sap Contract Number: </span>{' '}
          {workflowInfo.sap_contract_number}
        </div>
      </Fragment>
    )
  } else return null
  // }
}
export const columns = [
  {
    name: 'created_date',
    label: 'Created Date',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'action',
    label: 'User Action',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'current_bucket_name',
    label: 'From Bucket',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'future_bucket_name',
    label: 'To Bucket',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'user_name',
    label: 'Modified UserName',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'task_details',
    label: 'Task Details',
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
]
export const options = {
  filterType: 'multiselect',
  expandableRows: true,
  expandableRowsOnClick: true,
  renderExpandableRow: (rowData, rowMeta) => {
    return (
      <TableRow>
        <TableCell />
        <TableCell colSpan={2}>
          <br />
          {rowData[5][0].task_label === 'Message' ? (
            <TableRow>
              <TableCell>No Task Details found </TableCell>
            </TableRow>
          ) : (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Task Question</TableCell>
                  <TableCell>Task Response</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData[5].map(item => (
                  <TableRow>
                    <TableCell>{item.task_label}</TableCell>
                    <TableCell>{item.task_response_options}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <br />
        </TableCell>
      </TableRow>
    )
  },
  pagination: false,
  download: false,
  downloadOptions: { filename: 'workflowhistory.csv' },
  filter: false,
  selectableRows: 'none',
  elevation: 4,
  searchOpen: false,
  search: true,
  responsive: 'scrollMaxHeight',
  resizableColumns: false,
  viewColumns: false,
  print: false,
  textLabels: {
    body: {
      noMatch: '',
    },
  },
}
