const documentPayload = {
  'PA CAM': [
    'Notice Documents Report',
    'Payable Budget',
    'Productivity Weekly Volumes report',
    'Quality Metrics report',
    'Productivity Metrics report',
    'CAM Quarterly Inbox Report',
  ],

  'PA Lease': [
    'Pending Report',
    'InReview Report',
    'Notice Documents',
    'Easement Report',
    'Quarterly Inbox Report',
  ],
}

export default documentPayload
