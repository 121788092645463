import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputBase from '@material-ui/core/InputBase'
import RealEstate from '../contenttypes/RealEstate'
import PACAMPayableBudget from '../contenttypes/PACAMPayableBudget'
import PACAMPayableReconciliation from '../contenttypes/PACAMPayableReconciliation'
import PACAMPayableUnexpected from '../contenttypes/PACAMPayableUnexpected'
import PACAMRebillBudget from '../contenttypes/PACAMRebillBudget'
import PACAMRebillReconciliation from '../contenttypes/PACAMRebillReconciliation'
import PACAMRebillUnexpected from '../contenttypes/PACAMRebillUnexpected'
import apiConfig from '../../config/apiConfig'
import { useSelector } from 'react-redux'
import PALease from '../contenttypes/PALease'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
    marginTop: 24,
    backgroundColor: 'white',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  metadataHeader: {
    marginTop: 15,
    fontWeight: 'bold',
    fontSize: 20,
    color: '#830300',
    letterSpacing: 1.2,
  },
  metadataBody: {
    marginTop: 25,
  },
  metadataSubmitButton: {
    marginTop: 25,
    marginBottom: 25,
  },
  contentTypeStyle: {
    width: 300,
  },
  //Sets styling to all the input, selects fields in the child components
  selects: {
    width: 300,
    marginTop: 7.5,
    marginBottom: 7.5,
  },
  textfieldLabel: {
    marginLeft: 7,
  },
}))

const SearchMetaData = props => {
  const classes = useStyles()
  const docGroupDocType = useSelector(
    state => state.common.contentTypeAndDocumentData
  )

  return (
    <div>
      <FormControl className={classes.contentTypeStyle}>
        <InputLabel htmlFor="content-type">Content Type</InputLabel>
        <NativeSelect
          id="content-type"
          value={props.metaData.ContentType}
          input={<BootstrapInput />}
          disabled={true}
        >
          <option value={props.metaData.ContentType}>
            {props.metaData.ContentType}
          </option>
        </NativeSelect>
      </FormControl>
      {props.metaData.ContentType === apiConfig.apiUrl.REALESTATE ? (
        <RealEstate
          {...props}
          bootStrapInput={BootstrapInput}
          useStyleClass={classes}
          docGroupDocType={docGroupDocType}
          contentType={props.metaData.ContentType}
        />
      ) : props.metaData.ContentType === apiConfig.apiUrl.PACAMPAYABLEBUDGET ? (
        <PACAMPayableBudget
          {...props}
          bootStrapInput={BootstrapInput}
          useStyleClass={classes}
          docGroupDocType={docGroupDocType}
          contentType={props.metaData.ContentType}
        />
      ) : props.metaData.ContentType ===
        apiConfig.apiUrl.PACAMPAYABLERECONCILIATION ? (
        <PACAMPayableReconciliation
          {...props}
          bootStrapInput={BootstrapInput}
          useStyleClass={classes}
          docGroupDocType={docGroupDocType}
          contentType={props.metaData.ContentType}
        />
      ) : props.metaData.ContentType ===
        apiConfig.apiUrl.PACAMPAYABLEUNEXPECTED ? (
        <PACAMPayableUnexpected
          {...props}
          bootStrapInput={BootstrapInput}
          useStyleClass={classes}
          docGroupDocType={docGroupDocType}
          contentType={props.metaData.ContentType}
        />
      ) : props.metaData.ContentType === apiConfig.apiUrl.PACAMREBILLBUDGET ? (
        <PACAMRebillBudget
          {...props}
          bootStrapInput={BootstrapInput}
          useStyleClass={classes}
          docGroupDocType={docGroupDocType}
          contentType={props.metaData.ContentType}
        />
      ) : props.metaData.ContentType ===
        apiConfig.apiUrl.PACAMREBILLRECONCILIATION ? (
        <PACAMRebillReconciliation
          {...props}
          bootStrapInput={BootstrapInput}
          useStyleClass={classes}
          docGroupDocType={docGroupDocType}
          contentType={props.metaData.ContentType}
        />
      ) : props.metaData.ContentType ===
        apiConfig.apiUrl.PACAMREBILLUNEXPECTED ? (
        <PACAMRebillUnexpected
          {...props}
          bootStrapInput={BootstrapInput}
          useStyleClass={classes}
          docGroupDocType={docGroupDocType}
          contentType={props.metaData.ContentType}
        />
      ) : (
        <PALease
          {...props}
          bootStrapInput={BootstrapInput}
          useStyleClass={classes}
          docGroupDocType={docGroupDocType}
          contentType={props.metaData.ContentType}
        />
      )}
    </div>
  )
}

export default SearchMetaData
