/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import apiConfig from '../../config/apiConfig'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import './MetaDataStyle.css'
import { useAuth } from '@praxis/component-auth'
import SearchMetaData from './searchMetaData'
import IndexMetaData from '../index/IndexMetaData'
import { useDispatch, useSelector } from 'react-redux'
import { fetchContentTypeObj } from '../common/fetchContentTypeObj'
import { validateDate, validatePhysicalFileNo } from '../common/validation'
import { useToasts } from 'react-toast-notifications'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
    marginTop: 15,
    backgroundColor: 'white',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  metadataHeader: {
    marginTop: 15,
    fontWeight: 'bold',
    fontSize: 20,
    color: '#830300',
    letterSpacing: 1.2,
    height: 30,
    // marginBottom: 10
  },
  metadataBody: {
    marginTop: 10,
  },
  metadataSubmitButton: {
    marginTop: 25,
    marginBottom: 25,
  },
  contentTypeStyle: {
    width: 300,
  },
  //Sets styling to all the input, selects fields in the child components
  selects: {
    width: 300,
    marginTop: 7.5,
    marginBottom: 7.5,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    position: 'sticky',
    top: '0px',
    zIndex: 2,
    backgroundColor: '#e6e6e6',
  },
}))

function PaperComponent(props) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const MetaData = props => {
  const classes = useStyles()
  const auth = useAuth()
  const { session } = auth
  let userInfo = session.userInfo
  const [objIndex, setObjIndex] = useState({})
  const [objSearch, setObjSearch] = useState({})
  const [
    pendingIndexCounter,
    setPendingIndexCounter,
  ] = props.setPendingDocumentCounter
  const [loadConsole, setLoadConsole] = useState(false)
  const { addToast } = useToasts()

  const [serverResponse, setServerResponse] = useState({})
  const metaDataIndex = useSelector(state => state.index.indexMetaData)
  const metaDataSearch = useSelector(state => state.search.searchMetaData)
  const metaDataWorkflow = useSelector(state => state.workflow.workflowMetadata)
  const [disableButton, setDisableButton] = useState(false)
  const dispatch = useDispatch()

  const handleIndexMetadataSubmit = e => {
    e.preventDefault()
    setDisableButton(true)
    // let metaDataCopy = Object.assign({}, metaDataIndex)

    let bodyObj = fetchContentTypeObj(
      objIndex,
      userInfo,
      props.type,
      props.tossFilePath
    )

    let dateHasError
    if (objIndex.contentType === apiConfig.apiUrl.REALESTATE) {
      dateHasError = handleDate(bodyObj)
    }

    if (dateHasError) {
      addToast('Error!Please fill correct data', {
        appearance: 'error',
        autoDismiss: true,
      })
    } else {
      let url =
        props.batchType === apiConfig.apiUrl.NOBATCH
          ? `${apiConfig.apiUrl.noBatchUploadUrl}${apiConfig.apiUrl.key_url}`
          : `${apiConfig.apiUrl.batchUrl}${userInfo.lanId}/${props.batchType}/${props.recordId}${apiConfig.apiUrl.key_url}`
      let reqMethod =
        props.batchType === apiConfig.apiUrl.NOBATCH ? `POST` : `PUT`
      submitMetadata(url, bodyObj, reqMethod, props.type)
    }
  }

  const submitMetadata = async (url, bodyObj, reqMethod, type) => {
    await fetch(url, {
      method: reqMethod,
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.access_token,
        'Content-Type': 'application/json',
      },
      body: bodyObj,
    })
      .then(res => {
        setServerResponse(res)
        setLoadConsole(true)
        removeMetadataOnSuccessfulSubmit(type)
        // props.disableLoadIndexForm({},fileNameSearch)
      })
      .catch(err => console.log(err))
  }

  const removeMetadataOnSuccessfulSubmit = type => {
    let metadataCopy
    switch (type) {
      case 'search':
        metadataCopy = Object.assign({}, metaDataSearch)
        delete metadataCopy[props.fileNameSearch]
        dispatch({
          type: 'SAVE_SEARCH_METADATA',
          payload: metadataCopy,
        })
        break

      case 'index':
        metadataCopy = Object.assign({}, metaDataIndex)
        delete metadataCopy[props.fileNameSelected]

        dispatch({
          type: 'SAVE_INDEX_METADATA',
          payload: metadataCopy,
        })
        break

      case 'workflow':
        metadataCopy = Object.assign({}, metaDataWorkflow)
        delete metadataCopy[props.fileNameSearch]
        dispatch({
          type: 'SAVE_WORKFLOW_METADATA',
          payload: metadataCopy,
        })
        break

      default:
        return null
    }
  }

  const handleConsoleClose = () => {
    if (
      serverResponse.status === 200 &&
      props.type === apiConfig.apiUrl.INDEX
    ) {
      setLoadConsole(false)
      setPendingIndexCounter(!pendingIndexCounter)
      document.getElementById('delete' + props.fileNameSelected).click()

      return
    }
    if (serverResponse.status !== 200) {
      setLoadConsole(false)
      return
    }
    setLoadConsole(false)
    document.getElementById('delete' + props.fileNameSearch).click()
    props.disableLoadIndexForm({}, props.fileNameSearch)
  }

  //This function is modified to submit metadata for both workflow and editing in search
  const handleSearchMetadataSubmit = e => {
    e.preventDefault()
    // let bodyObj = fetchContentTypeObj(objSearch)
    setDisableButton(true)
    e.preventDefault()

    let bodyObj = fetchContentTypeObj(
      objSearch,
      userInfo,
      props.type,
      props.tossFilePath
    )

    let dateHasError

    if (objSearch.contentType === apiConfig.apiUrl.REALESTATE) {
      dateHasError = handleDate(bodyObj)
    }

    if (dateHasError) {
      addToast('Error!Please fill correct data', {
        appearance: 'error',
        autoDismiss: true,
      })
    } else {
      let url =
        apiConfig.apiUrl.updateMetaUrl + objSearch.id + apiConfig.apiUrl.key_url
      submitMetadata(url, bodyObj, 'PUT', props.type)
    }
  }

  const handleSearchMetaDataBlur = event => {
    event.preventDefault()
    // let metaDataSearchCopy = Object.assign({}, metaDataSearch)
    let metaDataSearchCopy =
      props.type === 'workflow'
        ? Object.assign({}, metaDataWorkflow)
        : Object.assign({}, metaDataSearch)

    let bodyObj = fetchContentTypeObj(
      objSearch,
      userInfo,
      props.type,
      props.tossFilePath
    )

    let mergedObj = Object.assign(
      {},
      JSON.parse(bodyObj),
      metaDataSearchCopy[props.fileNameSearch]
    )

    metaDataSearchCopy[props.fileNameSearch] = mergedObj

    if (props.type === apiConfig.apiUrl.WORKFLOW) {
      dispatch({
        type: 'SAVE_WORKFLOW_METADATA',
        payload: metaDataSearchCopy,
      })
    } else {
      dispatch({
        type: 'SAVE_SEARCH_METADATA',
        payload: metaDataSearchCopy,
      })
    }
  }

  //savedMetadata is in snake case and objIndex in camel case
  const setReduxMetadata = () => {
    let metaDataCopy = Object.assign({}, metaDataIndex)

    let savedMetaData = metaDataCopy[props.fileNameSelected]
    let bodyObj
    if (Object.keys(objIndex).length === 0 && savedMetaData) {
      bodyObj = savedMetaData
    } else {
      bodyObj = JSON.parse(
        fetchContentTypeObj(objIndex, userInfo, props.type, props.tossFilePath)
      )
      metaDataCopy[props.fileNameSelected] = bodyObj
      dispatch({
        type: 'SAVE_INDEX_METADATA',
        payload: metaDataCopy,
      })
    }
  }

  const handleDate = formData => {
    let validExpirationDate
    let validDocumentDate
    let validPhysicalFileNumber
    let parsedObj = JSON.parse(formData)
    if (parsedObj['document_date'] !== '') {
      validDocumentDate = validateDate(parsedObj['document_date'])
      if (validDocumentDate) return true
    }
    if (parsedObj['expiration_date'] !== '') {
      validExpirationDate = validateDate(parsedObj['expiration_date'])
      if (validExpirationDate) return true
    }
    if (parsedObj['physical_file_number'] !== '') {
      validPhysicalFileNumber = validatePhysicalFileNo(
        parsedObj['physical_file_number']
      )
      if (validPhysicalFileNumber) return true
    }

    return false
  }

  React.useEffect(() => {
    setReduxMetadata()
  }, [objIndex])

  return (
    <div className="metadata-container">
      {loadConsole && (
        <Dialog
          open={true}
          onClose={handleConsoleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="metadata-dialog-title"
        >
          <DialogTitle
            style={{ cursor: 'move' }}
            id="metadata-dialog-title"
            className="dialogtitle"
          >
            {serverResponse.status !== 200
              ? `Document submission failed`
              : `Document ${
                  props.batchType === apiConfig.apiUrl.NOBATCH
                    ? `Submitted`
                    : `Saved`
                }`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {serverResponse.status !== 200
                ? `Error code: ${serverResponse.status}`
                : `Document ${
                    props.batchType === apiConfig.apiUrl.NOBATCH
                      ? `Submitted`
                      : `Saved`
                  } Successfully`}
            </DialogContentText>
          </DialogContent>
          <Button autoFocus onClick={handleConsoleClose} color="primary">
            Ok
          </Button>
        </Dialog>
      )}
      {// Trying to club them into one was creating form validation issues(Material Ui)
      props.type === apiConfig.apiUrl.INDEX ? (
        // Index Metadata
        <div className="metadata-inner-container">
          <div className={classes.metadataHeader}>Metadata</div>
          <div className={classes.metadataBody} />
          <form
            className="form-container"
            validate
            autoComplete="off"
            onSubmit={handleIndexMetadataSubmit}
            // onChange={handleChange}
            // onBlur={handleIndexMetaDataBlur}
            onError={event => alert('error')}
          >
            <IndexMetaData
              {...props}
              metaDataObj={[objIndex, setObjIndex]}
              savedMetaData={metaDataIndex[props.fileNameSelected]}
              metaDataReduxState={metaDataIndex}
              // savedMetaDataFormatter={fetchContentTypeObj}
            />
            {props.batchType === apiConfig.apiUrl.NOBATCH ? (
              <Button
                variant="contained"
                className={classes.metadataSubmitButton}
                type="submit"
                color="primary"
                disabled={disableButton}
              >
                {disableButton ? (
                  <CircularProgress style={{ color: 'primary' }} size={24} />
                ) : (
                  'Submit'
                )}
              </Button>
            ) : (
              <Button
                variant="contained"
                className={classes.metadataSubmitButton}
                type="submit"
                color="primary"
              >
                Save
              </Button>
            )}
          </form>
        </div>
      ) : (
        // Search metadata
        <div className="metadata-inner-container">
          {/* <div className={classes.metadataHeader}>Metadata</div> */}
          <div className={classes.metadataBody} />
          <form
            className="form-container"
            validate
            autoComplete="off"
            onSubmit={handleSearchMetadataSubmit}
            onBlur={handleSearchMetaDataBlur}
          >
            <div className="save-goback-button-container">
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  className={classes.metadataSubmitButton}
                  type="submit"
                  color="primary"
                  disabled={disableButton}
                >
                  {disableButton ? (
                    <CircularProgress style={{ color: 'primary' }} size={24} />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button
                  variant="contained"
                  className={classes.metadataSubmitButton}
                  type="submit"
                  onClick={e =>
                    props.disableLoadIndexForm(e, props.fileNameSearch)
                  }
                  color="primary"
                >
                  Cancel
                </Button>
              </div>
              <div>
                <SearchMetaData
                  {...props}
                  metaDataObj={[objSearch, setObjSearch]}
                  savedMetaData={metaDataSearch[props.fileNameSearch]}
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

export default MetaData
