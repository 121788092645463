import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { HealthCheckStyle } from '../common/styles'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import SearchIcon from '@material-ui/icons/Search'
import TableChartIcon from '@material-ui/icons/TableChart'
import LinearScaleIcon from '@material-ui/icons/LinearScale'
import EmailIcon from '@material-ui/icons/Email'
import DataUsageIcon from '@material-ui/icons/DataUsage'
import SyncIcon from '@material-ui/icons/Sync'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit'
import UpdateIcon from '@material-ui/icons/Update'
import HelpIcon from '@material-ui/icons/Help'
import StorageIcon from '@material-ui/icons/Storage'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import FormDialog from '../support/supportDialog'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { TabPanel } from '../common/commonFunctionalities'
import { IncidentList } from '../support/incidentslist'
// import Typography from '@material-ui/core/Typography'

export const HealthCheck = () => {
  const classes = HealthCheckStyle()

  const [rdmapiHealth, setRdmapiHealth] = useState('')
  const [datascienceHealth, setDatascienceHealth] = useState('')
  const [rdmlocationHealth, setRdmlocationHealth] = useState('')
  const [rdmocrHealth, setRdmocrHealth] = useState('')
  const [ldmtordmsyncHealth, setLdmtordmsyncHealth] = useState('')
  const [rdmworkflowHealth, setRdmworkflowHealth] = useState('')
  const [rdmreportingHealth, setRdmreportingHealth] = useState('')
  const [rdmemailHealth, setRdmemailHealth] = useState('')
  const [rdmcoreapiHealth, setRdmcoreapiHealth] = useState('')
  const [docSplitHealth, setDocSplitHealth] = useState('')
  const [cascadingDBUpdates, setCascadingDBUpdates] = useState('')
  const [serviceNowHealth, setServiceNowHealth] = useState('')

  const [tossHealth, setTossHealth] = useState('')
  const [mongoHealth, setMongoHealth] = useState('')
  const [ESHealth, setESHealth] = useState('')
  const [postgresHealth, setPostgresHealth] = useState('')
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(0)
  const [addIncident, setAddIncident] = useState(false)

  const authDetails = useSelector(state => state.common.authDetails)

  const history = useHistory()

  useEffect(() => {
    if (authDetails && Object.keys(authDetails).length > 0) {
      // eslint-disable-next-line no-prototype-builtins
      if (!authDetails.hasOwnProperty('access_feature_support')) {
        history.push('/unauthorised')
      }
    }
  }, [authDetails, history])

  const loadingValues = value => {
    if (typeof value === 'string') {
      setRdmcoreapiHealth(value)

      setRdmocrHealth(value)
      setDatascienceHealth(value)
      setRdmlocationHealth(value)
      setLdmtordmsyncHealth(value)
      setRdmworkflowHealth(value)
      setRdmreportingHealth(value)
      setRdmemailHealth(value)
      setRdmapiHealth(value)
      setDocSplitHealth(value)
      setCascadingDBUpdates(value)
      setServiceNowHealth(value)
      setTossHealth(value)
      setMongoHealth(value)
      setESHealth(value)
      setPostgresHealth(value)
    } else if (typeof value === 'object') {
      setRdmcoreapiHealth(value[0])
      setRdmocrHealth(value[1])
      setRdmworkflowHealth(value[2])
      setRdmreportingHealth(value[3])
      setRdmemailHealth(value[4])
      setDatascienceHealth(value[5])
      setRdmlocationHealth(value[6])
      setLdmtordmsyncHealth(value[7])
      setRdmapiHealth(value[8])
      setDocSplitHealth(value[9])
      setCascadingDBUpdates(value[10])
      setServiceNowHealth(value[11])
      setTossHealth(value[12])
      setMongoHealth(value[13])
      setESHealth(value[14])
      setPostgresHealth(value[15])
    }
  }

  const callAllApis = () => {
    loadingValues('loading')
    // const headers = {
    //   // 'Content-Type': 'text/plain',
    //   'Access-Control-Allow-Origin': '*',
    //   crossdomain: true,
    // }
    Promise.all(
      [
        axios.get(
          apiConfig.apiUrl.rdmcoreapiHealth + apiConfig.apiUrl.searchKey
        ),
        axios.get(apiConfig.apiUrl.rdmocrHealth + apiConfig.apiUrl.searchKey),
        axios.get(
          apiConfig.apiUrl.rdmworkflowHealth + apiConfig.apiUrl.searchKey
        ),
        axios.get(
          apiConfig.apiUrl.rdmreportingHealth + apiConfig.apiUrl.searchKey
        ),
        axios.get(apiConfig.apiUrl.rdmemailHealth),
        axios.get(
          apiConfig.apiUrl.datascienceHealth + apiConfig.apiUrl.searchKey
        ),
        axios.get(
          apiConfig.apiUrl.rdmlocationHealth + apiConfig.apiUrl.searchKey
        ),
        axios.get(
          apiConfig.apiUrl.ldmtordmsyncHealth + apiConfig.apiUrl.searchKey
        ),
        axios.get(apiConfig.apiUrl.rdmapiHealth + apiConfig.apiUrl.searchKey),
        axios.get(apiConfig.apiUrl.docSplitHealth + apiConfig.apiUrl.searchKey),

        axios.get(
          apiConfig.apiUrl.cascadingDBupdate + apiConfig.apiUrl.searchKey
        ),
        axios.get(
          apiConfig.apiUrl.serviceNowHealth + apiConfig.apiUrl.searchKey
        ),
        axios.get(apiConfig.apiUrl.storageHealth + apiConfig.apiUrl.searchKey),
      ].map(p => p.catch(e => e))
    )
      .then(responses => {
        loadingValues('success')

        return responses.map(response => {
          return response.data
        })
      })
      .then(data => {
        let modifiedData = data.map(item => {
          //this piece of code is there to handle storageHealth response
          if (typeof item === 'object') {
            let actuatorResponse = []
            actuatorResponse.push(
              item.details.tossHealthCheck.status === 'UP'
                ? 'success'
                : 'failure'
            )
            actuatorResponse.push(
              item.details.mongo.status === 'UP' ? 'success' : 'failure'
            )
            actuatorResponse.push(
              item.details.elasticsearchRest.status === 'UP'
                ? 'success'
                : 'failure'
            )
            actuatorResponse.push(
              item.details.db.status === 'UP' ? 'success' : 'failure'
            )
            return actuatorResponse
          }
          return typeof item === 'undefined' ? 'failure' : 'success'
        })
        return modifiedData
      })
      .then(modifiedData => {
        //this piece of code is there to handle storageHealth response
        let lastItem = modifiedData.pop()
        modifiedData = [...modifiedData, ...lastItem]

        loadingValues(modifiedData)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    callAllApis()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Health Dashboard" />
        <Tab label="Incidents" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div className={classes.cardBox}>
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <SearchIcon /> Search Services{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {rdmcoreapiHealth === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {rdmcoreapiHealth === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {rdmcoreapiHealth === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <LibraryBooksIcon /> OCR Services{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {rdmocrHealth === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {rdmocrHealth === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {rdmocrHealth === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <LinearScaleIcon />
                &nbsp; Workflow Services{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {rdmworkflowHealth === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {rdmworkflowHealth === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {rdmworkflowHealth === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <TableChartIcon /> Reporting Services{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {rdmreportingHealth === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {rdmreportingHealth === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {rdmreportingHealth === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
        </div>
        <br />
        <div className={classes.cardBox}>
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <EmailIcon /> Email Services{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {rdmemailHealth === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {rdmemailHealth === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {rdmemailHealth === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <DataUsageIcon /> Data Science Services{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {datascienceHealth === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {datascienceHealth === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {datascienceHealth === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <LocationOnIcon /> Location Services{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {rdmlocationHealth === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {rdmlocationHealth === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {rdmlocationHealth === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <SyncIcon /> Sync Services{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {ldmtordmsyncHealth === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {ldmtordmsyncHealth === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {ldmtordmsyncHealth === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
        </div>
        <br />
        <div className={classes.cardBox}>
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <VerifiedUserIcon /> Auth Services{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {rdmapiHealth === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {rdmapiHealth === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {rdmapiHealth === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <HorizontalSplitIcon /> Document Split Services{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {docSplitHealth === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {docSplitHealth === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {docSplitHealth === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <UpdateIcon /> Update Services{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {cascadingDBUpdates === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {cascadingDBUpdates === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {cascadingDBUpdates === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <HelpIcon /> Support Services{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {serviceNowHealth === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {serviceNowHealth === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {serviceNowHealth === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
        </div>
        <br />
        <div className={classes.cardBox}>
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <StorageIcon /> TOSS Health{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {tossHealth === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {tossHealth === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {tossHealth === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <StorageIcon /> Mongo DB Health{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {mongoHealth === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {mongoHealth === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {mongoHealth === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <StorageIcon /> Elastic Search Health{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {ESHealth === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {ESHealth === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {ESHealth === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.title} color="textSecondary">
                <StorageIcon /> Postgres Health{' '}
              </div>
            </CardContent>
            <CardActions className={classes.loader}>
              {postgresHealth === 'loading' && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              {postgresHealth === 'success' && (
                <CheckCircleIcon
                  fontSize="large"
                  className={classes.progress}
                />
              )}
              {postgresHealth === 'failure' && (
                <CancelIcon fontSize="large" className={classes.failure} />
              )}{' '}
            </CardActions>
          </Card>
          &nbsp;&nbsp;&nbsp;
        </div>
        <br />
        <br />
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpen(true)}
        >
          Report issue
        </Button>
        {/* &nbsp;&nbsp; */}
        {/* <HtmlTooltip
          title={
            <React.Fragment>
              Please use this option to report <b>technical issues</b> . For
              enhancements / new features , please contact Product owner.
            </React.Fragment>
          }
          placement="right"
        >
          <Button>
            <InfoIcon />
          </Button>
        </HtmlTooltip> */}
        <FormDialog
          open={open}
          setOpen={setOpen}
          setValue={setValue}
          addIncident={addIncident}
          setAddIncident={setAddIncident}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <IncidentList flag={addIncident} setFlag={setAddIncident} />
      </TabPanel>
    </div>
  )
}
