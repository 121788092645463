import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

/**
 * This component requires Dialog title, dialog context, first button context, second button context and returns which button is clicked
 * @param {props} props
 */

const ConsoleWindow = props => {
  const [consoleWindowProps, setConsoleWindowProps] = props.cwProp
  const [open, setOpen] = useState(true)

  const handleEvent = (e, action) => {
    e.preventDefault()
    setConsoleWindowProps({ ...consoleWindowProps, buttonClicked: action })
    setOpen(false)
  }

  return (
    <div className="metadata-submit">
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {consoleWindowProps.dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {consoleWindowProps.dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={e => handleEvent(e, consoleWindowProps.dialogFirstButton)}
            color="primary"
          >
            {consoleWindowProps.dialogFirstButton}
          </Button>
          <Button
            onClick={e => handleEvent(e, consoleWindowProps.dialogSecondButton)}
            color="primary"
          >
            {consoleWindowProps.dialogSecondButton}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConsoleWindow
