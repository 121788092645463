import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import 'react-datepicker/dist/react-datepicker.css'
import { camPayableAndRecReturnedRecordsColumns } from './reportingTableMapping'
import { camReturnedRebillRecordsColumns } from './reportingTableMapping'
import moment from 'moment'
import { tableIcons } from '../common/commonFunctionalities'
import MaterialTable from 'material-table'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}))

export const CamPayableAndRecReturnedRecords = ({
  start,
  end,
  camPayableAndRecReturnedRecords,
  camRebillReturnedRecords,
  camPayableUnexpectedReturnedRecords,
  isLoading,
  getCamPayableAndRecReturnedRecords,
}) => {
  const classes = useStyles()

  useEffect(() => {
    getCamPayableAndRecReturnedRecords()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.root}>
      <MaterialTable
        title={
          // eslint-disable-next-line no-useless-concat
          'PA CAM - Payable Budget and Rec Quality Report:' +
          ' ' +
          moment(start).format('MM/DD/YYYY') +
          '  -  ' +
          moment(end).format('MM/DD/YYYY')
        }
        options={{
          showTitle: true,
          search: false,
          toolbar: true,
          filtering: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'PACAMPayableAndRecQualityReport',
          pageSizeOptions: [10, 25, 50, 100],
          loadingType: 'overlay',
          pageSize: 100,
          // paging: false,
          headerStyle: {
            position: 'sticky',
            top: 0,
            backgroundColor: 'rgb(231, 231, 231)',
            height: '50px',
          },
          // maxBodyHeight: '65vh',
          emptyRowsWhenPaging: false,
        }}
        columns={camPayableAndRecReturnedRecordsColumns}
        data={camPayableAndRecReturnedRecords}
        icons={tableIcons}
        isLoading={isLoading}
        localization={{
          body: {
            emptyDataSourceMessage: '',
          },
        }}
      />
      <MaterialTable
        title={
          // eslint-disable-next-line no-useless-concat
          'PA CAM - Payable Unexpected Quality Report:' +
          ' ' +
          moment(start).format('MM/DD/YYYY') +
          '  -  ' +
          moment(end).format('MM/DD/YYYY')
        }
        options={{
          showTitle: true,
          search: false,
          toolbar: true,
          filtering: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'PACAMPayableUnexpectedQualityReport',
          pageSizeOptions: [10, 25, 50, 100],
          loadingType: 'overlay',
          pageSize: 100,
          // paging: false,
          headerStyle: {
            position: 'sticky',
            top: 0,
            backgroundColor: 'rgb(231, 231, 231)',
            height: '50px',
          },
          // maxBodyHeight: '65vh',
          emptyRowsWhenPaging: false,
        }}
        columns={camReturnedRebillRecordsColumns}
        data={camPayableUnexpectedReturnedRecords}
        icons={tableIcons}
        isLoading={isLoading}
        localization={{
          body: {
            emptyDataSourceMessage: '',
          },
        }}
      />
      <MaterialTable
        title={
          // eslint-disable-next-line no-useless-concat
          'PA CAM - Rebill Quality Report:' +
          ' ' +
          moment(start).format('MM/DD/YYYY') +
          '  -  ' +
          moment(end).format('MM/DD/YYYY')
        }
        options={{
          showTitle: true,
          search: false,
          toolbar: true,
          filtering: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'PACAMRebillQualityReport',
          pageSizeOptions: [10, 25, 50, 100],
          loadingType: 'overlay',
          pageSize: 100,
          // paging: false,
          headerStyle: {
            position: 'sticky',
            top: 0,
            backgroundColor: 'rgb(231, 231, 231)',
            height: '50px',
          },
          // maxBodyHeight: '65vh',
          emptyRowsWhenPaging: false,
        }}
        columns={camReturnedRebillRecordsColumns}
        data={camRebillReturnedRecords}
        icons={tableIcons}
        isLoading={isLoading}
        localization={{
          body: {
            emptyDataSourceMessage: '',
          },
        }}
      />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  )
}
