import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useToasts } from 'react-toast-notifications'
import './AddLocationDataStyle.css'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import { validateDate } from '../common/validation'
import { useAuth } from '@praxis/component-auth'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)
const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))
export default function FormDialog({ locationNum, flag }) {
  const auth = useAuth()
  const { session } = auth
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const { addToast } = useToasts()
  const [obj, setObj] = useState({
    fileLetter: '',
    fileLetterDesc: '',
    fileType: '',
    tenantName: '',
    locationSuite: '',
    soldTo: '',
    acreage: '',
    expirationDate: '',
  })
  const [expLeaseDateError, setExpLeaseDateError] = useState(false)
  const addFileLetterSubmit = event => {
    let payload = {}
    payload['file_letter'] = obj.fileLetter
    payload['file_letter_desc'] = obj.fileLetterDesc
    payload['file_type'] = obj.fileType
    payload['tenant_name'] = obj.tenantName
    payload['location_suite'] = obj.locationSuite
    payload['sold_to'] = obj.soldTo
    payload['acreage'] = obj.acreage
    payload['expiration_date'] = obj.expirationDate
    payload['created_by'] = session.userInfo.fullName

    let url = apiConfig.apiUrl.addfileletter + `/${locationNum}`
    axios
      .post(url, payload)
      .then(res => {
        if (res.status === 200) {
          setObj('')
          handleClose()
          flag(true)
        }
        addToast('fileletter added successfully', {
          appearance: 'success',
          autoDismiss: true,
        })
      })
      .catch(err => {
        if (err.response.status === 400) {
          addToast(
            'Error - Requested FileLetter is already present for this location',
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
          handleClose()
        } else {
          console.log(err)
          addToast('Error!Issue in adding File Letter.', {
            appearance: 'error',
            autoDismiss: true,
          })
        }
        handleClose()
      })
  }
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setObj('')
  }
  const handleLocationSubmit = e => {
    e.preventDefault()
    addFileLetterSubmit()
  }
  return (
    <div style={{ textAlign: 'right' }}>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add File Letter & Description
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick="true"
        aria-labelledby="form-dialog-title"
      >
        <form validate autoComplete="off" onSubmit={handleLocationSubmit}>
          <DialogTitle id="form-dialog-title">
            Add File Letter and File Letter Description
          </DialogTitle>
          <DialogContent>
            <div className="metadata-container">
              <div className="metadata-textfield">
                <TextField
                  required
                  disabled
                  id="outlined-search"
                  label={'Location Number'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={locationNum}
                />
                <br />
                <TextField
                  required
                  id="outlined-search"
                  label={'File Letter'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.fileLetter}
                  onChange={e => setObj({ ...obj, fileLetter: e.target.value })}
                />{' '}
                <br />
                <TextField
                  required
                  id="outlined-search"
                  label={'File Letter Description'}
                  type={String}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.fileLetterDesc}
                  onChange={e =>
                    setObj({ ...obj, fileLetterDesc: e.target.value })
                  }
                />
                <br /> <br />
                <FormControl className={classes.selects}>
                  <InputLabel shrink htmlFor="filetype">
                    <b>File Type*</b>
                  </InputLabel>
                  <NativeSelect
                    id="filetype"
                    value={obj.fileType}
                    onChange={e =>
                      setObj({
                        ...obj,
                        fileType: e.target.value,
                      })
                    }
                    input={<BootstrapInput />}
                    required
                  >
                    <option value={''}></option>
                    <option value={'Tenant'}>Tenant</option>
                    <option value={'Excess Land'}>Excess Land</option>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />
                {/* <TextField
                  id="outlined-search"
                  label={'Total Files'}
                  type="number"
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  value={obj.total_files}
                  onChange={e =>
                    setObj({ ...obj, total_files: e.target.value })
                  }
                /> */}
                {obj.fileType === 'Tenant'
                  ? ((obj.soldTo = ''),
                    (obj.acreage = ''),
                    (
                      <div>
                        <TextField
                          id="outlined-search"
                          label={'Tenant Name'}
                          type={String}
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
                          value={obj.tenantName}
                          onChange={e =>
                            setObj({ ...obj, tenantName: e.target.value })
                          }
                        />
                        <br />
                        <TextField
                          id="outlined-search"
                          label={'Location Suite'}
                          type={String}
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
                          value={obj.locationSuite}
                          onChange={e =>
                            setObj({ ...obj, locationSuite: e.target.value })
                          }
                        />{' '}
                        <br />
                        <FormControl>
                          <TextField
                            id="fileletter-leaseexpirationdate"
                            label={'Lease Expiration Date'}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type={String}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={obj.expirationDate || undefined}
                            onChange={event => {
                              let valid = validateDate(event.target.value)
                              setExpLeaseDateError(valid)

                              setObj({
                                ...obj,
                                expirationDate: event.target.value,
                              })
                            }}
                            placeholder="mm/dd/yyyy"
                            error={expLeaseDateError}
                            helperText={
                              expLeaseDateError
                                ? 'Enter in correct format.For e.g. 09/23/2020'
                                : ''
                            }
                          />
                        </FormControl>
                      </div>
                    ))
                  : obj.fileType === 'Excess Land'
                  ? ((obj.tenantName = ''),
                    (obj.locationSuite = ''),
                    (obj.expirationDate = ''),
                    (
                      <div>
                        <TextField
                          id="outlined-search"
                          label={'Sold To'}
                          type={String}
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
                          value={obj.soldTo}
                          onChange={e =>
                            setObj({ ...obj, soldTo: e.target.value })
                          }
                        />
                        <br />
                        <TextField
                          id="outlined-search"
                          label={'How Much Acreage?'}
                          type={String}
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
                          value={obj.acreage}
                          onChange={e =>
                            setObj({ ...obj, acreage: e.target.value })
                          }
                        />{' '}
                        <br />
                      </div>
                    ))
                  : ''}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
